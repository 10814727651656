import { TaskGridSecondaryFilterModel } from "../../models/taskGridSecondaryFilterModel";

export const fillTaskExtraParams = (
    {
        responsable,
        detail,
        isRecurrent,
        frequency,
        fromHour,
        toHour,
        isPhotoRequired,
        isDataRequired,
        isCritical,
        workingPositionDepartments,
        baseTask,
        taskType,
        classifications,
    }: TaskGridSecondaryFilterModel,
    planId: string | null,
    assignedTo?: string,
    initialRender?: boolean,
    companyId?: string
): string => {
    const extraParams = new URLSearchParams();
    extraParams.append("IsRecurrent", String(isRecurrent));

    if (companyId !== "-1") extraParams.append("CompanyId", String(companyId));

    if (assignedTo === "workingPosition") {
        extraParams.append("IsAssignedToQR", "true");
        extraParams.append("AssignedTo", "WORKING_POSITION");
        extraParams.append("workingPositionId", responsable.value);

        if (workingPositionDepartments.value !== "-1")
            extraParams.append("DepartmentId", workingPositionDepartments.value);
    } else extraParams.append("AssignedTo", String(assignedTo));

    if (responsable.assignedTo === "user" && responsable.value.length)
        extraParams.append("UserId", String(responsable.value));
    if (responsable.assignedTo === "department" && responsable.value.length)
        extraParams.append("DepartmentId", String(responsable.value));

    if (responsable.assignedTo === "subDepartment" && responsable.value.length)
        extraParams.append("SubDepartmentId", String(responsable.value));
    if (detail.value.length) extraParams.append("Detail", String(detail.text));
    if (frequency) extraParams.append("Frequency", String(frequency));
    if (fromHour) extraParams.append("FromHour", fromHour);
    if (toHour) extraParams.append("ToHour", toHour);
    if (isPhotoRequired) extraParams.append("IsPhotoRequired", "true");
    if (isDataRequired) extraParams.append("IsDataRequired", "true");
    if (isCritical) extraParams.append("IsCritical", "true");
    if (initialRender) extraParams.append("InitialRender", "true");
    if (taskType && taskType !== "-1") extraParams.append("taskType", taskType);
    if (!!baseTask.length && baseTask !== "-1") extraParams.append("FK_BaseTask", String(baseTask));
    if (!!planId && !!planId.length) extraParams.append("FK_PlanAPPCC", String(planId));
    if (!!classifications.length)
        extraParams.append("BaseTaskTypesId", classifications.map(({ value }) => value).join(";"));
    return extraParams.toString();
};
