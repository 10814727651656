import { TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { TaskTemporalityDateRangeFormModel, TaskTemporalityDateRangeValue } from "app/models/02-TAR/TaskWizard";
import { days } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/utilities/initialValues";
import { TaskTemporalityDateRangeTranslations } from "app/translation/translationKeys";
import { t } from "i18next";

type IUseTaskTemporalityDateRangeRepeatEvery = {
    taskTemporalityDateRange: TaskTemporalityDateRangeFormModel;
    onChange: (value: Partial<TaskTemporalityModel>) => void;
};

export const useTaskTemporalityDateRangeRepeatEvery = ({
    taskTemporalityDateRange,
    onChange,
}: IUseTaskTemporalityDateRangeRepeatEvery) => {
    const { dbId, repeatEvery } = taskTemporalityDateRange;
    const handleChangeTemporality = (value: TaskTemporalityDateRangeValue) => {
        if (value === "WEEKLY") {
            handleSelectWeekly();
            return;
        }

        if (value === "MONTHLY") {
            handleSelectMonthly();
            return;
        }

        if (value === "CUSTOM") {
            handleSelectCustom();
            return;
        }
    };
    const handleSelectWeekly = () => {
        onChange({
            taskTemporalityDateRange: {
                dbId,
                custom: {
                    dateRange: taskTemporalityDateRange.custom.dateRange
                        .filter(({ dbId }) => dbId != null)
                        .map((ttdr) => ({ ...ttdr, isDeleted: true })),
                },
                monthly: {
                    day: "",
                    customDays: { startDay: "", endDay: "" },
                    value: "FIRST_X_DAYS",
                    text: "",
                },
                repeatEvery: repeatEvery || "1",
                text: t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_WEEKLY_LABEL),
                value: "WEEKLY",
                weeklyDays: days,
            },
        });
    };

    const handleSelectMonthly = () => {
        onChange({
            taskTemporalityDateRange: {
                dbId,
                custom: {
                    dateRange: taskTemporalityDateRange.custom.dateRange
                        .filter(({ dbId }) => dbId != null)
                        .map((ttdr) => ({ ...ttdr, isDeleted: true })),
                },
                monthly: {
                    day: "1",
                    customDays: { startDay: "", endDay: "" },
                    value: "FIRST_X_DAYS",
                    text: "",
                },
                repeatEvery: repeatEvery || "1",
                text: t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTHLY_LABEL),
                value: "MONTHLY",
                weeklyDays: days,
            },
        });
    };

    const handleSelectCustom = () => {
        onChange({
            taskTemporalityDateRange: {
                dbId,
                custom: {
                    dateRange: taskTemporalityDateRange.custom.dateRange
                        .filter(({ dbId }) => dbId != null)
                        .map((ttdr) => ({ ...ttdr, isDeleted: true })),
                },
                monthly: {
                    day: "",
                    customDays: { startDay: "", endDay: "" },
                    value: "FIRST_X_DAYS",
                    text: "",
                },
                repeatEvery: "",
                text: t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_CUSTOM_LABEL),
                value: "CUSTOM",
                weeklyDays: days,
            },
        });
    };

    return { onChangeTemporality: handleChangeTemporality };
};
