import { FC } from "react";
import { RowPositionModel } from "app/components_v2/Table/types";
import { MobileIssueRowBody } from "./MobileIssueRowBody/MobileIssueRowBody";
import { MobileRowHeader } from "./MobileRowHeader/MobileRowHeader";
import { IssueListDto } from "app/dtos/05-QUA/Issue/IssueListDto";

type MobileIssueRowProps = {
    data: IssueListDto;
    rowPosition: RowPositionModel;
    onClick?: () => void;
};

export const MobileIssueRow: FC<MobileIssueRowProps> = ({ data, rowPosition, onClick }) => {
    const {
        id,
        description,
        openDate,
        actualStatus,
        openedByUserId,
        openedByUserName,
        openedByProfilePictureUrl,
        auditCode,
    } = data;
    return (
        <div className={`issueMobileRow--${rowPosition}`}>
            <MobileRowHeader
                actualStatus={actualStatus}
                colorId={openedByUserId}
                name={openedByUserName}
                openDate={openDate}
                profilePictureUrl={openedByProfilePictureUrl || ""}
                onClick={onClick}
            />
            <MobileIssueRowBody description={description} id={String(id)} auditCode={auditCode} />
        </div>
    );
};
