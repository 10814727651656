import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCamera,
    faComment,
    faTriangleExclamation,
    faClipboardList,
    faQrcode,
} from "@fortawesome/pro-solid-svg-icons";

export type TaskPillIconsProps = {
    hasComents?: boolean;
    hasPhoto?: boolean;
    hasFields?: boolean;
    isCritical?: boolean;
    asignedToWorkingPositionId?: number | null;
};

export const TaskPillIcons: FC<TaskPillIconsProps> = ({
    hasComents,
    hasFields,
    hasPhoto,
    isCritical,
    asignedToWorkingPositionId,
}) => {
    return (
        <div className="taskPill__section__right__icons">
            {hasComents && <FontAwesomeIcon icon={faComment} />}
            {hasPhoto && <FontAwesomeIcon icon={faCamera} />}
            {hasFields && <FontAwesomeIcon icon={faClipboardList} />}
            {isCritical && <FontAwesomeIcon icon={faTriangleExclamation} />}
            {!!asignedToWorkingPositionId && <FontAwesomeIcon icon={faQrcode} />}
        </div>
    );
};
