import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import MeasurementUnitService from "app/services/05-QUA/MeasurementUnitService";
import { MeasurementUnitModel } from "app/models/05-QUA/MeasurementUnitModels/MeasurementUnitModel";
import { OptionsSearch } from "app/models/FormComponentsModel";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import { useSession } from "app/hooks";
import { ASSET_DYNAMIC_FIELD_LABEL } from "../../../constants/assetConstants";

export const useAssetDynamicFields = () => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const session = useSession();
    const language = session?.user.language;

    const [assetDynamicFieldOptions, setAssetDynamicFieldOptions] = useState<OptionsSearch[]>([]);
    const [isAssetDynamicFieldLoading, setIsAssetDynamicFieldLoading] = useState<boolean>(true);
    const [measurementTypeOptions, setMeasurementTypeOptions] = useState<MeasurementUnitModel[]>([]);

    const getAssetDynamicField = async () => {
        setIsAssetDynamicFieldLoading(true);
        const { data, status, getParsedError } = await QuaSelectorService.GetAssetDynamicFieldList();
        if (!status()) {
            setAssetDynamicFieldOptions([]);
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsAssetDynamicFieldLoading(false);
            return;
        }
        setAssetDynamicFieldOptions(
            data.map(({ label, value }) => ({
                value,
                label: translateLabel(label),
            }))
        );
        setIsAssetDynamicFieldLoading(false);
    };

    const getMeasurementUnits = async () => {
        const { data, status, getParsedError } = await MeasurementUnitService.GetList();
        const error = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), error)) return;
        setMeasurementTypeOptions(data.list);
    };

    const translateLabel = (label: string): string => {
        return t(ASSET_DYNAMIC_FIELD_LABEL[label] || label);
    };

    useEffect(() => {
        getMeasurementUnits();
    }, []);

    useEffect(() => {
        getAssetDynamicField();
    }, [language]);

    return {
        assetDynamicFieldOptions,
        isAssetDynamicFieldLoading,
        measurementTypeOptions,
        setMeasurementTypeOptions,
    };
};
