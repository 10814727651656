import { TaskHoursModel } from "app/models/02-TAR/TaskWizard";

export const isHoursEquals = (hours: TaskHoursModel[]) => {
    for (let i = 0; i < hours.length; i++) {
        for (let x = 0; x < hours.length; x++) {
            if (i === x) continue;
            if (
                new Date(hours[i].hour).getMinutes() === new Date(hours[x].hour).getMinutes() &&
                new Date(hours[i].hour).getHours() === new Date(hours[x].hour).getHours()
            )
                return true;
        }
    }

    return false;
};
