import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useGetDynamicFieldsTranslations } from "app/hooks/dynamicFields/useGetDynamicFieldsTranslations";
import { WizardTaskStep3 } from "app/models/02-TAR/TaskWizard";

export const StepThreeInfo: FC<WizardTaskStep3> = ({
    criticalTask,
    somethingToReportCheckBoxImage,
    somethingToReportImage,
    reportData,
    reportType,
    checkList,
    dynamicFields,
}) => {
    const { t } = useTranslation();
    const { getDynamicFieldTranslation } = useGetDynamicFieldsTranslations();

    return (
        <>
            {(reportType === "ASSET" || reportType === "DATA") && (
                <div>
                    <span className="stepFiveLabel">{t(TranslationKeys.DATA_REPORT_TITLE)}</span>
                    <div className="stepFivePlaceHolderInput">
                        <ul>
                            {reportData.map(({ value, id }) => (
                                <li key={id}>{value}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            {reportType === "CHECKLIST" && (
                <div>
                    <span className="stepFiveLabel">{t(TranslationKeys.CHECKLIST_TITLE)}</span>
                    <div className="stepFivePlaceHolderInput">
                        <ul>
                            {checkList.map(({ name, id }) => (
                                <li key={id}>{name}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            {reportType === "DYNAMIC_FIELD" && (
                <div>
                    <span className="stepFiveLabel">{t(TranslationKeys.DYNAMIC_FIELDS_TITLE)}</span>
                    <div className="stepFivePlaceHolderInput">
                        <ul>
                            {dynamicFields.map(({ dynamicFieldsType, label, id }) => (
                                <li key={id}>
                                    {getDynamicFieldTranslation(dynamicFieldsType)} - {label}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            {somethingToReportCheckBoxImage && (
                <div>
                    <span className="stepFiveLabel">{t(TranslationKeys.IMAGE_FOR_REPORT)}</span>
                    <div className="stepFivePlaceHolderInput">
                        <p>{t(TranslationCommon.YES)}</p>

                        {typeof somethingToReportImage === "object" && <p>{somethingToReportImage.name}</p>}
                    </div>
                </div>
            )}
            <div>
                <span className="stepFiveLabel">{t(TranslationKeys.IS_A_CRITICAL_TASK)}</span>
                <div className="stepFivePlaceHolderInput">
                    {criticalTask ? t([TranslationCommon.YES]) : t([TranslationCommon.NO])}
                </div>
            </div>
        </>
    );
};
