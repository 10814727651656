import { FC, useEffect, useState } from "react";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import {
    IssueTranslation,
    NotificationDistributionListTranslations,
    TranslationCommon,
} from "app/translation/translationKeys";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { SelectOptions } from "app/components_v2/__inputs";
import { NotificationDistributionListGridSF } from "app/pages/01-SEG/NotificationDistributionList/NotificationDistributionListGrid/models/NotificationDistributionListGridModels";
import { OptionsSearch } from "app/models/FormComponentsModel";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

type NotificationDistributionListFilterProps = {
    filterData: NotificationDistributionListGridSF;
    initialFilterData: NotificationDistributionListGridSF;
    isSecondaryFilterOpen: boolean;
    onCancel?: () => void;
    onSave?: (values: NotificationDistributionListGridSF) => void;
    isOpen: boolean;
};

export const NotificationDistributionListFilter: FC<NotificationDistributionListFilterProps> = ({
    filterData,
    initialFilterData,
    isSecondaryFilterOpen,
    onCancel,
    onSave,
    isOpen,
}) => {
    const { t } = useTranslation();

    const [internalFilterData, setInternalFilterData] = useState<NotificationDistributionListGridSF>(filterData);
    const [distributionListOptions, setDistributionListOptions] = useState<OptionsSearch[]>([]);

    const getDistributionListOptions = async () => {
        const { data, status } = await SegSelectorService.GetDistributionListTypes();

        if (!status()) {
            setDistributionListOptions([]);
            return;
        }

        setDistributionListOptions([
            { label: t(NotificationDistributionListTranslations.ALL_DISTRIBUTION_LISTS), value: "-1" },
            ...data.map((d) => ({ ...d, label: t(d.label) })),
        ]);
    };

    const handleInputChange = (values: Partial<NotificationDistributionListGridSF>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    const handleClickOutside = () => {
        if (!isOpen) return;
        handleInputChange(filterData);
        onCancel && onCancel();
    };

    const handleReset = () => handleInputChange(initialFilterData);

    const handleSave = () => onSave && onSave(internalFilterData);

    useEffect(() => {
        if (!isSecondaryFilterOpen) return;
        handleInputChange(filterData);
    }, [isSecondaryFilterOpen, filterData]);

    useEffect(() => {
        if (!isSecondaryFilterOpen) return;
        getDistributionListOptions();
    }, [isSecondaryFilterOpen]);

    return (
        <SecondaryFilter
            isOpen={isSecondaryFilterOpen}
            onClickOutside={handleClickOutside}
            onCancel={handleClickOutside}
            onReset={handleReset}
            onSave={handleSave}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
        >
            <SelectOptions
                isMulti={false}
                onChange={({ value }) => handleInputChange({ functionality: value })}
                options={distributionListOptions}
                label={t(IssueTranslation.ISSUE_CLASSIFICATION)}
                selectedValue={internalFilterData.functionality}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
            />
        </SecondaryFilter>
    );
};
