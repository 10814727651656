import { FC } from "react";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { TranslationCommon } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { TaskFields } from "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskFields/TaskFields";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { TaskStatus } from "app/shared/Constants";

type PreviewAssetsModalProps = {
    assets: TaskInstanceFieldModel[];
    onClose: () => void;
};

export const PreviewAssetsModal: FC<PreviewAssetsModalProps> = ({ onClose, assets }) => {
    const { t } = useTranslation();
    return (
        <GenericModal
            size="md-tall"
            footer={
                <GenericModalFooter
                    closeButton={{
                        text: t(TranslationCommon.CLOSE),
                        onClick: onClose,
                    }}
                />
            }
        >
            <TaskFields
                fields={assets}
                customSetFields={() => {}}
                readOnly
                statusCode={TaskStatus.PENDING}
                taskinstanceId={1}
            />
        </GenericModal>
    );
};
