import { FC } from "react";
import { OptionsOutsideSelect } from "../__inputs/SelectOptions/OptionsOutsideSelect";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { ReportDataList } from "app/models/02-TAR/TaskWizard";

export type SelectWithDisplayItems = {
    id: number;
    label: string;
};

type SelectWithDisplayProps = {
    isLoading: boolean;
    isErrored?: boolean;
    customError?: string;
    options: OptionsSearch[];
    items: SelectWithDisplayItems[];
    onChange: (assetId: OptionsSearch) => void;
    onClick: (id: number) => void;
    value: string;
    placeholder?: string;
    reportData: ReportDataList[];
    disabled?: boolean;
};

export const SelectWithDisplay: FC<SelectWithDisplayProps> = ({
    customError,
    options,
    items,
    onClick,
    onChange,
    disabled,
    placeholder,
}) => {
    return (
        <OptionsOutsideSelect
            onChange={(values) => onChange(values[values.length - 1])}
            onDeleteItem={(id) => onClick(Number(id))}
            options={options}
            values={items.map(({ id, label }) => ({ label, value: String(id) }))}
            errorMessage={customError}
            disabled={disabled}
            placeholder={placeholder}
            hidden={disabled}
            isMulti
        />
    );
};
