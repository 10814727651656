import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TaskTranslations, TranslationCommon } from "app/translation/translationKeys";
import {
    CustomErrorTaskTemporalityModel,
    EditDataModel,
    EditTaskDisabledTemporality,
    TaskTemporalityModel,
} from "app/models/02-TAR/Task/EditTask";
import { SelectOptions } from "app/components_v2/__inputs";
import { WhiteBoxCollapsable } from "app/components_v2/WhiteBox/WhiteBoxCollapsable/WhiteBoxCollapsable";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { TaskTemporalityPeriodical } from "app/components_v2/WizardTask/TaskTemporalityPeriodical/TaskTemporalityPeriodical";
import { TaskTemporalityOneDay } from "app/components_v2/WizardTask/TaskTemporalityOneDay/TaskTemporalityOneDay";
import { TaskTemporalitySporadic } from "app/components_v2/WizardTask/TaskTemporalitySporadic/TaskTemporalitySporadic";
import { TaskTemporalityDateRange } from "app/components_v2/WizardTask/TaskTemporalityDateRange/TaskTemporalityDateRange";
import { TaskTemporalityDisableds } from "app/components_v2/TaskTemporalityDisableds/TaskTemporalityDisableds";
import { useTaskTemporality } from "./hooks/useTaskTemporality";

type TaskTemporalityProps = EditDataModel<
    TaskTemporalityModel,
    CustomErrorTaskTemporalityModel,
    EditTaskDisabledTemporality
> & {
    min: Date;
    temporaryTypeOptions: OptionModel[];
};

export const TaskTemporality: FC<TaskTemporalityProps> = ({
    onChange,
    taskStart,
    taskHours,
    recurringTask,
    finish,
    customError,
    isDisabled,
    min,
    variant = "primary",
    temporalityType,
    temporaryTypeOptions,
    taskType,
    disabledFields,
    taskTemporalityDateRange,
}) => {
    const { t } = useTranslation();
    const { onChangeTaskTemporality } = useTaskTemporality({
        onChange,
        recurringTask,
        taskTemporalityDateRange,
        taskType,
        temporalityType,
    });

    if (disabledFields && disabledFields.disabledType !== "NONE") {
        return (
            <div className="taskTemporality">
                <WhiteBoxCollapsable
                    whiteBoxOptions={{ fullWidth: true }}
                    collapsableOptions={{ title: t(TaskTranslations.TASK_DATE_TITLE), border: "none", variant }}
                >
                    <TaskTemporalityDisableds
                        customError={customError}
                        onChange={onChange}
                        taskTemporality={{
                            finish,
                            recurringTask,
                            taskHours,
                            taskStart,
                            taskType,
                            temporalityType,
                            taskTemporalityDateRange,
                        }}
                        isDisabled={isDisabled}
                        min={min}
                    />
                </WhiteBoxCollapsable>
            </div>
        );
    }

    return (
        <div className="taskTemporality">
            <WhiteBoxCollapsable
                whiteBoxOptions={{ fullWidth: true }}
                collapsableOptions={{ title: t(TaskTranslations.TASK_DATE_TITLE), border: "none", variant }}
            >
                <div className="taskTemporality__container">
                    <SelectOptions
                        isMulti={false}
                        options={temporaryTypeOptions}
                        onChange={onChangeTaskTemporality}
                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                        selectedValue={temporalityType}
                        errorMessage={customError.temporalityType}
                    />
                    {temporalityType === "PERIODICAL" && (
                        <TaskTemporalityPeriodical
                            customError={customError}
                            finish={finish}
                            onChange={onChange}
                            recurringTask={recurringTask}
                            taskHours={taskHours}
                            taskStart={taskStart}
                            isDisabled={isDisabled}
                            min={min}
                        />
                    )}
                    {temporalityType === "ONE_DAY" && (
                        <TaskTemporalityOneDay
                            customError={customError}
                            onChange={onChange}
                            taskHours={taskHours}
                            taskStart={taskStart}
                            isDisabled={isDisabled}
                            min={min}
                        />
                    )}
                    {temporalityType === "SPORADIC" && (
                        <TaskTemporalitySporadic
                            customError={customError}
                            onChange={onChange}
                            taskStart={taskStart}
                            isDisabled={isDisabled}
                            min={min}
                        />
                    )}
                    {temporalityType === "RANGE_DATES" && (
                        <TaskTemporalityDateRange
                            customError={customError}
                            onChange={onChange}
                            isDisabled={isDisabled}
                            taskTemporalityDateRange={taskTemporalityDateRange}
                            taskStart={taskStart}
                            min={min}
                        />
                    )}
                </div>
            </WhiteBoxCollapsable>
        </div>
    );
};
