import { useGoBack, useSession, useTitle } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import { useGetDateFromURLParams } from "../../hooks/useGetDateFromURLParams";
import { useContext, useEffect, useState } from "react";
import { ReviewTaskContext } from "../../state/context/reviewTaskContext";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { ReviewWorkerDto } from "app/dtos/02-TAR/ReviewWorker/ReviewWorkerDto";
import { WorkerProfileCountersDto } from "app/dtos/02-TAR/ReviewWorker/WorkerProfileCountersDto";
import { TabPropsReduced } from "app/components_v2/Tabs";
import { DateRange } from "app/models/utilities";
import { ReviewWorkerService } from "app/services";
import { getDateFormattedSelects } from "app/helpers";
import { ReviewWorkerListModel } from "../../models";
import { PaginationDefaults } from "app/shared/Constants";
import { fillWorkerProfileCountersExtraParams } from "app/helpers/workerProfileExtraParams";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

const actualPageRecord: Record<number, ReviewWorkerListModel> = {
    0: "NotReviewed",
    1: "Reviewed",
    2: "Pending",
};

type IGetTasks = {
    pi?: number;
    date1?: Date;
    date2?: Date;
    tab?: number;
};

type IGetCounters = {
    date1?: Date;
    date2?: Date;
};

export const useReviewWorker = () => {
    const session = useSession();
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const { realParentGoBack } = useGoBack();
    const { userId } = useGetDateFromURLParams();
    const { dates, onDateChange } = useContext(ReviewTaskContext);
    const { endDate: endDateProps, startDate: startDateProps } = dates;

    useTitle(t(TranslationTitles.REVIEW_WORKER_PAGE_TITLE));

    const [firstDate, setFirstDate] = useState<Date | undefined>(startDateProps || new Date());
    const [secondDate, setSecondDate] = useState<Date | undefined>(endDateProps || new Date());
    const [currentTab, setCurrentTab] = useState(userId === session?.user.id ? 1 : 0);
    const [user, setUser] = useState<ReviewWorkerDto>();
    const [userLoading, setUserLoading] = useState<boolean>(true);
    const [taskInstanceList, setTaskInstanceList] = useState<TaskInstanceListDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [total, setTotal] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState(1);

    const [counters, setCounters] = useState<WorkerProfileCountersDto>({
        notReviewed: 0,
        evaluated: 0,
        pending: 0,
    });

    const goBack = () => realParentGoBack();

    const onChangeTab = (tabIndex: number) => {
        if (tabIndex === currentTab) return;
        setCurrentTab(tabIndex);
        setPageIndex(1);
        getTasks({ pi: 0, tab: tabIndex });
        getWorkerProfileCounters();
    };

    const tabs: TabPropsReduced[] = [
        {
            text: t(TranslationKeys.NOT_REVIEWED),
            rightCount: counters.notReviewed,
            onClick: onChangeTab,
            type: "dark",
            hidden: Number(userId) === session?.user.id,
        },
        {
            text: t(TranslationKeys.EVALUATED),
            rightCount: counters.evaluated,
            onClick: onChangeTab,
            type: "dark",
        },
        {
            text: t(TranslationKeys.PENDING),
            rightCount: counters.pending,
            onClick: onChangeTab,
            type: "dark",
        },
    ];

    const handleDateChange = (dates: DateRange) => {
        const [start, end] = dates;
        if (start) {
            setFirstDate(start);
            onDateChange({ startDate: start, endDate: undefined });
            setSecondDate(undefined);
        }
        if (!end) return;
        setSecondDate(end);
        onDateChange({ endDate: end });
        setPageIndex(1);
        getTasks({ pi: 0, date1: start, date2: end });
        getWorkerProfileCounters({ date1: start, date2: end });
    };

    const onChangePageIndex = (pi: number) => {
        setPageIndex(pi);
        getTasks({ pi: pi - 1 });
        getWorkerProfileCounters();
    };

    const fetchUserData = async () => {
        setUserLoading(true);

        const { data, status } = await ReviewWorkerService.GetOne(userId, {
            extraParams: `DeadlineDate=${getDateFormattedSelects(firstDate)}&DeadlineDate1=${getDateFormattedSelects(
                secondDate
            )}&CompanyId=${session?.workingCompany?.companyId}`,
        });
        if (!status()) {
            setUserLoading(false);
            goBack();
            return;
        }
        setUserLoading(false);
        setUser(data);
    };

    const getTasks = async ({ pi, date1, date2, tab }: IGetTasks = {}) => {
        const companyId = session?.workingCompany?.companyId || 0;
        const customerInstanceId = session?.user.customerInstanceId || 0;
        const supervisorId = session?.user.id || 0;
        const pageIndexFetch = pi !== undefined ? pi : pageIndex - 1;
        const deadlineDate = getDateFormattedSelects(date1 || firstDate);
        const deadlineDate2 = getDateFormattedSelects(date2 || secondDate);
        const tabIndex = tab !== undefined ? tab : currentTab;

        let extraparams = `CompanyId=${companyId}&CustomerInstanceId=${customerInstanceId}&SupervisorId=${supervisorId}&`;
        extraparams += `DeadlineDate=${deadlineDate}&DeadlineDate1=${deadlineDate2}&`;
        const acctualPage = actualPageRecord[tabIndex];

        if (acctualPage === "NotReviewed") extraparams += `NotReviewed=true&`;
        else if (acctualPage === "Reviewed") extraparams += "HasFeedback=true&";
        else if (acctualPage === "Pending") extraparams += "Pending=true&";

        setIsLoading(true);
        const { data, status } = await ReviewWorkerService.GetWorkerTaskInstanceList(userId, {
            pageIndex: pageIndexFetch,
            pageSize: PaginationDefaults.PAGE_SIZE,
            sortField: "seen",
            sortDirection: "asc",
            extraParams: extraparams,
        });

        if (!status()) {
            handleToast({
                title: t(TranslationCommon.FAILED_DATA_LOADED),
                type: "alert",
                variant: "danger",
            });
            setTotal(0);
            setTaskInstanceList([]);
            setIsLoading(false);
            return;
        }

        setTaskInstanceList(data.list);
        setTotal(data.count);
        setIsLoading(false);
    };

    const getWorkerProfileCounters = async ({ date1, date2 }: IGetCounters = {}) => {
        const deadlineDate = date1 || firstDate;
        const deadlineDate2 = date2 || secondDate;
        const companyId = session?.workingCompany?.companyId || 0;
        const supervisorId = session?.user.id || 0;

        const params = fillWorkerProfileCountersExtraParams(supervisorId, deadlineDate, deadlineDate2, companyId);
        const sr = await ReviewWorkerService.GetWorkerProfileCounters(userId, {
            extraParams: params,
        });
        if (!sr.status()) {
            setCounters({ notReviewed: 0, evaluated: 0, pending: 0 });
            return;
        }
        setCounters(sr.data);
    };

    const onStarsChange = () => {
        getTasks();
        getWorkerProfileCounters();
    };

    useEffect(() => {
        fetchUserData();
        getWorkerProfileCounters();
        getTasks();
    }, []);

    return {
        user,
        userLoading,
        taskInstanceList,
        isLoading,
        total,
        pageIndex,
        tabs,
        handleDateChange,
        onChangePageIndex,
        onStarsChange,
        currentTab,
        userId,
        firstDate,
        secondDate,
        actualPageRecord,
    };
};
