import { TaskStatus } from "app/shared/Constants";
import { convertUTCtoLocaleDate, getHoursFromDateUTC } from "../Date.utilities";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

export const getTaskInstanceHour = (
    { taskType, statusCode, deadlineDate, finishedDate }: TaskInstanceListDto,
    offset?: number
): string | undefined => {
    if (taskType === "SPORADIC") return undefined;

    if (finishedDate && statusCode === TaskStatus.COMPLETED)
        return getHoursFromDateUTC(convertUTCtoLocaleDate(finishedDate, offset ? offset * -60 : 0));
    if (deadlineDate != null && statusCode === TaskStatus.PENDING)
        return getHoursFromDateUTC(convertUTCtoLocaleDate(deadlineDate, offset ? offset * -60 : 0));
    return undefined;
};
