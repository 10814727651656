import { useContext, useEffect, useState } from "react";
import { ReviewTaskContext } from "../state/context/reviewTaskContext";
import { ReviewTaskTabs } from "../models";
import { ReviewPhotosCountersDto } from "app/dtos/02-TAR/TaskInstance/ReviewPhotosCountersDto";
import { useReviewSideBar } from "./useReviewSideBar";
import { INITIAL_REVIEW_TASK_FILTER_DATA, INITIAL_STATE_FILTER_DATA_REVIEW } from "../constants/reviewTaskConstants";
import { TabPropsReduced } from "app/components_v2/Tabs";
import { TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import TaskInstanceReviewService from "app/services/02-TAR/TaskInstanceReviewService";
import { useToast } from "app/hooks/Toast/useToast";
import { fillExtraParams } from "../utils";
import { PaginationDefaults } from "app/shared/Constants";
import { DateRange } from "app/models/utilities";
import { DatesReviewTask } from "app/hooks/Dates/types";
import { ReviewTaskFilterData } from "app/components_v2/__filters/ReviewTaskFilter/types";
import { fillCountersExtraParams } from "../utils/fillCountersExtraParams";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

type IGetTaskList = {
    pi?: number;
    tab?: ReviewTaskTabs;
    reviewDates?: DatesReviewTask;
    reviewFilterData?: ReviewTaskFilterData;
};

type IGetCounters = {
    reviewDates?: DatesReviewTask;
    reviewFilterData?: ReviewTaskFilterData;
};

const tabRecord: Record<number, ReviewTaskTabs> = {
    0: "TIMED_OUT",
    1: "COMPLETED",
    2: "PENDING",
};

const tabTaskToNumber: Record<ReviewTaskTabs, number> = {
    TIMED_OUT: 0,
    COMPLETED: 1,
    PENDING: 2,
};

export const useReviewTask = () => {
    const { t } = useTranslation();
    const session = useSession();
    const { handleToast } = useToast();

    const { dates, onDateChange } = useContext(ReviewTaskContext);
    const { endDate, startDate } = dates;

    const [currentTab, setCurrentTab] = useState<number>(0);
    const [counters, setCounters] = useState<ReviewPhotosCountersDto>({
        totalPending: 0,
        totalTimedOut: 0,
        totalCompleted: 0,
    });

    const [taskInstanceList, setTaskInstanceList] = useState<TaskInstanceListDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [total, setTotal] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
    const [isSecondaryFilterOpen, setIsSecondaryFilterOpen] = useState<boolean>(false);
    const [filterData, setFilterData] = useState<ReviewTaskFilterData>(INITIAL_REVIEW_TASK_FILTER_DATA);

    const {
        user,
        status: reviewStatus,
        fetchCarrouselItems,
        setStatus: setStatusCarrousel,
    } = useReviewSideBar(INITIAL_STATE_FILTER_DATA_REVIEW, startDate, endDate);

    const tabs: TabPropsReduced[] = [
        {
            text: t(TranslationKeys.REVIEW_TIMED_OUT),
            onClick: (current) => onClickChangeTab(current),
            type: "dark",
            rightCount: counters.totalTimedOut,
        },
        {
            text: t(TranslationKeys.REVIEW_COMPLETED),
            onClick: (current) => onClickChangeTab(current),
            type: "dark",
            rightCount: counters.totalCompleted,
        },
        {
            text: t(TranslationKeys.PENDING),
            onClick: (current) => onClickChangeTab(current),
            type: "dark",
            rightCount: counters.totalPending,
        },
    ];

    const getCounters = async ({ reviewDates, reviewFilterData }: IGetCounters = {}) => {
        const filterDataFetch = reviewFilterData ?? filterData;
        const datesFetch = reviewDates !== undefined ? reviewDates : dates;

        const extraParams = fillCountersExtraParams(
            session?.workingCompany?.companyId || 0,
            filterDataFetch,
            datesFetch.startDate,
            datesFetch.endDate,
            session?.user.id,
            isFirstLoad
        );

        const { data, status, error } = await TaskInstanceReviewService.GetReviewCounters({
            extraParams,
        });
        if (!status()) {
            handleToast({ title: t(TranslationCommon.FAILED_DATA_LOADED), variant: "danger", type: "alert" });
            console.error(error);
            return;
        }
        setCounters(data);
    };

    const getTaskList = async ({ pi, tab, reviewDates, reviewFilterData }: IGetTaskList = {}) => {
        setIsLoading(true);

        const pageIndexFetch = pi ?? pageIndex - 1;
        const tabFetch = tab ?? tabRecord[currentTab];
        const datesFetch = reviewDates ?? dates;
        const filterDataFetch = reviewFilterData ?? filterData;

        const extraparams = fillExtraParams(
            session?.workingCompany?.companyId || 0,
            tabFetch,
            filterDataFetch,
            datesFetch.startDate,
            datesFetch.endDate,
            session?.user.id,
            isFirstLoad
        );

        const { data, status } = await TaskInstanceReviewService.GetReviewTaskInstances({
            pageIndex: pageIndexFetch,
            pageSize: PaginationDefaults.PAGE_SIZE,
            sortField: "DeadlineDate",
            sortDirection: "asc",
            extraParams: extraparams,
        });

        if (!status()) {
            handleToast({ title: t(TranslationCommon.FAILED_DATA_LOADED), variant: "danger", type: "alert" });
            setIsLoading(false);
            return;
        }

        if (isFirstLoad && data.tab != null) setCurrentTab(tabTaskToNumber[data.tab]);

        setTaskInstanceList(data.list);
        setTotal(data.count);
        setIsLoading(false);
        setIsFirstLoad(false);
    };

    const fetchAll = async () => {
        await getTaskList();
        fetchCarrouselItems();
        getCounters();
    };

    const onChangePageIndex = async (pi: number) => {
        setPageIndex(pi);
        await getTaskList({ pi: pi - 1 });
        getCounters();
    };

    const onClickChangeTab = async (tab: number) => {
        if (tab === currentTab) return;
        if (isLoading) return;
        setCurrentTab(tab);
        setPageIndex(1);
        await getTaskList({ pi: 0, tab: tabRecord[tab] });
        getCounters();
    };

    const handleDateChange = async (dates: DateRange) => {
        const [start, end] = dates;
        if (start) {
            onDateChange({ startDate: start, endDate: undefined });
        }
        if (end && start) {
            onDateChange({ endDate: end });
            setPageIndex(1);
            setStatusCarrousel("loading");
            await getTaskList({ pi: 0, reviewDates: { endDate: end, startDate: start } });
            getCounters({ reviewDates: { endDate: end, startDate: start } });
            fetchCarrouselItems({ end, start });
        }
    };

    const onFilterChange = async (reviewFilterData: ReviewTaskFilterData) => {
        setFilterData(reviewFilterData);
        setPageIndex(1);
        setIsSecondaryFilterOpen(false);
        await getTaskList({ pi: 0, reviewFilterData });
        getCounters({ reviewFilterData });
    };

    const taskDetailOnStarsChange = async () => {
        await getTaskList();
        getCounters();
    };

    useEffect(() => {
        fetchAll();
    }, []);

    return {
        currentTab,
        user,
        reviewStatus,
        tabs,
        handleDateChange,
        onStarsValued: fetchAll,
        taskInstanceList,
        isLoading,
        total,
        pageIndex,
        onChangePageIndex,
        dates,
        isSecondaryFilterOpen,
        setIsSecondaryFilterOpen,
        filterData,
        onFilterChange,
        taskDetailOnStarsChange,
        actualPage: tabRecord[currentTab],
    };
};
