import { DayBubble, Label } from "app/components_v2/__inputs";
import { ReadOnlyInput } from "app/components_v2/__inputs/ReadOnlyInput/ReadOnlyInput";
import { CustomDays } from "app/models/02-TAR/TaskWizard";
import { TaskTemporalityDateRangeTranslations, TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type TaskTemporalityDateRangeWeeklyDisabledProps = {
    repeatEvery: string;
    weeklyDays: CustomDays[];
};

export const TaskTemporalityDateRangeWeeklyDisabled: FC<TaskTemporalityDateRangeWeeklyDisabledProps> = ({
    repeatEvery,
    weeklyDays,
}) => {
    const { t } = useTranslation();
    const repeatEveryNumber = Number(repeatEvery);

    const repeatEveryTitle =
        repeatEveryNumber > 1
            ? t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_WEEKLY_REPEAT_EVERY_LABEL_SINGULAR)
            : t(
                  TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_WEEKLY_REPEAT_EVERY_LABEL_PLURAL
              ).replace("{0}", `${repeatEveryNumber}`);

    return (
        <div className="taskTemporalityDateRangeWeeklyDisabled">
            <Label label={t(TranslationKeys.REPEAT)} />
            <div className="taskTemporalityDateRangeWeeklyDisabled__label">
                <ReadOnlyInput value={repeatEveryTitle} options={{ width: "auto" }} />
            </div>
            <div className="taskTemporalityDateRangeWeeklyDisabled__weeklyDays">
                {weeklyDays.map(({ value, id, isActive }) => (
                    <DayBubble active={isActive} letter={value} onClick={() => {}} key={id} readonly />
                ))}
            </div>
        </div>
    );
};
