import { AuthLayout } from "app/components_v2/__containers/AuthLayout/AuthLayout";
import { headerStepNumbersModel } from "app/components_v2/wizards/types";
import { PostLoginContext } from "./state/context/PostLoginContext";
import { PrivatePaths, PublicPaths } from "app/shared/Constants";
import { ReactElement, useEffect, useState } from "react";
import { StepOne } from "./Step1/StepOne";
import { TranslationCommon, TranslationTitles } from "app/translation/translationKeys";
import { useGoBack, useSession, useTitle } from "app/hooks";
import { useNavigate } from "react-router-dom";
import { UserCompanyModel } from "app/models/01-SEG/UserCompany/UserCompanyModel";
import { useTranslation } from "react-i18next";
import { useUserType } from "app/hooks/useUserType";
import { useWizard } from "app/hooks/useWizard";
import { UsrDepartmentModel } from "app/models/01-SEG/UserCompanyDepartment/UserCompanyDepartmentModel";
import { UsrSubDepartmentModel } from "app/models/01-SEG/UserCompanyDepartmentSubdepartment/UserCompanyDepartmentSubdepartmentModel";
import Loading from "app/components_v2/Loading/Loading";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

export const SelectDepartmentWizard = () => {
    const { t } = useTranslation();
    const { isWorker } = useUserType();
    const { goBack } = useGoBack();
    const session = useSession();
    const nav = useNavigate();
    const { translateCompany } = useCustomerTypeTranslation();

    useTitle(t(TranslationTitles.SELECT_DEPARTMENT_SUBDEPARTMENT_PAGE_TITLE));

    const headerStepNumbersValues: headerStepNumbersModel[] = [
        {
            title: translateCompany("SINGULAR"),
            hidden: !isWorker,
        },
    ];

    const { currentStepIndex } = useWizard(headerStepNumbersValues.length);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [workingCompany, setWorkingCompany] = useState<UserCompanyModel>();
    const [workingDepartment, setWorkingDepartment] = useState<UsrDepartmentModel>();
    const [workingSubDepartment, setWorkingSubDepartment] = useState<UsrSubDepartmentModel>();

    const steps: ReactElement[] = [<StepOne />];

    useEffect(() => {
        if (!session?.user?.isPrivacyAccepted) {
            nav(PrivatePaths.ACCEPT_POLICY);
            return;
        }

        if (session?.isActivationPending) {
            nav(PublicPaths.NEW_PASSWORD);
            return;
        }

        setIsLoading(false);
    }, []);

    return (
        <PostLoginContext.Provider
            value={{
                setStepOne: setWorkingCompany,
                setStepTwo: setWorkingDepartment,
                setStepThree: setWorkingSubDepartment,
                workingCompany,
                workingDepartment,
                workingSubDepartment,
            }}
        >
            {isLoading ? (
                Loading()
            ) : (
                <AuthLayout>
                    <div className={`selectDepartmentWizard`}>
                        <div className="selectDepartmentWizard__formContainer">{steps[currentStepIndex - 1]}</div>
                        {!!session?.workingCompany && (
                            <div className="selectDepartmentWizard__footer">
                                <Button
                                    text={t(TranslationCommon.CANCEL)}
                                    variant="primary"
                                    onClick={goBack}
                                    buttonType="submit"
                                />
                            </div>
                        )}
                    </div>
                </AuthLayout>
            )}
        </PostLoginContext.Provider>
    );
};
