import { isHoursOutOfRange } from "app/helpers/__validates/validateTaskHours";
import { isDropdownDynamicField } from "app/helpers/dynamicFields/dynamicFieldHelper";
import { useDynamicFieldsErrorHandler } from "app/hooks/dynamicFields/useDynamicFieldsErrorHandler";
import { useToast } from "app/hooks/Toast/useToast";
import {
    EditTaskDisabledFieldsModel,
    EditTaskValidation,
    TaskfieldOptionsError,
} from "app/models/02-TAR/Task/EditTask";
import { WizardTaskAllSteps } from "app/models/02-TAR/TaskWizard";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { IsEnabledModel } from "../../WizardTasks/Step2/models";
import { INITIAL_EDIT_TASK_DISABLEDS, INITIAL_EDIT_TASK_FORM_VALUE_ERRORS } from "../constants/editTaskConstants";
import { useValidateEditTaskTaskTemporality } from "./useValidateEditTaskTaskTemporality";

export const useEditTask = (initialStepsValues: WizardTaskAllSteps) => {
    const { t } = useTranslation();
    const [data, setData] = useState(initialStepsValues);
    const [dataCopy, setDataCopy] = useState(initialStepsValues);
    const { handleToast } = useToast();
    const { filterTaskFieldsWithoutErrorMessage, taskfieldOptionsDropdown } = useDynamicFieldsErrorHandler();
    const [baseTaskDisabledFields, setBaseTaskDisabledFields] =
        useState<EditTaskDisabledFieldsModel>(INITIAL_EDIT_TASK_DISABLEDS);
    const [validations, setValidations] = useState<EditTaskValidation>(INITIAL_EDIT_TASK_FORM_VALUE_ERRORS);
    const [isEnabled, setIsEnabled] = useState<IsEnabledModel>({
        workingPosition: true,
        user: false,
        subdepartment: false,
    });
    const [showModalHoursOutOfTime, setShowModalHoursOutOfTime] = useState<boolean>(false);

    const handleErrorsChange = (values: Partial<EditTaskValidation>) => {
        setValidations((prev) => ({ ...prev, ...values }));
    };
    const { validateTemporality, validateTaskEnds } = useValidateEditTaskTaskTemporality({
        data,
        errors: validations.temporalityDateRange,
        onErrorsChange: handleErrorsChange,
    });

    const updateFields = (fields: Partial<WizardTaskAllSteps>) => {
        if (!!fields.checkList || !!fields.dynamicFields || !!fields.reportData)
            setValidations((prev) => ({ ...prev, reportData: "" }));
        setData((prev: WizardTaskAllSteps) => {
            return { ...prev, ...fields };
        });
    };

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let check = true;

        handleErrorsChange(INITIAL_EDIT_TASK_FORM_VALUE_ERRORS);

        if (!data.taskTitle.length) {
            handleErrorsChange({ taskTitle: t(TranslationCommon.INPUT_NOT_EMPTY) });
            check = false;
        }

        if (!data.companyForTask.value.length) {
            handleErrorsChange({ companyForTask: t(TranslationCommon.SELECT_OPTION) });
            check = false;
        }

        if (data.companyForTask.value.length && !data.DepartmentForTask.value.length) {
            handleErrorsChange({ DepartmentForTask: t(TranslationCommon.SELECT_OPTION) });
            check = false;
        }

        if (
            data.companyForTask.worksWithQr &&
            (!data.userForTask.value.length || data.userForTask.value === "-1") &&
            (!data.workingPositionForTask.value.length || data.workingPositionForTask.value === "-1")
        ) {
            handleErrorsChange({ userOrQRForTask: t(TranslationKeys.MUST_SELECT_USER_OR_QR_BODY) });
            check = false;
        }

        const isValidTemporality = baseTaskDisabledFields.disabledType === "NONE" ? validateTemporality() : true;

        // Si la validación de la termporalidad está bien no sobrescribas la validación
        check = isValidTemporality ? check : false;
        if (baseTaskDisabledFields.disabledType === "PERIODICAL") {
            check = validateTaskEnds();
        }

        if (data.reportType === "DATA") {
            const isAtLeastOneValue = data.reportData.filter(({ assetId }) => !assetId).filter(({ value }) => value);

            if (!isAtLeastOneValue.length) {
                handleErrorsChange({ reportData: t(TranslationCommon.INPUT_NOT_EMPTY) });
                check = false;
            }
        }

        if (data.reportType === "ASSET") {
            const isAtLeastOneValue = data.reportData
                .filter(({ assetId }) => assetId)
                .filter(({ value, isDeleted }) => value && !isDeleted);

            if (!isAtLeastOneValue.length) {
                handleErrorsChange({ reportData: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
                check = false;
            }
        }

        if (data.reportType === "DYNAMIC_FIELD") {
            const dynamicFields = data.dynamicFields.filter(({ isDeleted }) => !isDeleted);
            if (!dynamicFields.length) {
                handleErrorsChange({ reportData: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
                check = false;
            }

            const dynamicfieldsErrors = dynamicFields
                .map(({ id, label, dynamicFieldsType, taskFieldOptions }) => ({
                    dynamicFieldsType: dynamicFieldsType,
                    errorMessage: !label.length ? t(TranslationCommon.INPUT_NOT_EMPTY) : "",
                    id: id,
                    taskfieldOptions: isDropdownDynamicField(dynamicFieldsType)
                        ? taskfieldOptionsDropdown(taskFieldOptions)
                        : [],
                }))
                .filter(filterTaskFieldsWithoutErrorMessage);
            handleErrorsChange({ dynamicFields: dynamicfieldsErrors });

            if (dynamicfieldsErrors.length) check = false;
        }

        if (data.reportType === "CHECKLIST") {
            const checklist = data.checkList.filter(({ isDeleted }) => !isDeleted);
            const errorChecklist: TaskfieldOptionsError[] = checklist.map(({ id, name }) => ({
                errorMessage: !name.length ? t(TranslationKeys.CHECKLIST_CANNOT_BE_EMPTY) : "",
                id,
            }));

            handleErrorsChange({ checklist: errorChecklist });

            if (checklist.filter(({ isEnabled }) => isEnabled).length === 0) {
                handleToast({
                    title: t(TranslationModals.FAILED_SAVE_TASK_TITLE),
                    subtitle: t(TranslationErrors.CHECKLIST_CANNOT_BE_ALL_DISABLED),
                    variant: "danger",
                    type: "alert",
                });
                return false;
            }
        }

        if (!check) {
            handleToast({
                title: t(TranslationModals.FAILED_SAVE_TASK_TITLE),
                subtitle: t(TranslationModals.FAILED_SAVE_TASK_DESCRIPTION),
                variant: "danger",
                type: "alert",
            });
            return false;
        }
        if (isHoursOutOfRange(data.taskHours)) {
            setShowModalHoursOutOfTime(true);
            return false;
        }

        return true;
    };

    const setEnableUserDept = (values: IsEnabledModel) => setIsEnabled(values);

    const onConfirmModalHoursOutOfTime = () => {
        setShowModalHoursOutOfTime(false);
    };

    const onCloseModalHoursOutOfTime = () => {
        setShowModalHoursOutOfTime(false);
    };

    return {
        data,
        onChange: updateFields,
        setData,
        validate,
        validations,
        isEnabledUserDept: isEnabled,
        setEnableUserDept,
        dataCopy,
        setDataCopy,
        onConfirmModalHoursOutOfTime,
        onCloseModalHoursOutOfTime,
        showModalHoursOutOfTime,
        setValidations,
        baseTaskDisabledFields,
        setBaseTaskDisabledFields,
    };
};
