import { AVAILABLE_ACTIVE_SCREENS, PrivatePaths, RegexPatterns, SecScreen, Views } from "app/shared/Constants";
import { CentersAndDepartmentsTab } from "./CentersAndDepartmentsTab";
import { CIDepartamentalStructureModel } from "app/models/01-SEG/CIDepartamentalStructureModel";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { ErrorUserPageFormValues, UserPageFormValues } from "./types";
import { FC, useEffect, useState } from "react";
import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { getDateFormattedSelects } from "app/helpers";
import { getInitials } from "app/helpers/getInitials";
import { hasPermissionToDelete, hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { isEqual } from "lodash";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { RoleService, UserService } from "app/services";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useGetInvalidTranslation } from "app/hooks/User/useGetInvalidTranslation";
import { useModal } from "app/hooks/Modal/useModal";
import { useNavigate, useParams } from "react-router-dom";
import { UserInfoForm } from "./UserInfoForm";
import { UserModel } from "app/models/01-SEG/User/UserModel";
import { UserModelToUserPageForm } from "app/mappers/Users/UserModelToUserPageForm";
import { UserPageFormToUserModel } from "app/mappers/Users/UserPageFormToUserModel";
import { useScrollTop } from "app/hooks/global/useScrollTop";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import Helper from "app/helpers/TranslationHelper";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { isUserTypeAdmin } from "app/helpers/userTypeCheck";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { ErrorConstants } from "app/shared/errorConstants";

type IGetDescriptionDeleteModal = {
    isActiveUser: boolean;
    isRelatedWithTasks: boolean;
};

const INITIAL_FORM_VALUES: UserPageFormValues = {
    username: "",
    userCode: "",
    name: "",
    lastName: "",
    email: "",
    initialScreen: "",
    userRoleModelList: [],
    userCompanyList: [],
    userType: "-1",
    fk_JobDescription: "-1",
    isAutomaticActivationOpen: false,
};

const INITIAL_ERROR_FORM_VALUES: ErrorUserPageFormValues = {
    errorUsername: "",
    erroruserCode: "",
    errorName: "",
    errorLastName: "",
    errorEmail: "",
    errorInitialScreen: "",
    errorUserType: "",
    errorJobDescription: "",
    errorUserCompanyList: "",
    errorAutomaticActivationDate: "",
    errorRoleLis: "",
};

const userCompanyErrorList = [
    ErrorConstants.USERCOMPANIES_0_IS_INVALID,
    ErrorConstants.USERCOMPANIES_1_IS_INVALID,
    ErrorConstants.USERCOMPANIES_2_IS_INVALID,
    ErrorConstants.USERCOMPANIES_3_IS_INVALID,
    ErrorConstants.USERCOMPANIES_4_IS_INVALID,
    ErrorConstants.USERCOMPANIES_5_IS_INVALID,
    ErrorConstants.USERCOMPANIES_6_IS_INVALID,
    ErrorConstants.USERCOMPANIES_7_IS_INVALID,
    ErrorConstants.USERCOMPANIES_8_IS_INVALID,
    ErrorConstants.USERCOMPANIES_9_IS_INVALID,
];

export const NewUserTabs: FC = () => {
    const { t } = useTranslation();
    const { userId } = useParams();
    const session = useSession();
    const { handleToast } = useToast();
    const headerColor = useScrollTop(20);
    const nav = useNavigate();
    const { getInactiveTitleAndDate } = useGetInvalidTranslation();
    const { getErrorMessage } = useFetchErrors();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [user, setUser] = useState<UserModel>();
    const [formValues, setFormValues] = useState<UserPageFormValues>(INITIAL_FORM_VALUES);
    const [previousFormValues, setPreviousFormValues] = useState<UserPageFormValues>(INITIAL_FORM_VALUES);
    const [errorFormValues, setErrorFormValues] = useState<ErrorUserPageFormValues>(INITIAL_ERROR_FORM_VALUES);
    const [activeScreenOptions, setActiveScreenOptions] = useState<OptionModel[]>([]);
    const [roleOptions, setRoleOptions] = useState<OptionModel[]>([]);
    const [companyOptions, setCompanyOptions] = useState<OptionModel[]>([]);
    const [isActiveUser, setIsActiveUser] = useState<boolean>(true);
    const [isUserSaving, setIsUserSaving] = useState<boolean>(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [userLoading, setUserLoading] = useState(true);
    const [isDesactivatingUser, setIsDesactivatingUser] = useState<boolean>(false);
    const [modifiedData, setModifiedData] = useState<CIDepartamentalStructureModel[]>([]);
    const [originalData, setOriginalData] = useState<CIDepartamentalStructureModel[]>([]);
    const { isModalOpen, handleOpen, handleClose } = useModal();
    const isPageDisabled = (!!userId && !hasPermissionToEdit(SecScreen.USER_MANAGEMENT)) || !isActiveUser;

    const {
        isModalOpen: isCancelModalOpen,
        handleOpen: handleCancelMoldalOpen,
        handleClose: handleCancelModalClose,
    } = useModal();

    const getUser = async (id: string | number) => {
        setUserLoading(true);

        const sr = await UserService.GetOne(id);

        if (!sr.status()) {
            handleToast({
                title: t(TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE),
                variant: "danger",
                type: "alert",
            });
            setUserLoading(false);
            nav(`/${PrivatePaths.USER_MANAGEMENT}`);
            return;
        }
        if (isUserTypeAdmin(sr.data.userType)) {
            nav(`/${PrivatePaths.USER_MANAGEMENT}`);
            return;
        }
        setUser(sr.data);
        setFormValues(UserModelToUserPageForm(sr.data));
        setPreviousFormValues(UserModelToUserPageForm(sr.data));
        setIsActiveUser(sr.data.isActive);
        setUserLoading(false);
    };

    const getActiveScreens = async () => {
        if (!userId) return;
        const sr = await UserService.GetActiveScreens(Number(userId));
        if (!sr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        addActiveScreens(sr.data);
    };

    const resetActiveScreens = async (rolesId: number[]) => {
        handleInputChange({ initialScreen: Views.DASHBOARD });
        if (!rolesId.length) {
            setActiveScreenOptions([
                {
                    label: Helper.translateRowTitle(Views.DASHBOARD, t, translateCustomerTypeKeys),
                    value: Views.DASHBOARD,
                },
            ]);
            return;
        }

        const { status, data } = await RoleService.GetRoleList(rolesId);

        if (!status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }
        const screens = data.flatMap(({ modules }) =>
            modules.flatMap(({ screenPatents }) => screenPatents.map(({ screenCode }) => screenCode))
        );
        const screensFiltered = [...new Set(screens)];
        addActiveScreens(screensFiltered);
    };

    const addActiveScreens = (screens: string[]) => {
        const screensArray: OptionsSearch[] = [];

        screens.forEach((screen: string) => {
            if (!AVAILABLE_ACTIVE_SCREENS.includes(screen)) return;
            screensArray.push({
                label: Helper.translateRowTitle(screen, t, translateCustomerTypeKeys),
                value: screen,
            });
        });

        screensArray.push({
            label: Helper.translateRowTitle(Views.DASHBOARD, t, translateCustomerTypeKeys),
            value: Views.DASHBOARD,
        });
        setActiveScreenOptions(screensArray);
    };

    const getRoles = async () => {
        const sr = await SegSelectorService.GetRoles();

        if (!sr.status() || sr.data.length === 0) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        setRoleOptions(sr.data);
    };

    const getCompanies = async () => {
        const sr = await SegSelectorService.GetCompanyNamesWithIds();

        if (!sr.status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        if (sr.data.length > 0)
            setCompanyOptions(
                sr.data.map((data) => ({
                    ...data,
                    initials: getInitials(data.label),
                }))
            );
    };

    const handleInputChange = (values: Partial<UserPageFormValues>) => {
        setFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleErrorChange = (values: Partial<ErrorUserPageFormValues>) => {
        setErrorFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleDesactivateUser = async () => {
        setIsDesactivatingUser(true);
        const { status } = await UserService.ActivateUser(Number(userId), { value: !isActiveUser });
        if (!status()) {
            handleToast({
                title: t(TranslationModals.TOAST_GENERIC_ERROR),
                type: "alert",
                variant: "danger",
            });
            setIsDesactivatingUser(false);
            handleClose();
            return;
        }

        handleToast({
            title: isActiveUser ? t(TranslationKeys.USER_DEACTIVATED) : t(TranslationKeys.USER_ACTIVATED),
            variant: "success",
            type: "alert",
        });
        setIsActiveUser(!isActiveUser);
        setIsDesactivatingUser(false);

        if (user)
            setUser({
                ...user,
                isRelatedWithTasks: false,
                automaticActivationDate: !isActiveUser ? undefined : user.automaticActivationDate,
            });
        if (!isActiveUser) {
            handleInputChange({
                automaticActivationDate: undefined,
                isAutomaticActivationOpen: false,
            });
        }
        handleClose();
        nav(`/${PrivatePaths.USER_MANAGEMENT}`);
    };

    const handleCancel = () => {
        setFormValues(previousFormValues);
        handleCancelModalClose();
    };

    const handleSubmit = async () => {
        if (validate()) return;
        if (userId) await handleUpdate();
        else await handleSave();
        setPreviousFormValues(formValues);
    };

    const validate = () => {
        let error = false;
        handleErrorChange(INITIAL_ERROR_FORM_VALUES);
        const {
            name,
            lastName,
            userCode,
            email,
            initialScreen,
            username,
            userType,
            userCompanyList,
            userRoleModelList,
        } = formValues;
        if (!userCode.length) {
            handleErrorChange({ erroruserCode: t(TranslationCommon.INPUT_NOT_EMPTY) });
            error = true;
        }
        if (userCode.length > 20) {
            handleErrorChange({ erroruserCode: t(TranslationKeys.USERCODE_MIN_LENGTH) });
            error = true;
        }

        if (!userId && !username.length) {
            handleErrorChange({ errorUsername: t(TranslationCommon.INPUT_NOT_EMPTY) });
            error = true;
        }
        if (!name.length) {
            handleErrorChange({ errorName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            error = true;
        }
        if (!lastName.length) {
            handleErrorChange({ errorLastName: t(TranslationCommon.INPUT_NOT_EMPTY) });
            error = true;
        }
        if (!userCode.length) {
            handleErrorChange({ erroruserCode: t(TranslationCommon.INPUT_NOT_EMPTY) });
            error = true;
        }
        if (!userId && !email.length) {
            handleErrorChange({ errorEmail: t(TranslationCommon.INPUT_NOT_EMPTY) });
            error = true;
        } else if (!userId && !new RegExp(RegexPatterns.mail).test(email)) {
            handleErrorChange({ errorEmail: t(TranslationKeys.INPUT_VALID_EMAIL) });
            error = true;
        }
        if (!!userId && !initialScreen.length) {
            handleErrorChange({ errorInitialScreen: t(TranslationCommon.INPUT_NOT_EMPTY) });
            error = true;
        }
        if (userType === "-1") {
            handleErrorChange({ errorUserType: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            error = true;
        }

        if (!userCompanyList.length) {
            handleErrorChange({
                errorUserCompanyList: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION),
            });
            error = true;
        }

        if (!userRoleModelList.length) {
            handleErrorChange({
                errorRoleLis: !!userId
                    ? t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION)
                    : t(TranslationCommon.INPUT_NOT_EMPTY),
            });
            error = true;
        }

        return error;
    };

    const handleUpdate = async () => {
        if (!userId) return;
        if (!user) return;
        setIsUserSaving(true);
        const { status } = await UserService.Edit(UserPageFormToUserModel(formValues, user), userId);
        if (!status()) {
            handleToast({
                title: t(TranslationModals.FAILED_EDIT),
                variant: "danger",
                type: "alert",
            });
            setIsUserSaving(false);
            return;
        }
        handleToast({
            title: t(TranslationModals.TOAST_SUCCESS_EDIT),
            variant: "success",
            type: "alert",
        });
        setPreviousFormValues(formValues);
        const formValuesEdited = formValues;
        if (isActiveUser) {
            formValuesEdited.automaticActivationDate = undefined;
            formValuesEdited.isAutomaticActivationOpen = false;
        }
        setUser(UserPageFormToUserModel(formValuesEdited, user));
        setIsUserSaving(false);
        nav(`/${PrivatePaths.USER_MANAGEMENT}`);
    };

    const handleSave = async () => {
        setIsUserSaving(true);

        const {
            email,
            name,
            lastName,
            username,
            userRoleModelList,
            userCompanyList,
            userType,
            userCode,
            automaticActivationDate,
            fk_JobDescription,
        } = formValues;

        const { status, getParsedError, data } = await UserService.Save({
            customerInstanceId: session?.user.customerInstanceId,
            userCode,
            email,
            firstName: name,
            initialScreenCode: Views.DASHBOARD,
            isActive: true,
            lastName,
            passwordType: "PASSWORD",
            username: username,
            userRoleModelList,
            companyIds: userCompanyList.map(({ value }) => Number(value)),
            userType: userType,
            fK_JobDescription: fk_JobDescription !== "-1" ? fk_JobDescription : undefined,
            automaticActivationDate: automaticActivationDate
                ? getDateFormattedSelects(automaticActivationDate)
                : undefined,
        } as UserModel);

        const errorMessage = getErrorMessage(getParsedError());
        userCompanyErrorList.includes(errorMessage)
            ? translateCustomerTypeKeys(errorMessage, { firstReplace: "SINGULAR", isGenderSensitive: true })
            : errorMessage;
        if (!status()) {
            handleToast({
                title: errorMessage,
                variant: "danger",
                type: "alert",
            });
            setIsUserSaving(false);
            return;
        }

        handleToast({
            title: t(TranslationModals.SUCCESS_SAVE),
            variant: "success",
            type: "alert",
        });
        setIsUserSaving(false);

        if (!session?.isOnBoarding) {
            nav(`/${PrivatePaths.USER_MANAGEMENT}/edit/${data}`);
            setCurrentTab(1);
            return;
        }
        nav(PrivatePaths.USER_MANAGEMENT);
    };

    const postData = async () => {
        if (!userId) return;
        setIsUserSaving(true);

        const { data, status } = await UserService.PutAllCompanies(Number(userId), modifiedData);
        if (!status()) {
            handleToast({
                title: t(TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE),
                variant: "danger",
                type: "alert",
            });
            setIsUserSaving(false);
            return;
        }
        setOriginalData(data);
        setModifiedData(data);
        const assignedCompanies: OptionModel[] = data
            .filter(({ assigned }) => assigned)
            .map(({ companyName, companyId, logoUrl }) => ({
                label: companyName,
                value: String(companyId),
                profilePictureURL: logoUrl,
                initials: getInitials(companyName),
            }));
        setFormValues((prev) => ({ ...prev, userCompanyList: assignedCompanies }));
        setPreviousFormValues((prev) => ({ ...prev, userCompanyList: assignedCompanies }));
        handleToast({
            title: t(TranslationModals.TOAST_SUCCESS_SAVE),
            variant: "success",
            type: "alert",
        });
        setIsUserSaving(false);
        nav(`/${PrivatePaths.USER_MANAGEMENT}`);
        return;
    };

    const getDeleteModalDescription = ({ isActiveUser, isRelatedWithTasks }: IGetDescriptionDeleteModal): string => {
        if (!isActiveUser) return t(TranslationKeys.EDIT_FORM_USER_WILL_ACTIVATE);
        if (isRelatedWithTasks) return t(TranslationKeys.EDIT_FORM_USER_WILL_DEACTIVATE);
        return t(TranslationKeys.EDIT_FORM_USER_WILL_DEACTIVATE_NO_RELATED_TASK);
    };

    const tabs: TabPropsReduced[] = [
        {
            text: t(TranslationTitles.GENERAL_INFO_TITLE),
            onClick: () => setCurrentTab(0),
            type: "dark",
            children: (
                <UserInfoForm
                    userFormValues={formValues}
                    activeScreensOptions={activeScreenOptions}
                    roleOptions={roleOptions}
                    companyOptions={companyOptions}
                    onInputChange={handleInputChange}
                    onSubmit={handleSubmit}
                    isEditable={!!userId}
                    isActive={isActiveUser}
                    errorFormValues={errorFormValues}
                    userId={userId}
                    onRoleChange={resetActiveScreens}
                />
            ),
        },
        {
            text: translateCustomerTypeKeys(TranslationTitles.COMPANIES_AND_DEPARTMENTS_TITLE, {
                firstReplace: "PLURAL",
            }),
            onClick: () => setCurrentTab(1),
            type: "dark",
            children: (
                <CentersAndDepartmentsTab
                    onChangeNewData={(data) => {
                        setModifiedData(data.modifiedData);
                        setOriginalData(data.originalData);
                    }}
                    isDisabled={isPageDisabled}
                    values={originalData}
                    modifiedValues={modifiedData}
                />
            ),
            hidden: !userId,
        },
    ];

    useEffect(() => {
        if (userId) getUser(userId);
        else setUserLoading(false);
    }, [userId]);

    useEffect(() => {
        getActiveScreens();
        getRoles();
        getCompanies();
    }, []);

    return (
        <>
            {isModalOpen && (
                <ConfirmModal
                    onClose={handleClose}
                    onConfirm={handleDesactivateUser}
                    isLoading={isDesactivatingUser}
                    onConfirmText={
                        isActiveUser
                            ? t(TranslationKeys.EDIT_FORM_USER_DEACTIVATE)
                            : t(TranslationKeys.EDIT_FORM_USER_ACTIVATE)
                    }
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={getDeleteModalDescription({
                        isActiveUser,
                        isRelatedWithTasks: !!user?.isRelatedWithTasks,
                    })}
                    title={
                        isActiveUser
                            ? t(TranslationKeys.EDIT_FORM_USER_DEACTIVATE_QUESTION)
                            : t(TranslationKeys.EDIT_FORM_USER_ACTIVATE_QUESTION)
                    }
                />
            )}
            {isCancelModalOpen && (
                <ConfirmModal
                    onClose={handleCancelModalClose}
                    onConfirm={handleCancel}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={t(TranslationKeys.UNDO_CHANGES)}
                />
            )}
            <FormLayout
                isLoading={userLoading}
                variant={currentTab === 0 ? "gray" : "white"}
                headerOptions={{
                    buttonLabel: isActiveUser
                        ? t(TranslationKeys.EDIT_FORM_USER_DEACTIVATE)
                        : t(TranslationKeys.EDIT_FORM_USER_ACTIVATE),
                    showDeleteButton: !!userId && hasPermissionToDelete(SecScreen.USER_MANAGEMENT),
                    type: "disable",
                    badge: {
                        isVisible: !!userId,
                        title:
                            !isActiveUser && user
                                ? getInactiveTitleAndDate(user.automaticActivationDate || null).title
                                : undefined,
                    },
                    isActive: isActiveUser,
                    avatar: userId
                        ? {
                              avatarName: `${user?.firstName} ${user?.lastName}`,
                              colorId: Number(userId),
                              img: user?.profilePictureURL,
                          }
                        : undefined,
                    isLoading: userLoading,
                    onClick: handleOpen,
                    title: !userId ? t(TranslationKeys.NEW_USER) : undefined,
                    background: headerColor ? (isActiveUser ? "primary" : "grey") : "transparent",
                    tabsOptions: {
                        tabs: <Tabs tabs={tabs} currentTab={currentTab} hideChildren={true} />,
                    },
                }}
                footer={
                    isActiveUser &&
                    !userLoading &&
                    hasPermissionToEdit(SecScreen.USER_MANAGEMENT) && (
                        <GenericModalFooter
                            confirmButton={
                                currentTab === 0
                                    ? {
                                          text: t(TranslationCommon.SAVE),
                                          form: "userInfoForm",
                                          type: "submit",
                                      }
                                    : {
                                          text: t(TranslationCommon.SAVE),
                                          disabled: isEqual(originalData, modifiedData),
                                          onClick: () => postData(),
                                      }
                            }
                            closeButton={
                                currentTab === 0
                                    ? {
                                          text: t(TranslationCommon.CANCEL),
                                          onClick: () =>
                                              !isEqual(formValues, previousFormValues) && handleCancelMoldalOpen(),
                                          buttonType: "tertiary",
                                          disabled: isEqual(formValues, previousFormValues),
                                      }
                                    : {
                                          text: t(TranslationCommon.CANCEL),
                                          onClick: () => setModifiedData(originalData),
                                          buttonType: "tertiary",
                                          disabled: isEqual(originalData, modifiedData),
                                      }
                            }
                            loading={isUserSaving}
                        />
                    )
                }
            >
                {tabs[currentTab].children}
            </FormLayout>
        </>
    );
};
