import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IssueHistoryReopenModel, IssueHistoryReopenModelError } from "../models/types";
import {
    INITIAL_ERROR_ISSUE_HISTORY_REOPEN_MODAL_FORM_VALUES,
    INITIAL_ISSUE_HISTORY_REOPEN_MODAL_FORM_VALUES,
} from "../constants/issueHistoryReopenModalConstants";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { TranslationCommon, TranslationErrors } from "app/translation/translationKeys";
import IssueService from "app/services/05-QUA/IssueService";
import { OpenCloseStatusCode } from "app/shared/types/OpenCloseStatusCode";
import AuditInstanceIssueService from "app/services/05-QUA/AuditInstanceIssueService";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import { IssueServiceType } from "app/shared/types/IssueTypes";

type IUseIssueHistoryReopen = {
    actualStatus: OpenCloseStatusCode;
    id: number;
    onFinish: () => void;
    type?: IssueServiceType;
};

export const useIssueHistoryReopen = ({ actualStatus, id, onFinish, type = "ISSUE" }: IUseIssueHistoryReopen) => {
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { t } = useTranslation();

    const [formValues, setFormValues] = useState<IssueHistoryReopenModel>(
        INITIAL_ISSUE_HISTORY_REOPEN_MODAL_FORM_VALUES
    );
    const [errorsFormValues, setErrorsFormValues] = useState<IssueHistoryReopenModelError>(
        INITIAL_ERROR_ISSUE_HISTORY_REOPEN_MODAL_FORM_VALUES
    );
    const [correctiveMeassureOptions, setCorrectiveMeassureOptions] = useState<OptionModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { correctiveMeassures, description } = formValues;

    const handleInputChange = (values: Partial<IssueHistoryReopenModel>) => {
        setFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleErrorChange = (values: Partial<IssueHistoryReopenModelError>) => {
        setErrorsFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleAddCorrectiveMeasure = (values: OptionModel[]) => {
        if (values.length > 10) {
            handleToast({
                variant: "danger",
                title: t(TranslationErrors.ERROR_MESSAGE_MAX_10_COR_MES),
                type: "alert",
            });
            return;
        }
        handleInputChange({ correctiveMeassures: values });
    };

    const handleDeleteCorrectiveMeasure = (id: string) => {
        const selectedCorrMes = correctiveMeassures.find(({ value }) => value === id);
        if (!selectedCorrMes) return;
        const corrMessFiltered = correctiveMeassures.filter(({ value }) => value !== id);
        handleInputChange({ correctiveMeassures: corrMessFiltered });
    };

    const handleClose = () => {
        setIsModalVisible(false);
        setFormValues(INITIAL_ISSUE_HISTORY_REOPEN_MODAL_FORM_VALUES);
        setErrorsFormValues(INITIAL_ERROR_ISSUE_HISTORY_REOPEN_MODAL_FORM_VALUES);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!validate()) return;

        setIsSubmitting(true);

        const correctiveMeassuresId = formValues.correctiveMeassures.map(({ value }) => Number(value));

        const service = type === "ISSUE" ? IssueService : AuditInstanceIssueService;
        const serviceCall =
            actualStatus === "OPEN"
                ? service.CloseAsync(id, {
                      issueStatus: { comment: description },
                      correctiveMeasures: correctiveMeassuresId,
                  })
                : service.ReopenAsync(id, { comment: description });

        const { status, getParsedError } = await serviceCall;

        if (!status()) {
            handleToast({
                variant: "danger",
                title: getErrorMessage(getParsedError()),
                type: "alert",
            });
            setIsSubmitting(false);
            return;
        }

        setIsSubmitting(false);
        handleClose();
        onFinish();
    };

    const validate = (): boolean => {
        let isValid = true;
        setErrorsFormValues(INITIAL_ERROR_ISSUE_HISTORY_REOPEN_MODAL_FORM_VALUES);
        if (!description.length) {
            handleErrorChange({ errorDescription: t(TranslationCommon.INPUT_NOT_EMPTY) });
            isValid = false;
        }

        return isValid;
    };

    const getData = async () => {
        setIsLoading(true);
        const { data, status, getParsedError } = await QuaSelectorService.GetCorrectiveMeassures();

        if (!status()) {
            handleToast({
                variant: "danger",
                title: getErrorMessage(getParsedError()),
                type: "alert",
            });
            setIsLoading(false);
            setCorrectiveMeassureOptions([]);
            return;
        }

        setIsLoading(false);
        setCorrectiveMeassureOptions(data);
    };

    useEffect(() => {
        getData();
    }, []);

    return {
        formValues,
        errorsFormValues,
        correctiveMeassureOptions,
        isLoading,
        isModalVisible,
        isSubmitting,
        onSubmit: handleSubmit,
        onAddCorrectiveMeasure: handleAddCorrectiveMeasure,
        onDeleteCorrectiveMeasure: handleDeleteCorrectiveMeasure,
        onChangeVisibilityModal: setIsModalVisible,
        onClose: handleClose,
        onInputChange: handleInputChange,
    };
};
