import { UserGridProfilePicute } from "../models/userGridSecondaryFilterModel";

export const userProfilePictureMapper = (profilePicture: UserGridProfilePicute) => {
    const profilePictureRecord: Record<UserGridProfilePicute, boolean | null> = {
        "-1": null,
        HAS_PROFILE_PICTURE: true,
        DO_NOT_HAS_PROFILE_PICTUTE: false,
    };

    return profilePictureRecord[profilePicture];
};
