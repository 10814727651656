import { v4 } from "uuid";
import { BaseTaskFieldModel } from "app/models/02-TAR/BaseTask/BaseTaskFieldModel";
import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { WizardTaskAllSteps } from "app/models/02-TAR/TaskWizard";
import { convertUTCtoLocaleDate, getHoursFromDateUTC } from "app/helpers";
import { selectRecurringTaskCustomValue, selectRecurringTaskValue, setDays } from "../../../utilities";

export const baseTaskToAllSteps = (
    task: WizardTaskAllSteps,
    baseTask: BaseTaskModel,
    offset?: number
): WizardTaskAllSteps => {
    const {
        name,
        description,
        fotoExample,
        isPhotoRequired,
        isCritical,
        fotoExampleId,
        baseTaskCheckList,
        baseTaskFields,
        id,
        reportType,
        baseTaskRangeHours,
        baseTaskScheduler,
        baseTaskTemporalityDateRange,
        neverEnds,
        numRecurrencies,
        taskType,
        endDate,
        taskTemporalityType,
        minPhotosAllowed,
    } = baseTask;

    const taskFieldsValidated: BaseTaskFieldModel[] = baseTaskFields || [];

    const hasChecklist = !!baseTaskCheckList?.length && reportType === "CHECKLIST";
    const isRecurrent = baseTaskScheduler?.typeCode !== "ONE_TIME";

    return {
        ...task,
        taskTitle: name ?? task.taskTitle,
        taskDesc: description ?? task.taskDesc,
        somethingToReportCheckBoxImage: fotoExample && fotoExample.length !== 0 ? true : false,
        somethingToReportImage: fotoExample && fotoExample.length !== 0 ? fotoExample : "",
        fotoExampleId: fotoExampleId ? Number(fotoExampleId) : undefined,
        isPhotoRequired,
        minPhotosAllowed: minPhotosAllowed || (isPhotoRequired ? 1 : null),
        criticalTask: isCritical,
        fK_BaseTask: id,
        fK_PlanAPPCC: baseTask.fK_PlanAPPCC !== undefined ? String(baseTask.fK_PlanAPPCC) : "-1",

        // Temporality
        temporalityType: taskTemporalityType,
        taskHours:
            taskTemporalityType === "PERIODICAL" || (taskTemporalityType === "ONE_DAY" && !!baseTaskRangeHours.length)
                ? baseTaskRangeHours.map(({ hour, maxHour }) => {
                      const offsetInMinutes = offset ? offset * -60 : 0;
                      const startHourFormated = getHoursFromDateUTC(
                          convertUTCtoLocaleDate(new Date(hour), offsetInMinutes)
                      );
                      const endHourFormated = maxHour
                          ? getHoursFromDateUTC(convertUTCtoLocaleDate(new Date(maxHour), offsetInMinutes))
                          : undefined;
                      return {
                          id: v4(),
                          hour: startHourFormated,
                          maxHour: endHourFormated,
                          type: maxHour ? "range" : "hour",
                      };
                  })
                : [],
        recurringTask: {
            isRecurrent,
            options: [],
            value:
                baseTaskScheduler?.calendarDayNumber !== 0
                    ? "custom"
                    : baseTaskScheduler?.repeatEvery !== 1
                    ? "custom"
                    : selectRecurringTaskValue(baseTaskScheduler?.typeCode || "ONE_TIME"),
            custom: {
                customValue:
                    baseTaskScheduler?.calendarDayNumber !== 0
                        ? "everyMonth"
                        : selectRecurringTaskCustomValue(baseTaskScheduler?.typeCode || "ONE_TIME"),
                days: setDays(baseTaskScheduler),
                repeatEvery: baseTaskScheduler?.repeatEvery || 1,
                selectedOptions: {
                    text: "",
                    value:
                        baseTaskScheduler?.calendarDayNumber !== 0
                            ? "everyMonthDayOfMonth"
                            : baseTaskScheduler?.calendarWeekNumber !== 0
                            ? "everyMonthFirstDayOfWeek"
                            : "",
                },
            },
            text: baseTaskScheduler?.name || "",
        },
        finish: {
            checked: neverEnds ? "never" : endDate ? "el" : numRecurrencies ? "after" : "never",
            value: neverEnds ? null : numRecurrencies ? numRecurrencies : endDate ? endDate : null,
        },
        taskType,

        // step 3

        checkList: hasChecklist
            ? baseTaskCheckList.map(({ id, name, isDeleted }) => ({
                  id: v4(),
                  dbId: Number(id),
                  isEnabled: true,
                  name,
                  isDeleted,
                  fK_BaseTaskCheckList: id,
              }))
            : [],
        reportType,
        dynamicFields: taskFieldsValidated.map(
            ({ dynamicFieldType, id, isRequired, label, baseTaskFieldOptions, isDeleted }, i) => ({
                dbId: id,
                dynamicFieldsType: dynamicFieldType || "DATE",
                id: v4(),
                isDeleted,
                isRequired: !!isRequired,
                label: label || "",
                taskFieldOptions:
                    baseTaskFieldOptions?.map((taskfield) => ({
                        label: taskfield.label,
                        id: v4(),
                    })) || [],
                order: i,
            })
        ),
    };
};
