import { FC, useContext, useState } from "react";
import { DateRangePicker, Input, SelectOptions, TimePicker } from "../__inputs";
import { useSession } from "app/hooks";
import {
    formatDateWithHour,
    formatDatesDateRange,
    formatDatesSimpleDate,
    getDateFormattedWithHoursSelects,
    getDateFromRangeString,
} from "app/helpers";
import { useTranslation } from "react-i18next";
import { DynamicFieldTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { Collapsable } from "../Collapsable/Collapsable";
import { DateTimePicker } from "../__inputs/DateTimePicker/DateTimePicker";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { TaskDynamicField } from "./TaskDynamicField";
import { TaskInstanceFieldValueModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldValueModel";
import { isCorrectIncorrectDynamicField, isDropdownDynamicField } from "app/helpers/dynamicFields/dynamicFieldHelper";
import IssueService from "app/services/05-QUA/IssueService";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";
import { useNavigate } from "react-router-dom";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";

type TaskDetailDynamicFieldsProps = {
    taskinstanceFields: TaskInstanceFieldModel[];
    onChange?: (TaskInstanceFields: TaskInstanceFieldModel[]) => void;
    readonly: boolean;
    errors?: number[];
};

type dropdownItem = { id: number; label: string; value: string };

export const TaskDetailDynamicFields: FC<TaskDetailDynamicFieldsProps> = ({
    taskinstanceFields,
    onChange,
    readonly,
    errors = [],
}) => {
    const { t } = useTranslation();
    const { open } = useContext(IssueModalContext);
    const nav = useNavigate();
    const session = useSession();

    const offset = session?.user.timeZoneOffset;

    const [taskInstanceFieldsCopy, setTaskInstanceFieldsCopy] = useState<TaskInstanceFieldModel[]>(taskinstanceFields);

    const handleInputChange = (id: number, newValue: string | dropdownItem[]) => {
        const data: TaskInstanceFieldModel[] = taskInstanceFieldsCopy.map((field) => {
            if (field.id !== id) return field;
            const taskInstanceFieldValues: TaskInstanceFieldValueModel[] = Array.isArray(newValue)
                ? newValue.map((dropItem) => ({ ...dropItem, min: null, max: null, measureUnit: "" }))
                : [{ ...field.taskInstanceFieldValues[0], value: newValue }];

            return {
                ...field,
                taskInstanceFieldValues: taskInstanceFieldValues,
            };
        });

        setTaskInstanceFieldsCopy(data);
        onChange && onChange(data);

        const updatedField = data.find(({ id: taskFieldId }) => taskFieldId === id);
        Array.isArray(newValue) && handleDynamicFieldIssue(updatedField, id, newValue);
    };

    const handleDynamicFieldIssue = async (
        updatedField: TaskInstanceFieldModel | undefined,
        id: number,
        newValue: dropdownItem[]
    ) => {
        if (!updatedField) return;

        if (!isCorrectIncorrectDynamicField(updatedField.dynamicFieldType)) return;

        const currentSelection = newValue.find(({ value }) => value === "true");
        if (!currentSelection) return;

        const previousTaskInstanceField = taskInstanceFieldsCopy.find(
            ({ id: taskInstanceFieldId }) => taskInstanceFieldId === id
        );
        if (!previousTaskInstanceField) return;

        const previousValues = previousTaskInstanceField.taskInstanceFieldValues.map(({ value }) => value);
        const isEmptyValues = previousValues.every((value) => value === "");
        const previousSelected = previousTaskInstanceField.taskInstanceFieldValues.find(
            ({ value }) => value === "true"
        );

        // If selected the same option or first selection is CORRECT do nothing
        if (
            previousSelected?.label === currentSelection.label ||
            (isEmptyValues && currentSelection.label === DynamicFieldTranslations.CORRECT)
        )
            return;

        if (!isEmptyValues && currentSelection.label === DynamicFieldTranslations.CORRECT) {
            await IssueService.DeleteIssueByTaskInstanceFieldIdAsync(id);
            return;
        }

        if (currentSelection.label === DynamicFieldTranslations.INCORRECT) {
            open({
                issueType: "TASK",
                taskinstanceId: previousTaskInstanceField.fK_TaskInstance,
                taskInstanceFieldId: id,
                onCancel: () => handleInputChange(id, addValueToOptions(updatedField.taskInstanceFieldValues, "")),
                issueServiceType: "ISSUE",
            });
            return;
        }
    };

    const addValueToOptions = (
        taskInstanceFieldValues: { id: number; label: string }[],
        selectedLabel: string
    ): dropdownItem[] => {
        return taskInstanceFieldValues.map((taskInstanceField) => ({
            ...taskInstanceField,
            value: t(taskInstanceField.label) === t(selectedLabel) ? "true" : "false",
        }));
    };

    const hasError = (id: number) => (errors.includes(id) ? t(TranslationCommon.INPUT_NOT_EMPTY) : "");

    const handleDateTimeChange = (value: string, id: number) => {
        const newDateTime = new Date(value);
        handleInputChange(id, formatDateWithHour(newDateTime));
    };

    const redirectIssue = (id: number | null | undefined) =>
        id != null && hasPermissionToView(session, SecScreen.ISSUE) && nav(`/${PrivatePaths.ISSUE}/edit/${id}`);

    const hasDynamicFieldIssue = (taskInstanceFieldValues: TaskInstanceFieldValueModel[]) =>
        taskInstanceFieldValues.some(
            ({ label, value }) => label === DynamicFieldTranslations.INCORRECT && value === "true"
        );

    return (
        <Collapsable title={t(TranslationKeys.DATAS_TO_REPORT)} isCollapsable={false}>
            {taskInstanceFieldsCopy?.map(
                ({ dynamicFieldType, id, isRequired, label, taskInstanceFieldValues, fK_Issue }) => {
                    return (
                        <div key={id} className="fullWidth">
                            {dynamicFieldType === "TEXT" ? (
                                <Input
                                    onChange={(newValue) => handleInputChange(id, newValue)}
                                    label={label}
                                    isOptional={!isRequired}
                                    placeholder={label}
                                    errorMessage={hasError(id)}
                                    readonly={readonly}
                                    value={taskInstanceFieldValues[0].value}
                                />
                            ) : dynamicFieldType === "NUMBER" ? (
                                <TaskDynamicField
                                    onChange={(newValue) => handleInputChange(id, newValue)}
                                    label={label}
                                    errorMessage={hasError(id)}
                                    fieldValue={taskInstanceFieldValues[0].value}
                                    isRequired={isRequired}
                                    readonly={readonly}
                                />
                            ) : dynamicFieldType === "TIME" ? (
                                <TimePicker
                                    label={label}
                                    onChange={(newValue) => handleInputChange(id, newValue)}
                                    value={taskInstanceFieldValues[0].value}
                                    isOptional={!isRequired}
                                    errorMessage={hasError(id)}
                                    readonly={readonly}
                                />
                            ) : dynamicFieldType === "DATETIME" ? (
                                <DateTimePicker
                                    label={label}
                                    isOptional={!isRequired}
                                    value={getDateFormattedWithHoursSelects(new Date(taskInstanceFieldValues[0].value))}
                                    onChange={(dateValue) => handleDateTimeChange(dateValue, id)}
                                    readonly={readonly}
                                    errorMessage={hasError(id)}
                                />
                            ) : dynamicFieldType === "DATERANGE" ? (
                                <DateRangePicker
                                    onChange={(dates) => handleInputChange(id, formatDatesDateRange(dates, offset))}
                                    range
                                    showMoreOptions
                                    label={label}
                                    isOptional={!isRequired}
                                    errorMessage={hasError(id)}
                                    readonly={readonly}
                                    startDate={
                                        taskInstanceFieldValues[0].value
                                            ? getDateFromRangeString(taskInstanceFieldValues[0].value, true)
                                            : undefined
                                    }
                                    endDate={getDateFromRangeString(taskInstanceFieldValues[0].value)}
                                    placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE_RANGE)}
                                />
                            ) : isDropdownDynamicField(dynamicFieldType) ? (
                                <SelectOptions
                                    isMulti={false}
                                    onChange={({ label }) =>
                                        handleInputChange(id, addValueToOptions(taskInstanceFieldValues, label))
                                    }
                                    label={label}
                                    isOptional={!isRequired}
                                    errorMessage={hasError(id)}
                                    readonly={readonly}
                                    selectedValue={
                                        taskInstanceFieldValues.find(({ value }) => value)?.id
                                            ? String(taskInstanceFieldValues.find(({ value }) => value === "true")?.id)
                                            : ""
                                    }
                                    options={taskInstanceFieldValues.map(({ id, label }) => ({
                                        label: t(label),
                                        value: String(id),
                                    }))}
                                    placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT)}
                                />
                            ) : dynamicFieldType === "DATE" ? (
                                <DateRangePicker
                                    onChange={([date]) => handleInputChange(id, formatDatesSimpleDate(date, offset))}
                                    label={label}
                                    isOptional={!isRequired}
                                    errorMessage={hasError(id)}
                                    readonly={readonly}
                                    startDate={
                                        taskInstanceFieldValues[0].value
                                            ? new Date(taskInstanceFieldValues[0].value)
                                            : undefined
                                    }
                                    range={false}
                                    placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_DATE)}
                                />
                            ) : (
                                isCorrectIncorrectDynamicField(dynamicFieldType) && (
                                    <SelectOptions
                                        isMulti={false}
                                        onChange={({ label }) =>
                                            handleInputChange(id, addValueToOptions(taskInstanceFieldValues, label))
                                        }
                                        label={label}
                                        isOptional={!isRequired}
                                        errorMessage={hasError(id)}
                                        readonly={readonly}
                                        selectedValue={
                                            taskInstanceFieldValues.find(({ value }) => value)?.id
                                                ? String(
                                                      taskInstanceFieldValues.find(({ value }) => value === "true")?.id
                                                  )
                                                : ""
                                        }
                                        options={taskInstanceFieldValues.map(({ id, label }) => ({
                                            label: t(label),
                                            value: String(id),
                                        }))}
                                        placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT)}
                                        readOnlyIconOptions={
                                            hasDynamicFieldIssue(taskInstanceFieldValues)
                                                ? {
                                                      value: faTriangleExclamation,
                                                      variant: "error",
                                                      onClick: () => redirectIssue(fK_Issue),
                                                  }
                                                : undefined
                                        }
                                    />
                                )
                            )}
                        </div>
                    );
                }
            )}
        </Collapsable>
    );
};
