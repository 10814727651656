import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import {
    WorkingPositionInsertModel,
    WorkingPositionModel,
} from "app/models/02-TAR/WorkingPosition/WorkingPositionModel";
import { PaginatedList } from "app/models/ServiceResponse/PaginatedResult";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { CompanyDepartmentDto } from "app/dtos/01-SEG/DepartmentDto";

export default class WorkingPositionService {
    // GET One WorkingPosition
    public static async GetOne(id: string | number, params?: PaginationParams) {
        return FetchService.get<WorkingPositionModel>({
            url: `${ApiPaths.TAR_API}/WorkingPosition/${id}`,
            paginationParams: params,
        });
    }

    // GET WorkingPosition List
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<WorkingPositionModel>>({
            url: `${ApiPaths.TAR_API}/WorkingPosition`,
            paginationParams: params,
        });
    }
    // GET Task List Formatted in CSV
    public static async GetDataExport(paginationParams?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.TAR_API}/WorkingPosition/export`,
            paginationParams,
            csvExport: true,
        });
    }

    // GET DepartmentList
    public static async GetDepartments(code: string, params?: PaginationParams) {
        return FetchService.get<PaginatedList<CompanyDepartmentDto, string>>({
            url: `${ApiPaths.TAR_API}/WorkingPosition/Departments/${code}`,
            paginationParams: params,
        });
    }

    // POST New WorkingPosition
    public static async Save(values: WorkingPositionInsertModel) {
        return FetchService.post({
            url: `${ApiPaths.TAR_API}/WorkingPosition`,
            body: values,
        });
    }

    // UPDATE Task Data
    public static async Edit(values: WorkingPositionInsertModel) {
        return FetchService.put({
            url: `${ApiPaths.TAR_API}/WorkingPosition/${values.id}`,
            body: values,
        });
    }

    // ACTIVATE Task Data
    public static async Activation(id: number | string) {
        return FetchService.put({
            url: `${ApiPaths.TAR_API}/WorkingPosition/activation/${id}`,
        });
    }

    // DEACTIVATE Task Data
    public static async Delete(id: number | string) {
        return FetchService.delete({
            url: `${ApiPaths.TAR_API}/WorkingPosition/${id}`,
        });
    }
}
