import { useEffect, useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useToast } from "app/hooks/Toast/useToast";
import { UserService } from "app/services";
import {
    TranslationCommon,
    TranslationErrors,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { UserCentersAndDepartmentsData } from "./types";
import CompanyDepartmentBox from "app/components_v2/CompanyDepartmentBox/CompanyDepartmentBox";
import {
    CIDepartamentalStructureModel,
    SimpleDepartmentModel,
    SimpleSubdepartmentModel,
} from "app/models/01-SEG/CIDepartamentalStructureModel";
import { useTitle } from "app/hooks";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { Button } from "app/components_v2/__buttons/Button/Button";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { SecScreen } from "app/shared/Constants";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";

type CentersAndDepartmentsTabProps = {
    onChangeNewData: (data: UserCentersAndDepartmentsData) => void;
    isDisabled?: boolean;
    values: CIDepartamentalStructureModel[];
    modifiedValues: CIDepartamentalStructureModel[];
};

export const CentersAndDepartmentsTab: FC<CentersAndDepartmentsTabProps> = ({
    onChangeNewData,
    isDisabled,
    modifiedValues,
    values,
}) => {
    const { t } = useTranslation();
    const { userId } = useParams<{ userId: string }>();
    const { handleToast } = useToast();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    useTitle(
        translateCustomerTypeKeys(TranslationTitles.USER_CENTERS_AND_DEPARTMENTS_PAGE_TITLE, { firstReplace: "PLURAL" })
    );

    const [originalData, setOriginalData] = useState<CIDepartamentalStructureModel[]>([]);
    const [modifiedData, setModifiedData] = useState<CIDepartamentalStructureModel[]>([]);
    const [isSelectAllCompAndDeptOpen, setIsSelectAllCompAndDeptOpen] = useState<boolean>(false);

    const getData = async () => {
        if (!userId) return;

        const { data, status } = await UserService.GetAllCompanies(Number(userId));
        if (!status()) {
            handleToast({
                title: t(TranslationErrors.GENERIC_ERROR_MESSAGE_TITLE),
                variant: "danger",
                type: "alert",
            });
            return;
        }

        setOriginalData(data);
        setModifiedData(data);
    };

    const handleModifyData = (companyId: number, newData: SimpleDepartmentModel[]) => {
        setModifiedData((prevData) => {
            const userCompanyData = prevData.map((company) => {
                if (company.companyId === companyId) {
                    return {
                        ...company,
                        departments: newData,
                    };
                }
                return company;
            });
            onChangeNewData({ modifiedData: userCompanyData, originalData: originalData });
            return userCompanyData;
        });
    };

    const handleChangeAssigned = (companyId: number) => {
        setModifiedData((prevData) => {
            const userCompanyData = prevData.map((company) => {
                if (company.companyId === companyId) {
                    return {
                        ...company,
                        assigned: !company.assigned,
                        departments: company.departments.map((department) => ({
                            ...department,
                            assigned: company.assigned ? false : department.assigned,
                            subdepartments: department.subdepartments.map((subdept: SimpleSubdepartmentModel) => ({
                                ...subdept,
                                assigned: false,
                                //  TODO: --SUBDEPARTMENT--
                                // assigned: company.assigned ? false : subdept.assigned,
                            })),
                        })),
                    };
                }
                return company;
            });
            onChangeNewData({ modifiedData: userCompanyData, originalData: originalData });
            return userCompanyData;
        });
    };

    const handleConfirmSelectAll = () => {
        setModifiedData((prev) => {
            const allSelected = prev.map(({ departments, ...company }) => ({
                ...company,
                assigned: true,
                departments: departments.map((department) => ({ ...department, assigned: true })),
            }));
            onChangeNewData({ modifiedData: allSelected, originalData: originalData });

            return allSelected;
        });
        setIsSelectAllCompAndDeptOpen(false);
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setOriginalData(values);
    }, [values]);

    useEffect(() => {
        setModifiedData(modifiedValues);
    }, [modifiedValues]);

    return (
        <>
            {isSelectAllCompAndDeptOpen && (
                <ConfirmModal
                    onClose={() => setIsSelectAllCompAndDeptOpen(false)}
                    onConfirm={handleConfirmSelectAll}
                    onConfirmText={t(TranslationCommon.ACCEPT)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={translateCustomerTypeKeys(
                        TranslationModals.EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_TITLE,
                        {
                            firstReplace: "PLURAL",
                            isGenderSensitive: true,
                        }
                    )}
                    description={translateCustomerTypeKeys(
                        TranslationModals.EDIT_FORM_USER_ADD_ALL_COMPANIES_AND_DEPARTMENTS_DESCRIPTION,
                        {
                            firstReplace: "PLURAL",
                            isGenderSensitive: true,
                        }
                    )}
                />
            )}
            <div className="userInfoForm ">
                <div className="userInfoForm--secondTab">
                    {hasPermissionToEdit(SecScreen.USER_MANAGEMENT) && (
                        <div className="userInfoForm__addAllDepartments">
                            <Button
                                text={t(TranslationCommon.ADD_ALL)}
                                onClick={() => setIsSelectAllCompAndDeptOpen(true)}
                                iconRight={faPlus}
                            />
                        </div>
                    )}
                    {modifiedData.map(({ assigned, companyId, companyName, departments }) => (
                        <CompanyDepartmentBox
                            assigned={assigned}
                            key={companyId}
                            title={companyName}
                            departments={departments}
                            sendModifiedData={(newData: SimpleDepartmentModel[]) =>
                                handleModifyData(companyId, newData)
                            }
                            handleToggleChecked={() => handleChangeAssigned(companyId)}
                            isDisabled={isDisabled}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};
