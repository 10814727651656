import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { WorkerProfileCountersDto } from "app/dtos/02-TAR/ReviewWorker/WorkerProfileCountersDto";
import { ReviewWorkerDto } from "app/dtos/02-TAR/ReviewWorker/ReviewWorkerDto";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

export default class ReviewWorkerService {
    // GET Single Worker Profile
    public static async GetOne(userId: number, params?: PaginationParams) {
        return FetchService.get<ReviewWorkerDto>({
            url: `${ApiPaths.TAR_API}/ReviewWorker/${userId}`,
            paginationParams: params,
        });
    }

    // GET Worker Profile List
    public static async GetData(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<ReviewWorkerDto>>({
            url: `${ApiPaths.TAR_API}/ReviewWorker`,
            paginationParams: params,
        });
    }

    // GET Worker Profile NotReviewed and Evaluated counters
    public static async GetWorkerProfileCounters(userId?: number, params?: PaginationParams) {
        return FetchService.get<WorkerProfileCountersDto>({
            url: `${ApiPaths.TAR_API}/ReviewWorker/WorkerProfileCounters/${userId}`,
            paginationParams: params,
        });
    }

    public static async GetWorkerTaskInstanceList(userId?: number, params?: PaginationParams) {
        return FetchService.get<PaginatedResult<TaskInstanceListDto>>({
            url: `${ApiPaths.TAR_API}/ReviewWorker/WorkerTaskInstance/${userId}`,
            paginationParams: params,
        });
    }
}
