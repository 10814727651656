import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { RadioButton } from "app/components_v2/RadioButton/RadioButton";
import { Input } from "app/components_v2/__inputs/Input/Input";
import { WizardTaskStep4, TaskEnds } from "app/models/02-TAR/TaskWizard";

type TaskEndsTimesProps = {
    onChange: (fields: Partial<WizardTaskStep4>) => void;
    finish: TaskEnds;
    customError?: string;
    disabled?: boolean;
    inverted?: boolean;
};

export const TaskEndsTimes: FC<TaskEndsTimesProps> = ({ finish, onChange, customError, disabled, inverted }) => {
    const { t } = useTranslation();

    if (finish.checked !== "after" && disabled) return null;
    return (
        <div className="taskEndsContainer">
            <div className="taskEndsContainer__radioInput">
                <RadioButton
                    onChange={() =>
                        onChange({
                            finish: {
                                ...finish,
                                checked: "after",
                                value: 0,
                            },
                        })
                    }
                    checked={finish.checked === "after"}
                    label={t(TranslationKeys.AFTER)}
                    htmlFor={"taskEnds_after"}
                    disabled={disabled}
                    inverted={inverted}
                />
                <Input
                    onChange={(newValue) =>
                        onChange({
                            finish: {
                                ...finish,
                                value: Number(newValue),
                            },
                        })
                    }
                    value={finish.value && finish.checked === "after" ? `${finish.value}` : ""}
                    placeholder={`5 ${t(TranslationKeys.REPETITIONS)}`}
                    disabled={!disabled ? finish.checked !== "after" : true}
                    type="number"
                    min={0}
                    errorMessage={customError}
                />
            </div>
        </div>
    );
};
