import { convertUTCtoLocaleDate, getHoursFromDateUTC } from "app/helpers";
import { v4 } from "uuid";
import {
    selectRecurringTaskCustomValue,
    selectRecurringTaskValue,
    setDateRangeWeeklyDays,
    setDays,
} from "./setInitialValues";
import { MeasurementTypes } from "app/shared/Constants";
import { AssetDynamicFieldModel } from "app/models/05-QUA/AssetModels";
import { TaskBodyWithIdModel, TaskFieldModel2, assetTaskFromBack } from "app/models/02-TAR/Task/TaskBody";
import {
    WizardTaskAllSteps,
    ReportDataList,
    AssetsToReport,
    ReportType,
    TaskTemporalityDateRangeFormModel,
} from "app/models/02-TAR/TaskWizard";
import { INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL } from "../constants/editTaskConstants";

export const fillFormValues = (
    values: TaskBodyWithIdModel,
    isDuplicate: boolean,
    offset?: number
): WizardTaskAllSteps => {
    const {
        description,
        name,
        fK_PlanAPPCC,
        fK_Company,
        fK_Department,
        fK_Subdepartment,
        fK_User,
        fK_WorkingPosition,
        workingPosition,
        user: userName,
        company: companyName,
        department: departmentName,
        subdepartment: subDepartmentName,
        isCritical,
        taskRangeHours,
        taskScheduler,
        neverEnds,
        numRecurrencies,
        endDate,
        fotoExampleId,
        fotoExample,
        isPhotoRequired,
        activeReassignedTo,
        taskCheckList,
        taskFields2,
        fK_BaseTask,
        taskType,
        startDate,
        taskTemporalityType,
        minPhotosAllowed,
    } = values;

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const taskFieldsValidated: TaskFieldModel2[] = taskFields2 || [];

    const isTaskFieldNone = taskFieldsValidated.filter((x) => !x.isDeleted).length === 0;
    const hasAssets = !isTaskFieldNone
        ? taskFieldsValidated.filter((x) => x.fK_Asset2 && !x.isDeleted).length > 0
        : false;

    const hasChecklist = !!taskCheckList?.length;
    const isRangeRegister = taskFieldsValidated
        .filter((x) => !x.isDeleted)
        .every(({ asset2 }) => asset2 != null && (asset2.fK_Company === 0 || asset2.fK_Company === null));
    const reportType: ReportType = hasChecklist
        ? "CHECKLIST"
        : isTaskFieldNone
        ? "NONE"
        : hasAssets
        ? isRangeRegister
            ? "RANGE_REGISTER"
            : "ASSET"
        : "DYNAMIC_FIELD";

    const dynamicFields = taskFieldsValidated.filter((x) => x.asset2 == null);
    const assets = getAsset(taskFieldsValidated);

    const isRecurrent = taskScheduler?.typeCode !== "ONE_TIME";

    const reportData: ReportDataList[] = assets.map(({ id, name }) => {
        const selectedAssetfields = taskFieldsValidated.filter((x) => x.fK_Asset2 === id);
        const isDeletedAsset = taskFieldsValidated
            .filter((x) => x.fK_Asset2 === id)
            .every(({ isDeleted }) => isDeleted);

        return {
            id: v4(),
            dbId: id,
            value: name || "",
            label: name,
            assetId: id,
            isDeleted: isDeletedAsset,
            assetsFields: selectedAssetfields.map<AssetsToReport>(
                ({
                    fK_AssetFieldRange,
                    assetFieldRange,
                    dynamicFieldType,
                    label,
                    id,
                    isDeleted,
                    isHidden,
                    taskFieldOptions,
                    isRequired,
                }) => ({
                    id: id || 0,
                    max: assetFieldRange?.max,
                    min: assetFieldRange?.min,
                    unit: assetFieldRange?.unit || MeasurementTypes.CELSIUS,
                    name: label || "",
                    type: label || "",
                    assetDynamicField: dynamicFieldType as AssetDynamicFieldModel,
                    assetFieldRangeId: fK_AssetFieldRange,
                    isDeleted,
                    isHidden,
                    isRequired: isRequired || true,
                    assetFieldOption: !!taskFieldOptions?.length
                        ? taskFieldOptions.map(({ label }) => ({
                              fK_AssetField: 0,
                              id: 0,
                              isDeleted: false,
                              name: label,
                          }))
                        : [],
                })
            ),
        };
    });

    return {
        fK_BaseTask: fK_BaseTask,
        taskTitle: name ?? "",
        taskDesc: description ?? "",
        belongsToAPPCC: fK_PlanAPPCC ? true : false,
        fK_PlanAPPCC: fK_PlanAPPCC ? String(fK_PlanAPPCC) : "-1",

        // Step 2
        companyForTask: {
            label: companyName || "",
            value: fK_Company ? String(fK_Company) : "",
            worksWithQr: false,
        },
        DepartmentForTask: {
            label: departmentName || "",
            value: fK_Department ? String(fK_Department) : "",
        },
        SubDepartementForTask: {
            label: subDepartmentName || "",
            value: fK_Subdepartment ? String(fK_Subdepartment) : "",
        },
        userForTask: {
            label: userName || "",
            value: fK_User ? String(fK_User) : "",
        },
        workingPositionForTask: {
            label: workingPosition?.customerInstanceWorkingPosition?.qrCode || "",
            value: fK_WorkingPosition ? String(fK_WorkingPosition) : "",
        },

        // Step 3
        somethingToReportCheckBox: false,
        reportType: reportType,
        somethingToReportDesc: taskFieldsValidated[0]?.label || "",
        somethingToReportCheckBoxImage: fotoExample && fotoExample.length !== 0 ? true : false,
        somethingToReportImage: fotoExample && fotoExample.length !== 0 ? fotoExample : "",
        imageBase64: null,
        fotoExampleId: Number(fotoExampleId),
        minPhotosAllowed: minPhotosAllowed || (isPhotoRequired ? 1 : null),
        criticalTask: isCritical,
        isPhotoRequired: isPhotoRequired,
        activeReassignedTo: activeReassignedTo,
        reportData: reportData,
        checkList: hasChecklist
            ? taskCheckList.map(({ id, name, isDeleted, isEnabled, fK_BaseTaskCheckList }) => ({
                  id: v4(),
                  dbId: isDuplicate ? undefined : Number(id),
                  name,
                  isDeleted,
                  isEnabled,
                  fK_BaseTaskCheckList,
              }))
            : [],
        // FIXME: taskfield
        // Treure reportType === "dynamicFields" || (reportType === "none" && !hasAssets)
        // per reportType === "dynamicFields"
        dynamicFields:
            // reportType === "dynamicFields" || (reportType === "none" && !hasAssets)
            //     ?
            // taskFieldsValidated.map(
            dynamicFields.map(({ dynamicFieldType, id, isRequired, label, taskFieldOptions, isDeleted, order }) => ({
                dbId: id,
                dynamicFieldsType: dynamicFieldType || "DATE",
                id: v4(),
                isDeleted,
                isRequired: !!isRequired,
                label: label || "",
                taskFieldOptions:
                    taskFieldOptions?.map((taskfield) => ({
                        label: taskfield.label,
                        id: v4(),
                    })) || [],
                order,
            })),
        // : []

        // Step 4
        temporalityType: taskTemporalityType,
        taskTemporalityDateRange: getDateRange(values),
        taskStart: isDuplicate ? new Date().toString() : !!startDate ? startDate.toString() : tomorrow.toString(),
        taskHours: taskRangeHours
            ? taskRangeHours.map(({ hour, maxHour }) => {
                  const offsetInMinutes = offset ? offset * -60 : 0;
                  const startHourFormated = getHoursFromDateUTC(
                      convertUTCtoLocaleDate(new Date(hour), offsetInMinutes)
                  );
                  const endHourFormated = maxHour
                      ? getHoursFromDateUTC(convertUTCtoLocaleDate(new Date(maxHour), offsetInMinutes))
                      : undefined;
                  return {
                      id: v4(),
                      hour: startHourFormated,
                      maxHour: endHourFormated,
                      type: maxHour ? "range" : "hour",
                  };
              })
            : [],
        recurringTask: {
            isRecurrent,
            options: [],
            value:
                taskScheduler?.calendarDayNumber !== 0
                    ? "custom"
                    : taskScheduler?.repeatEvery !== 1
                    ? "custom"
                    : selectRecurringTaskValue(taskScheduler?.typeCode || "ONE_TIME"),
            custom: {
                customValue:
                    taskScheduler?.calendarDayNumber !== 0
                        ? "everyMonth"
                        : selectRecurringTaskCustomValue(taskScheduler?.typeCode || "ONE_TIME"),
                days: setDays(taskScheduler),
                repeatEvery: taskScheduler?.repeatEvery || 1,
                selectedOptions: {
                    text: "",
                    value:
                        taskScheduler?.calendarDayNumber !== 0
                            ? "everyMonthDayOfMonth"
                            : taskScheduler?.calendarWeekNumber !== 0
                            ? "everyMonthFirstDayOfWeek"
                            : "",
                },
            },
            text: taskScheduler?.name || "",
        },
        finish: {
            checked: neverEnds ? "never" : endDate ? "el" : numRecurrencies ? "after" : "never",
            value: neverEnds ? null : numRecurrencies ? numRecurrencies : endDate ? endDate : null,
        },
        taskType,
    };
};

const getAsset = (taskFieldsValidated: TaskFieldModel2[]): assetTaskFromBack[] => {
    const assets: assetTaskFromBack[] = [];
    const assetIds: number[] = [];
    taskFieldsValidated.forEach((asset) => {
        if (asset.asset2 != null && asset.fK_Asset2 != null && !assetIds.includes(asset.fK_Asset2)) {
            assets.push(asset.asset2);
            assetIds.push(asset.fK_Asset2);
        }
    });
    return assets || [];
};

const getDateRange = ({
    taskTemporalityType,
    taskTemporalityDateRangeScheduler,
    taskTemporalityDateRange,
}: TaskBodyWithIdModel): TaskTemporalityDateRangeFormModel => {
    const isDateRangeWeeklyOrMonthly =
        taskTemporalityType === "RANGE_DATES" &&
        taskTemporalityDateRangeScheduler !== null &&
        taskTemporalityDateRangeScheduler.type !== "CUSTOM";

    const isDateRangeCustom = taskTemporalityType === "RANGE_DATES" && !!taskTemporalityDateRange.length;

    if (!isDateRangeWeeklyOrMonthly && !isDateRangeCustom) return INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL;

    if (isDateRangeCustom) {
        return {
            ...INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL,
            value: "CUSTOM",
            custom: {
                dateRange: taskTemporalityDateRange.map(({ endDate, isDeleted, startDate, id }) => ({
                    dbId: null,
                    id: v4(),
                    startDate: startDate.toString(),
                    endDate: endDate.toString(),
                    isDeleted,
                })),
            },
        };
    }
    if (!isDateRangeWeeklyOrMonthly) return INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL;

    const { id, type, repeatEvery, monthlyDay, monthlyType, monthlyStartDay, monthlyEndDay } =
        taskTemporalityDateRangeScheduler;
    const isMonthlyDays = monthlyType !== "" && monthlyType !== "CUSTOM" && monthlyDay != null;
    const isMonthlyCustomDays =
        monthlyType !== "" && monthlyType === "CUSTOM" && monthlyStartDay != null && monthlyEndDay != null;

    return {
        dbId: id,
        custom: {
            dateRange: [],
        },
        monthly:
            type === "MONTHLY"
                ? {
                      day: isMonthlyDays ? String(monthlyDay) : "",
                      customDays: {
                          startDay: isMonthlyCustomDays ? String(monthlyStartDay) : "",
                          endDay: isMonthlyCustomDays ? String(monthlyEndDay) : "",
                      },
                      value: monthlyType || "FIRST_X_DAYS",
                      text: "",
                  }
                : INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL.monthly,
        repeatEvery: String(repeatEvery),
        text: "",
        value: type,
        weeklyDays:
            type === "WEEKLY"
                ? setDateRangeWeeklyDays(taskTemporalityDateRangeScheduler)
                : INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL.weeklyDays,
    };
};
