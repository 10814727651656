import { faCircleXmark, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { DateRangePicker } from "app/components_v2/__inputs";
import { TaskTemporalityDateRangeCustomFormModel } from "app/models/02-TAR/TaskWizard";
import { TaskTranslations } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type TaskTemporalityDateRangeCustomProps = {
    taskTemporalityDates: TaskTemporalityDateRangeCustomFormModel[];
    isDisabled?: boolean;
    isAddNewVisible: boolean;
    onChangeDates: (dates: Date | [Date | null, Date | null] | null, id: string) => void;
    onAddNew: () => void;
    onDelete: (id: string) => void;
};

export const TaskTemporalityDateRangeCustom: FC<TaskTemporalityDateRangeCustomProps> = ({
    taskTemporalityDates,
    isDisabled,
    isAddNewVisible,
    onChangeDates,
    onAddNew,
    onDelete,
}) => {
    const { t } = useTranslation();

    return (
        <div className="taskTemporalityDateRangeCustom">
            {taskTemporalityDates.map(({ startDate, endDate, id }) => (
                <div className="taskTemporalityDateRangeCustom__date" key={id}>
                    <DateRangePicker
                        onChange={(dates) => onChangeDates(dates, id)}
                        startDate={new Date(startDate)}
                        endDate={!!endDate?.length ? new Date(endDate) : undefined}
                        range={true}
                        disabled={isDisabled}
                    />
                    <FontAwesomeIcon icon={faCircleXmark} className="deleteInputIcon" onClick={() => onDelete(id)} />
                </div>
            ))}
            {isAddNewVisible && (
                <AddNewButton onClick={onAddNew} icon={faPlus}>
                    {t(TaskTranslations.ADD_TASK_TEMPORALITY_RANGE_DATES)}
                </AddNewButton>
            )}
        </div>
    );
};
