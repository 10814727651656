import { Label } from "app/components_v2/__inputs";
import { CustomErrorTaskTemporalityModel, TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { TaskEnds } from "app/models/02-TAR/TaskWizard";
import {
    TaskEndsNever,
    TaskEndsSpecificDay,
    TaskEndsTimes,
} from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step4/components";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type TaskTemporalityPeriodicalEndsProps = {
    finish: TaskEnds;
    isDisabled?: boolean;
    customError: CustomErrorTaskTemporalityModel;
    min: string;
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
};

export const TaskTemporalityPeriodicalEnds: FC<TaskTemporalityPeriodicalEndsProps> = ({
    finish,
    onChange,
    isDisabled,
    customError,
    min,
}) => {
    const { t } = useTranslation();

    return (
        <div className="taskTemporalityPeriodical__recurrency__ends">
            <Label label={t(TranslationKeys.TASK_ENDS)} />

            <TaskEndsNever finish={finish} onChange={onChange} disabled={isDisabled} />
            <TaskEndsSpecificDay
                finish={finish}
                onChange={onChange}
                customError={customError.finishEl}
                min={min}
                disabled={isDisabled}
                errorClassName="task__temp__errorMessage"
            />
            <TaskEndsTimes
                finish={finish}
                onChange={onChange}
                customError={customError.finishResp}
                disabled={isDisabled}
            />
        </div>
    );
};
