import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useTitle } from "app/hooks/CustomHooks";
import { useLicenseTabs } from "./hooks/useLicenseTabs";
import { TabPropsReduced, Tabs } from "app/components_v2/Tabs/Tabs";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import LicenseForm from "./LicenseForm";
import ModulesForm from "./ModulesForm/ModulesForm";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { FormLayout } from "app/components_v2/Layout/FormLayout/FormLayout";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";

const LicenseTabs: FC = () => {
    const { t } = useTranslation();
    const { instanceId } = useParams();

    const {
        currentTab,
        fetchData,
        fetchConfig,
        handleChangeLicenseForm,
        handleChangeModules,
        handleDelete,
        handleSubmit,
        isDisabled,
        isLoading,
        isSaving,
        model,
        modules,
        normalized,
        setCurrentTab,
        setShowConfirmModal,
        showConfirmModal,
        showModal,
        status,
        handleCancel,
        validations,
        isLoadingConfirmModal,
    } = useLicenseTabs(instanceId);

    useTitle(t(TranslationTitles.MANAGE_LICENSE_PAGE_TITLE));

    const tabs: TabPropsReduced[] = [
        {
            text: t(TranslationTitles.GENERAL_INFO_TITLE),
            onClick: () => {
                setCurrentTab(0);
                fetchData();
            },
            type: "dark",
            children: model && (
                <LicenseForm
                    model={{ ...model, isActive: true }}
                    onChange={handleChangeLicenseForm}
                    errors={validations}
                />
            ),
        },
        {
            text: t(TranslationKeys.ENABLED_MODULES),
            onClick: () => {
                setCurrentTab(1);
                fetchConfig();
            },
            type: "dark",
            children: (
                <ModulesForm
                    isLoading={isLoading}
                    normalized={normalized}
                    modules={modules}
                    onChangeModules={handleChangeModules}
                />
            ),
        },
    ];

    return (
        <>
            {showConfirmModal && (
                <ConfirmModal
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    onClose={() => setShowConfirmModal(false)}
                    onConfirm={() => handleDelete()}
                    description={t(TranslationCommon.ARE_YOU_SURE)}
                    title={`${t(TranslationCommon.DELETE)} ${t(TranslationTitles.LICENSES_TITLE)}`}
                    isLoading={isLoadingConfirmModal}
                />
            )}
            <FormLayout
                variant="gray"
                headerOptions={{
                    isLoading: status === "loading",
                    title: `${t(TranslationKeys.MANAGE)} ${t(TranslationTitles.LICENSES_TITLE)}`,
                    buttonLabel: t(TranslationCommon.DELETE),
                    showDeleteButton: true,
                    onClick: showModal,
                    type: "delete",
                    tabsOptions: {
                        tabs: <Tabs tabs={tabs} currentTab={currentTab} hideChildren={true} />,
                    },
                }}
                isLoading={status === "loading"}
                footer={
                    <GenericModalFooter
                        confirmButton={{
                            text: t(TranslationCommon.SAVE),
                            type: "submit",
                            form: "licenseForm",
                            fullWidth: false,
                        }}
                        closeButton={{
                            text: t(TranslationCommon.CANCEL),
                            onClick: handleCancel,
                            disabled: isDisabled(),
                            buttonType: "tertiary",
                        }}
                        loading={isSaving}
                    />
                }
            >
                <form onSubmit={handleSubmit} id="licenseForm">
                    <div className="editIssue__container">{tabs[currentTab].children}</div>
                </form>
            </FormLayout>
        </>
    );
};

export default LicenseTabs;
