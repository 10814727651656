import { Label } from "app/components_v2/__inputs";
import { ReadOnlyInput } from "app/components_v2/__inputs/ReadOnlyInput/ReadOnlyInput";
import { getDayMonthFormatedWithYearNumbers } from "app/helpers";
import { TaskTemporalityDateRangeCustom } from "app/models/02-TAR/TaskWizard";
import { TaskTemporalityReadOnlyTranslations } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type TaskTemporalityDateRangeCustomDisabledProps = {
    custom: TaskTemporalityDateRangeCustom;
};

export const TaskTemporalityDateRangeCustomDisabled: FC<TaskTemporalityDateRangeCustomDisabledProps> = ({ custom }) => {
    const { t } = useTranslation();

    return (
        <div className="taskTemporalityDateRangeCustomDisabled">
            <Label label={t(TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_DATE_RANGE_TITLE)} />

            <div className="taskTemporalityDateRangeCustomDisabled__dates">
                {custom.dateRange.map(({ startDate, endDate, id }) => (
                    <ReadOnlyInput
                        key={id}
                        value={`${getDayMonthFormatedWithYearNumbers(
                            startDate,
                            "/"
                        )} - ${getDayMonthFormatedWithYearNumbers(endDate, "/")}`}
                        options={{ width: "auto" }}
                    />
                ))}
            </div>
        </div>
    );
};
