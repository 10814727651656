import { Label } from "app/components_v2/__inputs";
import { ReadOnlyInput } from "app/components_v2/__inputs/ReadOnlyInput/ReadOnlyInput";
import { Divider } from "app/components_v2/Divider/Divider";
import { CustomErrorTaskTemporalityModel, TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { TaskHoursModel } from "app/models/02-TAR/TaskWizard";
import { TaskStart } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskTemporality/components";
import { TaskTemporalityReadOnlyTranslations, TaskTranslations } from "app/translation/translationKeys";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { TaskTemporalityTitleDisabled } from "../TaskTemporalityTitleDisabled/TaskTemporalityTitleDisabled";

type TaskTemporalityOneDayDisabledProps = {
    taskStart: string;
    customError: CustomErrorTaskTemporalityModel;
    isDisabled?: boolean;
    min?: Date;
    taskstartLabel?: string;
    hint?: ReactNode;
    taskHours: TaskHoursModel[];
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
};

export const TaskTemporalityOneDayDisabled: FC<TaskTemporalityOneDayDisabledProps> = ({
    customError,
    onChange,
    taskStart,
    hint,
    isDisabled,
    min,
    taskstartLabel,
    taskHours,
}) => {
    const { t } = useTranslation();

    const getValue = (hour: string, maxHour?: string) => {
        if (!maxHour?.length) return hour;

        return `${hour} - ${maxHour}`;
    };

    return (
        <div className="taskTemporalityOneDayDisabled">
            <TaskTemporalityTitleDisabled title={t(TaskTranslations.TASK_TEMPORALITY_ONE_DAY)} />
            <TaskStart
                onChange={onChange}
                taskStart={taskStart}
                customError={customError.taskStart}
                disabled={isDisabled}
                min={min}
                label={taskstartLabel}
                hint={hint}
                readonly
            />
            <Divider />
            <Label label={t(TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_HOURS_TITLE)} />
            <div className="taskTemporalityOneDayDisabled__hours">
                {taskHours.map(({ id, hour, maxHour }) => (
                    <ReadOnlyInput key={id} options={{ width: "auto" }} value={getValue(hour, maxHour)} type="date" />
                ))}
            </div>
        </div>
    );
};
