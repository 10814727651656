import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";

export default class TaskinstanceFieldService {
    public static async UpdateIsOperative(fkTaskinstance: number, fkAsset2: number, isOperative?: boolean) {
        return FetchService.put({
            url: `${ApiPaths.TAR_API}/TaskinstanceField/${fkTaskinstance}/${fkAsset2}`,
            body: { isOperative },
        });
    }
}
