import { UserType } from "app/shared/types/UserTypes";

type IProps = {
    userId?: number;
    companyId?: number;
    userType?: UserType;
};

export const fillIssueExtraParams = ({ companyId, userId, userType }: IProps) => {
    const extraParams = new URLSearchParams();

    if (!!userId) extraParams.append("userId", userId.toString() || "");
    if (!!companyId) extraParams.append("companyId", companyId.toString() || "");
    if (userType === "WORKER") extraParams.append("onlySupervisors", "true");

    return extraParams.toString();
};
