import { BaseTaskFormModel, BaseTaskValidationModel } from "../models/EditBaseTaskFormModels";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { days } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/utilities/initialValues";

export const INITIAL_DATA_BASE_TASK_MODEL: BaseTaskFormModel = {
    taskTitle: "",
    taskDesc: "",
    belongsToAPPCC: false,
    fK_PlanAPPCC: "",
    DepartmentForTask: { label: "", value: "" },
    SubDepartementForTask: { label: "", value: "" },
    userForTask: { label: "", value: "" },
    isPhotoRequired: false,
    somethingToReportImage: "",
    minPhotosAllowed: null,
    criticalTask: false,
    checkList: [],
    dynamicFields: [],
    reportData: [],
    reportType: "NONE",
    allowAnyData: false,

    // BaseTask Temporality
    temporalityType: "NONE",
    taskStart: new Date().toString(),
    taskHours: [],
    recurringTask: {
        isRecurrent: true,
        options: [],
        value: "everyDay",
        custom: {
            customValue: "day",
            days,
            repeatEvery: 1,
            selectedOptions: { text: "", value: "" },
        },
        text: "everyDay",
    },
    finish: {
        checked: "never",
        value: null,
    },
    taskType: "NORMAL",

    // Unused
    somethingToReportCheckBox: false,
};
export const INITIAL_BASE_TASK_VALIDATIONS: BaseTaskValidationModel = {
    taskTitle: "",
    baseTaskType: "",
    planAPPCC: "",
    dynamicFields: [],
    reportData: "",
    checklist: [],

    // BaseTask Temporality
    taskStart: "",
    taskHours: {
        value: "",
        errors: [],
    },
    finishEl: "",
    finishResp: "",
    customReps: "",
    customDaysBubles: "",
    customMonth: "",
    selectOption: "",
    dateRange: "",
    temporalityType: "",
    temporalityDateRangeRepeatEvery: "",
    temporalityDateRange: {
        weekly: "",
        monthlyDay: "",
        monthlyStartDay: "",
        monthlyEndDay: "",
    },

    // Unused
    companyForTask: "",
};

export const EMPTY_BASE_TASK_TYPE_OPTION: OptionsSearch = { label: "", value: "" };
