import { PaginatedList } from "app/models/ServiceResponse/PaginatedResult";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import {
    TaskBodyWithIdModel,
    WizardTaskBody,
    WizardTaskBodyAndId,
    TaskBodyToSendModel,
} from "app/models/02-TAR/Task/TaskBody";
import { TabTaskModel } from "app/models/02-TAR/TaskPage";
import { TaskCountersDto } from "app/dtos/02-TAR/Task/TaskCountersDto";
import { TaskListDto } from "app/dtos/02-TAR/Task/TaskListDto";
import { ReassignTaskModel } from "app/models/02-TAR/Task/ReassignTaskModel";

export default class TaskService {
    // GET Single Task
    public static async GetOne(taskId: string | number) {
        return FetchService.get<TaskBodyWithIdModel>({
            url: `${ApiPaths.TAR_API}/Task/${taskId}`,
        });
    }

    // GET Task List
    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedList<TaskListDto, TabTaskModel>>({
            url: `${ApiPaths.TAR_API}/Task`,
            paginationParams,
        });
    }

    // GET Task List
    public static async GetCounters(paginationParams?: PaginationParams) {
        return FetchService.get<TaskCountersDto>({
            url: `${ApiPaths.TAR_API}/Task/Counters`,
            paginationParams,
        });
    }

    // GET Task List Formatted in CSV
    public static async GetDataExport(paginationParams?: PaginationParams) {
        return FetchService.get({
            url: `${ApiPaths.TAR_API}/Task/export`,
            paginationParams,
            csvExport: true,
        });
    }

    // POST New Task
    public static async Save(values: WizardTaskBody) {
        return FetchService.post<WizardTaskBodyAndId>({
            url: `${ApiPaths.TAR_API}/Task`,
            body: values,
        });
    }

    // UPDATE Task Data
    public static async Edit(values: TaskBodyToSendModel) {
        return FetchService.put<WizardTaskBodyAndId>({
            url: `${ApiPaths.TAR_API}/Task/${values.id}`,
            body: values,
        });
    }

    // UPDATE Reasign Task
    public static async ReasignTask(reasignValues: ReassignTaskModel) {
        return FetchService.post({
            url: `${ApiPaths.TAR_API}/Task/Reassign`,
            body: reasignValues,
        });
    }

    // UPDATE Cancel Reasign Task
    public static async CancelReasign(taskId: number) {
        return FetchService.put({
            url: `${ApiPaths.TAR_API}/Task/CancelReassignation/${taskId}`,
        });
    }

    // DELETE Task
    public static async Delete(taskId: number) {
        return FetchService.delete<TaskBodyWithIdModel>({
            url: `${ApiPaths.TAR_API}/Task/${taskId}`,
        });
    }

    // DELETE MultiTask
    public static async MultipleDelete(taskIds: number[]) {
        return FetchService.delete({
            url: `${ApiPaths.TAR_API}/Task/DeleteMultiple`,
            body: taskIds,
        });
    }
}
