import { CustomErrorTaskTemporalityModel, TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { FC, ReactNode } from "react";
import { TaskTemporalityDateRangeFormModel } from "app/models/02-TAR/TaskWizard";
import { TaskTemporalityDateRangeCustom } from "./TaskTemporalityDateRangeCustom/TaskTemporalityDateRangeCustom";
import { TaskTemporalityDateRangeMonthly } from "./TaskTemporalityDateRangeMonthly/TaskTemporalityDateRangeMonthly";
import { TaskTemporalityDateRangeRepeatEvery } from "./TaskTemporalityDateRangeRepeatEvery/TaskTemporalityDateRangeRepeatEvery";
import { TaskTemporalityDateRangeWeekly } from "./TaskTemporalityDateRangeWeekly/TaskTemporalityDateRangeWeekly";
import { useTaskTemporalityDateRangeCustom } from "./hooks/useTaskTemporalityDateRangeCustom";
import { useTaskTemporalityDateRangeMonthly } from "./hooks/useTaskTemporalityDateRangeMonthly";
import { useTaskTemporalityDateRangeRepeatEvery } from "./hooks/useTaskTemporalityDateRangeRepeatEvery";
import { useTaskTemporalityDateRangeWeek } from "./hooks/useTaskTemporalityDateRangeWeek";
import { TaskStart } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskTemporality/components";

type TaskTemporalityDateRangeProps = {
    customError: CustomErrorTaskTemporalityModel;
    numberMaxOfDates?: number;
    isDisabled?: boolean;
    taskTemporalityDateRange?: TaskTemporalityDateRangeFormModel;
    min?: Date;
    taskstartLabel?: string;
    taskStart: string;
    hint?: ReactNode;
    onChange: (value: Partial<TaskTemporalityModel>) => void;
};

export const TaskTemporalityDateRange: FC<TaskTemporalityDateRangeProps> = ({
    customError,
    isDisabled,
    numberMaxOfDates = 12,
    taskTemporalityDateRange,
    hint,
    min,
    taskstartLabel,
    taskStart,
    onChange,
}) => {
    if (!taskTemporalityDateRange) return null;

    const { onChangeTemporality } = useTaskTemporalityDateRangeRepeatEvery({ onChange, taskTemporalityDateRange });
    const { onChangeWeeklyDays } = useTaskTemporalityDateRangeWeek({ onChange, taskTemporalityDateRange });
    const { onChangeCustomDays, onChangeDays, onChangeMonthly } = useTaskTemporalityDateRangeMonthly({
        onChange,
        taskTemporalityDateRange,
    });
    const { onAddNew, onChangeDates, onDelete } = useTaskTemporalityDateRangeCustom({
        onChange,
        taskTemporalityDateRange,
    });

    const { monthly, repeatEvery, value, weeklyDays } = taskTemporalityDateRange;
    const taskTemporalityDateRangeFiltered = taskTemporalityDateRange.custom.dateRange.filter(
        ({ isDeleted }) => !isDeleted
    );
    const isAddNewVisible = taskTemporalityDateRangeFiltered.length < numberMaxOfDates && !isDisabled;

    return (
        <>
            <div className="taskTemporalityPeriodical__taskStart">
                <TaskStart
                    onChange={onChange}
                    taskStart={taskStart}
                    customError={customError.taskStart}
                    disabled={isDisabled}
                    min={min}
                    label={taskstartLabel}
                    hint={hint}
                />
            </div>
            <div className="taskTemporalityDateRange">
                <TaskTemporalityDateRangeRepeatEvery
                    repeatEvery={repeatEvery}
                    temporalityValue={value}
                    onChangeRepeatEvery={(value) =>
                        onChange({ taskTemporalityDateRange: { ...taskTemporalityDateRange, repeatEvery: value } })
                    }
                    onChangeTemporality={onChangeTemporality}
                    repeatEveryError={customError.temporalityDateRangeRepeatEvery}
                    isDisabled={isDisabled}
                />
                {taskTemporalityDateRange.value === "WEEKLY" && (
                    <TaskTemporalityDateRangeWeekly
                        onChange={onChangeWeeklyDays}
                        weeklyDays={weeklyDays}
                        weeklyDaysError={customError.temporalityDateRange.weekly}
                    />
                )}
                {taskTemporalityDateRange.value === "MONTHLY" && (
                    <TaskTemporalityDateRangeMonthly
                        customErrors={customError.temporalityDateRange}
                        monthlyTemporality={monthly}
                        onChangeMonthly={onChangeMonthly}
                        onChangeDays={onChangeDays}
                        onChangeCustomDays={onChangeCustomDays}
                    />
                )}
                {taskTemporalityDateRange.value === "CUSTOM" && (
                    <TaskTemporalityDateRangeCustom
                        isAddNewVisible={isAddNewVisible}
                        isDisabled={isDisabled}
                        onAddNew={onAddNew}
                        onChangeDates={onChangeDates}
                        onDelete={onDelete}
                        taskTemporalityDates={taskTemporalityDateRangeFiltered}
                    />
                )}
                {!!customError.dateRange.length && <ErrorMessage errorMessage={customError.dateRange} />}
            </div>
        </>
    );
};
