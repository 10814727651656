import { FC } from "react";
import { NotificationDistributionListModel } from "app/models/01-SEG/NotificationDistributionList/NotificationDistributionListModel";
import { useTranslation } from "react-i18next";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { faEnvelope, faPen, faTrash, faUser } from "@fortawesome/pro-regular-svg-icons";
import { hasPermissionToDelete } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";

type MobileNotificationDistributionListRowProps = {
    data: NotificationDistributionListModel;
    onClickEdit: (record: NotificationDistributionListModel) => void;
    onClickDelete: (record: NotificationDistributionListModel) => void;
};

export const MobileNotificationDistributionListRow: FC<MobileNotificationDistributionListRowProps> = ({
    data,
    onClickEdit,
    onClickDelete,
}) => {
    const { t } = useTranslation();
    const { functionality, companyName, users, emails } = data;

    return (
        <div className="mobileNotificationDistributionListRow">
            <div className="mobileNotificationDistributionListRow__header">
                <p className="mobileNotificationDistributionListRow__title text_clamp text_clamp-2">
                    {t(functionality)}
                </p>
                <p className="mobileNotificationDistributionListRow__small text_clamp text_clamp-2">
                    {companyName || "-"}
                </p>
            </div>
            <div className="mobileNotificationDistributionListRow__counter">
                <Badge title={String(users.length)} variant="blue" icon={faUser} iconPosition="right" />
            </div>
            <div className="mobileNotificationDistributionListRow__counter">
                <Badge
                    title={String(emails?.emails.split(";").length || 0)}
                    variant="blue"
                    icon={faEnvelope}
                    iconPosition="right"
                />
            </div>
            <CellIcons
                icons={[
                    {
                        icon: faPen,
                        onClick: () => onClickEdit(data),
                    },
                    {
                        icon: faTrash,
                        onClick: () => onClickDelete(data),
                        hidden: !hasPermissionToDelete(SecScreen.DISTRIBUTION_LIST),
                    },
                ]}
            />
        </div>
    );
};
