import { TaskTemporalityDateRangeFormModel } from "app/models/02-TAR/TaskWizard";
import { TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { v4 } from "uuid";

type IUseTaskTemporalityDateRangeCustom = {
    taskTemporalityDateRange: TaskTemporalityDateRangeFormModel;
    onChange: (value: Partial<TaskTemporalityModel>) => void;
};

export const useTaskTemporalityDateRangeCustom = ({
    taskTemporalityDateRange,
    onChange,
}: IUseTaskTemporalityDateRangeCustom) => {
    const handleChangeDates = (dates: Date | [Date | null, Date | null] | null, id: string) => {
        if (dates === null || !Array.isArray(dates)) return;
        const [startDate, endDate] = dates;
        if (startDate === null || endDate === null) return;
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                custom: {
                    dateRange: taskTemporalityDateRange.custom.dateRange.map((ttdr) =>
                        ttdr.id === id
                            ? {
                                  ...ttdr,
                                  startDate: startDate.toString(),
                                  endDate: endDate.toString(),
                              }
                            : ttdr
                    ),
                },
            },
        });
    };

    const handleAddNew = () => {
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                custom: {
                    dateRange: [
                        ...taskTemporalityDateRange.custom.dateRange,
                        {
                            dbId: null,
                            startDate: new Date().toString(),
                            endDate: new Date().toString(),
                            id: v4(),
                            isDeleted: false,
                        },
                    ],
                },
            },
        });
    };

    const handleDelete = (id: string) => {
        const selectedDate = taskTemporalityDateRange.custom.dateRange.find((ttdr) => ttdr.id === id);
        if (!selectedDate) return;

        if (selectedDate.dbId === null) {
            onChange({
                taskTemporalityDateRange: {
                    ...taskTemporalityDateRange,
                    custom: { dateRange: taskTemporalityDateRange.custom.dateRange.filter((ttdr) => ttdr.id !== id) },
                },
            });
            return;
        }

        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                custom: {
                    dateRange: taskTemporalityDateRange.custom.dateRange.map((ttdr) =>
                        ttdr.id === id
                            ? {
                                  ...ttdr,
                                  isDeleted: true,
                              }
                            : ttdr
                    ),
                },
            },
        });
    };

    return {
        onChangeDates: handleChangeDates,
        onAddNew: handleAddNew,
        onDelete: handleDelete,
    };
};
