import { convertUTCtoLocaleDate } from "../Date.utilities";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

export const isDisabledRange = (taskInstance: TaskInstanceListDto) => {
    return taskInstance.taskType !== "SPORADIC"
        ? !!taskInstance.startDate &&
              convertUTCtoLocaleDate(taskInstance.startDate) > new Date() &&
              taskInstance.taskTemporalityType !== "RANGE_DATES"
        : false;
};
