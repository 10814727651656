import { DayBubble } from "app/components_v2/__inputs";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { CustomDays } from "app/models/02-TAR/TaskWizard";
import { FC } from "react";

type TaskTemporalityDateRangeWeeklyProps = {
    weeklyDays: CustomDays[];
    weeklyDaysError: string;
    onChange: (id: number) => void;
};

export const TaskTemporalityDateRangeWeekly: FC<TaskTemporalityDateRangeWeeklyProps> = ({
    onChange,
    weeklyDays,
    weeklyDaysError,
}) => {
    return (
        <div className="taskTemporalityDateRangeWeekly">
            <div className="taskTemporalityDateRangeWeekly__bubbles">
                {weeklyDays.map(({ id, isActive, value }) => (
                    <DayBubble key={id} active={isActive} letter={value} onClick={() => onChange(id)} />
                ))}
            </div>
            {!!weeklyDaysError.length && <ErrorMessage errorMessage={weeklyDaysError} />}
        </div>
    );
};
