import { TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import {
    TaskTemporalityDateRangeFormModel,
    TaskTemporalityDateRangeMonthlyCustomDays,
    TaskTemporalityDateRangeMonthlyValue,
} from "app/models/02-TAR/TaskWizard";
import { TaskTemporalityDateRangeTranslations } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type IUseTaskTemporalityDateRangeMonthly = {
    taskTemporalityDateRange: TaskTemporalityDateRangeFormModel;
    onChange: (value: Partial<TaskTemporalityModel>) => void;
};

export const useTaskTemporalityDateRangeMonthly = ({
    onChange,
    taskTemporalityDateRange,
}: IUseTaskTemporalityDateRangeMonthly) => {
    const { t } = useTranslation();

    const { monthly } = taskTemporalityDateRange;
    const { day } = monthly;
    const handleChangeMonthly = (monthlyValue: TaskTemporalityDateRangeMonthlyValue) => {
        if (monthlyValue === "FIRST_X_DAYS") {
            handleChangeMonthlyFirsXDays();
            return;
        }

        if (monthlyValue === "LAST_X_DAYS") {
            handleChangeMonthlyLastXDays();
            return;
        }

        if (monthlyValue === "CUSTOM") {
            handleChangeMonthlyCustom();
            return;
        }
    };
    const handleChangeMonthlyFirsXDays = () => {
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                monthly: {
                    customDays: { startDay: "", endDay: "" },
                    day: day || "1",
                    value: "FIRST_X_DAYS",
                    text: t(
                        TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTLHY_FIRST_X_DAYS_LABEL
                    ),
                },
            },
        });
    };
    const handleChangeMonthlyLastXDays = () => {
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                monthly: {
                    customDays: { startDay: "", endDay: "" },
                    day: day || "1",
                    value: "LAST_X_DAYS",
                    text: t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTLHY_LAST_X_DAYS_LABEL),
                },
            },
        });
    };
    const handleChangeMonthlyCustom = () => {
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                monthly: {
                    customDays: { startDay: "1", endDay: "1" },
                    day: "",
                    value: "CUSTOM",
                    text: t(
                        TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTLHY_CUSTOM_X_DAYS_LABEL
                    ),
                },
            },
        });
    };
    const handleChangeDays = (dayValue: string) => {
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                monthly: { ...monthly, day: dayValue },
            },
        });
    };
    const handleChangeCustomDays = (days: TaskTemporalityDateRangeMonthlyCustomDays) => {
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                monthly: { ...monthly, customDays: days },
            },
        });
    };

    return {
        onChangeMonthly: handleChangeMonthly,
        onChangeDays: handleChangeDays,
        onChangeCustomDays: handleChangeCustomDays,
    };
};
