import { SelectOptions } from "app/components_v2/__inputs";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { USER_GRID_LOGGED_ONCE, USER_GRID_PROFILE_PICTURE } from "app/pages/01-SEG/Users/constants/userGridConstants";
import {
    UserGridLoggedOnce,
    UserGridProfilePicute,
    UserGridSecondaryFilterModel,
} from "app/pages/01-SEG/Users/models/userGridSecondaryFilterModel";
import { TranslationCommon, TranslationKeys, UserTranslations } from "app/translation/translationKeys";
import { isEqual } from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

type UserFilterProps = {
    filterData: UserGridSecondaryFilterModel;
    initialFilterData: UserGridSecondaryFilterModel;
    isSecondaryFilterOpen: boolean;
    onCancel?: () => void;
    onSave?: (values: UserGridSecondaryFilterModel) => void;
    isOpen: boolean;
};
export const UserFilter: FC<UserFilterProps> = ({
    filterData,
    initialFilterData,
    isOpen,
    isSecondaryFilterOpen,
    onCancel,
    onSave,
}) => {
    const { t } = useTranslation();
    const [internalFilterData, setInternalFilterData] = useState<UserGridSecondaryFilterModel>(filterData);

    const userLoggedOptions: OptionModel[] = [
        { label: t(UserTranslations.USERS_LOGGED_ONCE_ALL), value: USER_GRID_LOGGED_ONCE.ALL },
        { label: t(UserTranslations.USERS_LOGGED_ONCE), value: USER_GRID_LOGGED_ONCE.ONCE },
        { label: t(UserTranslations.USERS_NOT_LOGGED_ONCE), value: USER_GRID_LOGGED_ONCE.NEVER },
    ];
    const userProfilePictureOptions: OptionModel[] = [
        { label: t(TranslationCommon.SELECT_OPTION), value: USER_GRID_PROFILE_PICTURE["-1"] },
        { label: t(UserTranslations.USERS_HAS_PROFILE_PICTURE), value: USER_GRID_PROFILE_PICTURE.HAS_PROFILE_PICTURE },
        {
            label: t(UserTranslations.USERS_NOT_PROFILE_PICTURE),
            value: USER_GRID_PROFILE_PICTURE.DO_NOT_HAS_PROFILE_PICTUTE,
        },
    ];

    const handleInputChange = (values: Partial<UserGridSecondaryFilterModel>) => {
        setInternalFilterData((prev) => ({ ...prev, ...values }));
    };

    const handleClickOutside = () => {
        if (!isOpen) return;
        handleInputChange(filterData);
        onCancel && onCancel();
    };

    const handleReset = () => handleInputChange(initialFilterData);

    const handleSave = () => onSave && onSave(internalFilterData);

    return (
        <SecondaryFilter
            isOpen={isSecondaryFilterOpen}
            onClickOutside={handleClickOutside}
            onCancel={handleClickOutside}
            onReset={handleReset}
            onSave={handleSave}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
        >
            <SelectOptions
                isMulti={false}
                onChange={({ value }) => handleInputChange({ loggedOnce: value as UserGridLoggedOnce })}
                options={userLoggedOptions}
                label={t(UserTranslations.USERS_LOGGED_ONCE_LABEL)}
                selectedValue={internalFilterData.loggedOnce !== "ALL" ? internalFilterData.loggedOnce : undefined}
                placeholder={t(UserTranslations.USERS_LOGGED_ONCE_ALL)}
            />
            <SelectOptions
                isMulti={false}
                onChange={({ value }) => handleInputChange({ profilePicture: value as UserGridProfilePicute })}
                options={userProfilePictureOptions}
                label={t(UserTranslations.USERS_PROFILE_LABEL)}
                selectedValue={
                    internalFilterData.profilePicture !== "-1" ? internalFilterData.profilePicture : undefined
                }
                placeholder={t(TranslationCommon.SELECT_OPTION)}
            />
        </SecondaryFilter>
    );
};
