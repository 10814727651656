import { DragFile } from "app/components_v2/__draggableFiles/DragFile/DragFile";
import { FC } from "react";
import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { ImageDragFile } from "app/components_v2/__draggableFiles/DragFile/types";
import { SecScreen } from "app/shared/Constants";
import { TranslationKeys } from "app/translation/translationKeys";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";
import { ProgresssBarWithLabel } from "app/components_v2/ProgressBarWithLabel/ProgressBarWithLabel";

type TaskDetailDragFileProps = {
    images?: ImageDragFile[];
    errorMessage?: string;
    isDisabled?: boolean;
    minPhotosAllowed: number | null;
    onChange: (photos: ImageDragFile[]) => Promise<void>;
    onDelete: (id: string) => void;
    onEditImage?: (image: ImageDragFile, editedImageId: string) => void;
};

export const TaskDetailDragFile: FC<TaskDetailDragFileProps> = ({
    onChange,
    onDelete,
    errorMessage,
    images,
    onEditImage,
    isDisabled,
    minPhotosAllowed = 1,
}) => {
    const { t } = useTranslation();
    const session = useSession();

    const totalImages = images?.length || 0;
    const minPhotos = minPhotosAllowed || 1;
    const percentage = (totalImages * 100) / minPhotos;

    return (
        <div className="taskDetailDragFile">
            <div className="taskDetailDragFile__header">
                <h2
                    className={`taskDetailDragFile__title ${minPhotos > 1 ? "" : "taskDetailDragFile__title--primary"}`}
                >
                    {t(TranslationKeys.ATTACHED_FILES)}
                </h2>
                {minPhotos > 1 && (
                    <div className="taskDetailDragFile__progressBar">
                        <ProgresssBarWithLabel
                            percentage={percentage}
                            barSize="sm"
                            barLabel={`${totalImages}/${minPhotos}`}
                            barLabelSize="sm"
                        />
                    </div>
                )}
            </div>
            <DragFile
                name={t(TranslationKeys.PHOTOGRAPHIC_REGISTER)}
                placeholder={t(TranslationKeys.CLICK_TO_UPLOAD)}
                subPlaceholder={t(TranslationKeys.UPLOAD_TYPE)}
                onChange={onChange}
                onDelete={onDelete}
                images={images}
                disabled={isDisabled}
                captureMode={hasPermissionToView(session, SecScreen.ALLOW_GALERY_PHOTO) ? "both" : "camera"}
                onEditImage={onEditImage}
                errorMessage={errorMessage}
                editable
                multiple
            />
        </div>
    );
};
