import {
    NotificationDistributionListErrors,
    NotificationDistributionListFormValues,
    SelectedNotificationDistributionList,
} from "../models/NotificationDistributionListFormValues";
import { NotificationDistributionListGridSF } from "../models/NotificationDistributionListGridModels";

export const INITIAL_NOTIFICATION_DISTRIBUTION_LIST_SELECTED: SelectedNotificationDistributionList = {
    id: 0,
    functionality: "",
    companyName: "",
    fK_Company: "",
    fK_DistributionList: "",
    userList: [],
    emails: [],
};

export const INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS: NotificationDistributionListErrors = {
    errorFunctionality: "",
    errorCompany: "",
    errorUserList: "",
    errorEmails: "",
    errorNotSelected: "",
};

export const INITIAL_NOTIFICATION_DISTRIBUTION_LIST_FORM_VALUES: NotificationDistributionListFormValues = {
    companyList: [],
    fK_DistributionList: "",
    userList: [],
    emails: [],
    notifyUsers: false,
    notifyEmails: false,
};

export const INITIAL_NOTIFICATION_DISTRIBUTION_LIST_SF: NotificationDistributionListGridSF = {
    functionality: "-1",
};
