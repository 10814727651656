import { FC } from "react";
import { SelectOptions } from "app/components_v2/__inputs/SelectOptions/SelectOptions";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { WizardTaskStep4, RecurrentTaskModel, MonthlyValues } from "app/models/02-TAR/TaskWizard";

type CustomSelectOptionsEveryMonthProps = {
    onChange: (fields: Partial<WizardTaskStep4>) => void;
    recurringTask: RecurrentTaskModel;
    weekOptions: OptionsSearch[];
    customError?: string;
    disabled?: boolean;
};

export const CustomSelectOptionsEveryMonth: FC<CustomSelectOptionsEveryMonthProps> = ({
    recurringTask,
    onChange,
    weekOptions,
    customError,
    disabled,
}) => {
    return (
        // Select para la el mes
        //  -> tarea recurrente
        //      -> personalizar
        //          -> Mes
        <SelectOptions
            isMulti={false}
            onChange={({ label, value }) => {
                onChange({
                    recurringTask: {
                        ...recurringTask,
                        custom: {
                            ...recurringTask.custom,
                            customValue: "everyMonth",
                            selectedOptions: {
                                text: label,
                                value: value as MonthlyValues,
                            },
                        },
                    },
                });
            }}
            options={weekOptions}
            selectedValue={recurringTask.custom.selectedOptions.value}
            errorMessage={customError}
            disabled={disabled}
            isSearchable={false}
        />
    );
};
