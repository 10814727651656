import { BreadcrumsTranslation } from "app/shared/Constants";
import { QuantityType } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { TFunction } from "react-i18next";
import { TranslationCommon, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";

export const translateHelper = (
    crumb: string,
    t: TFunction<"translation", undefined>,
    translateCompany: (quantityType: QuantityType) => string
) => {
    crumb = crumb.split("?")[0];

    if (crumb.length > 0) {
        switch (crumb) {
            case BreadcrumsTranslation.MY_PROFILE:
                crumb = t(TranslationKeys.MY_PROFILE);
                break;
            case BreadcrumsTranslation.USERS_PAGE:
                crumb = t(TranslationTitles.USERS_TITLE);
                break;
            case BreadcrumsTranslation.GROUP_COMPANY:
                crumb = translateCompany("PLURAL");
                break;
            case BreadcrumsTranslation.ROLE_PAGE:
                crumb = t(TranslationKeys.ROLES);
                break;
            case BreadcrumsTranslation.COMPANY_PAGE:
                crumb = translateCompany("SINGULAR");
                break;
            case BreadcrumsTranslation.DEPARTMENT_PAGE:
                crumb = t(TranslationTitles.DEPARTMENT_TITLE);
                break;
            case BreadcrumsTranslation.NEW:
                crumb = t(TranslationCommon.NEW_M);
                break;
            case BreadcrumsTranslation.EDIT:
                crumb = t(TranslationCommon.EDIT);
                break;
            case BreadcrumsTranslation.LICENSE_PAGE:
                crumb = t(TranslationTitles.LICENSES_TITLE);
                break;
            case BreadcrumsTranslation.TEMPORARY:
                crumb = t(TranslationKeys.TEMPORARY);
                break;
            case BreadcrumsTranslation.SELECT_DEPARTMENT:
                crumb = t(TranslationKeys.SELECT_DEPARTMENT);
                break;
            case BreadcrumsTranslation.TASK_PHOTO:
                crumb = t(TranslationTitles.HEADER_REVIEW_TASKS_TITLE);
                break;
            case BreadcrumsTranslation.DETAIL:
                crumb = t(TranslationKeys.DETAIL);
                break;
            case BreadcrumsTranslation.FEEDBACK:
                crumb = t(TranslationKeys.FEEDBACK);
                break;
            case BreadcrumsTranslation.DUPLICATE:
                crumb = t(TranslationKeys.DUPLICATE);
                break;
            case BreadcrumsTranslation.TASKS:
                crumb = t(TranslationTitles.TASK_PAGE_TITLE);
                break;
            case BreadcrumsTranslation.TASKS_HISTORY:
                crumb = t(TranslationTitles.TASK_HISTORY_PAGE_TITLE);
                break;
            case BreadcrumsTranslation.DETAILS:
                crumb = t(TranslationCommon.DETAILS);
                break;
            case BreadcrumsTranslation.ASSET_PAGE:
                crumb = t(TranslationKeys.ASSET_PAGE);
                break;
            case BreadcrumsTranslation.ASSET_RANGE_PAGE:
                crumb = t(TranslationTitles.ASSET_RANGE_PAGE_TITLE);
                break;
            case BreadcrumsTranslation.FAST_NEW:
                crumb = t(TranslationCommon.NEW_M);
                break;
            case BreadcrumsTranslation.RANKING:
                crumb = t(TranslationKeys.RANKING);
                break;
            case BreadcrumsTranslation.WORKING_POSITION:
                crumb = t(TranslationKeys.WORKING_POSITION);
                break;
            case BreadcrumsTranslation.RANKING_SUPERVISOR:
                crumb = t(TranslationKeys.RANKING_TITLE_USER);
                break;
            case BreadcrumsTranslation.TEMPORARY_ROLE_PAGE:
                crumb = t(TranslationTitles.TEMPORARY_ROLES_TITLE);
                break;
            case BreadcrumsTranslation.BASE_TASK_PAGE:
                crumb = t(TranslationTitles.BASE_TASK_TITLE);
                break;
            case BreadcrumsTranslation.BASE_TASK_PAGE_APPCC:
                crumb = t(TranslationTitles.BASE_TASK_REGISTRY_TITLE);
                break;
            case BreadcrumsTranslation.ISSUE_PAGE:
                crumb = t(TranslationTitles.ISSUE_PAGE_TITLE);
                break;
            case BreadcrumsTranslation.PLAN_APPCC_PAGE:
                crumb = t(TranslationTitles.PLAN_APPCC_PAGE_TITLE);
                break;
            case BreadcrumsTranslation.TASK_APPCC_PAGE:
                crumb = t(TranslationKeys.TASK_PAGE_REGISTRY_TITLE);
                break;
            case BreadcrumsTranslation.AUDIT_HISTORY:
                crumb = t(TranslationTitles.AUDIT_GRID_TITLE);
                break;
            default:
                crumb = "";
                break;
        }
    }

    return crumb;
};
