import {
    CheckListModel,
    DynamicFieldsModel,
    DynamicFieldsType,
    ReportDataList,
    ReportType,
} from "app/models/02-TAR/TaskWizard";
import {
    CustomErrorReportDataModel,
    DataToReportModel,
    EditDataModel,
    EditTaskDisabledDataToReportModel,
} from "app/models/02-TAR/Task/EditTask";
import { DataToReportReadOnly } from "../../../components/DataToReportReadOnly/DataToReportReadOnly";
import { ExtraData } from "../ExtraData/ExtraData";
import { FC, useEffect } from "react";
import {
    getCorrectIncorrectOptions,
    isCorrectIncorrectDynamicField,
    isDropdownDynamicField,
} from "app/helpers/dynamicFields/dynamicFieldHelper";
import {
    onAddNewCheckList,
    onBlurCheckList,
    onDeleteCheckList,
} from "app/helpers/dataToReport/dataToReportChecklistHelper";
import { OptionsSearchT } from "app/models/FormComponentsModel";
import { TaskTranslations, TranslationKeys } from "app/translation/translationKeys";
import { useDynamicFields } from "app/hooks/useDynamicFields";
import { useGetAssets } from "app/hooks/Asset/useGetAssets";
import { useGetDynamicFieldsTranslations } from "app/hooks/dynamicFields/useGetDynamicFieldsTranslations";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import { WhiteBoxCollapsable } from "app/components_v2/WhiteBox/WhiteBoxCollapsable/WhiteBoxCollapsable";
import { Divider } from "app/components_v2/Divider/Divider";
import { FieldValues, SelectDataToReport } from "app/components_v2/WizardTask/SelectDataToReport/SelectDataToReport";

type DataToReportProps = EditDataModel<
    DataToReportModel,
    CustomErrorReportDataModel,
    EditTaskDisabledDataToReportModel
> & {
    fK_PlanAPPCC?: string;
    options: OptionsSearchT<ReportType>[];
};

export const DataToReport: FC<DataToReportProps> = ({
    onChange,
    reportData,
    customError,
    reportType,
    companyId,
    checkList,
    isDisabled,
    dynamicFields,
    disabledFields,
    fK_PlanAPPCC,
    options,
    allowAnyData,
    variant = "primary",
    ...extraDataValues
}) => {
    const { t } = useTranslation();

    const { assets, error: isAssetErrored, isLoading: isAssetsLoading, getAssets } = useGetAssets();
    const { getDynamicFieldTranslation } = useGetDynamicFieldsTranslations();
    const { dynamicFieldsOptions, getDynamicFields, isLoadingDynamicFields } = useDynamicFields();

    const isDynamicFieldOrChecklist = reportType === "DYNAMIC_FIELD" || reportType === "CHECKLIST";

    const handleChange = async (inputValues: Partial<FieldValues>) => {
        if (inputValues.fieldValue === null || inputValues.selectableValue === "NONE") {
            resetReportFields(inputValues);

            const customContainer = document.querySelector(".formLayoutScroll__children ");
            if (!customContainer) return;
            customContainer.scrollTo({ top: customContainer.scrollHeight, behavior: "smooth" });
            return;
        }

        if (inputValues.selectableValue === "ASSET" || inputValues.selectableValue === "RANGE_REGISTER") {
            handleSelectAsset(inputValues);
            return;
        }

        if (inputValues.selectableValue === "CHECKLIST") {
            handleSelectCheckList(inputValues);
            return;
        }

        if (inputValues.selectableValue === "DYNAMIC_FIELD" && inputValues.dynamicFieldsType) {
            handleSelectDynamicField(inputValues.dynamicFieldsType);
            return;
        }
    };

    const resetReportFields = ({ selectableValue, dynamicFieldsType }: Partial<FieldValues>) => {
        const reportDataReset: ReportDataList[] | undefined = [
            ...reportData
                .map((data) => ({
                    ...data,
                    isDeleted: true,
                }))
                .filter(({ dbId }) => dbId),
        ];
        const newReportData: ReportDataList = {
            id: v4(),
            isDeleted: false,
            value: "",
        };
        const checklistReset: CheckListModel[] | undefined = [
            ...checkList
                .map((data) => ({ ...data, isDeleted: true }))
                .filter(({ dbId }) => dbId)
                .filter(({ name }) => name),
        ];
        const newChecklist: CheckListModel = {
            id: v4(),
            name: "",
            isDeleted: false,
            isEnabled: true,
            fK_BaseTaskCheckList: null,
        };
        const resetDynamicField: DynamicFieldsModel[] | undefined = [
            ...dynamicFields.map((data) => ({ ...data, isDeleted: true })).filter(({ dbId }) => dbId),
        ];

        onChange({
            reportType: selectableValue,
            reportData: selectableValue === "DATA" ? [...reportDataReset, newReportData] : reportDataReset,
            checkList: selectableValue === "CHECKLIST" ? [...checkList, newChecklist] : checklistReset,
            dynamicFields: selectableValue !== "DYNAMIC_FIELD" || !dynamicFieldsType ? resetDynamicField : [],
        });
    };

    const handleSelectAsset = ({ fieldValue }: Partial<FieldValues>) => {
        if (!fieldValue) return;

        const assetFiltered = assets.find(({ id }) => id === Number(fieldValue.value));
        if (!assetFiltered) return;
        const { id: assetId, name, assetFields } = assetFiltered;

        onChange({
            reportData: [
                ...reportData,
                {
                    id: v4(),
                    isDeleted: false,
                    value: name,
                    assetId: Number(assetId),
                    assetsFields: assetFields.map(
                        ({
                            assetDynamicField,
                            label,
                            assetFieldRange,
                            id,
                            isHidden,
                            assetFieldOption,
                            isRequired,
                        }) => ({
                            id: Number(id),
                            name: label,
                            assetDynamicField: assetDynamicField,
                            assetFieldRangeId: assetFieldRange?.id,
                            isDeleted: false,
                            max: assetFieldRange?.max,
                            min: assetFieldRange?.min,
                            unit: assetFieldRange?.unit,
                            isHidden,
                            assetFieldOption,
                            isRequired,
                        })
                    ),
                },
            ],
        });
    };

    const handleDeleteAsset = (id: number) => {
        onChange({
            reportData: reportData
                .map((report) =>
                    report.assetId === id
                        ? {
                              ...report,
                              isDeleted: true,
                              assetsFields: report.assetsFields?.map((assetField) => ({
                                  ...assetField,
                                  isDeleted: true,
                              })),
                          }
                        : report
                )
                .filter(({ dbId, isDeleted }) => (!dbId && !isDeleted) || dbId),
        });
    };

    const handleSelectCheckList = ({ fieldValue }: Partial<FieldValues>) => {
        if (!fieldValue?.id) return;
        onChange({
            checkList: checkList.map((value) =>
                value.id === fieldValue.id ? { ...value, name: fieldValue.value } : value
            ),
        });
    };

    const handleAddNewCheckList = () => {
        onChange({ checkList: onAddNewCheckList(checkList) });
    };

    const handleDeleteCheckList = (checkListId: string) => {
        onChange({ checkList: onDeleteCheckList(checkListId, checkList) });
    };

    const handleBlurCheckList = () => {
        onChange({ checkList: onBlurCheckList(checkList) });
    };

    const handleSelectDynamicField = (dynamicFieldType: DynamicFieldsType) => {
        const dynamicFieldsFiltered = dynamicFields.filter(({ isDeleted }) => !isDeleted);
        if (dynamicFieldsFiltered.length >= 20) return;

        const lastDynamicField = dynamicFields.at(-1);
        onChange({
            dynamicFields: [
                ...dynamicFields,
                {
                    dynamicFieldsType: dynamicFieldType,
                    id: v4(),
                    isRequired: true,
                    taskFieldOptions: isCorrectIncorrectDynamicField(dynamicFieldType)
                        ? getCorrectIncorrectOptions()
                        : isDropdownDynamicField(dynamicFieldType)
                        ? [{ id: v4(), label: "" }]
                        : [],
                    label: "",
                    isDeleted: false,
                    order: lastDynamicField?.order != null ? lastDynamicField.order + 1 : 0,
                },
            ],
        });
    };
    const handleDynamicFieldInputChange = (value: string, id: string) => {
        onChange({
            dynamicFields: [
                ...dynamicFields.map((field) =>
                    field.id === id
                        ? {
                              ...field,
                              label: value,
                          }
                        : field
                ),
            ],
        });
    };
    const handleDynamicFieldCheckboxChange = (value: boolean, id: string) => {
        onChange({
            dynamicFields: [
                ...dynamicFields.map((field) =>
                    field.id === id
                        ? {
                              ...field,
                              isRequired: value,
                          }
                        : field
                ),
            ],
        });
    };
    const handleDeleteDynamicField = (id: string) => {
        const selectedDynamicField = dynamicFields.find((field) => field.id === id);
        if (!selectedDynamicField) return;
        if (selectedDynamicField.dbId) {
            onChange({
                dynamicFields: dynamicFields.map((field) =>
                    field.id === id ? { ...field, isDeleted: true, taskFieldOptions: [] } : field
                ),
            });
            return;
        }

        onChange({
            dynamicFields: dynamicFields.filter((field) => field.id !== id),
        });
    };
    const handleDeleteDynamicDropDownField = (id: string, itemId: string) => {
        const selectedDynamicField = dynamicFields.find((field) => field.id === id);
        if (!selectedDynamicField) return;
        if (selectedDynamicField.taskFieldOptions.length < 2) return;
        onChange({
            dynamicFields: [
                ...dynamicFields.map((field) =>
                    field.id === id
                        ? {
                              ...field,
                              taskFieldOptions: [
                                  ...field.taskFieldOptions.filter((taskfield) => taskfield.id !== itemId),
                              ],
                          }
                        : field
                ),
            ],
        });
    };
    const handleChangeDynamicDropDownField = (id: string, itemId: string, value: string) => {
        const selectedDynamicField = dynamicFields.find((field) => field.id === id);
        if (!selectedDynamicField) return;
        onChange({
            dynamicFields: [
                ...dynamicFields.map((field) => {
                    if (field.id !== id) return field;
                    return {
                        ...field,
                        taskFieldOptions: [
                            ...field.taskFieldOptions.map((taskfield) => {
                                if (taskfield.id !== itemId) return taskfield;
                                return {
                                    ...taskfield,
                                    label: value,
                                };
                            }),
                        ],
                    };
                }),
            ],
        });
    };
    const handleAddNewDynamicDropDownField = (id: string) => {
        const selectedDynamicField = dynamicFields.find((field) => field.id === id);
        if (!selectedDynamicField) return;
        if (selectedDynamicField.taskFieldOptions.length >= 10) return;
        onChange({
            dynamicFields: [
                ...dynamicFields.map((field) => {
                    if (field.id !== id) return field;
                    return {
                        ...field,
                        taskFieldOptions: [...field.taskFieldOptions, { id: v4(), label: "" }],
                    };
                }),
            ],
        });
    };

    const handleEnableCheckList = (index: number) =>
        onChange({
            checkList: checkList.map((value, i) => {
                if (index === i) return { ...value, isEnabled: !value.isEnabled };
                return value;
            }),
        });

    useEffect(() => {
        if (reportType === "DYNAMIC_FIELD") getDynamicFields();
        if (reportType === "RANGE_REGISTER") getAssets(null);
    }, [reportType]);

    useEffect(() => {
        if (reportType === "ASSET") getAssets(companyId || 0, fK_PlanAPPCC);
    }, [reportType, companyId]);

    return (
        <div className="dataToReportGrid">
            <WhiteBoxCollapsable
                whiteBoxOptions={{ fullWidth: true }}
                collapsableOptions={{
                    title:
                        allowAnyData || reportType !== "NONE"
                            ? t(TranslationKeys.DATA_TO_REPORT_ATTACHED)
                            : t(TaskTranslations.TASK_ATTATHCMENTS_TITLE),
                    border: "none",
                    variant,
                }}
            >
                <div className="dataToReport">
                    <ExtraData
                        {...extraDataValues}
                        isDisabled={isDisabled}
                        customError={customError}
                        onChange={onChange}
                        disabledFields={disabledFields}
                    />
                    {!allowAnyData && reportType === "NONE" ? null : !allowAnyData && isDynamicFieldOrChecklist ? (
                        <>
                            <Divider />
                            <DataToReportReadOnly
                                dynamicfields={dynamicFields.filter((x) => !x.isDeleted)}
                                checkList={checkList.filter((x) => !x.isDeleted)}
                                reportType={reportType}
                                onEnable={handleEnableCheckList}
                            />
                        </>
                    ) : (
                        <>
                            <Divider />
                            <SelectDataToReport
                                onChange={handleChange}
                                options={options}
                                reportData={reportData}
                                value={reportType}
                                assets={assets}
                                onDeleteAsset={handleDeleteAsset}
                                isAssetsLoading={isAssetsLoading}
                                isAssetErrored={!!isAssetErrored}
                                customError={customError.reportData}
                                dynamicFieldsError={customError.dynamicFields}
                                checklistError={customError.checklist}
                                disabled={isDisabled}
                                checkList={checkList}
                                onAddNewChecklist={handleAddNewCheckList}
                                onDeleteCheckList={handleDeleteCheckList}
                                onBlurCheckList={handleBlurCheckList}
                                dynamicFieldsOptions={dynamicFieldsOptions.map(({ label, value }) => ({
                                    label: getDynamicFieldTranslation(label as DynamicFieldsType),
                                    value,
                                }))}
                                isLoadingDynamicFields={isLoadingDynamicFields}
                                dynamicFields={dynamicFields}
                                onDynamicFieldInputChange={handleDynamicFieldInputChange}
                                onDynamicFieldCheckboxChange={handleDynamicFieldCheckboxChange}
                                onDeleteDynamicField={handleDeleteDynamicField}
                                onDeleteDynamicDropDownField={handleDeleteDynamicDropDownField}
                                onChangeDynamicDropDownField={handleChangeDynamicDropDownField}
                                onAddNewDynamicDropDownField={handleAddNewDynamicDropDownField}
                                disabledChecklist={disabledFields?.disabledDataToReport}
                                disabledDynamicFields={disabledFields?.disabledDataToReport}
                                hideSelect={
                                    !allowAnyData && (reportType === "ASSET" || reportType === "RANGE_REGISTER")
                                }
                            />
                        </>
                    )}
                </div>
            </WhiteBoxCollapsable>
        </div>
    );
};
