import { SortedTypeModel } from "app/components_v2/Table/types";
import {
    UserGridLoggedOnce,
    UserGridProfilePicute,
    UserGridSecondaryFilterModel,
} from "../models/userGridSecondaryFilterModel";

export const INITIAL_USER_GRID_SORT_FIELD = "firstName";
export const INITIAL_USER_GRID_SORT_DIRECTION: SortedTypeModel = "asc";
export const INITIAL_USER_GRID_SECONDARY_FILTER: UserGridSecondaryFilterModel = {
    loggedOnce: "ALL",
    profilePicture: "-1",
};
export const USER_GRID_LOGGED_ONCE: Record<UserGridLoggedOnce, UserGridLoggedOnce> = {
    ALL: "ALL",
    ONCE: "ONCE",
    NEVER: "NEVER",
};

export const USER_GRID_PROFILE_PICTURE: Record<UserGridProfilePicute, UserGridProfilePicute> = {
    "-1": "-1",
    HAS_PROFILE_PICTURE: "HAS_PROFILE_PICTURE",
    DO_NOT_HAS_PROFILE_PICTUTE: "DO_NOT_HAS_PROFILE_PICTUTE",
};
