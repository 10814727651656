import { FC, useEffect, useState } from "react";
import { ReviewTaskFilterData } from "./types";
import { isEqual } from "lodash";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { CheckBox } from "app/components_v2/CheckBox/CheckBox";
import { useTranslation } from "react-i18next";
import { ReviewTaskTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { FilterStars } from "../DashboardFilter/components/FilterStars/FilterStars";
import { SelectOptions } from "app/components_v2/__inputs";
import { DataToReportReview } from "app/models/utilities";
import { DataToReportReviewValues } from "app/shared/Constants";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { fillExtraparams, IFillExtraparams } from "./helpers/fillExtraparams";

type ReviewTaskFilterProps = IFillExtraparams & {
    filterData: ReviewTaskFilterData;
    initialFilterData: ReviewTaskFilterData;
    isOpen: boolean;
    onCancel?: () => void;
    onSave?: (values: ReviewTaskFilterData) => void;
    onClickOutside?: () => void;
    onReset?: () => void;
};

export const ReviewTaskFilter: FC<ReviewTaskFilterProps> = ({
    filterData,
    initialFilterData,
    isOpen,
    companyId,
    endDate,
    startDate,
    onCancel,
    onSave,
    onClickOutside,
    onReset,
}) => {
    const { t } = useTranslation();

    const [internalFilterData, setInternalFilterData] = useState<ReviewTaskFilterData>(filterData);
    const [taskOptions, setTaskOptions] = useState<OptionModel[]>([]);
    const [isLoadingTask, setIsLoadingTask] = useState<boolean>(true);

    const dataToReportOptions: OptionModel[] = [
        { label: t(ReviewTaskTranslations.ALL_DATA_TO_REPORT), value: DataToReportReviewValues.ALL },
        { label: t(ReviewTaskTranslations.NO_DATA_TO_REPORT), value: DataToReportReviewValues.NO_DATA },
        { label: t(ReviewTaskTranslations.WITH_DATA_TO_REPORT), value: DataToReportReviewValues.WITH_DATA },
        { label: t(ReviewTaskTranslations.WITH_CHECKLIST_TO_REPORT), value: DataToReportReviewValues.WITH_CHECKLIST },
        { label: t(ReviewTaskTranslations.WITH_ASSET_TO_REPORT), value: DataToReportReviewValues.WITH_ASSET },
    ];

    const handleInputChange = (values: Partial<ReviewTaskFilterData>) => {
        setInternalFilterData((prev) => ({ ...prev, ...values }));
    };

    const getTaskOptions = async () => {
        setIsLoadingTask(true);
        const extraParams = fillExtraparams({ startDate, endDate, companyId });
        const { data, status } = await TarSelectorService.GetTasks({ extraParams });

        setIsLoadingTask(false);
        setTaskOptions(status() ? [{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...data] : []);
    };

    useEffect(() => {
        setInternalFilterData(filterData);
    }, [filterData]);

    useEffect(() => {
        if (!isOpen) return;
        getTaskOptions();
    }, [isOpen]);

    return (
        <SecondaryFilter
            isOpen={isOpen}
            onSave={() => {
                onSave && onSave(internalFilterData);
            }}
            onCancel={() => {
                onCancel && onCancel();
                handleInputChange(filterData);
            }}
            onClickOutside={() => {
                onClickOutside && onClickOutside();
                handleInputChange(filterData);
            }}
            onReset={() => {
                onReset && onReset();
                handleInputChange(initialFilterData);
            }}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
        >
            <SelectOptions
                isMulti={false}
                onChange={({ value }) => handleInputChange({ dataToReportReview: value as DataToReportReview })}
                options={dataToReportOptions}
                selectedValue={internalFilterData.dataToReportReview}
                label={t(ReviewTaskTranslations.LINKED_DATA)}
            />
            <SelectOptions
                isMulti={false}
                onChange={({ value }) => handleInputChange({ taskId: value })}
                options={taskOptions}
                selectedValue={internalFilterData.taskId !== "-1" ? internalFilterData.taskId : undefined}
                label={t(TranslationKeys.TASK_PAGE_TASK_SUBTITLE)}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
                isLoading={isLoadingTask}
            />
            <div className="reviewTaskFilter">
                <FilterStars
                    filterData={internalFilterData}
                    onChange={({ star, state }) => handleInputChange({ [star]: state })}
                />
                <div className="reviewTaskFilter__checkboxes">
                    <CheckBox
                        label={t(TranslationKeys.PIC_ASSOCIATED_TASK)}
                        htmlFor={TranslationKeys.PIC_ASSOCIATED_TASK}
                        onChange={(checked) => handleInputChange({ isPhotoRequired: checked })}
                        checked={!!internalFilterData.isPhotoRequired}
                    />
                    <CheckBox
                        label={t(TranslationKeys.TASK_COMMENTS_TITLE)}
                        htmlFor={TranslationKeys.TASK_COMMENTS_TITLE}
                        onChange={(checked) => handleInputChange({ hasComments: checked })}
                        checked={!!internalFilterData.hasComments}
                    />
                </div>
            </div>
        </SecondaryFilter>
    );
};
