import { FC, useEffect, useState } from "react";
import { TaskService } from "app/services";
import { MobileTaskRow } from "../../07-TAR-CRUD/TaskGrid/components/MobileTaskRow/MobileTaskRow";
import { Section, SectionsShowProp } from "../Section/Section";
import { TaskBodyWithIdModel } from "app/models/02-TAR/Task/TaskBody";

export const TableTaskPillSection: FC<SectionsShowProp> = ({ show }) => {
    const [data, setData] = useState<TaskBodyWithIdModel>();

    useEffect(() => {
        const fetch = async () => {
            const { data, status } = await TaskService.GetOne(1);
            if (status()) setData(data);
        };
        fetch();
    }, []);

    const [isChecked, setIsChecked] = useState<boolean>(false);

    return (
        <Section title="Table Task pill" show={show}>
            <button onClick={() => setIsChecked((prev) => !prev)}>Click</button>
            <div style={{ display: "flex", gap: "16px", width: "100%", maxWidth: "375px" }}>
                {data ? (
                    <MobileTaskRow
                        data={{
                            ...data,
                            id: Number(data.id),
                            name: data.name ?? "",
                            description: data.description ?? "",
                            hasCheckList: !!data.taskCheckList?.length,
                            hasDataToReport: !!data.taskFields2?.length,
                            hasPhotoExample: !!data.fotoExampleId,
                            workingPosition: data.workingPosition?.name ?? null,
                            taskRangeHours: [],
                            isDateRange: !!data.taskTemporalityDateRange.length,
                            company: {
                                id: 1,
                                imageURL: "",
                                name: "Barra Alta",
                            },
                        }}
                        isChecked={isChecked}
                        onChangeCheckbox={(value) => setIsChecked(value)}
                        selectedTab="user"
                        rowPosition="even"
                    />
                ) : (
                    <></>
                )}
            </div>
        </Section>
    );
};
