import { FC } from "react";
import { ReviewCarrouselItemModel } from "../models";
import { ReviewCarrouselItem } from "./CarrouselItem/ReviewCarrouselItem";
import CarrouselItemSkelleton from "app/components_v2/__skelletons/CarrouselItemSkelleton/CarrouselItemSkelleton";

type ReviewCarrouselProps = {
    listItems: ReviewCarrouselItemModel[];
    onItemClick: (id: number, customerInstanceId: number) => void;
    isLoading?: boolean;
};

export const ReviewCarrousel: FC<ReviewCarrouselProps> = ({ listItems, onItemClick, isLoading }) => {
    const handleClick = (id: number, customerInstanceId: number) => {
        onItemClick(id, customerInstanceId);
    };

    return (
        <>
            {!(!isLoading && !listItems.length) && (
                <div
                    className={`reviewHeader__carrousel${
                        !isLoading && !listItems.length ? "--empty" : ""
                    } showScrollbarDesktopOnly`}
                >
                    {isLoading ? (
                        <CarrouselItemSkelleton quantity={6} />
                    ) : (
                        listItems.map((prop) => (
                            <ReviewCarrouselItem
                                {...prop}
                                key={prop.id}
                                onClick={() => handleClick(prop.id, prop.customerInstanceId)}
                            />
                        ))
                    )}
                </div>
            )}
        </>
    );
};
