import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { IssueInsertModel } from "app/models/05-QUA/IssueModels/IssueModel";

export default class AssetIssueService {
    public static async Save(issue: IssueInsertModel) {
        return FetchService.post<number>({
            url: `${ApiPaths.QUA_API}/AssetIssue`,
            body: issue,
        });
    }
}
