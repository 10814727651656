import { faQrcode } from "@fortawesome/pro-light-svg-icons";
import {
    faArrowRightToBracket,
    faBallotCheck,
    faBuildings,
    faBusinessTime,
    faCheckDouble,
    faClipboardList,
    faClockRotateLeft,
    faEnvelopes,
    faFileCertificate,
    faGear,
    faImage,
    faInputNumeric,
    faLock,
    faMoneyCheckPen,
    faPlusSquare,
    faRankingStar,
    faShieldHalved,
    faShieldKeyhole,
    faStar,
    faTemperatureList,
    faTicketAirline,
    faTriangleExclamation,
    faUsersRectangle,
    faX,
} from "@fortawesome/pro-regular-svg-icons";
import { Avatar } from "app/components_v2/Avatar/Avatar";
import { hasPermissionToViewConfigIcon } from "app/helpers/permissions/hasPermissionToViewConfigIcon";
import { GetUserTypeDescription } from "app/helpers/userType/GetUserTypeDescription";
import { useSession, useWindowSize } from "app/hooks";
import useEscapeToClose from "app/hooks/useEscapeToClose";
import { useLogOut } from "app/hooks/useLogout";
import { useUserType } from "app/hooks/useUserType";
import { ChangeCompany } from "app/routes/components";
import { hasPermissionToAdd, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { Languages, PrivatePaths, SecScreen, USER_TYPE, screenSize } from "app/shared/Constants";
import { TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { changeLanguage } from "i18next";
import { FC } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LanguageSelector } from "../__inputs/LanguageSelector/LanguageSelector";
import { Icon } from "../Icon/Icon";
import { CollapsableSidebar } from "./CollapsableSidebar/CollapsableSidebar";
import { SidebarItem } from "./SidebarItem/SidebarItem";
import { useWizardTaskVisibility } from "app/hooks/useWizardTaskVisibility";
import { Actions } from "app/state/actions";
import { useDispatch } from "react-redux";
import { useUpdateLanguage } from "app/hooks/Profile/useUpdateLanguage";
import { Button } from "../__buttons/Button/Button";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";

type MenuSideBarProps = {
    hide: boolean;
    setHide: (value: boolean) => void;
    showSideBar: boolean;
    setShowSideBar: (value: boolean) => void;
};

export const MenuSideBar: FC<MenuSideBarProps> = ({ showSideBar, setShowSideBar, hide, setHide }) => {
    const { t } = useTranslation();
    const session = useSession();
    const isOnBoarding = session?.isOnBoarding;
    const handleLogOut = useLogOut();
    const dispatch = useDispatch();
    const { isAnalist, isSupervisor, isSupervisorOrAnalist, isAdmin } = useUserType();
    const { width } = useWindowSize();
    const location = useLocation();
    const { handleChangeLanguage } = useUpdateLanguage();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();
    const { openWizardTask } = useWizardTaskVisibility();

    const handleSideBar = () => {
        setHide(true);
        setTimeout(() => {
            setShowSideBar(false);
        }, 300);
    };
    useEscapeToClose(handleSideBar);

    const options = [
        { value: "es", label: Languages.ESPANOL },
        { value: "en", label: Languages.ENGLISH },
    ];

    // Reports
    const showReports =
        (hasPermissionToView(session, SecScreen.TASK_HISTORY) ||
            hasPermissionToView(session, SecScreen.REGISTRY_HISTORY) ||
            hasPermissionToView(session, SecScreen.COMPANY_RANKING) ||
            hasPermissionToView(session, SecScreen.ISSUE) ||
            hasPermissionToView(session, SecScreen.AUTOMATIC_RECORD)) &&
        !isOnBoarding;

    // Task Manager
    const hasPermisionToCreateTask = hasPermissionToView(session, SecScreen.TASK) && hasPermissionToAdd(SecScreen.TASK);
    const showTasks = (hasPermissionToView(session, SecScreen.TASK) || hasPermisionToCreateTask) && !isOnBoarding;

    // Task Supervision
    const showSupervisedPage =
        (hasPermissionToView(session, SecScreen.TASK_REVIEW) ||
            hasPermissionToView(session, SecScreen.MY_VALORATION)) &&
        !isOnBoarding;

    // APPCC Module
    const hasPermissionToCreateRegistry =
        hasPermissionToAdd(SecScreen.REGISTRY_MANAGEMENT) &&
        hasPermissionToView(session, SecScreen.REGISTRY_MANAGEMENT);

    const showPlanAPPCCSection =
        (hasPermissionToView(session, SecScreen.PLAN_APPCC) ||
            hasPermissionToView(session, SecScreen.APPCC_BTASK_MANAG) ||
            hasPermissionToView(session, SecScreen.REGISTRY_MANAGEMENT) ||
            hasPermissionToView(session, SecScreen.ASSETS) ||
            hasPermissionToView(session, SecScreen.CORR_MEASURE_MANAGER) ||
            hasPermissionToView(session, SecScreen.ASSET_RANGE) ||
            hasPermissionToCreateRegistry) &&
        !isOnBoarding;

    // Audit
    const showAuditSection =
        (hasPermissionToView(session, SecScreen.AUDIT_MANAGEMENT) ||
            hasPermissionToView(session, SecScreen.AUDIT_INST_MANAG) ||
            hasPermissionToView(session, SecScreen.AUDIT_INST_REPORT) ||
            hasPermissionToView(session, SecScreen.AUDIT_INST_REV_NC)) &&
        !isOnBoarding;

    const showControlPanel = () => {
        if (!isOnBoarding)
            return (
                location.pathname.split("/")[1] !== "licenses" &&
                hasPermissionToViewConfigIcon(isAdmin) &&
                width < screenSize.TABLET
            );

        return width < screenSize.TABLET && isAdmin;
    };

    const CollapsableKeys = {
        TASK: "TASK",
        REVIEW: "REVIEW",
        ANALYSIS: "ANALYSIS",
        APPCC: "APPCC",
        AUDIT: "AUDIT",
        CONTROL_PANEL: "CONTROL_PANEL",
    } as const;

    type CollapsableType = keyof typeof CollapsableKeys;

    const checkCollapsableState = (collapsable: CollapsableType): boolean => {
        switch (collapsable) {
            case CollapsableKeys.TASK:
                return false;
            case CollapsableKeys.REVIEW:
                return isSupervisor;
            case CollapsableKeys.ANALYSIS:
                return isAnalist;
            case CollapsableKeys.APPCC:
                return hasPermissionToView(session, SecScreen.PLAN_APPCC);
            case CollapsableKeys.AUDIT:
                return hasPermissionToView(session, SecScreen.AUDIT_INST_MANAG);
            case CollapsableKeys.CONTROL_PANEL:
                return false;
            default:
                return false;
        }
    };

    const onClickCreateTask = () => {
        handleSideBar();
        openWizardTask();
    };

    const handleClickLicense = () => {
        dispatch(Actions.updateIsOnBoarding(false));
        handleSideBar();
    };

    return (
        <>
            {createPortal(
                <div
                    className={`${showSideBar ? "menuSideBar fullHeightOpacity" : "hide fullHeightOpacity"}`}
                    onClick={handleSideBar}
                >
                    <div className={`menuSideBar__menu${hide ? "--hide" : ""} `}>
                        <div className="menuSideBar__menu__items" onClick={(e) => e.stopPropagation()}>
                            <div className="menuSideBar__menu__container">
                                <div className="menuSideBar__container">
                                    <ChangeCompany />
                                    <div className="menuSideBar__container__items">
                                        {/* Informes */}
                                        {showReports && (
                                            <CollapsableSidebar
                                                title={t(TranslationKeys.MENU_SIDEBAR_REPORTS)}
                                                isOpen={checkCollapsableState(CollapsableKeys.ANALYSIS)}
                                            >
                                                {/* Ranking de Centros de Trabajo */}
                                                {hasPermissionToView(session, SecScreen.COMPANY_RANKING) &&
                                                    isSupervisorOrAnalist && (
                                                        <SidebarItem
                                                            icon={faRankingStar}
                                                            onClick={handleSideBar}
                                                            text={
                                                                isSupervisor
                                                                    ? t(TranslationKeys.GLOBAL_RANKING_DEPARTMENTS)
                                                                    : translateCustomerTypeKeys(
                                                                          TranslationKeys.GLOBAL_RANKING_COMPANIES,
                                                                          { firstReplace: "PLURAL" }
                                                                      )
                                                            }
                                                            to={
                                                                session?.user?.companies?.length === 1 &&
                                                                session.user.userType === "ANALIST"
                                                                    ? `/${PrivatePaths.COMPANY_RANKING}/company/${session?.user?.companies[0].companyId}`
                                                                    : session?.user?.companies?.length === 1 &&
                                                                      session.user.userType === USER_TYPE.SUPERVISOR
                                                                    ? `/${PrivatePaths.COMPANY_RANKING}/ranking-teams/${session?.user?.companies[0].companyId}`
                                                                    : session?.user.userType === USER_TYPE.SUPERVISOR
                                                                    ? `/${PrivatePaths.COMPANY_RANKING}/ranking-teams`
                                                                    : `/${PrivatePaths.COMPANY_RANKING}`
                                                            }
                                                        />
                                                    )}

                                                {/* Histórico de Tareas */}
                                                {hasPermissionToView(session, SecScreen.TASK_HISTORY) && (
                                                    <SidebarItem
                                                        icon={faClockRotateLeft}
                                                        onClick={handleSideBar}
                                                        text={t(TranslationTitles.TASK_HISTORY_PAGE_TITLE)}
                                                        to={`/${PrivatePaths.TASK_HISTORY}`}
                                                    />
                                                )}

                                                {/* Histórico de Registros */}
                                                {hasPermissionToView(session, SecScreen.REGISTRY_HISTORY) && (
                                                    <SidebarItem
                                                        icon={faBusinessTime}
                                                        onClick={handleSideBar}
                                                        text={t(TranslationTitles.REGISTRY_HISTORY_PAGE_TITLE)}
                                                        to={`/${PrivatePaths.REGISTRY_HISTORY}`}
                                                    />
                                                )}

                                                {/* CRUD Issue */}
                                                {hasPermissionToView(session, SecScreen.ISSUE) && (
                                                    <SidebarItem
                                                        text={t(TranslationTitles.ISSUE_PAGE_TITLE)}
                                                        onClick={handleSideBar}
                                                        icon={faTriangleExclamation}
                                                        to={`/${PrivatePaths.ISSUE}`}
                                                    />
                                                )}
                                                {/* Registros Automáticos */}
                                                {hasPermissionToView(session, SecScreen.AUTOMATIC_RECORD) && (
                                                    <SidebarItem
                                                        text={t(TranslationTitles.AUTOMATIC_RECORD_PAGE_TITLE)}
                                                        onClick={handleSideBar}
                                                        icon={faTemperatureList}
                                                        to={`/${PrivatePaths.AUTOMATIC_RECORDS}`}
                                                    />
                                                )}
                                            </CollapsableSidebar>
                                        )}

                                        {/* Gestión de Tareas */}
                                        {showTasks && (
                                            <CollapsableSidebar
                                                title={t(TranslationKeys.MENU_SIDEBAR_TASK_MANAGER)}
                                                isOpen={checkCollapsableState(CollapsableKeys.TASK)}
                                            >
                                                {/* Bolsa de Tareas */}
                                                {hasPermissionToView(session, SecScreen.BASE_TASK_MANAGEMENT) &&
                                                    hasPermissionToView(session, SecScreen.TASK) && (
                                                        <SidebarItem
                                                            icon={faClipboardList}
                                                            onClick={handleSideBar}
                                                            text={t(TranslationTitles.BASE_TASK_TITLE)}
                                                            to={`/${PrivatePaths.BASE_TASK_MANAGEMENT}`}
                                                        />
                                                    )}

                                                {/* Gestor de Tareas */}
                                                {hasPermissionToView(session, SecScreen.TASK) && (
                                                    <SidebarItem
                                                        icon={faClipboardList}
                                                        onClick={handleSideBar}
                                                        text={t(TranslationTitles.TASK_PAGE_TITLE)}
                                                        to={`/${PrivatePaths.TASK_PAGE}`}
                                                    />
                                                )}

                                                {/* Alta Guiada de Tareas */}
                                                {hasPermisionToCreateTask && (
                                                    <SidebarItem
                                                        icon={faPlusSquare}
                                                        onClick={onClickCreateTask}
                                                        text={t(TranslationKeys.MENU_SIDEBAR_NEW_TASK_WIZARD_TITLE)}
                                                        to={`/${PrivatePaths.TASK_PAGE}`}
                                                    />
                                                )}
                                            </CollapsableSidebar>
                                        )}

                                        {/* Supervisión de Tareas */}
                                        {showSupervisedPage && (
                                            <CollapsableSidebar
                                                title={t(TranslationKeys.MENU_SIDEBAR_TASK_REVIEW)}
                                                isOpen={checkCollapsableState(CollapsableKeys.REVIEW)}
                                            >
                                                {/* Revisar Tareas */}
                                                {hasPermissionToView(session, SecScreen.TASK_REVIEW) && (
                                                    <SidebarItem
                                                        icon={faImage}
                                                        onClick={handleSideBar}
                                                        text={t(TranslationTitles.REVIEW_TASKS_TITLE)}
                                                        to={`/${PrivatePaths.TASK_PHOTO}`}
                                                    />
                                                )}

                                                {/* Mis Valoraciones */}
                                                {hasPermissionToView(session, SecScreen.MY_VALORATION) && (
                                                    <SidebarItem
                                                        icon={faStar}
                                                        onClick={handleSideBar}
                                                        text={t(TranslationTitles.REVIEW_FEEDBACK_TITLE)}
                                                        to={`/${PrivatePaths.FEEDBACK_TASKS}?comments=true`}
                                                    />
                                                )}
                                            </CollapsableSidebar>
                                        )}

                                        {/* APPCC */}
                                        {showPlanAPPCCSection && (
                                            <CollapsableSidebar
                                                title={t(TranslationKeys.MENU_SIDEBAR_APPCC_PLAN)}
                                                isOpen={checkCollapsableState(CollapsableKeys.APPCC)}
                                            >
                                                {/* CRUD Base Task APPCC */}
                                                {hasPermissionToView(session, SecScreen.APPCC_BTASK_MANAG) &&
                                                    hasPermissionToView(session, SecScreen.REGISTRY_MANAGEMENT) && (
                                                        <SidebarItem
                                                            text={t(TranslationTitles.BASE_TASK_REGISTRY_TITLE)}
                                                            onClick={handleSideBar}
                                                            icon={faClipboardList}
                                                            to={`/${PrivatePaths.BASE_TASK_APPCC_MANAGEMENT}`}
                                                        />
                                                    )}

                                                {/* CRUD Plan */}
                                                {hasPermissionToView(session, SecScreen.PLAN_APPCC) && (
                                                    <SidebarItem
                                                        text={t(TranslationTitles.PLAN_APPCC_PAGE_TITLE)}
                                                        onClick={handleSideBar}
                                                        icon={faShieldHalved}
                                                        to={`/${PrivatePaths.PLAN_APPCC}`}
                                                    />
                                                )}

                                                {/* CRUD Registry */}
                                                {hasPermissionToView(session, SecScreen.REGISTRY_MANAGEMENT) && (
                                                    <SidebarItem
                                                        icon={faClipboardList}
                                                        onClick={handleSideBar}
                                                        text={t(TranslationKeys.TASK_PAGE_REGISTRY_TITLE)}
                                                        to={`/${PrivatePaths.REGISTRY_MANAGEMENT}`}
                                                    />
                                                )}
                                                {/* CRUD Asset */}
                                                {hasPermissionToView(session, SecScreen.ASSETS) && (
                                                    <SidebarItem
                                                        icon={faInputNumeric}
                                                        onClick={handleSideBar}
                                                        text={t(
                                                            TranslationTitles.PERMISSION_TITLE_ASSET_PAGE_PERMISSION
                                                        )}
                                                        to={`/${PrivatePaths.ASSETS}`}
                                                    />
                                                )}
                                                {/* CRUD Corrective Measure */}
                                                {hasPermissionToView(session, SecScreen.CORR_MEASURE_MANAGER) && (
                                                    <SidebarItem
                                                        icon={faMoneyCheckPen}
                                                        onClick={handleSideBar}
                                                        text={t(
                                                            TranslationTitles.PERMISSION_TITLE_CORR_MEASURE_MANAGER
                                                        )}
                                                        to={`/${PrivatePaths.CORR_MEASURE_MANAGER}`}
                                                    />
                                                )}
                                                {/* Fast Create Registry */}
                                                {hasPermissionToCreateRegistry && (
                                                    <SidebarItem
                                                        icon={faPlusSquare}
                                                        onClick={onClickCreateTask}
                                                        text={t(TranslationKeys.MENU_SIDEBAR_NEW_REGISTRY)}
                                                        to={`/${PrivatePaths.REGISTRY_MANAGEMENT}`}
                                                    />
                                                )}
                                                {/* CRUD Asset Range */}
                                                {hasPermissionToView(session, SecScreen.ASSET_RANGE) && (
                                                    <SidebarItem
                                                        icon={faInputNumeric}
                                                        onClick={handleSideBar}
                                                        text={t(
                                                            TranslationTitles.PERMISSION_TITLE_ASSET_RANGE_PAGE_PERMISSION
                                                        )}
                                                        to={`/${PrivatePaths.ASSETS_RANGE}`}
                                                    />
                                                )}
                                            </CollapsableSidebar>
                                        )}

                                        {/* Audit */}
                                        {showAuditSection && (
                                            <CollapsableSidebar
                                                title={t(TranslationKeys.MENU_SIDEBAR_AUDIT)}
                                                isOpen={checkCollapsableState(CollapsableKeys.AUDIT)}
                                            >
                                                {/* Plantillas de Auditoría */}
                                                {hasPermissionToView(session, SecScreen.AUDIT_MANAGEMENT) && (
                                                    <SidebarItem
                                                        text={t(TranslationTitles.AUDIT_TEMPLATE_TITLE)}
                                                        onClick={handleSideBar}
                                                        icon={faFileCertificate}
                                                        to={`/${PrivatePaths.AUDIT_MANAGEMENT}`}
                                                    />
                                                )}

                                                {/* Bolsa de Items */}
                                                {hasPermissionToView(session, SecScreen.AUDIT_MANAGEMENT) && (
                                                    <SidebarItem
                                                        text={t(TranslationTitles.BASE_AUDIT_GROUP_CHECKLIST_ITEM)}
                                                        onClick={handleSideBar}
                                                        icon={faBallotCheck}
                                                        to={`/${PrivatePaths.BASE_AUDIT_GROUP_CHECKLIST_ITEM}`}
                                                    />
                                                )}

                                                {/* Auditorías */}
                                                {(hasPermissionToView(session, SecScreen.AUDIT_INST_MANAG) ||
                                                    hasPermissionToView(session, SecScreen.AUDIT_INST_REV_NC) ||
                                                    hasPermissionToView(session, SecScreen.AUDIT_INST_REPORT)) && (
                                                    <SidebarItem
                                                        text={t(TranslationTitles.AUDIT_GRID_TITLE)}
                                                        onClick={handleSideBar}
                                                        icon={faCheckDouble}
                                                        to={`/${PrivatePaths.AUDIT_INST_MANAG}`}
                                                    />
                                                )}
                                            </CollapsableSidebar>
                                        )}

                                        {/* Panel de Control */}
                                        {showControlPanel() && (
                                            <CollapsableSidebar
                                                title={t(TranslationKeys.MENU_SIDEBAR_CONTROL_PANEL)}
                                                isOpen={checkCollapsableState(CollapsableKeys.CONTROL_PANEL)}
                                            >
                                                {(isAnalist || isAdmin) && (
                                                    <SidebarItem
                                                        text={t(TranslationTitles.HEADER_ON_BOARDING_TITLE)}
                                                        onClick={handleSideBar}
                                                        icon={faTicketAirline}
                                                        to={`/${PrivatePaths.ON_BOARDING}`}
                                                    />
                                                )}
                                                {isAdmin && (
                                                    <SidebarItem
                                                        text={t(TranslationTitles.LICENSES_TITLE)}
                                                        onClick={handleClickLicense}
                                                        icon={faLock}
                                                        to={`/${PrivatePaths.LICENSE_PAGE}`}
                                                    />
                                                )}
                                                {hasPermissionToView(session, SecScreen.CENTERS_MANAGEMENT) && (
                                                    <SidebarItem
                                                        text={translateCustomerTypeKeys(
                                                            TranslationTitles.COMPANIES_MAINTENANCE_TITLE,
                                                            { firstReplace: "PLURAL" }
                                                        )}
                                                        onClick={handleSideBar}
                                                        icon={faBuildings}
                                                        to={`/${PrivatePaths.CENTERS_MANAGEMENT}`}
                                                    />
                                                )}
                                                {hasPermissionToView(session, SecScreen.ROLE_MANAGEMENT) && (
                                                    <SidebarItem
                                                        text={t(TranslationTitles.ROLES_MAINTENANCE_TITLE)}
                                                        onClick={handleSideBar}
                                                        icon={faShieldKeyhole}
                                                        to={`/${PrivatePaths.ROLE_MANAGEMENT}`}
                                                    />
                                                )}
                                                {hasPermissionToView(session, SecScreen.USER_MANAGEMENT) && (
                                                    <SidebarItem
                                                        text={t(TranslationTitles.USERS_TITLE)}
                                                        onClick={handleSideBar}
                                                        icon={faUsersRectangle}
                                                        to={`/${PrivatePaths.USER_MANAGEMENT}`}
                                                    />
                                                )}
                                                {hasPermissionToView(session, SecScreen.QR) && !isOnBoarding && (
                                                    <SidebarItem
                                                        text={t(TranslationTitles.WORKING_POSITION_TITLE)}
                                                        onClick={handleSideBar}
                                                        icon={faQrcode}
                                                        to={`/${PrivatePaths.QR}`}
                                                    />
                                                )}
                                                {hasPermissionToView(session, SecScreen.DISTRIBUTION_LIST) && (
                                                    <SidebarItem
                                                        text={t(TranslationTitles.EMAIL_DISTRIBUTION_LIST_TITLE)}
                                                        onClick={handleSideBar}
                                                        icon={faEnvelopes}
                                                        to={`/${PrivatePaths.DISTRIBUTION_LIST}`}
                                                    />
                                                )}
                                            </CollapsableSidebar>
                                        )}
                                    </div>

                                    <div className="menuSideBar__menu__footer">
                                        <div className="menuSideBar__menu__footer__config">
                                            {!isOnBoarding && (
                                                <SidebarItem
                                                    icon={faGear}
                                                    onClick={handleSideBar}
                                                    text={t(TranslationTitles.CONFIGURATION_TITLE)}
                                                    to={`/${PrivatePaths.MY_PROFILE}?config=true`}
                                                />
                                            )}
                                            <div className="menuSideBar__menu__items__item">
                                                <LanguageSelector
                                                    options={options}
                                                    isMenu
                                                    onSelectedLanguage={({ value }) => changeLanguage(value)}
                                                    onChangeLanguage={handleChangeLanguage}
                                                    language={session?.user.language || "es"}
                                                />
                                            </div>
                                        </div>
                                        <div className="menuSideBar__footer">
                                            <Avatar
                                                avatarName={`${session?.user.firstName} ${session?.user.lastName}`}
                                                colorId={session?.user.id || 1}
                                                name={`${session?.user.firstName} ${session?.user.lastName}`}
                                                img={session?.user.profilePictureURL || ""}
                                                goMyProfile={!isOnBoarding}
                                                role={
                                                    session?.user.jobDescription?.description ||
                                                    GetUserTypeDescription(session?.user.userType || "SUPERVISOR", t)
                                                }
                                                onClick={handleSideBar}
                                            />
                                            {!session?.appSource && (
                                                <Button iconLeft={faArrowRightToBracket} onClick={handleLogOut} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menuSideBar__cross">
                            <Icon icon={faX} theme="dark" onClick={handleSideBar} />
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};
