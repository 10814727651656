import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { ReportDataList } from "app/models/02-TAR/TaskWizard";

export const mapReportDataToAssetPreview = (reportData: ReportDataList[]): TaskInstanceFieldModel[] => {
    return reportData.flatMap(({ assetId, assetsFields = [], label, value }) =>
        assetsFields.map(({ name, assetDynamicField, isHidden, assetFieldOption, max, min, unit }, i) => {
            const dynamicFieldTypeValid = assetDynamicField || "TEXT";
            const assetField: TaskInstanceFieldModel = {
                dynamicFieldType: dynamicFieldTypeValid,
                id: i,
                isHidden,
                isRequired: true,
                label: name,
                order: i,
                isOperative: true,
                taskInstanceFieldValues:
                    dynamicFieldTypeValid === "DROPDOWN"
                        ? assetFieldOption.map((afo, afoKey) => ({
                              id: afoKey,
                              label: afo.name,
                              max: max ?? null,
                              min: min ?? null,
                              measureUnit: unit ?? "",
                              value: "",
                          }))
                        : [
                              {
                                  id: 1,
                                  label: name,
                                  max: max ?? null,
                                  min: min ?? null,
                                  measureUnit: unit ?? "",
                                  value: "",
                              },
                          ],
                fK_TaskInstance: i,
                asset2: {
                    assetFields: [],
                    assetId: "",
                    assetType: {
                        assetTypeOptions: [],
                        description: "",
                        id: 1,
                        max: 0,
                        min: 0,
                        name: "",
                        type: "NUMBER",
                    },
                    customerInstanceId: 35,
                    description: "",
                    fK_AssetType: 33,
                    fK_Company: 1,
                    fK_PlanAPPCC: 1,
                    id: assetId || 0,
                    isDeleted: false,
                    isRelatedWithTasks: null,
                    issueCount: 0,
                    issueCountTotal: 0,
                    name: label || value || "",
                    notWorking: false,
                    sensorSerialNumber: "",
                },
            };
            return assetField;
        })
    );
};
