import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { DragFileWithLabel } from "app/components_v2/DragFileWithLabel/DragFileWithLabel";
import { faPaperPlane, faSave, faTriangleExclamation, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FC, useContext } from "react";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { IssueModalHeader } from "./IssueModalHeader/IssueModalHeader";
import {
    IssueTranslation,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { OptionsOutsideSelect, SelectOptions, Switch, TextArea } from "app/components_v2/__inputs";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { useIssueModal } from "app/hooks/Issue/useIssueModal";
import { useTitle } from "app/hooks";
import { useTranslation } from "react-i18next";
import Spinner from "app/components_v2/Spinner/Spinner";
import { SelectWithActions } from "app/components_v2/SelectWithActions/SelectWithActions";
import { IssueClassificationModal } from "../IssueClassificationModal/IssueClassificationModal";
import { ConfirmModal } from "../ConfirmModal/ConfirmModal";
import { useIssueClassification } from "app/hooks/Issue/useIssueClassification";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { SecScreen } from "app/shared/Constants";
import { IssueServiceType } from "app/shared/types/IssueTypes";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";

const formId = "issueModelForm";

type FooterModel = {
    text?: string;
    icon?: IconDefinition;
};

type IssueModalProps = {
    onCloseModal?: (isSubmitting: boolean) => void;
    disableAsset?: boolean;
    variant?: TabHeaderVariants;
    type?: IssueServiceType;
    footerOptions?: FooterModel;
    onOpenWizardTask?: (value: boolean) => void;
    setIssueId?: (value: number) => void;
};

export const IssueModal: FC<IssueModalProps> = ({
    onCloseModal,
    disableAsset,
    variant = "primary",
    type,
    footerOptions,
    onOpenWizardTask,
    setIssueId,
}) => {
    const { t } = useTranslation();
    const { issueServiceType } = useContext(IssueModalContext);

    const {
        assetOptions,
        attachments,
        auditInfo,
        auditInstanceId,
        createTask,
        description,
        errorFormValues,
        fk_Asset,
        fK_IssueClassification,
        handleAddSupervisors,
        handleChangeDragFile,
        handleDeleteImage,
        handleDeleteSupervisors,
        handleEditImage,
        handleInputChange,
        handleSubmit,
        isCritic,
        isDataLoading,
        isFormSubmitting,
        issueType,
        selectsRef,
        supervisors,
        supervisorsOptions,
        taskInstanceFieldId,
        close,
    } = useIssueModal({ type: type || issueServiceType, onOpenWizardTask, setIssueId });

    const {
        addNewIssueClassification,
        deleteIssueClassification,
        isDeleteModalLoading,
        isIssueClassificationModalVisible,
        issueClassificationOptions,
        lastItems,
        onCancel,
        onDeleteIssueClassification,
        onEditIssueClassification,
        selectActions,
        selectedIssueClassification,
        closeDeleteModal,
    } = useIssueClassification({
        fK_IssueClassification,
        onInputChange: (value) => handleInputChange({ fK_IssueClassification: String(value) }),
    });

    useTitle(t(TranslationTitles.NEW_ISSUE_PAGE_TITLE));

    const { errorDescription, errorSupervisor } = errorFormValues;

    const handleClose = () => {
        close();
        onCloseModal && onCloseModal(false);
    };

    const showIssueClassification = () => {
        if (hasPermissionToEdit(SecScreen.ISSUE_CLASSIFICATION)) return true;
        if (issueClassificationOptions.length > 1 && !hasPermissionToEdit(SecScreen.ISSUE_CLASSIFICATION)) return true;
        return false;
    };

    return (
        <>
            {deleteIssueClassification.isOpen && (
                <ConfirmModal
                    onClose={closeDeleteModal}
                    onConfirm={onDeleteIssueClassification}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={t(IssueTranslation.DELETE_ISSUE_CLASSIFICATION_TITLE)}
                    description={t(IssueTranslation.DELETE_ISSUE_CLASSIFICATION_DESCRIPTION).replace(
                        "{0}",
                        deleteIssueClassification.issueClassification?.name || ""
                    )}
                    isLoading={isDeleteModalLoading}
                    type="delete"
                    variant={variant}
                />
            )}
            {isIssueClassificationModalVisible && (
                <IssueClassificationModal
                    onAddNew={addNewIssueClassification}
                    onCancel={onCancel}
                    onEdit={onEditIssueClassification}
                    selectedIssueClassification={selectedIssueClassification}
                    variant={variant}
                />
            )}
            <GenericModal
                footer={
                    <GenericModalFooter
                        confirmButton={{
                            text:
                                footerOptions?.text || taskInstanceFieldId
                                    ? t(IssueTranslation.ISSUE_SAVE_CONFIRM_BUTTON)
                                    : t(TranslationKeys.SEND_MANUAL_ISSUE),
                            form: formId,
                            type: "submit",
                            iconLeft: footerOptions?.icon || taskInstanceFieldId ? faSave : faPaperPlane,
                            variant,
                        }}
                        closeButton={{
                            text: t(TranslationCommon.CANCEL),
                            buttonType: "tertiary",
                            onClick: handleClose,
                            variant,
                        }}
                        buttonsJustification="spaceBetween"
                        loading={isFormSubmitting}
                    />
                }
                size="md-tall"
            >
                {isDataLoading ? (
                    <div className="issueModal__sppiner">
                        <Spinner />
                    </div>
                ) : (
                    <form className="issueModal" id={formId} onSubmit={(e) => handleSubmit(e, onCloseModal)}>
                        <div className="issueModal__header">
                            <TableError
                                icon={faTriangleExclamation}
                                title={t(TranslationTitles.NEW_ISSUE_PAGE_TITLE)}
                                variant="warning"
                            />
                        </div>
                        <div className="issueModal__info">
                            {auditInstanceId && auditInfo && <IssueModalHeader values={auditInfo} />}
                            <TextArea
                                label={t(TranslationKeys.ISSUE_PROBLEM_DETECTED_LABEL)}
                                text={description}
                                onChange={(value) => handleInputChange({ description: value })}
                                errorMessage={errorDescription}
                                placeholder={t(TranslationKeys.PROBLEM_DETECTED_PLACEHOLDER)}
                                maxLength={500}
                                showTranscription
                            />
                            <DragFileWithLabel
                                onChange={handleChangeDragFile}
                                onDelete={handleDeleteImage}
                                onEditImage={handleEditImage}
                                images={attachments}
                            />
                        </div>
                        {issueType !== "TASK" && issueType !== "AUDIT" && (
                            <SelectOptions
                                isMulti={false}
                                onChange={({ value }) => handleInputChange({ fk_Asset: value })}
                                options={assetOptions}
                                selectedValue={fk_Asset === "-1" ? undefined : fk_Asset}
                                isSearchable
                                isOptional={!onCloseModal}
                                placeholder={t(TranslationCommon.SELECT_OPTION)}
                                label={t(TranslationKeys.ISSUE_ASSET_LABEL)}
                                disabled={disableAsset}
                            />
                        )}
                        {issueType === "AUDIT" && (
                            <>
                                <Switch
                                    label={t(IssueTranslation.ISSUE_MODAL_AUDIT_NON_CONFORMITY)}
                                    checked={isCritic}
                                    onChange={(value) => handleInputChange({ isCritic: value })}
                                />
                                <Switch
                                    label={t(IssueTranslation.ISSUE_MODAL_AUDIT_CREATE_TASK)}
                                    checked={createTask}
                                    onChange={(value) => handleInputChange({ createTask: value })}
                                />
                            </>
                        )}
                        {showIssueClassification() && (
                            <SelectWithActions
                                actions={selectActions}
                                lastItems={lastItems}
                                onChange={({ value }) =>
                                    handleInputChange({
                                        fK_IssueClassification: value,
                                    })
                                }
                                options={issueClassificationOptions}
                                label={`${t(IssueTranslation.ISSUE_CLASSIFICATION)} (${t(
                                    TranslationKeys.INPUT_OPTIONAL
                                )})`}
                                placeholder={t(IssueTranslation.ISSUE_CLASSIFICATION)}
                                selectedValue={fK_IssueClassification !== "-1" ? fK_IssueClassification : undefined}
                            />
                        )}
                        {issueType !== "AUDIT" && !taskInstanceFieldId && (
                            <div ref={selectsRef} className="issueModal__selects">
                                <Collapsable border="none" title={t(TranslationKeys.SUPERVISOR_ISSUE_LABEL)}>
                                    <OptionsOutsideSelect
                                        onChange={handleAddSupervisors}
                                        onDeleteItem={handleDeleteSupervisors}
                                        options={supervisorsOptions}
                                        values={supervisors}
                                        isSearchable
                                        autoInitials
                                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                                        errorMessage={errorSupervisor}
                                    />
                                </Collapsable>
                            </div>
                        )}
                    </form>
                )}
            </GenericModal>
        </>
    );
};
