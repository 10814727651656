import { loggedOnceMapper } from "../helpers/loggedOnceMapper";
import { userProfilePictureMapper } from "../helpers/userProfilePictureMapper";
import { UserGridSecondaryFilterModel } from "../models/userGridSecondaryFilterModel";

type IextraparamsUserGridCounters = {
    customerInstanceId: number;
    companyId: string;
    filterData: UserGridSecondaryFilterModel;
};

export const fillUserGridCountersExtraParams = ({
    companyId,
    customerInstanceId,
    filterData,
}: IextraparamsUserGridCounters): string => {
    const { loggedOnce, profilePicture } = filterData;
    const loggedOnceMapped = loggedOnceMapper(loggedOnce);
    const profilePictureMapped = userProfilePictureMapper(profilePicture);

    const params = new URLSearchParams();

    params.append("customerInstanceId", String(customerInstanceId));
    if (companyId !== "-1") params.append("companyId", companyId);
    if (loggedOnceMapped != null) params.append("loggedOnce", String(loggedOnceMapped));
    if (profilePictureMapped != null) params.append("hasProfilePicture", String(profilePictureMapped));

    return params.toString();
};
