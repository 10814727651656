import { UserGridLoggedOnce } from "../models/userGridSecondaryFilterModel";

export const loggedOnceMapper = (loggedOnce: UserGridLoggedOnce) => {
    const loggedOnceMapper: Record<UserGridLoggedOnce, boolean | null> = {
        ALL: null,
        ONCE: true,
        NEVER: false,
    };
    return loggedOnceMapper[loggedOnce];
};
