import { useToast } from "app/hooks/Toast/useToast";
import { TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { TaskTemporalityDateRangeFormModel } from "app/models/02-TAR/TaskWizard";
import { TranslationErrors } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type IUseTaskTemporalityDateRangeWeek = {
    taskTemporalityDateRange: TaskTemporalityDateRangeFormModel;
    onChange: (value: Partial<TaskTemporalityModel>) => void;
};

export const useTaskTemporalityDateRangeWeek = ({
    taskTemporalityDateRange,
    onChange,
}: IUseTaskTemporalityDateRangeWeek) => {
    const { handleToast } = useToast();
    const { t } = useTranslation();

    const { weeklyDays } = taskTemporalityDateRange;
    const handleChangeWeeklyDays = (dayId: number) => {
        const selectedDays = weeklyDays.filter((day) => day.isActive);
        const numberOfSelectedDays = selectedDays.length;
        const selectedDay = weeklyDays.find(({ isActive }) => isActive);

        if ((numberOfSelectedDays === 0 || numberOfSelectedDays > 1) && dayId === 6) {
            handleToast({
                title: t(TranslationErrors.TASK_TEMPORALITY_WEEKLY_SUNDAY_ERROR_TITLE),
                subtitle: t(TranslationErrors.TASK_TEMPORALITY_WEEKLY_SUNDAY_ERROR_DESCRIPTION),
                type: "alert",
                variant: "danger",
            });
            return;
        }

        if (numberOfSelectedDays === 0) {
            selectSpecificDay(dayId);
            return;
        }

        if (selectedDays[0].id === dayId) {
            desSelectAll();
            return;
        }

        if (selectedDays[0].id > dayId) {
            selectSpecificDay(dayId);
            return;
        }

        if (numberOfSelectedDays > 1) {
            selectSpecificDay(dayId);
            return;
        }

        if (!selectedDay) return;

        selectRangeOfDays(dayId, selectedDay.id);
    };

    const selectSpecificDay = (dayId: number) => {
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                weeklyDays: weeklyDays.map((weeklyDay) => ({ ...weeklyDay, isActive: weeklyDay.id === dayId })),
            },
        });
    };

    const selectRangeOfDays = (dayId: number, selectedDayId: number) => {
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                weeklyDays: weeklyDays.map((weeklyDay) => {
                    return {
                        ...weeklyDay,
                        isActive: weeklyDay.id <= dayId && weeklyDay.id >= selectedDayId,
                    };
                }),
            },
        });
    };

    const desSelectAll = () => {
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                weeklyDays: weeklyDays.map((weeklyDay) => {
                    return {
                        ...weeklyDay,
                        isActive: false,
                    };
                }),
            },
        });
    };

    return { onChangeWeeklyDays: handleChangeWeeklyDays };
};
