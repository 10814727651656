import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIssueClassification } from "app/hooks/Issue/useIssueClassification";
import { IssueTranslation, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { IssueClassificationModel } from "app/models/05-QUA/IssueClassificationModels/IssueClassificationModel";
import { SelectWithActions } from "app/components_v2/SelectWithActions/SelectWithActions";
import { IssueClassificationModal } from "app/components_v2/__modals/IssueClassificationModal/IssueClassificationModal";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { IssueBodyTitle } from "../IssueBodyTitle/IssueBodyTitle";
import { IssueModel } from "app/models/05-QUA/IssueModels/IssueModel";

type IssueClassificationProps = {
    issueClassification: IssueClassificationModel;
    onEdit?: (values: Partial<IssueModel>) => void;
    readOnly?: boolean;
};

export const IssueClassification: FC<IssueClassificationProps> = ({ issueClassification, onEdit, readOnly }) => {
    const { t } = useTranslation();

    const { id, name } = issueClassification;
    const [issueClassificationId, setIssueClassificationId] = useState<string>(String(id));

    const {
        addNewIssueClassification,
        deleteIssueClassification,
        isDeleteModalLoading,
        isIssueClassificationModalVisible,
        issueClassificationOptions,
        lastItems,
        onCancel,
        onDeleteIssueClassification,
        onEditIssueClassification,
        selectActions,
        selectedIssueClassification,
        closeDeleteModal,
    } = useIssueClassification({
        fK_IssueClassification: issueClassificationId,
        onInputChange: setIssueClassificationId,
    });

    return (
        <div className="issueBodyDescription">
            {deleteIssueClassification.isOpen && (
                <ConfirmModal
                    onClose={closeDeleteModal}
                    onConfirm={onDeleteIssueClassification}
                    onConfirmText={t(TranslationCommon.DELETE)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    title={t(IssueTranslation.DELETE_ISSUE_CLASSIFICATION_TITLE)}
                    description={t(IssueTranslation.DELETE_ISSUE_CLASSIFICATION_DESCRIPTION).replace(
                        "{0}",
                        deleteIssueClassification.issueClassification?.name || ""
                    )}
                    isLoading={isDeleteModalLoading}
                    type="delete"
                    variant="primary"
                />
            )}
            {isIssueClassificationModalVisible && (
                <IssueClassificationModal
                    onAddNew={addNewIssueClassification}
                    onCancel={onCancel}
                    onEdit={onEditIssueClassification}
                    selectedIssueClassification={selectedIssueClassification}
                    variant="primary"
                />
            )}
            {readOnly ? (
                <>
                    <IssueBodyTitle>{t(IssueTranslation.ISSUE_CLASSIFICATION)}</IssueBodyTitle>
                    <p className="issueBodyDescription__description">{name}</p>
                </>
            ) : (
                <SelectWithActions
                    actions={selectActions}
                    lastItems={lastItems}
                    onChange={({ value }) => {
                        setIssueClassificationId(value);
                        onEdit &&
                            onEdit({
                                fK_IssueClassification: Number(value),
                            });
                    }}
                    options={issueClassificationOptions}
                    label={`${t(IssueTranslation.ISSUE_CLASSIFICATION)} (${t(TranslationKeys.INPUT_OPTIONAL)})`}
                    placeholder={t(IssueTranslation.ISSUE_CLASSIFICATION)}
                    selectedValue={issueClassificationId !== "-1" ? issueClassificationId : undefined}
                />
            )}
        </div>
    );
};
