import { AuditInstanceStatus } from "app/shared/types/AuditInstanceTypes";
import { useState } from "react";
import { fillExtraparams } from "app/pages/05-QUA/Issue/helpers/fillExtraparams";
import { useToast } from "app/hooks/Toast/useToast";
import { useTranslation } from "react-i18next";
import AuditInstanceService from "app/services/05-QUA/AuditInstanceService";
import { PaginationDefaults, PrivatePaths } from "app/shared/Constants";
import { useNavigate } from "react-router-dom";
import { AuditTranslation } from "app/translation/translationKeys";
import AuditInstanceIssueService from "app/services/05-QUA/AuditInstanceIssueService";
import { IssueListDto } from "app/dtos/05-QUA/Issue/IssueListDto";

type UseAuditStartParams = { auditInstanceId: number; actualStatus: AuditInstanceStatus };

export const useAuditIssues = ({ auditInstanceId, actualStatus }: UseAuditStartParams) => {
    const { handleToast } = useToast();
    const { t } = useTranslation();
    const nav = useNavigate();

    const [auditIssuesList, setAuditIssuesList] = useState<IssueListDto[]>([]);
    const [auditIssuesPageIndex, setAuditIssuesPageIndex] = useState(1);
    const [totalAuditIssueList, setTotalAuditIssueList] = useState<number>(0);
    const [isAuditIssuesLoading, setAuditIssuesIsLoading] = useState<boolean>(true);
    const [isAuditIssuesConfirmModalOpen, setAuditIssuesIsConfirmModalOpen] = useState<boolean>(false);
    const [isCompleting, setIsCompleting] = useState<boolean>(false);
    const [isCloseAuditModalOpen, setIsCloseAuditModalOpen] = useState<boolean>(false);
    const [isClosing, setIsClosing] = useState<boolean>(false);

    const fetchAuditIssuesData = async ({ pi }: { pi?: number }) => {
        setAuditIssuesIsLoading(true);

        const pageIndexFetch = pi !== undefined ? pi : auditIssuesPageIndex;

        const extraParams = fillExtraparams({
            currentTab: "AUDIT",
            companyId: "-1",
            sortDirection: "desc",
            sortField: "isCritical",
            auditInstanceId: auditInstanceId.toString(),
            isCreated: actualStatus === "IN_PROGRESS" ? false : true,
            issueStatusState: "BOTH",
        });

        const { data } = await AuditInstanceIssueService.GetData({
            pageIndex: pageIndexFetch - 1,
            pageSize: PaginationDefaults.PAGE_SIZE,
            extraParams,
        });
        setAuditIssuesList(data.list);
        setTotalAuditIssueList(data.count);
        setAuditIssuesIsLoading(false);
    };

    const handleComplete = async () => {
        setIsCompleting(true);
        const { status } = await AuditInstanceService.CompleteAuditInstanceReview(auditInstanceId);

        if (!status()) {
            handleToast({
                title: t(AuditTranslation.AUDIT_FAILED_FINISH_REVIEW),
                variant: "danger",
                type: "alert",
            });
            setIsCompleting(false);
            return;
        }
        setIsCompleting(false);
        nav(`/${PrivatePaths.AUDIT_INST_MANAG}`);
    };

    const handleAuditIssuesConfirmModalOpen = () => {
        const isAllIssueClosed = auditIssuesList.every(({ actualStatus }) => actualStatus === "CLOSED");
        if (!isAllIssueClosed) {
            handleToast({
                title: t(AuditTranslation.AUDIT_ALL_ISSUES_HAS_TO_BE_CLOSED),
                variant: "danger",
                type: "alert",
            });
            return;
        }
        setAuditIssuesIsConfirmModalOpen(true);
    };
    const confirmAuditIssuesModalSubmit = () => {
        handleComplete();
        setAuditIssuesIsConfirmModalOpen(false);
    };

    const handleCloseAudit = async () => {
        setIsCloseAuditModalOpen(false);
        setIsClosing(true);

        const { status } = await AuditInstanceService.CompleteAuditInstanceReview(auditInstanceId);

        if (!status()) {
            handleToast({
                title: t(AuditTranslation.AUDIT_FAILED_CLOSE),
                variant: "danger",
                type: "alert",
            });
            setIsClosing(false);
            return;
        }
        setIsClosing(false);
        nav(`/${PrivatePaths.AUDIT_INST_MANAG}`);
    };

    return {
        fetchAuditIssuesData,
        isAuditIssuesLoading,
        auditIssuesList,
        auditIssuesPageIndex,
        setAuditIssuesPageIndex,
        totalAuditIssueList,
        handleAuditIssuesConfirmModalOpen,
        isAuditIssuesConfirmModalOpen,
        setAuditIssuesIsConfirmModalOpen,
        confirmAuditIssuesModalSubmit,
        isCompleting,
        isCloseAuditModalOpen,
        setIsCloseAuditModalOpen,
        isClosing,
        handleCloseAudit,
    };
};
