import { getDateFormattedSelects } from "app/helpers";

export type IFillExtraparams = {
    startDate: Date;
    endDate: Date;
    companyId: number;
};

export const fillExtraparams = ({ companyId, endDate, startDate }: IFillExtraparams) => {
    const params = new URLSearchParams();

    if (startDate) params.append("DeadlineDate", getDateFormattedSelects(startDate));
    if (endDate) params.append("DeadlineDate1", getDateFormattedSelects(endDate));
    if (companyId) params.append("CompanyId", String(companyId));

    return params.toString();
};
