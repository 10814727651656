import { ApiPaths } from "app/shared/Constants";
import FetchService from "../Fetch/FetchService";
import { IssueClassificationModel } from "app/models/05-QUA/IssueClassificationModels/IssueClassificationModel";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { PaginatedList } from "app/models/ServiceResponse/PaginatedResult";

export default class IssueClassificationService {
    public static async GetOne(id: number) {
        return FetchService.get<IssueClassificationModel>({
            url: `${ApiPaths.QUA_API}/IssueClassification/${id}`,
        });
    }

    public static async GetData(paginationParams?: PaginationParams) {
        return FetchService.get<PaginatedList<IssueClassificationModel>>({
            url: `${ApiPaths.QUA_API}/IssueClassification`,
            paginationParams,
        });
    }

    public static async Save(name: string) {
        return FetchService.post<IssueClassificationModel>({
            url: `${ApiPaths.QUA_API}/IssueClassification/`,
            body: { name },
        });
    }

    public static async Edit(issueClassification: IssueClassificationModel) {
        return FetchService.put({
            url: `${ApiPaths.QUA_API}/IssueClassification/${issueClassification.id}`,
            body: issueClassification,
        });
    }

    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/IssueClassification/${id}`,
        });
    }
}
