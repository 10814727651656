import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { RadioButton } from "app/components_v2/RadioButton/RadioButton";
import { WizardTaskStep4, TaskEnds } from "app/models/02-TAR/TaskWizard";

type TaskEndsNeverProps = {
    onChange: (fields: Partial<WizardTaskStep4>) => void;
    finish: TaskEnds;
    disabled?: boolean;
    className?: string;
    inverted?: boolean;
};

export const TaskEndsNever: FC<TaskEndsNeverProps> = ({ finish, onChange, disabled, inverted }) => {
    const { t } = useTranslation();

    const handleChange = () =>
        onChange({
            finish: {
                ...finish,
                checked: "never",
                value: null,
            },
        });
    if (finish.checked !== "never" && disabled) return null;
    return (
        <div className="taskendsNever">
            <RadioButton
                onChange={handleChange}
                checked={finish.checked === "never"}
                label={`${t(TranslationKeys.NEVER)}`}
                htmlFor={"taskEnds_never"}
                disabled={disabled}
                inverted={inverted}
            />
        </div>
    );
};
