import { FC } from "react";
import { useTranslation } from "react-i18next";
import { WizardTaskStep1 } from "app/models/02-TAR/TaskWizard";
import { TranslationKeys } from "app/translation/translationKeys";

export const StepOneInfo: FC<WizardTaskStep1> = ({ belongsToAPPCC, fK_PlanAPPCC, taskDesc, taskTitle }) => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <span className="stepFiveLabel">{t(TranslationKeys.TASK_NAME_LABEL)}</span>
                <div className="stepFivePlaceHolderInput">{taskTitle}</div>
            </div>

            {taskDesc && (
                <div>
                    <span className="stepFiveLabel">{t(TranslationKeys.TASK_DESCRIPTION)}</span>
                    <div className="stepFivePlaceHolderInput">{taskDesc}</div>
                </div>
            )}

            {belongsToAPPCC && (
                <div>
                    <span className="stepFiveLabel">{t(TranslationKeys.PLAN_APPCC_LABEL)}</span>
                    <div className="stepFivePlaceHolderInput">{fK_PlanAPPCC}</div>
                </div>
            )}
        </>
    );
};
