import { Navigate, Route, Routes } from "react-router-dom";
import { PublicPaths, SecScreen } from "app/shared/Constants";
import PrivateRoute from "app/routes/PrivateRoute";
import { NotificationDistributionListGrid } from "./NotificationDistributionListGrid/NotificationDistributionListGrid";

const NotificationDistributionListPage = () => {
    return (
        <Routes>
            <Route path={PublicPaths.WILDCARD} element={<Navigate to={""} />}></Route>
            <Route
                path={""}
                element={
                    <PrivateRoute component={NotificationDistributionListGrid} viewCode={SecScreen.DISTRIBUTION_LIST} />
                }
            />
        </Routes>
    );
};

export default NotificationDistributionListPage;
