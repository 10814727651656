import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { NotificationDistributionListDto } from "app/dtos/01-SEG/NotificationDistributionListDto/NotificationDistributionListDto";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { NotificationDistributionListModel } from "app/models/01-SEG/NotificationDistributionList/NotificationDistributionListModel";

export default class NotificationDistributionListService {
    public static async GetOne(id: number) {
        return FetchService.get<NotificationDistributionListModel>({
            url: `${ApiPaths.SEG_API}/NotificationDistributionList/${id}`,
        });
    }

    public static async GetData(parms: PaginationParams) {
        return FetchService.get<PaginatedResult<NotificationDistributionListModel>>({
            url: `${ApiPaths.SEG_API}/NotificationDistributionList`,
            paginationParams: parms,
        });
    }

    public static async Save(body: NotificationDistributionListDto) {
        return FetchService.post({
            url: `${ApiPaths.SEG_API}/NotificationDistributionList`,
            body,
        });
    }

    public static async Edit(id: number, body: NotificationDistributionListDto) {
        return FetchService.put({
            url: `${ApiPaths.SEG_API}/NotificationDistributionList/${id}`,
            body,
        });
    }

    public static async Delete(id: number) {
        return FetchService.delete({
            url: `${ApiPaths.SEG_API}/NotificationDistributionList/${id}`,
        });
    }
}
