import { Input, SelectOptions } from "app/components_v2/__inputs";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import {
    TaskTemporalityDateRangeMonthlyCustomDays,
    TaskTemporalityDateRangeMonthlyFormModel,
    TaskTemporalityDateRangeMonthlyValue,
} from "app/models/02-TAR/TaskWizard";
import { TASK_TEMPORALITY_DATE_RANGE_MONTHLY_VALUES } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/constants/editTaskConstants";
import { TaskTemporalityDateRangeTranslations } from "app/translation/translationKeys";
import { t } from "i18next";
import { FC } from "react";

type TaskTemporalityDateRangeMonthlyProps = {
    monthlyTemporality: TaskTemporalityDateRangeMonthlyFormModel;
    customErrors: {
        monthlyDay: string;
        monthlyStartDay: string;
        monthlyEndDay: string;
    };
    onChangeMonthly: (value: TaskTemporalityDateRangeMonthlyValue) => void;
    onChangeDays: (value: string) => void;
    onChangeCustomDays: (days: TaskTemporalityDateRangeMonthlyCustomDays) => void;
};

export const TaskTemporalityDateRangeMonthly: FC<TaskTemporalityDateRangeMonthlyProps> = ({
    monthlyTemporality,
    customErrors,
    onChangeMonthly,
    onChangeDays,
    onChangeCustomDays,
}) => {
    const { customDays, day, value } = monthlyTemporality;

    const options: OptionModel[] = [
        {
            label: t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTLHY_FIRST_X_DAYS_LABEL),
            value: TASK_TEMPORALITY_DATE_RANGE_MONTHLY_VALUES.FIRST_X_DAYS,
        },
        {
            label: t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTLHY_LAST_X_DAYS_LABEL),
            value: TASK_TEMPORALITY_DATE_RANGE_MONTHLY_VALUES.LAST_X_DAYS,
        },
        {
            label: t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTLHY_CUSTOM_X_DAYS_LABEL),
            value: TASK_TEMPORALITY_DATE_RANGE_MONTHLY_VALUES.CUSTOM,
        },
    ];

    return (
        <div className="taskTemporalityDateRangeMonthly">
            <SelectOptions
                isMulti={false}
                onChange={({ value }) => onChangeMonthly(value as TaskTemporalityDateRangeMonthlyValue)}
                options={options}
                selectedValue={value}
                isSearchable
            />
            {(value === "FIRST_X_DAYS" || value === "LAST_X_DAYS") && (
                <Input onChange={onChangeDays} value={day} placeholder="10" errorMessage={customErrors.monthlyDay} />
            )}
            {value === "CUSTOM" && (
                <div className="taskTemporalityDateRangeMonthly__custom">
                    <Input
                        onChange={(day) => onChangeCustomDays({ ...customDays, startDay: day })}
                        value={customDays.startDay}
                        label={t(
                            TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTLHY_CUSTOM_START_DATE_LABEL
                        )}
                        placeholder="10"
                        errorMessage={customErrors.monthlyStartDay}
                    />
                    <Input
                        onChange={(day) => onChangeCustomDays({ ...customDays, endDay: day })}
                        value={customDays.endDay}
                        label={t(
                            TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTLHY_CUSTOM_END_DATE_LABEL
                        )}
                        placeholder="16"
                        errorMessage={customErrors.monthlyEndDay}
                    />
                </div>
            )}
        </div>
    );
};
