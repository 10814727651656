import { ReadOnlyInput } from "app/components_v2/__inputs/ReadOnlyInput/ReadOnlyInput";
import { FC } from "react";

type ChecklistItemReadOnlyProps = {
    labels: string[];
    enableds?: boolean[];
    onEnable?: (index: number) => void;
};

export const ChecklistItemReadOnly: FC<ChecklistItemReadOnlyProps> = ({ labels, enableds, onEnable }) => {
    return (
        <div className="checklistItemReadOnly">
            {labels.map((label, i) => (
                <li key={i}>
                    <ReadOnlyInput
                        value={label}
                        isEnabled={enableds && enableds[i]}
                        onEnable={() => onEnable && onEnable(i)}
                    />
                </li>
            ))}
        </div>
    );
};
