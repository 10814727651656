import { useContext, useEffect, useState } from "react";
import { UserGridContext } from "../state/context/userGridContext";
import { useSession, useTitle } from "app/hooks";
import { UserListDto } from "app/dtos/01-SEG/UserListDto";
import { UserCounters } from "app/dtos/00-LOGIN/User/UserCounters";
import { TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { INITIAL_USER_GRID_SORT_DIRECTION, INITIAL_USER_GRID_SORT_FIELD } from "../constants/userGridConstants";
import { fillUserGridExtraParams } from "../Components/fillUserGridExtraParams";
import { UserService } from "app/services";
import { PaginationDefaults } from "app/shared/Constants";
import { useToast } from "app/hooks/Toast/useToast";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { fillUserGridCountersExtraParams } from "../Components/fillUserGridCountersExtraparams";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { TabPropsReduced } from "app/components_v2/Tabs";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { UserGridSecondaryFilterModel } from "../models/userGridSecondaryFilterModel";

type IGetUsers = {
    pi?: number;
    companyId?: string;
    tab?: number;
    sfValues?: UserGridSecondaryFilterModel;
};

type IGetCounters = {
    companyId?: string;
    sfValues?: UserGridSecondaryFilterModel;
};

export const useUserGrid = () => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const session = useSession();
    const { getErrorMessage } = useFetchErrors();
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();

    const {
        currentTab,
        onChangeCurrentTab,
        onChangePageIndex,
        onCompanyChange,
        onQueryChange,
        onSortChange,
        pageIndex,
        query,
        selectCompany,
        sortDirection,
        sortField,
        filterIcon,
        onSFChange,
        secondaryFilterValues,
    } = useContext(UserGridContext);
    const [data, setData] = useState<UserListDto[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [counters, setCounters] = useState<UserCounters>({
        activeUsers: 0,
        inactiveUsers: 0,
        temporaryInactiveUsers: 0,
    });
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const [isSecondaryFilterOpen, setIsSecondaryFilterOpen] = useState<boolean>(false);

    useTitle(t(TranslationTitles.USERS_PAGE_TITLE));

    const handleTabChange = (tabIndex: number) => {
        if (tabIndex === currentTab) return;
        onChangeCurrentTab(tabIndex);
        onChangePageIndex(1);
        onSortChange(INITIAL_USER_GRID_SORT_FIELD, INITIAL_USER_GRID_SORT_DIRECTION);
        setIsLoading(true);
        getUsers({ pi: 0, tab: tabIndex });
        getCounters();
    };

    const handleSortChange = (sortField: string, sortDirection?: SortedTypeModel) => {
        onSortChange(sortField, sortDirection || INITIAL_USER_GRID_SORT_DIRECTION);
    };

    const handleSubmitQuery = () => {
        if (!query.length) {
            onQueryChange("");
            setShowSearchInput(false);
        }
        getUsers({ pi: 0 });
        getCounters();
        onChangePageIndex(1);
    };

    const handleCompanyChange = ({ label, value }: OptionModel) => {
        onCompanyChange({
            company: { label, value },
            isOpen: false,
        });
        onChangePageIndex(1);
        getUsers({ pi: 0, companyId: value });
        getCounters({ companyId: value });
        if (value !== "-1") changeWorkingCompanyByCompanyId(Number(value));
    };

    const handlePageIndexChange = (pi: number) => {
        onChangePageIndex(pi);
        getUsers({ pi: pi - 1 });
        getCounters();
    };

    const handleFilterIconClick = () => {
        onCompanyChange({ isOpen: true });
        setShowSearchInput(false);
    };

    const getUsers = async ({ companyId, pi, tab, sfValues }: IGetUsers = {}) => {
        setIsLoading(true);
        const pageIndexExtraparam = pi ?? pageIndex - 1;
        const currentTabFetch = tab ?? currentTab;
        const companyFetch = companyId || selectCompany.company.value;
        const customerInstanceId = session?.user.customerInstanceId || 0;
        const filterData = sfValues || secondaryFilterValues;

        const extraparams = fillUserGridExtraParams({
            companyId: companyFetch,
            currentTab: currentTabFetch,
            customerInstanceId,
            filterData,
        });

        const {
            data: usersData,
            getParsedError,
            status,
        } = await UserService.GetData({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageIndexExtraparam,
            extraParams: extraparams,
            query: query,
            sortDirection: sortDirection === "default" ? INITIAL_USER_GRID_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_USER_GRID_SORT_FIELD : sortField,
        });

        if (!status()) {
            handleToast({ title: getErrorMessage(getParsedError()), variant: "danger", type: "alert" });
            setIsLoading(false);
            return;
        }

        setData(usersData.list);
        setTotal(usersData.count);
        setIsLoading(false);
    };

    const getCounters = async ({ companyId, sfValues }: IGetCounters = {}) => {
        const companyFetch = companyId || selectCompany.company.value;
        const customerInstanceId = session?.user.customerInstanceId || 0;
        const filterData = sfValues || secondaryFilterValues;

        const extraParams = fillUserGridCountersExtraParams({
            companyId: companyFetch,
            customerInstanceId,
            filterData,
        });

        const {
            data: userCounters,
            getParsedError,
            status,
        } = await UserService.GetCounters({
            extraParams,
            query: query,
        });

        if (!status()) {
            handleToast({ title: getErrorMessage(getParsedError()), variant: "danger", type: "alert" });
            setIsLoading(false);
            return;
        }
        setCounters(userCounters);
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const extraparams = fillUserGridExtraParams({
            companyId: selectCompany.company.value || String(session?.workingCompany?.companyId),
            currentTab,
            customerInstanceId: session?.user.customerInstanceId || 0,
            filterData: secondaryFilterValues,
        });

        return UserService.GetDataExport({
            extraParams: extraparams,
            query: query,
            sortDirection: sortDirection === "default" ? INITIAL_USER_GRID_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_USER_GRID_SORT_FIELD : sortField,
            ...params,
        });
    };

    const onSaveSecondaryFilter = (values: UserGridSecondaryFilterModel) => {
        onSFChange(values);
        setIsSecondaryFilterOpen(false);
        getUsers({ pi: 0, sfValues: values });
        getCounters({ sfValues: values });
        onChangePageIndex(1);
    };

    const tabs: TabPropsReduced[] = [
        {
            text: t(TranslationKeys.ACTIVE_TAB),
            onClick: () => {
                handleTabChange(0);
            },
            type: "dark",
            rightCount: counters.activeUsers,
        },
        {
            text: t(TranslationKeys.INACTIVE_TAB),
            onClick: () => {
                handleTabChange(1);
            },
            type: "dark",
            rightCount: counters.inactiveUsers,
        },
        {
            text: t(TranslationKeys.USER_GRID_INACTIVE_WITH_DATE_TAB),
            onClick: () => {
                handleTabChange(2);
            },
            type: "dark",
            rightCount: counters.temporaryInactiveUsers,
        },
    ];

    useEffect(() => {
        getUsers();
        getCounters();
    }, [sortField, sortDirection]);

    return {
        currentTab,
        data,
        filterIcon,
        handleCompanyChange,
        handleExport,
        handleFilterIconClick,
        handlePageIndexChange,
        handleSortChange,
        handleSubmitQuery,
        isLoading,
        onCompanyChange,
        onQueryChange,
        pageIndex,
        query,
        selectCompany,
        setShowSearchInput,
        showSearchInput,
        sortDirection,
        sortField,
        tabs,
        total,
        secondaryFilterValues,
        handleChangeSecondaryFilterVisibility: setIsSecondaryFilterOpen,
        isSecondaryFilterOpen,
        handleSaveSecondaryFilter: onSaveSecondaryFilter,
    };
};
