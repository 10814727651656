import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToast } from "app/hooks/Toast/useToast";
import { TranslationErrors } from "app/translation/translationKeys";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { IssueAssetLastStepProps } from "../IssueAssetLastStep";
import { MAX_ALLOWED_ISSUE_SUPERVISORS } from "app/shared/Constants";

export const useIssueAssetLastStep = ({ onChange, onFinish, supervisors }: IssueAssetLastStepProps) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();
    const [errorSupervisor, setErrorSupervisor] = useState<string>("");

    const handleAddSupervisors = (values: OptionModel[]) => {
        if (values.length > MAX_ALLOWED_ISSUE_SUPERVISORS) {
            handleToast({
                variant: "danger",
                title: t(TranslationErrors.ERROR_MESSAGE_MAX_20_SUPERVISORS),
                type: "alert",
            });
            return;
        }
        setErrorSupervisor("");
        onChange(values);
    };

    const handleDeleteSupervisors = (id: string) => {
        const selectedSupervisors = supervisors.find(({ value }) => value === id);
        if (!selectedSupervisors) return;
        const supervisorsFiltered = supervisors.filter(({ value }) => value !== id);
        onChange(supervisorsFiltered);
    };

    const validate = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let isValid = true;
        setErrorSupervisor("");

        if (!supervisors.length) {
            setErrorSupervisor(t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION));
            isValid = false;
        }

        if (isValid) onFinish();
    };

    return {
        errorSupervisor,
        handleAddSupervisors,
        handleDeleteSupervisors,
        validate,
    };
};
