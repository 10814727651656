import { DynamicFieldItem } from "../DynamicFieldItem/DynamicFieldItem";
import { DynamicTaskFieldOption } from "app/models/02-TAR/TaskWizard";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FC, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Label } from "app/components_v2/__inputs";
import { TaskfieldOptionsError } from "app/models/02-TAR/Task/EditTask";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type DynamicFieldDropdownItemsProps = {
    items: DynamicTaskFieldOption[];
    hideAddNew: boolean;
    error?: TaskfieldOptionsError[];
    disabled?: boolean;
    onDeleteItem: (id: string) => void;
    onAddNewItem: () => void;
    onChangeItem: (id: string, value: string) => void;
};

export const DynamicFieldDropdownItems: FC<DynamicFieldDropdownItemsProps> = ({
    hideAddNew,
    items,
    onChangeItem,
    onDeleteItem,
    disabled,
    error,
    onAddNewItem,
}) => {
    const { t } = useTranslation();
    const ref = useRef(false);

    const handleAddNew = () => {
        if (disabled) return;
        ref.current = true;
        onAddNewItem();
    };

    return (
        <div className="dynamicFieldItemDropdown__items">
            <Label label={t(TranslationKeys.DATA_TO_REPORT_DYNAMIC_OPTION_TITLE)} size="sm" disabled={disabled} />
            {items.map((item) => (
                <DynamicFieldItem
                    id={item.id}
                    onChange={(value) => onChangeItem(item.id, value)}
                    onDeleteItem={() => onDeleteItem(item.id)}
                    value={item.label}
                    key={item.id}
                    errorMessage={error?.find((taskfield) => taskfield.id === item.id)?.errorMessage}
                    focus={ref.current}
                    disabled={disabled}
                    hideDeleteButton={items.length === 1}
                    isAnOption
                    dynamicFieldType="DROPDOWN"
                />
            ))}
            {!hideAddNew && !disabled && (
                <div className="dynamicFieldItemDropdown__addNew">
                    <div className="dynamicFieldItemDropdown__addNew__container" onClick={handleAddNew}>
                        <FontAwesomeIcon className="dynamicFieldItemDropdown__addNew__icon" icon={faPlus} />
                        <p className="dynamicFieldItemDropdown__addNew__text">{t(TranslationKeys.ADD_OPTION)}</p>
                    </div>
                </div>
            )}
        </div>
    );
};
