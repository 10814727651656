import { OptionModel } from "app/models/02-TAR/OptionModel";
import { TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { TaskType } from "app/models/02-TAR/Task/TaskBody";
import {
    RecurrentTaskModel,
    TaskTemporalityDateRangeFormModel,
    TaskTemporalityDateRangeCustomFormModel,
    TaskTemporalityType,
} from "app/models/02-TAR/TaskWizard";
import { TASK_TEMPORALITY_TYPE_ARRAY } from "app/shared/Constants";
import { TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { days } from "../../../utilities/initialValues";

type IUseTaskTemporality = {
    temporalityType: TaskTemporalityType;
    recurringTask: RecurrentTaskModel | null;
    taskTemporalityDateRange: TaskTemporalityDateRangeFormModel;
    taskType: TaskType;
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
};

export const useTaskTemporality = ({
    temporalityType,
    recurringTask,
    taskTemporalityDateRange,
    taskType,
    onChange,
}: IUseTaskTemporality) => {
    const { t } = useTranslation();

    const handleChangeTaskTemporalityType = ({ value }: OptionModel) => {
        const selectedType = TASK_TEMPORALITY_TYPE_ARRAY.find((type) => type === value);

        if (!selectedType || selectedType === temporalityType) return;

        if (selectedType === "PERIODICAL") {
            handleSelectPeriodical();
            return;
        }

        if (selectedType === "ONE_DAY") {
            handleSelectOneDay();
            return;
        }

        if (selectedType === "SPORADIC") {
            handleSelectSporadic();
            return;
        }

        if (selectedType === "RANGE_DATES") {
            handleSelectDateRange();
            return;
        }
    };

    const handleSelectPeriodical = () => {
        if (!recurringTask) return;
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                custom: {
                    dateRange: taskTemporalityDateRange.custom.dateRange
                        .filter(({ dbId }) => dbId != null)
                        .map((ttdr) => ({ ...ttdr, isDeleted: true })),
                },
            },
            temporalityType: "PERIODICAL",
            taskType: taskType === "SPORADIC" ? "NORMAL" : taskType,
            recurringTask: {
                ...recurringTask,
                isRecurrent: true,
                value: "everyDay",
                text: `${t(TranslationKeys.EVERY_DAY)}`,
                custom: {
                    ...recurringTask.custom,
                    customValue: "day",
                    selectedOptions: { text: "", value: "" },
                },
            },
            finish: {
                checked: "never",
                value: null,
            },
        });
    };

    const handleSelectOneDay = () => {
        if (!recurringTask) return;
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                custom: {
                    dateRange: taskTemporalityDateRange.custom.dateRange
                        .filter(({ dbId }) => dbId != null)
                        .map((ttdr) => ({ ...ttdr, isDeleted: true })),
                },
            },
            temporalityType: "ONE_DAY",
            taskType: taskType === "SPORADIC" ? "NORMAL" : taskType,
            recurringTask: {
                ...recurringTask,
                isRecurrent: false,
                value: "everyDay",
                text: `${t(TranslationKeys.EVERY_DAY)}`,
            },
            finish: {
                checked: "never",
                value: null,
            },
        });
    };

    const handleSelectSporadic = () => {
        onChange({
            taskTemporalityDateRange: {
                ...taskTemporalityDateRange,
                custom: {
                    dateRange: taskTemporalityDateRange.custom.dateRange
                        .filter(({ dbId }) => dbId != null)
                        .map((ttdr) => ({ ...ttdr, isDeleted: true })),
                },
            },
            taskType: "SPORADIC",
            taskHours: [],
            finish: {
                checked: "never",
                value: null,
            },
            temporalityType: "SPORADIC",
            recurringTask: {
                isRecurrent: false,
                options: [],
                value: "",
                custom: {
                    customValue: "day",
                    days,
                    repeatEvery: 1,
                    selectedOptions: { text: "", value: "" },
                },
                text: "",
            },
        });
    };

    const handleSelectDateRange = () => {
        if (!recurringTask) return;
        onChange({
            taskHours: [],
            temporalityType: "RANGE_DATES",
            taskType: taskType === "SPORADIC" ? "NORMAL" : taskType,
            recurringTask: {
                ...recurringTask,
                isRecurrent: false,
                value: "everyDay",
                text: `${t(TranslationKeys.EVERY_DAY)}`,
            },
            finish: {
                checked: "never",
                value: null,
            },
            taskTemporalityDateRange: {
                dbId: taskTemporalityDateRange.dbId,
                custom: {
                    dateRange: taskTemporalityDateRange.custom.dateRange
                        .filter(({ dbId }) => dbId != null)
                        .map((ttdr) => ({ ...ttdr, isDeleted: true })),
                },
                monthly: {
                    day: "",
                    customDays: { startDay: "", endDay: "" },
                    value: "FIRST_X_DAYS",
                    text: "",
                },
                repeatEvery: "1",
                text: "Semanal",
                value: "WEEKLY",
                weeklyDays: days,
            },
        });
    };

    return { onChangeTaskTemporality: handleChangeTaskTemporalityType };
};
