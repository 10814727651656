import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TarSelectorService from "app/services/02-TAR/TarSelectorService";
import { TranslationCommon } from "app/translation/translationKeys";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { IsEnabledModel } from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step2/models";
import { CompanyOptionModel } from "app/dtos/01-SEG/Company/CompanyOptionModel";
import { TaskBelongsModel } from "app/models/02-TAR/Task/EditTask";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";

export const useTaskBelongs = (
    companyForTask: OptionsSearch,
    SubDepartementForTask: OptionsSearch,
    DepartmentForTask: OptionsSearch,
    userForTask: OptionsSearch,
    workingPositionForTask: OptionsSearch,
    onChange: (fields: Partial<TaskBelongsModel>) => void,
    companyId: number,
    departmentId: number | null,
    setEnableUserSubdept: (value: IsEnabledModel) => void,
    companyValues: CompanyOptionModel[]
) => {
    const [usersValues, setUsersValues] = useState<OptionsSearch[]>([]);
    const [departmentsValues, setDepartmentsValues] = useState<OptionsSearch[]>([]);
    const [subDepartmentsValues, setSubDepartmentsValues] = useState<OptionsSearch[]>([]);
    const [workingPositionValues, setWorkingPositionValues] = useState<OptionsSearch[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSubDepartmentLoading, setIsSubDepartmentLoading] = useState<boolean>(true);
    const { t } = useTranslation();

    const changeUserSelected = (selectedUserId: string, selectedUserName: string) => {
        onChange({
            userForTask: {
                label: selectedUserName,
                value: selectedUserId,
            },
            SubDepartementForTask: {
                label: "",
                value: "",
            },
            workingPositionForTask: {
                label: "",
                value: "",
            },
        });
    };

    const changeCompanySelected = (selectedCompanyId: string, selectedCompanyName: string) => {
        const selectedCompany = companyValues?.find(({ value }) => value === selectedCompanyId);
        const worksWithQr = !!selectedCompany?.worksWithQR;
        onChange({
            companyForTask: {
                ...companyForTask,
                label: selectedCompanyName,
                value: selectedCompanyId,
                worksWithQr,
            },
            DepartmentForTask: {
                label: "",
                value: "",
            },
            SubDepartementForTask: {
                label: "",
                value: "",
            },
            userForTask: {
                label: "",
                value: "",
            },
            workingPositionForTask: {
                label: "",
                value: "",
            },
        });

        setEnableUserSubdept({
            workingPosition: true,
            user: false,
            subdepartment: false,
        });
    };

    const changeDepartmentSelected = (selectedDepartmentId: string, selectedDepartmentName: string) => {
        onChange({
            userForTask: {
                label: "",
                value: "",
            },
            DepartmentForTask: {
                label: selectedDepartmentName,
                value: selectedDepartmentId,
            },
            SubDepartementForTask: {
                label: "",
                value: "",
            },
            workingPositionForTask: {
                label: "",
                value: "",
            },
        });
        setEnableUserSubdept({
            workingPosition: true,
            user: false,
            subdepartment: false,
        });
    };

    const changeSubDepartmentSelected = (selectedSubdepartmentId: string, selectedSubdepartmentName: string) => {
        onChange({
            SubDepartementForTask: {
                ...SubDepartementForTask,
                label: selectedSubdepartmentName,
                value: selectedSubdepartmentId,
            },
        });
    };

    const changeWPSelected = (selectedWPId: string, selectedWPName: string) => {
        onChange({
            workingPositionForTask: {
                ...workingPositionForTask,
                label: selectedWPName,
                value: selectedWPId,
            },
        });
    };

    const enableUser = () => {
        setEnableUserSubdept({
            user: true,
            subdepartment: false,
            workingPosition: false,
        });
        onChange({
            SubDepartementForTask: {
                label: "",
                value: "",
            },
            workingPositionForTask: {
                label: "",
                value: "",
            },
        });
    };

    const enableSubdept = () => {
        setEnableUserSubdept({
            subdepartment: true,
            user: false,
            workingPosition: false,
        });
        onChange({
            userForTask: {
                label: "",
                value: "",
            },
            workingPositionForTask: {
                label: "",
                value: "",
            },
        });
    };

    const enableWP = () => {
        setEnableUserSubdept({
            subdepartment: false,
            user: false,
            workingPosition: true,
        });
        onChange({
            userForTask: {
                label: "",
                value: "",
            },
            SubDepartementForTask: {
                label: "",
                value: "",
            },
        });
    };

    useEffect(() => {
        const fetch = async () => {
            if (userForTask.value.length === 0)
                onChange({
                    userForTask: {
                        label: "",
                        value: "",
                    },
                });
            else if (DepartmentForTask.value.length === 0)
                onChange({
                    SubDepartementForTask: {
                        label: "",
                        value: "",
                    },
                });

            setIsLoading(true);
            const departmentsSr = await SegSelectorService.GetDepartmentNamesWithIds({
                extraParams: `companyId=${companyId}`,
            });

            if (departmentsSr.status()) setDepartmentsValues(departmentsSr.data);

            setIsLoading(false);
        };

        fetch();
    }, [companyId]);

    useEffect(() => {
        const fetch = async () => {
            setIsSubDepartmentLoading(true);
            // TODO: --SUBDEPARTMENT--
            // const subDepartmentsSr = await SegSelectorService.GetSubDepartmentNamesWithIds({
            //     extraParams: `DepartmentId=${departmentId}`,
            // });
            const usersSr = await SegSelectorService.GetUserNamesWithIds({
                extraParams: `CompanyId=${companyId}&DepartmentId=${departmentId}&HasLoggedIn=false`,
            });
            const wpSr = await TarSelectorService.GetWorkingPostions({
                extraParams: `companyId=${companyId}&IsDeleted=false`,
            });

            // TODO: --SUBDEPARTMENT--
            // const [subdepartmentResponse, userResponse, workingPositionResponse] = await Promise.all([
            //     subDepartmentsSr,
            //     usersSr,
            //     wpSr,
            // ]);
            const [userResponse, workingPositionResponse] = await Promise.all([usersSr, wpSr]);

            // TODO: --SUBDEPARTMENT--
            // if (userResponse.status() && subdepartmentResponse.status() && workingPositionResponse.status()) {
            if (userResponse.status() && workingPositionResponse.status()) {
                setUsersValues(userResponse.data);
                setWorkingPositionValues([
                    { value: "-1", label: t(TranslationCommon.SELECT_OPTION) },
                    ...workingPositionResponse.data,
                ]);

                // TODO: --SUBDEPARTMENT--
                // setSubDepartmentsValues([
                //     { value: "-1", label: t(TranslationCommon.SELECT_OPTION) },
                //     ...subDepartmentsSr.data,
                // ]);
            }

            setIsSubDepartmentLoading(false);
        };

        if (departmentId === null || departmentId === 0) return;
        fetch();
    }, [departmentId]);

    useEffect(() => {
        setEnableUserSubdept({
            subdepartment: false,
            // subdepartment: SubDepartementForTask.value.length !== 0,
            user: userForTask.value.length !== 0,
            workingPosition: workingPositionForTask.value.length !== 0,
        });
    }, []);

    return {
        isLoading,
        enableUser,
        changeCompanySelected,
        changeUserSelected,
        usersValues,
        enableSubdept,
        changeDepartmentSelected,
        departmentsValues,
        isSubDepartmentLoading,
        subDepartmentsValues,
        changeSubDepartmentSelected,
        workingPositionValues,
        enableWP,
        changeWPSelected,
    };
};
