import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TaskTranslations, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { Switch } from "app/components_v2/__inputs/Switch/Switch";
import { SwitchDragFile } from "app/components_v2/SwitchDragFile/SwitchDragFile";
import {
    EditDataModel,
    ExtraDataModel,
    CustomErrorExtraDataModel,
    EditTaskDisabledExtraDataModel,
} from "app/models/02-TAR/Task/EditTask";
import { useExtraData } from "../DataToReport/hooks/useExtraData";
import { SelectOptions } from "app/components_v2/__inputs";
import { MAX_PHOTOS_ALLOWED_PER_TASK } from "app/shared/Constants";

export const ExtraData: FC<
    EditDataModel<ExtraDataModel, CustomErrorExtraDataModel, EditTaskDisabledExtraDataModel>
> = ({
    criticalTask,
    isDisabled,
    isPhotoRequired,
    onChange,
    somethingToReportImage,
    disabledFields,
    imageBase64,
    minPhotosAllowed,
}) => {
    const { t } = useTranslation();

    const { handleChangePhoto, onChangeImg, onDeleteImg } = useExtraData({ onChange, disabledFields });

    return (
        <div className="extraData">
            <div className="dataToReport__photoRegister">
                <div className="dataToReport__switchContainer">
                    <Switch
                        checked={criticalTask}
                        onChange={(value) => onChange({ criticalTask: value })}
                        disabled={isDisabled || disabledFields?.disabledIsCritical}
                        label={t(TranslationKeys.CRITICAL_TASK)}
                    />
                </div>
            </div>
            <SwitchDragFile
                image={imageBase64 || somethingToReportImage}
                isChecked={isPhotoRequired}
                onDeleteImage={onDeleteImg}
                handleChangePhoto={handleChangePhoto}
                onChangePhoto={onChangeImg}
                disabledCheckbox={isDisabled || disabledFields?.disabledIsPhotoRequired}
                disabledImage={isDisabled || disabledFields?.disabledFotoExample}
                isImageEditable
            />
            {isPhotoRequired && (
                <SelectOptions
                    isMulti={false}
                    onChange={({ value }) => onChange({ minPhotosAllowed: Number(value) })}
                    options={Array.from({ length: MAX_PHOTOS_ALLOWED_PER_TASK }).map((_, index) => ({
                        label: `${index + 1}`,
                        value: `${index + 1}`,
                    }))}
                    selectedValue={minPhotosAllowed != null ? String(minPhotosAllowed) : ""}
                    label={t(TaskTranslations.MIN_ALLOWED_PHOTOS_LABEL)}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                    disabled={isDisabled}
                    readonly={!isDisabled && disabledFields?.disabledIsPhotoRequired}
                    isSearchable
                />
            )}
        </div>
    );
};
