import { FC } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGoBack } from "app/hooks";
import { faListCheck } from "@fortawesome/pro-solid-svg-icons";
import { TranslationKeys } from "app/translation/translationKeys";
import { TaskPillList } from "../../01-TAR-Dashboard/TaskPillList";
import { TaskDetailModal } from "../../01-TAR-Dashboard/TaskDetail/TaskDetailModal";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import { ReviewTaskTabs } from "../models";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

export type ReviewTaskListProps = {
    onStarsValued?: () => void;
    onChangePageIndex: (value: number) => void;
    data: TaskInstanceListDto[];
    isLoading: boolean;
    total: number;
    pageIndex: number;
    actualPage: ReviewTaskTabs;
    taskDetailOnStarsChange: () => void;
};

export const ReviewTaskList: FC<ReviewTaskListProps> = ({
    onStarsValued,
    data,
    isLoading,
    total,
    onChangePageIndex,
    pageIndex,
    taskDetailOnStarsChange,
    actualPage,
}) => {
    const { t } = useTranslation();
    const { taskInstanceId } = useParams();
    const nav = useNavigate();
    const { goBackToNotificationPage, removeUrlFromPathnames } = useGoBack();
    const location = useLocation();

    const onClose = () => {
        if (goBackToNotificationPage()) {
            removeUrlFromPathnames(location.pathname);
            return;
        }
        nav(`/${PrivatePaths.TASK_PHOTO}`);
    };

    return (
        <>
            {!data.length && !isLoading ? (
                <div className="fullHeight">
                    <TableError icon={faListCheck} title={t(TranslationKeys.EMPTY_LIST_TASK_REVIEW)} size="xl" />
                </div>
            ) : (
                <TaskPillList
                    isLoading={isLoading}
                    onDetailClick={(id) => nav(`task/${id}`)}
                    list={data}
                    total={total || 0}
                    onChangePageIndex={onChangePageIndex}
                    pageIndex={pageIndex}
                    onStarsValued={onStarsValued}
                    // TODO: Preguntar al Jordi si quiere foto
                    // photoExampleRequired={false}
                    editable={false}
                    skeletonOptions={{ type: actualPage === "PENDING" ? "withOutStars" : "withStars" }}
                    starColor={!hasPermissionToEdit(SecScreen.TASK_REVIEW) ? "yellow" : undefined}
                    starSize={!hasPermissionToEdit(SecScreen.TASK_REVIEW) ? "xs" : undefined}
                    showValoratedBy
                    hideFeedback={actualPage === "PENDING"}
                    isSupervisor={actualPage !== "PENDING"}
                    canBeDisabled
                />
            )}
            {taskInstanceId && (
                <TaskDetailModal
                    editTaskDetail={false}
                    taskInstanceId={taskInstanceId}
                    onStarsChange={taskDetailOnStarsChange}
                    onClose={onClose}
                />
            )}
        </>
    );
};
