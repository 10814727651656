import { faQrcode } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

type AvatarQrProps = {
    title: string;
};

export const AvatarQr: FC<AvatarQrProps> = ({ title = "" }) => {
    return (
        <div className="avatarQr" title={title}>
            <FontAwesomeIcon icon={faQrcode} />
        </div>
    );
};
