import { FC } from "react";
import { AuditIssuePillListSkelleton } from "../AuditIssuePillListSkelleton/AuditIssuePillListSkelleton";
import { AuditIssuePill } from "../AuditIssuePill/AuditIssuePill";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { faCheckDouble } from "@fortawesome/pro-regular-svg-icons";
import Pagination from "app/components_v2/Pagination/Pagination";
import { useTranslation } from "react-i18next";
import { AuditTranslation } from "app/translation/translationKeys";
import { IssueListDto } from "app/dtos/05-QUA/Issue/IssueListDto";

type AuditIssuePillListProps = {
    isLoading: boolean;
    issueList: IssueListDto[];
    pageIndex: number;
    setPageIndex: (pageindex: number) => void;
    pageSize: number;
    total: number;
    handleNavigate: (issue: IssueListDto) => void;
};

export const AuditIssuePillList: FC<AuditIssuePillListProps> = ({
    issueList,
    handleNavigate,
    isLoading,
    setPageIndex,
    pageIndex,
    pageSize,
    total,
}) => {
    const { t } = useTranslation();
    if (isLoading) return <AuditIssuePillListSkelleton />;
    if (!issueList.length)
        return <TableError title={t(AuditTranslation.AUDIT_ISSUE_PLACEHOLDER)} icon={faCheckDouble} />;

    return (
        <>
            <div className="auditIssuePillList">
                {issueList.map((issue, key) => (
                    <AuditIssuePill key={key} issue={issue} onPillClick={() => handleNavigate(issue)} />
                ))}
                {total / pageSize >= 1 && (
                    <Pagination onClick={setPageIndex} pageIndex={pageIndex || 1} total={total} pageSize={pageSize} />
                )}
            </div>
        </>
    );
};
