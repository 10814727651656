import { base64ToFile } from "app/helpers/images/base64ToFile";
import { DropdownList } from "app/components_v2/Dropdown/DropdownList/DropdownList";
import { EditImage } from "app/components_v2/EditImage/EditImage";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { faCamera, faPhotoFilm } from "@fortawesome/pro-regular-svg-icons";
import { FC, useRef, useState, DragEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IDropDownListItem } from "app/components_v2/Dropdown/types";
import { ImageDragFile } from "./types";
import { ImagePreview } from "app/components_v2/ImagePreview";
import { Modal } from "app/components_v2/__modals/base/Modal/Modal";
import { Popover } from "app/components_v2/Popover/Popover";
import { DragFileTranslations, TranslationKeys } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import isDeviceMobile from "app/helpers/isMobile";
import isiOS from "app/helpers/isIos";
import WebcamCapture from "app/components_v2/WebcamCapture/WebcamCapture";
import { useToast } from "app/hooks/Toast/useToast";
import { validTypes } from "app/shared/Constants";

export type DragFileProps = {
    captureMode?: "both" | "camera";
    disabled?: boolean;
    errorMessage?: string;
    height76?: boolean;
    htmlFor?: string;
    images?: ImageDragFile[];
    maxFiles?: number;
    multiple?: boolean;
    name?: string;
    onlyImages?: boolean;
    placeholder?: string;
    subPlaceholder?: string;
    editable?: boolean;
    onChange: (files: ImageDragFile[]) => void;
    onDelete: (id: string) => void;
    onEditImage?: (image: ImageDragFile, editedImageId: string) => void;
};

type DragFileImageToEdit = {
    isOpen: boolean;
    base64: string;
    id: string;
    url: string;
    takenInRealTime?: boolean;
};

const INITIAL_EDIT_DRAG_FILE: DragFileImageToEdit = {
    base64: "",
    id: "",
    isOpen: false,
    url: "",
    takenInRealTime: false,
};

export const DragFile: FC<DragFileProps> = ({
    captureMode = "camera",
    disabled,
    errorMessage,
    htmlFor,
    images = [],
    maxFiles = 5,
    multiple,
    name,
    onChange,
    onDelete,
    onEditImage,
    onlyImages = true,
    placeholder,
    subPlaceholder,
    height76,
    editable,
}) => {
    const { t } = useTranslation();
    const { handleToast } = useToast();

    const [isWebcamOpen, setIsWebcamOpen] = useState<boolean>(false);
    const [isCameraPopoverOpen, setIsCameraPopoverOpen] = useState<boolean>(false);
    const [imageToEdit, setImageToEdit] = useState<DragFileImageToEdit>(INITIAL_EDIT_DRAG_FILE);

    const dragFileRef = useRef<HTMLDivElement | null>(null);
    const fileRef = useRef<HTMLInputElement | null>(null);

    const onClick = () => {
        const isUsingAndroid = !isiOS() && isDeviceMobile();
        if (isUsingAndroid) {
            if (captureMode === "camera") {
                setIsWebcamOpen(true);
                return;
            }
            setIsCameraPopoverOpen(true);
            return;
        }

        fileRef.current?.click();
    };

    const handleInputChange = (files: FileList | null) => {
        if (!files?.length) return;
        const chosenFiles: File[] = Array.from(files);
        const currentLength = images.length;
        const filesSliced = chosenFiles.slice(0, maxFiles - currentLength);

        const dragFileImages = filesToImageDragFile(filesSliced, isiOS());
        if (!dragFileImages.length) {
            handleToast({ title: t(DragFileTranslations.INVALID_FILE_TYPE), type: "alert", variant: "warning" });
            return;
        }
        onChange(dragFileImages);
    };

    const filesToImageDragFile = (files: File[], takenInRealTime: boolean): ImageDragFile[] =>
        files
            .filter(({ type }) => (onlyImages ? type.startsWith("image/") : true))
            .map((file) => ({
                fileName: file.name,
                id: v4(),
                isErrored: false,
                isLoading: true,
                file: file,
                takenInRealTime,
            }));

    const onChangeWebcamVisiblity = (value: boolean) => setIsWebcamOpen(value);

    const onChangeWebcamValue = (photoBase64: string) => {
        onChangeWebcamVisiblity(false);
        onChange([
            {
                fileName: `photo_${new Date().getTime()}`,
                id: v4(),
                isErrored: false,
                isLoading: true,
                base64: photoBase64,
                takenInRealTime: true,
            },
        ]);
    };

    const handleMakePhoto = () => {
        setIsCameraPopoverOpen(false);
        setIsWebcamOpen(true);
    };

    const handleTakPhotoCamera = () => {
        setIsCameraPopoverOpen(false);
        fileRef.current?.click();
    };

    const dropDownItems: IDropDownListItem[] = [
        { text: t(TranslationKeys.DRAG_FILE_MAKE_PHOTO), onClick: handleMakePhoto, icon: faCamera },
        {
            text: t(TranslationKeys.DRAG_FILE_GALLERY),
            onClick: handleTakPhotoCamera,
            icon: faPhotoFilm,
        },
    ];

    const onClickOutside = (e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        setIsCameraPopoverOpen(false);
    };

    const onScroll = (e: Event) => {
        e.preventDefault();
        setIsCameraPopoverOpen(false);
    };

    const handleDropFiles = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (images.length >= maxFiles) return;

        const droppedFiles = Array.from(e.dataTransfer.files);

        const newFiles = droppedFiles.filter(({ type }) => (onlyImages ? type.startsWith("image/") : true));
        const currentLength = images.length;
        const filesSliced = newFiles.slice(0, maxFiles - currentLength);

        const dragfilesImages = filesToImageDragFile(filesSliced, false);
        if (!dragfilesImages.length) {
            handleToast({ title: t(DragFileTranslations.INVALID_FILE_TYPE), type: "alert", variant: "warning" });
            return;
        }
        onChange(filesToImageDragFile(filesSliced, false));
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

    const handleEditImage = (photoBase64: string, takenInRealTime: boolean) => {
        onEditImage &&
            onEditImage(
                {
                    fileName: `photo_${new Date().getTime()}`,
                    id: imageToEdit.id,
                    isErrored: false,
                    isLoading: true,
                    base64: photoBase64,
                    takenInRealTime: takenInRealTime,
                },
                imageToEdit.id
            );
        setImageToEdit(INITIAL_EDIT_DRAG_FILE);
    };

    return (
        <>
            {isWebcamOpen && (
                <WebcamCapture closeModal={() => onChangeWebcamVisiblity(false)} onPhotoCapture={onChangeWebcamValue} />
            )}
            {isCameraPopoverOpen && (
                <Popover
                    target={dragFileRef.current}
                    position="bottomCenter"
                    zIndexUp
                    onClickOutside={onClickOutside}
                    onScroll={onScroll}
                >
                    <DropdownList items={dropDownItems} />
                </Popover>
            )}
            {imageToEdit.isOpen && (!!imageToEdit.base64.length || !!imageToEdit.url.length) && (
                <Modal portal className="zIndexUp webcamCapture" showBlur={false}>
                    <div className="webcamCapture__container">
                        <EditImage
                            onCancel={() => setImageToEdit(INITIAL_EDIT_DRAG_FILE)}
                            onAccept={(base64) => handleEditImage(base64, !!imageToEdit.takenInRealTime)}
                            file={
                                !!imageToEdit.base64.length
                                    ? base64ToFile(imageToEdit.base64, "imagen.png", "image/png")
                                    : undefined
                            }
                            url={!!imageToEdit.url.length ? imageToEdit.url : undefined}
                        />
                    </div>
                </Modal>
            )}
            {images.length < maxFiles && (
                <div
                    className={`dragFile ${height76 ? "dragFile--height76" : ""} ${
                        disabled ? "dragFile--disabled" : ""
                    }`}
                    onClick={() => !disabled && onClick()}
                    onDrop={handleDropFiles}
                    onDragOver={handleDragOver}
                >
                    <div
                        className={`dragFile__upload${height76 ? "--height76" : ""} ${
                            disabled ? "dragFile__upload--disabled" : ""
                        }`}
                    >
                        <div className={`dragFile__upload__icon${disabled ? "--disabled" : ""}`} ref={dragFileRef}>
                            <FontAwesomeIcon icon={faCamera} />
                        </div>
                        <div className="dragFile__upload__container">
                            <label htmlFor={htmlFor}>{placeholder}</label>
                            <label className="dragFile__upload__container__subPlaceholder" htmlFor={htmlFor}>
                                {subPlaceholder}
                            </label>
                        </div>
                    </div>
                    <div>
                        <input
                            type="file"
                            name={name}
                            id={htmlFor}
                            onChange={(e) => handleInputChange(e.target.files)}
                            value=""
                            hidden={true}
                            ref={fileRef}
                            className="mainInputFile__input"
                            accept={onlyImages ? "image/*" : validTypes.join(",")}
                            multiple={multiple}
                            // @ts-ignore
                            capture={captureMode === "camera" || isiOS() ? "camera" : undefined}
                        />
                    </div>
                </div>
            )}
            {!!errorMessage?.length && <ErrorMessage errorMessage={errorMessage} />}

            <div className="dragFile__preview">
                {images.map(({ id, isLoading, isErrored, fileName, mediaType, base64, url, takenInRealTime }) => (
                    <ImagePreview
                        loading={isLoading}
                        error={isErrored}
                        key={id}
                        handleDeleteImage={() => onDelete(id)}
                        name={fileName || ""}
                        size={0}
                        type={mediaType || ""}
                        disabled={disabled}
                        editable={editable}
                        handleEditImage={() =>
                            setImageToEdit({ isOpen: true, base64: base64 || "", id, url: url || "", takenInRealTime })
                        }
                    />
                ))}
            </div>
        </>
    );
};
