import { FC, useState } from "react";
import { ProviderProps } from "../types";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";
import { IssueServiceType, IssueTypeCodeModel } from "app/shared/types/IssueTypes";
import { IssueModalAuditInfo, IssueModalOptions } from "app/state/context/issueModalContext/models/type";

export const IssueModalProvider: FC<ProviderProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [issueType, setIssueType] = useState<IssueTypeCodeModel>("GENERIC");
    const [taskInstanceId, setTaskInstanceId] = useState<number | null>(null);
    const [assetId, setAssetId] = useState<number>();
    const [assetNotWorking, setAssetNotWorking] = useState<boolean | null>(null);
    const [auditInfo, setAuditInfo] = useState<IssueModalAuditInfo | null>(null);
    const [auditInstanceId, setAuditInstanceId] = useState<number | null>(null);
    const [auditGroupChecklistInstanceId, setAuditGroupChecklistInstanceId] = useState<number | null>(null);
    const [companyId, setCompanyId] = useState<number | null>(null);
    const [taskInstanceFieldId, setTaskInstanceFieldId] = useState<number | null>(null);
    const [onCancel, setOnCancel] = useState<() => void>();
    const [issueServiceType, setIssueServiceType] = useState<IssueServiceType>("ISSUE");

    const open = ({
        issueType,
        taskinstanceId,
        assetId,
        auditInfo,
        auditInstanceId,
        auditGroupChecklistInstanceId,
        isAssetWorking,
        companyId,
        taskInstanceFieldId,
        onCancel,
        issueServiceType,
    }: IssueModalOptions) => {
        setIsOpen(true);
        setIssueType(issueType);
        setTaskInstanceId(taskinstanceId || null);
        setAssetId(assetId);
        setAuditInfo(auditInfo || null);
        setAuditInstanceId(auditInstanceId || null);
        setAuditGroupChecklistInstanceId(auditGroupChecklistInstanceId || null);
        setCompanyId(companyId || null);
        setTaskInstanceFieldId(taskInstanceFieldId || null);
        if (isAssetWorking != null) setAssetNotWorking(!isAssetWorking);
        if (onCancel != undefined) setOnCancel(() => onCancel);
        setIssueServiceType(issueServiceType || "ISSUE");
    };

    const fillValues = ({
        issueType,
        taskinstanceId,
        assetId,
        auditInfo,
        auditInstanceId,
        auditGroupChecklistInstanceId,
        isAssetWorking,
        companyId,
        taskInstanceFieldId,
    }: IssueModalOptions) => {
        setIssueType(issueType);
        setTaskInstanceId(taskinstanceId || null);
        setAssetId(assetId);
        setAuditInfo(auditInfo || null);
        setAuditInstanceId(auditInstanceId || null);
        setAuditGroupChecklistInstanceId(auditGroupChecklistInstanceId || null);
        setCompanyId(companyId || null);
        setTaskInstanceFieldId(taskInstanceFieldId || null);
        if (isAssetWorking != null) setAssetNotWorking(!isAssetWorking);
    };

    const reset = () => {
        setIsOpen(false);
        setIssueType("GENERIC");
        setTaskInstanceId(null);
        setAssetId(undefined);
        setAuditInfo(null);
        setAuditInstanceId(null);
        setAuditGroupChecklistInstanceId(null);
        setAssetNotWorking(null);
        setTaskInstanceFieldId(null);
    };

    const close = () => {
        reset();
        onCancel && onCancel();
    };

    return (
        <IssueModalContext.Provider
            value={{
                isOpen,
                issueType,
                taskInstanceId,
                reset,
                open,
                assetId,
                fillValues,
                assetNotWorking,
                auditInfo,
                auditInstanceId,
                auditGroupChecklistInstanceId,
                companyId,
                taskInstanceFieldId,
                issueServiceType,
                close,
            }}
        >
            {children}
        </IssueModalContext.Provider>
    );
};
