import FetchService from "../Fetch/FetchService";
import { ApiPaths } from "app/shared/Constants";
import { Asset2ToBackModel } from "app/models/05-QUA/AssetModels";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { AssetModel2 } from "app/models/05-QUA/AssetModels/AssetModel2";
import { Bool } from "app/dtos/Bool";
import { AssetTabCountersDto } from "app/dtos/05-QUA/Asset/AssetTabCountersDto";

export default class AssetRangeService {
    public static async GetOne(id: number) {
        return FetchService.get<AssetModel2>({
            url: `${ApiPaths.QUA_API}/AssetRange/${id}`,
        });
    }

    public static async GetList(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<AssetModel2>>({
            url: `${ApiPaths.QUA_API}/AssetRange`,
            paginationParams: params,
        });
    }

    public static async GetCounters(params?: PaginationParams) {
        return FetchService.get<AssetTabCountersDto>({
            url: `${ApiPaths.QUA_API}/AssetRange/Counters`,
            paginationParams: params,
        });
    }

    public static async GetDataExport(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<Asset2ToBackModel>>({
            url: `${ApiPaths.QUA_API}/AssetRange/export`,
            paginationParams: params,
            csvExport: true,
        });
    }

    public static async Save(asset: Asset2ToBackModel) {
        return FetchService.post<AssetModel2>({
            url: `${ApiPaths.QUA_API}/AssetRange`,
            body: asset,
        });
    }

    public static async Update(asset: Asset2ToBackModel, id: number) {
        return FetchService.put<AssetModel2>({
            url: `${ApiPaths.QUA_API}/AssetRange/${id}`,
            body: asset,
        });
    }

    // PUT NotWorking Asset
    public static async NotWorking(id: number, notWorking: Bool) {
        return FetchService.put<AssetModel2>({
            url: `${ApiPaths.QUA_API}/AssetRange/NotWorking/${id}`,
            body: notWorking,
        });
    }

    public static async Activate(id: number | string) {
        return FetchService.put({
            url: `${ApiPaths.QUA_API}/AssetRange/activation/${id}`,
        });
    }

    public static async Delete(id: number | string) {
        return FetchService.delete({
            url: `${ApiPaths.QUA_API}/AssetRange/${id}`,
        });
    }
}
