import { TaskHoursModel } from "app/models/02-TAR/TaskWizard";
import { TaskHourValidation } from "app/pages/02-TAR/07-TAR-CRUD/WizardTasks/Step4/models/StepFourModel";
import i18n from "app/translation/i18n";
import { TranslationKeys } from "app/translation/translationKeys";

export const validateHours = (hours: TaskHoursModel[], t: Function): TaskHourValidation => {
    let check: TaskHourValidation = {
        value: "",
        errors: [],
    };

    if (hours.length === 0) {
        check = {
            value: t(TranslationKeys.NO_TIME_SELECTED),
            errors: [],
        };
    }

    for (let i = 0; i < hours.length; i++) {
        const { hour, maxHour, type, id } = hours[i];
        if (!hour.length) {
            check.errors.push({ id, value: t(TranslationKeys.TIME_EMPTY) });
            break;
        }

        if (type === "range") {
            if (!maxHour?.length) {
                check.errors.push({ id, value: t(TranslationKeys.MAX_TIME_EMPTY) });
                break;
            }
            const sameHourValidation = validateIsSameHourAndMaxHour(hour, maxHour);
            if (sameHourValidation) {
                check.errors.push({ id, value: sameHourValidation });
                break;
            }
        }

        for (let x = 0; x < hours.length; x++) {
            if (i <= x) continue;
            let hour1 = createDateFroHour(hours[i].hour);
            let maxHour1 = createDateFroHour(hours[i].maxHour!);
            let type1 = hours[i].type;

            let hour2 = createDateFroHour(hours[x].hour);
            let maxHour2 = createDateFroHour(hours[x].maxHour!);
            let type2 = hours[x].type;

            if (type1 === "range" && maxHour1 && hour1 > maxHour1) {
                maxHour1 = createDateFroHour(hours[i].maxHour!, true);
            }
            if (type2 === "range" && maxHour2 && hour2 > maxHour2) {
                maxHour2 = createDateFroHour(hours[x].maxHour!, true);
            }

            if (type1 === "hour" && type2 === "hour" && hour1.getTime() === hour2.getTime()) {
                check.value = t(TranslationKeys.SAME_TIMES);
                continue;
            }

            if (type1 === "hour" && type2 === "range") {
                if (maxHour2 && hour2.getTime() < maxHour2.getTime() && hayInterseccion(hour1, hour2, maxHour2)) {
                    check.value = t(TranslationKeys.OVERLAP_TIME);
                    break;
                }

                if (maxHour2 && hour2.getTime() > maxHour2.getTime() && hayInterseccion(hour1, hour2, maxHour2)) {
                    check.value = t(TranslationKeys.OVERLAP_TIME);
                    break;
                }
            }
            if (type1 === "range" && type2 === "hour") {
                if (maxHour1 && hour1.getTime() > maxHour1.getTime() && hayInterseccion(hour2, hour1, maxHour1)) {
                    check.value = t(TranslationKeys.OVERLAP_TIME);
                    break;
                }
            }
            if (type1 === "range" && type2 === "range" && hayInterseccionTwoDates(hour1, maxHour1, hour2, maxHour2)) {
                check.value = t(TranslationKeys.OVERLAP_TIME);
                break;
            }
        }
    }

    return check;
};

export const isHoursOutOfRange = (hours: TaskHoursModel[]): boolean => {
    let hasOurOutOfRange: boolean = false;
    for (let i = 0; i < hours.length; i++) {
        // Si la hora es un rango de fechas
        if (hours[i].type === "range") {
            let hour = createDateFroHour(hours[i].hour);
            let maxHour = createDateFroHour(hours[i].maxHour!);

            // Si la hora es más grande que la hora máxima
            if (hour.getHours() > maxHour.getHours()) {
                hasOurOutOfRange = true;
            }

            // Si la hora es igual pero els minuts son més grans que el minuts máxims
            if (hour.getHours() === maxHour.getHours() && hour.getMinutes() > maxHour.getMinutes()) {
                hasOurOutOfRange = true;
            }
        }

        for (let x = 0; x < hours.length; x++) {
            if (i === x) break;

            let hour1 = createDateFroHour(hours[i].hour);
            let maxHour1 = createDateFroHour(hours[i].maxHour!);
            let type1 = hours[i].type;

            let hour2 = createDateFroHour(hours[x].hour);
            let maxHour2 = createDateFroHour(hours[x].maxHour!);
            let type2 = hours[x].type;

            // Comprobar intersección de los de tipo rango en ambos
            if (type1 === "range" && type2 === "range" && hayInterseccionTwoDates(hour1, maxHour1, hour2, maxHour2)) {
                hasOurOutOfRange = true;
            }

            // Comprobar intersección de los de tipo rango en ambos
            if (
                (type1 === "hour" && type2 === "range" && hayInterseccion(hour2, maxHour2, hour1)) ||
                (type1 === "range" && type2 === "hour" && hayInterseccion(hour1, maxHour1, hour2))
            ) {
                hasOurOutOfRange = true;
            }
        }
    }

    return hasOurOutOfRange;
};

export function hayInterseccionTwoDates(hora1Inicio: Date, hora1Fin: Date, hora2Inicio: Date, hora2Fin: Date) {
    if (hora2Inicio > hora2Fin) {
        // Si hora1Inicio es posterior a hora2Fin o igual a hora2Inicio, no hay intersección
        if (hora1Inicio >= hora2Fin || hora1Inicio <= hora2Inicio) {
            return false;
        }
        // Si no, hay intersección si hora1Fin es posterior a hora2Inicio
        return hora1Fin > hora2Inicio;
    }

    // Si hora2Fin no es para el día siguiente
    const interseccion = hora1Inicio <= hora2Fin && hora2Inicio <= hora1Fin;
    return interseccion;
}

export function hayInterseccion(hora1Inicio: Date, hora2Inicio: Date, hora2Fin: Date) {
    // Verificar si hora2Fin es para el día siguiente
    if (hora2Inicio > hora2Fin) {
        // Si hora1Inicio es posterior a hora2Fin o igual a hora2Inicio, no hay intersección
        if (hora1Inicio >= hora2Fin || hora1Inicio <= hora2Inicio) {
            return false;
        }
        // Si no, hay intersección
        return true;
    }

    // Si hora2Fin no es para el día siguiente
    const interseccion = hora1Inicio <= hora2Fin && hora2Inicio <= hora1Inicio;
    return interseccion;
}

const validateIsSameHourAndMaxHour = (hourProp: string, maxHourProp: string): string | null => {
    let hour = createDateFroHour(hourProp);
    let maxHour = createDateFroHour(maxHourProp);

    if (hour.getHours() === maxHour.getHours() && hour.getMinutes() === maxHour.getMinutes()) {
        return i18n.t(TranslationKeys.SAME_TIMES);
    }

    return null;
};

const createDateFroHour = (hour: string, addOne?: boolean): Date =>
    addOne ? new Date(`2023-01-02:${hour}`) : new Date(`2023-01-01:${hour}`);
