import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { Collapsable } from "app/components_v2/Collapsable/Collapsable";
import { GroupedTaskField } from "./GroupedTaskField/GroupedTaskField";

type TaskFieldsProps = {
    fields: TaskInstanceFieldModel[];
    customSetFields: (e: TaskInstanceFieldModel) => void;
    readOnly?: boolean;
    customErrors?: Map<number | string, string> | undefined;
    errorRef?: (ref: HTMLDivElement | null) => void;
    disabled?: boolean;
    statusCode: string;
    taskinstanceId: number;
};

export const TaskFields: FC<TaskFieldsProps> = ({
    fields,
    customSetFields,
    readOnly = false,
    customErrors,
    errorRef,
    disabled,
    statusCode,
    taskinstanceId,
}) => {
    const { t } = useTranslation();
    const title = t(TranslationKeys.TASK_DETAIL_DATA_TO_REPORT_TITLE);

    const groupByAssetId = () => {
        const groupedObjects: Record<string, TaskInstanceFieldModel[]> = {};

        // Group by Asset
        fields.forEach((field) => {
            const id = field.asset2?.id;
            if (id == null) return;
            if (!groupedObjects[id]) groupedObjects[id] = [];
            groupedObjects[id].push(field);
        });

        // Map to array and sort each Asset
        const fieldsMapped = Object.values(groupedObjects)
            .map((values) =>
                values.sort((field1, field2) => {
                    // Order by NUMBER first, then TEXT
                    if (field1.dynamicFieldType === "NUMBER" && field2.dynamicFieldType === "TEXT") return -1;
                    if (field1.dynamicFieldType === "TEXT" && field2.dynamicFieldType === "NUMBER") return 1;

                    // Order first by inserted order
                    const orderComparison = (field1.order ?? 0) - (field2.order ?? 0);
                    if (orderComparison !== 0) return orderComparison;

                    return 0;
                })
            )
            // Sort groups by the lowest order value in each group
            .sort((groupA, groupB) => {
                const minOrderA = groupA[0]?.order ?? 0;
                const minOrderB = groupB[0]?.order ?? 0;
                return minOrderA - minOrderB;
            });

        return fieldsMapped;
    };

    return (
        <div className="taskFields__container">
            <Collapsable title={title}>
                <div className="taskFields__content">
                    {groupByAssetId().map((groupedFields, i) => (
                        <GroupedTaskField
                            key={i}
                            groupedFields={groupedFields}
                            customSetFields={customSetFields}
                            readOnly={readOnly}
                            customErrors={customErrors}
                            disabled={disabled}
                            errorRef={errorRef}
                            statusCode={statusCode}
                            taskinstanceId={taskinstanceId}
                        />
                    ))}
                </div>
            </Collapsable>
        </div>
    );
};
