import { NotificationDistributionListGridSF } from "../models/NotificationDistributionListGridModels";

export const fillExtraParams = ({ functionality }: NotificationDistributionListGridSF, companyId?: string) => {
    const extraParams = new URLSearchParams();

    if (!!companyId && companyId !== "-1") extraParams.append("fK_Company", companyId);
    if (functionality) extraParams.append("functionality", functionality);

    return extraParams.toString();
};
