import { PaginatedList } from "app/models/ServiceResponse/PaginatedResult";
import { PaginatedResult } from "app/models/ServiceResponse/PaginatedResult";
import { TaskInstanceModel } from "../../models/02-TAR/TaskInstance/TaskInstanceModel";
import { ApiPaths } from "../../shared/Constants";
import FetchService from "../Fetch/FetchService";
import { ReviewTaskTabs } from "app/pages/02-TAR/02-TAR-ReviewFotos/models";
import { PaginationParams } from "app/models/02-TAR/PaginationParamsModel";
import { ReviewPhotosCountersDto } from "app/dtos/02-TAR/TaskInstance/ReviewPhotosCountersDto";
import { ReviewFeedbackCountersDto } from "app/dtos/02-TAR/TaskInstance/ReviewFeedbackCountersDto";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

export default class TaskInstanceReviewService {
    // GET task instances to review.
    public static async GetReviewTaskInstances(params?: PaginationParams) {
        return FetchService.get<PaginatedList<TaskInstanceListDto, ReviewTaskTabs>>({
            url: `${ApiPaths.TAR_API}/TaskInstanceReview`,
            paginationParams: params,
        });
    }

    // GET counters of task instances to review.
    public static async GetReviewCounters(params?: PaginationParams) {
        return FetchService.get<ReviewPhotosCountersDto>({
            url: `${ApiPaths.TAR_API}/TaskInstanceReview/Counters`,
            paginationParams: params,
        });
    }

    // GET list of task instance with/without feedback.
    public static async GetFeedbackTaskInstances(params?: PaginationParams) {
        return FetchService.get<PaginatedResult<TaskInstanceModel>>({
            url: `${ApiPaths.TAR_API}/TaskInstanceReview/Feedback`,
            paginationParams: params,
        });
    }

    // GET counters of task instance list with and without feedback.
    public static async GetFeedbackCounters(params?: PaginationParams) {
        return FetchService.get<ReviewFeedbackCountersDto>({
            url: `${ApiPaths.TAR_API}/TaskInstanceReview/Feedback/Counters`,
            paginationParams: params,
        });
    }
}
