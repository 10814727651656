import { DistributionListFilterType } from "../../models/NotificationDistributionListGridModels";
import {
    NotificationDistributionListErrors,
    NotificationDistributionListFormValues,
} from "../../models/NotificationDistributionListFormValues";
import {
    NotificationDistributionListTranslations,
    TranslationCommon,
    TranslationErrors,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { FC, FormEvent, useEffect, useState, KeyboardEvent } from "react";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { GenericModalFooter } from "app/components_v2/__modals/base/GenericModal/GenericModalFooter/GenericModalFooter";
import { getInitials } from "app/helpers/getInitials";
import { hasPermissionToEdit } from "app/routes/HelperRoleBasedAccess";
import {
    INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS,
    INITIAL_NOTIFICATION_DISTRIBUTION_LIST_FORM_VALUES,
} from "../../constants/NotificationDistributionListConstants";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { Input, Label, OptionsOutsideSelect, SelectOptions, Switch } from "app/components_v2/__inputs";
import { SecScreen } from "app/shared/Constants";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSession } from "app/hooks";
import { useTranslation } from "react-i18next";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import Spinner from "app/components_v2/Spinner/Spinner";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { AddNewButton } from "app/components_v2/__buttons/AddNewButton/AddNewButton";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { Tag } from "app/components_v2/Tag/Tag";
import { ErrorMessage } from "app/components_v2/ErrorMessage/ErrorMessage";
import { validateEmail } from "app/helpers/__validates/validateCompanyForm";

type CreateNotificationDistributionListUsersModalProps = {
    title: string;
    onClose: () => void;
    onSubmit: (values: NotificationDistributionListFormValues) => Promise<void>;
};

type LabelAddAllProps = {
    label: string;
    onClick: () => void;
};

export const CreateNotificationDistributionListUsersModal: FC<CreateNotificationDistributionListUsersModalProps> = ({
    title,
    onClose,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const session = useSession();
    const { handleErrorManager } = useErrorManager();
    const { getErrorMessage } = useFetchErrors();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [usersOptions, setUsersOptions] = useState<OptionModel[]>([]);
    const [distributionListTypes, setDistributionListTypes] = useState<OptionModel[]>([]);
    const [companyOptions, setCompanyOptions] = useState<OptionModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [addAllUsersModalOpen, setAddAllUsersModalOpen] = useState<boolean>(false);
    const [addAllCompaniesModalOpen, setAddAllCompaniesModalOpen] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [formValues, setFormValues] = useState<NotificationDistributionListFormValues>(
        INITIAL_NOTIFICATION_DISTRIBUTION_LIST_FORM_VALUES
    );
    const [notificationDistributionListErrors, setNotificationDistributionListErrors] =
        useState<NotificationDistributionListErrors>(INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS);

    const { errorFunctionality, errorCompany, errorUserList, errorEmails, errorNotSelected } =
        notificationDistributionListErrors;
    const { companyList, fK_DistributionList, userList, emails, notifyUsers, notifyEmails } = formValues;

    const handleInputChange = (values: Partial<NotificationDistributionListFormValues>) => {
        setNotificationDistributionListErrors(INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS);
        setFormValues((prev) => ({ ...prev, ...values }));
    };

    const handleErrorChange = (values: Partial<NotificationDistributionListErrors>) => {
        setNotificationDistributionListErrors((prev) => ({ ...prev, ...values }));
    };

    const distributionListFilter: Record<number, DistributionListFilterType> = {
        1: "SporadicTasksPermission",
        2: "AuditReportPermission",
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validate()) return;
        setIsLoadingSave(true);
        await onSubmit({
            ...formValues,
            userList: notifyUsers ? userList : [],
            emails: notifyEmails ? emails : [],
        });
        setIsLoadingSave(false);
    };

    const validate = () => {
        let isValid = true;
        setNotificationDistributionListErrors(INITIAL_NOTIFICATION_DISTRIBUTION_LIST_ERRORS);

        if (!fK_DistributionList.length) {
            handleErrorChange({ errorFunctionality: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            isValid = false;
        }

        if (!companyList.length && fK_DistributionList !== "3") {
            handleErrorChange({ errorCompany: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            isValid = false;
        }

        if (!notifyUsers && !notifyEmails) {
            handleErrorChange({
                errorNotSelected: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION),
            });
            isValid = false;
        }
        if (notifyUsers && !userList.length) {
            handleErrorChange({ errorUserList: t(TranslationErrors.SELECT_AT_LEAST_ONE_OPTION) });
            isValid = false;
        }
        if (notifyEmails && !emails.length) {
            handleErrorChange({ errorEmails: t(TranslationErrors.ADD_AT_LEAST_ONE) });
            isValid = false;
        }

        return isValid;
    };

    const getDistributionListTypes = async () => {
        const { data, status, getParsedError } = await SegSelectorService.GetDistributionListTypes();

        const distributionErrorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), distributionErrorMessage)) return;

        if (!data.length) {
            setDistributionListTypes([]);
            return;
        }
        setDistributionListTypes(data.map((option) => ({ ...option, label: t(option.label) })));
    };

    const getUsers = async (companiesId: string, distributionListId: number) => {
        const supervisorParams = new URLSearchParams();
        if (session?.user.id) supervisorParams.append("userId", session?.user.id.toString());
        supervisorParams.append("includeMyself", "true");

        if (!!companiesId.length) supervisorParams.append("CompaniesId", companiesId);

        if (distributionListId == 1 || distributionListId == 2)
            supervisorParams.append(distributionListFilter[distributionListId], "true");

        const supervisorAndAnalistUsersPromise = SegSelectorService.GetDistributionListUsers({
            extraParams: supervisorParams.toString(),
        });

        return supervisorAndAnalistUsersPromise;
    };

    const handleChangeDistributionList = async ({ value }: OptionModel) => {
        setIsLoading(true);
        handleInputChange({ fK_DistributionList: value });

        if (value === "3") {
            await handleChangeCompany([], Number(value));
            setIsLoading(false);
            return;
        }

        const companyOptionsPromise = SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `supervisorId=${session?.user.id}`,
        });

        const { data, status, getParsedError } = await companyOptionsPromise;
        const companyErrorMessage = getErrorMessage(getParsedError());
        setFormValues((prev) => ({ ...prev, userList: [], companyList: [] }));
        if (handleErrorManager(status(), companyErrorMessage)) return;

        setCompanyOptions(
            data.map((data) => ({
                ...data,
                initials: getInitials(data.label),
            }))
        );
        setIsLoading(false);
    };

    const handleChangeCompany = async (values: OptionModel[], distributionListId: number) => {
        const companiesId = values.map(({ value }) => value).join(";");
        handleInputChange({ companyList: values });
        handleGetUsers(companiesId, distributionListId);
    };

    const handleGetUsers = async (companiesId: string, distributionListId: number) => {
        const { data, status } = await getUsers(companiesId, distributionListId);
        if (!status()) {
            setUsersOptions([]);
            setIsLoading(false);
            return;
        }

        handleInputChange({ userList: userList.filter(({ value }) => data.some((x) => x.value === value)) });
        setUsersOptions(data.map((data) => ({ ...data, initials: getInitials(data.label) })));
    };

    const handleDeleteComapny = async (id: string) => {
        const selectedCompany = companyList.find(({ value }) => value === id);

        if (!selectedCompany) return;

        if (companyList.length === 1) {
            handleInputChange({ companyList: [], userList: [] });
            return;
        }

        await handleChangeCompany(
            companyList.filter(({ value }) => value !== id),
            Number(fK_DistributionList)
        );
    };

    const handleDelteUser = (id: string) => {
        const { userList } = formValues;
        const selectedItem = userList.find(({ value }) => value === id);
        if (!selectedItem) return;

        handleInputChange({ userList: userList.filter(({ value }) => value !== id) });
    };

    const onConfirmAddAllUsers = () => {
        handleInputChange({
            userList: [...userList, ...usersOptions.filter((uo) => !userList.some((ul) => uo.value === ul.value))],
        });
        setAddAllUsersModalOpen(false);
    };

    const onConfirmAddAllCompanies = () => {
        handleInputChange({
            companyList: [
                ...companyList,
                ...companyOptions.filter((co) => !companyList.some((cl) => co.value === cl.value)),
            ],
        });
        setAddAllCompaniesModalOpen(false);
        const companiesId = companyOptions.map(({ value }) => value).join(";");
        handleGetUsers(companiesId, Number(fK_DistributionList));
    };

    const handleAddEmail = (e?: KeyboardEvent<HTMLInputElement>) => {
        e?.stopPropagation();
        e?.preventDefault();
        if (!validateEmail(email)) {
            handleErrorChange({ errorEmails: t(TranslationErrors.EMAIL_IS_INVALID) });
            return;
        }
        handleInputChange({ emails: [...emails, email] });
        setEmail("");
    };

    const handleDeleteEmail = (index: number) => handleInputChange({ emails: emails.filter((_, i) => i !== index) });

    useEffect(() => {
        getDistributionListTypes();
    }, []);

    const LabelAddAll: FC<LabelAddAllProps> = ({ label, onClick }) => (
        <div className="createNotificationDistributionListUsersModal__addAll">
            <Label label={label} />
            <div className="createNotificationDistributionListUsersModal__addAll__button">
                <AddNewButton onClick={onClick} icon={faPlus}>
                    {t(TranslationCommon.ADD_ALL)}
                </AddNewButton>
            </div>
        </div>
    );

    return (
        <GenericModal
            header={{ title, onClose }}
            size="md"
            footer={
                hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST) && (
                    <GenericModalFooter
                        confirmButton={{
                            text: t(TranslationCommon.SAVE),
                            form: "notificationDistributionListUsers",
                            type: "submit",
                            disabled: !distributionListTypes.length,
                        }}
                        closeButton={{
                            text: t(TranslationCommon.CANCEL),
                            buttonType: "tertiary",
                            onClick: onClose,
                        }}
                        loading={isLoadingSave}
                    />
                )
            }
        >
            <>
                {addAllUsersModalOpen && (
                    <ConfirmModal
                        onClose={() => setAddAllUsersModalOpen(false)}
                        onConfirm={onConfirmAddAllUsers}
                        onConfirmText={t(TranslationCommon.ACCEPT)}
                        onCloseText={t(TranslationCommon.CANCEL)}
                        title={t(TranslationModals.NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_USERS_TITLE)}
                        description={t(TranslationModals.NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_USERS_DESCRIPTION)}
                    />
                )}
                {addAllCompaniesModalOpen && (
                    <ConfirmModal
                        onClose={() => setAddAllCompaniesModalOpen(false)}
                        onConfirm={onConfirmAddAllCompanies}
                        onConfirmText={t(TranslationCommon.ACCEPT)}
                        onCloseText={t(TranslationCommon.CANCEL)}
                        title={translateCustomerTypeKeys(
                            TranslationModals.NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_COMPANIES_TITLE,
                            { firstReplace: "PLURAL", isGenderSensitive: true }
                        )}
                        description={translateCustomerTypeKeys(
                            TranslationModals.NOTIFICATION_DISTRIBUTION_LIST_ADD_ALL_COMPANIES_DESCRIPTION,
                            { firstReplace: "PLURAL", isGenderSensitive: true }
                        )}
                    />
                )}
                <form onSubmit={handleSubmit} className="addUsersModal" id="notificationDistributionListUsers">
                    <div className="notificationDistributionList__modal">
                        <SelectOptions
                            isMulti={false}
                            onChange={handleChangeDistributionList}
                            options={distributionListTypes}
                            label={t(
                                NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_TYPE_LABEL
                            )}
                            selectedValue={fK_DistributionList}
                            placeholder={t(TranslationCommon.SELECT_OPTION)}
                            isLoading={isLoading}
                            errorMessage={errorFunctionality}
                        />
                        {!!fK_DistributionList.length &&
                            fK_DistributionList !== "3" &&
                            (isLoading ? (
                                <div className="notificationDistributionList__modal__spinner">
                                    <Spinner />
                                </div>
                            ) : (
                                <div className="createNotificationDistributionListUsersModal__label">
                                    <LabelAddAll
                                        label={translateCustomerTypeKeys(
                                            NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_COMPANY_LABEL,
                                            { firstReplace: "SINGULAR" }
                                        )}
                                        onClick={() => setAddAllCompaniesModalOpen(true)}
                                    />
                                    <OptionsOutsideSelect
                                        onChange={(values) => handleChangeCompany(values, Number(fK_DistributionList))}
                                        options={companyOptions}
                                        values={companyList}
                                        placeholder={t(TranslationCommon.SELECT_OPTION)}
                                        isLoading={isLoading}
                                        errorMessage={errorCompany}
                                        onDeleteItem={handleDeleteComapny}
                                    />
                                </div>
                            ))}

                        {(!!formValues.companyList.length || fK_DistributionList === "3") && (
                            <div className="createNotificationDistributionListUsersModal__inputs">
                                <div className="createNotificationDistributionListUsersModal__inputs__content">
                                    <Switch
                                        label={t(
                                            NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_USERS
                                        )}
                                        checked={notifyUsers}
                                        onChange={(value) => handleInputChange({ notifyUsers: value })}
                                    />
                                    {notifyUsers && (
                                        <div className="createNotificationDistributionListUsersModal__label">
                                            <LabelAddAll
                                                label={t(TranslationKeys.USERS)}
                                                onClick={() => setAddAllUsersModalOpen(true)}
                                            />
                                            <OptionsOutsideSelect
                                                onChange={(values) => handleInputChange({ userList: values })}
                                                options={usersOptions}
                                                onDeleteItem={handleDelteUser}
                                                values={userList}
                                                hidden={!hasPermissionToEdit(SecScreen.DISTRIBUTION_LIST)}
                                                placeholder={t(TranslationKeys.SELECT_USERS)}
                                                isLoading={isLoading}
                                                isMulti
                                                errorMessage={errorUserList}
                                                autoInitials
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="createNotificationDistributionListUsersModal__inputs__content">
                                    <Switch
                                        label={t(
                                            NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL
                                        )}
                                        checked={notifyEmails}
                                        onChange={(value) => handleInputChange({ notifyEmails: value })}
                                    />
                                    {notifyEmails && (
                                        <>
                                            {emails.map((email, i) => (
                                                <Tag
                                                    message={email}
                                                    onClose={() => handleDeleteEmail(i)}
                                                    variant="white"
                                                />
                                            ))}
                                            <Input
                                                label={t(
                                                    NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL_LABEL
                                                )}
                                                placeholder={t(
                                                    NotificationDistributionListTranslations.NOTIFICATION_DISTRIBUTION_LIST_NOTIFY_BY_EMAIL_PLACEHOLDER
                                                )}
                                                onChange={setEmail}
                                                errorMessage={errorEmails}
                                                onPressEnter={handleAddEmail}
                                                value={email}
                                            />
                                            <AddNewButton onClick={() => handleAddEmail()} icon={faPlus}>
                                                {t(TranslationCommon.ADD)}
                                            </AddNewButton>
                                        </>
                                    )}
                                </div>
                                <ErrorMessage errorMessage={errorNotSelected} />
                            </div>
                        )}
                    </div>
                </form>
            </>
        </GenericModal>
    );
};
