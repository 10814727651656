import { TaskStatus } from "app/shared/Constants";
import { TFunction } from "react-i18next";
import { getDayMonthFormatedUTC } from "../Date.utilities";
import { TranslationCommon } from "app/translation/translationKeys";
import { TaskInstanceListDto } from "app/dtos/02-TAR/TaskInstance/TaskInstanceListDto";

export const getStartDateText = (
    taskInstance: TaskInstanceListDto,
    lang: string,
    t: TFunction<"translation", undefined>
) => {
    if (taskInstance.taskTemporalityType !== "RANGE_DATES" || taskInstance.startDate == null) return "";
    if (taskInstance.statusCode === TaskStatus.PENDING)
        return `${t(TranslationCommon.START_DATE_LABEL)}: ${getDayMonthFormatedUTC(
            new Date(taskInstance.startDate),
            lang
        )}`;

    return "";
};
