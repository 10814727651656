import { loggedOnceMapper } from "../helpers/loggedOnceMapper";
import { userProfilePictureMapper } from "../helpers/userProfilePictureMapper";
import { UserGridLoggedOnce, UserGridSecondaryFilterModel } from "../models/userGridSecondaryFilterModel";

type IextraparamsUserGrid = {
    customerInstanceId: number;
    companyId: string;
    currentTab: number;
    filterData: UserGridSecondaryFilterModel;
};

export const fillUserGridExtraParams = ({
    companyId,
    currentTab,
    customerInstanceId,
    filterData,
}: IextraparamsUserGrid): string => {
    const { loggedOnce, profilePicture } = filterData;
    const loggedOnceMapped = loggedOnceMapper(loggedOnce);
    const profilePictureMapped = userProfilePictureMapper(profilePicture);

    const params = new URLSearchParams();

    params.append("customerInstanceId", String(customerInstanceId));
    if (companyId !== "-1") params.append("companyId", companyId);
    if (currentTab === 2) params.append("AutomaticActivationDate", "true");
    else params.append("IsActive", `${currentTab === 0 ? "true" : "false"}`);
    if (loggedOnceMapped != null) params.append("loggedOnce", String(loggedOnceMapped));
    if (profilePictureMapped != null) params.append("hasProfilePicture", String(profilePictureMapped));

    return params.toString();
};
