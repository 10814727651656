import { PrivatePaths } from "app/shared/Constants";
import { useLocation } from "react-router-dom";

export const useShowHeader = () => {
    const location = useLocation();
    const urlsToShowHeader: string[] = [
        PrivatePaths.CENTERS_MANAGEMENT,
        PrivatePaths.ROLE_MANAGEMENT,
        PrivatePaths.TEMPORARY_ROLE_PAGE,
        PrivatePaths.USER_MANAGEMENT,
        PrivatePaths.TASK_PAGE,
        PrivatePaths.QR,
        PrivatePaths.ISSUE,
        PrivatePaths.BASE_TASK_MANAGEMENT,
        PrivatePaths.BASE_TASK_APPCC_MANAGEMENT,
        PrivatePaths.PLAN_APPCC,
        PrivatePaths.BASE_AUDIT_GROUP_CHECKLIST_ITEM,
        PrivatePaths.AUTOMATIC_RECORDS,
        PrivatePaths.DISTRIBUTION_LIST,
        PrivatePaths.LICENSE_PAGE,
        PrivatePaths.PATCH_NOTES,
    ];
    const urlsToHideHeader: string[] = [
        `${PrivatePaths.CENTERS_MANAGEMENT}/edit`,
        `${PrivatePaths.USER_MANAGEMENT}/edit`,
        `${PrivatePaths.ROLE_MANAGEMENT}/edit`,
        `${PrivatePaths.TEMPORARY_ROLE_PAGE}/edit`,
        `${PrivatePaths.TASK_PAGE}/edit`,
        `${PrivatePaths.TASK_PAGE}/duplicate`,
        `${PrivatePaths.QR}/edit`,
        `${PrivatePaths.BASE_TASK_MANAGEMENT}/edit`,
        `${PrivatePaths.BASE_TASK_APPCC_MANAGEMENT}/edit`,
        `${PrivatePaths.BASE_TASK_MANAGEMENT}/duplicate`,
        `${PrivatePaths.BASE_TASK_APPCC_MANAGEMENT}/duplicate`,
        `${PrivatePaths.ISSUE}/edit`,
        `${PrivatePaths.ISSUE}/edit`,
        `${PrivatePaths.ON_BOARDING}`,
        `${PrivatePaths.DISTRIBUTION_LIST}/edit`,
    ];
    const urlsToHideHeaderWithOutParam: string[] = [
        `${PrivatePaths.USER_MANAGEMENT}/new`,
        `${PrivatePaths.TASK_PAGE}/fastnew`,
        `${PrivatePaths.QR}/new`,
        `${PrivatePaths.BASE_TASK_MANAGEMENT}/new`,
        `${PrivatePaths.BASE_TASK_APPCC_MANAGEMENT}/new`,
        `${PrivatePaths.QR}/print`,
    ];

    // "audit-history/id/completed",
    const urlsToHideHeaderWith: string[] = [];

    const isUrlWithParamInMiddleValid = (url: string, currentUrl: string): boolean => {
        const urlSplitted = url.split("/").filter((x) => x.length);

        const currentUrlSplitted = currentUrl.split("/");

        return currentUrlSplitted.every((_, i) => {
            // Misma url pero que no sean numéricos ej:
            // audit-history contra audit-hsitory
            if (urlSplitted[i] === currentUrlSplitted[i] && isNaN(Number(urlSplitted[i]))) return true;

            // Diferente url pero la del location es un número, es decir es la ID
            if (urlSplitted[i] !== currentUrlSplitted[i] && !isNaN(Number(urlSplitted[i]))) return true;

            return false;
        });
    };

    const hideHeaderWithParams = (pathname: string, urlsToHideHeaderWith: string[]) => {
        return urlsToHideHeaderWith.some((url) => isUrlWithParamInMiddleValid(pathname, url) && url.length >= 3);
    };

    const showHeader = () => {
        const url = location.pathname
            .toLocaleLowerCase()
            .split("/")
            .filter((x) => !!x.length);
        const currentUrlWithOutParam = url.slice(0, url.length - 1).join("/");
        const currentUrlWithOutParamDeleted = url.join("/");

        if (hideHeaderWithParams(location.pathname, urlsToHideHeaderWith)) return false;

        if (urlsToHideHeaderWithOutParam.includes(currentUrlWithOutParamDeleted)) return false;

        if (urlsToShowHeader.includes(url[0]) && !urlsToHideHeader.includes(currentUrlWithOutParam)) return true;

        return false;
    };

    return showHeader;
};
