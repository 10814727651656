import { ReviewTaskTabs } from "../models";
import { getDateFormattedSelects } from "app/helpers/Date.utilities";
import { ReviewTaskFilterData } from "app/components_v2/__filters/ReviewTaskFilter/types";

export const fillExtraParams = (
    companyId: number,
    actualTab: ReviewTaskTabs,
    {
        fiveStar,
        fourStar,
        threeStar,
        twoStar,
        oneStar,
        hasComments,
        isPhotoRequired,
        dataToReportReview,
        taskId,
    }: ReviewTaskFilterData,
    startDate?: Date,
    endDate?: Date,
    supervisorId?: number,
    initialRender?: boolean
): string => {
    let params = `CompanyId=${companyId}&`;
    params += `TaskReviewStatusCode=${actualTab}&`;
    params += `AsignedToUserId=-1&`;
    params += `AsignedToDepartmentId=-1&`;
    params += `AsignedToSubdepartmentId=-1&`;
    params += `OrderByBlueStar=true&`;

    if (startDate) params += `deadlineDate=${getDateFormattedSelects(startDate)}&`;
    if (endDate) params += `deadlineDate1=${getDateFormattedSelects(endDate)}&`;
    if (supervisorId) params += `supervisorId=${supervisorId}&`;
    if (oneStar) params += `oneStar=${oneStar}&`;
    if (twoStar) params += `twoStar=${twoStar}&`;
    if (threeStar) params += `threeStar=${threeStar}&`;
    if (fourStar) params += `fourStar=${fourStar}&`;
    if (fiveStar) params += `fiveStar=${fiveStar}&`;
    if (initialRender) params += `initialRender=${initialRender}&`;
    if (hasComments) params += `hasComments=true&`;
    if (isPhotoRequired) params += `isPhotoRequired=true&`;
    if (dataToReportReview !== "ALL") params += `dataToReportReview=${dataToReportReview}&`;
    if (taskId !== "-1") params += `taskId=${taskId}&`;
    return params;
};
