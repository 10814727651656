import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { HeaderContext } from "../../../components_v2/Header/context/headerContext";
import { notificationVariantRecord } from "../constants/notificationConstants";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import { SignalRService } from "app/services";
import { SystemNotificationList } from "./SystemNotificationList";
import { SystemNotificationModel } from "app/models/02-TAR/SystemNotification/SystemNotificationModel";
import { useChangeWorkingSession } from "app/hooks/useChangeWorkingSession";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "app/hooks";
import SystemNotificationService from "app/services/02-TAR/SystemNotificationService";
import { PatchNotesModalContext } from "app/state/context/PatchNotesModalContext/PatchNotesModalContext";

export const Notification = () => {
    const { handleClearAllNotifications } = useContext(HeaderContext);
    const { changeWorkingCompanyByCompanyId } = useChangeWorkingSession();
    const nav = useNavigate();
    const session = useSession();
    const userId = session?.user.id;
    const signalR = SignalRService.getSignalR();
    const { openModal } = useContext(PatchNotesModalContext);

    const [systemNotifications, setSystemNotifications] = useState<SystemNotificationModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getSystemNotifications = async () => {
        const sr = await SystemNotificationService.GetSystemNotifications({
            extraParams: `ReceiverUserId=${userId}`,
            pageSize: 20,
            pageIndex: 0,
        });
        if (sr.status()) setSystemNotifications(sr.data.list);
        setIsLoading(false);
    };

    const handleSeenNotification = async (notification: SystemNotificationModel) => {
        const { id, redirectURL } = notification;
        if (!id) return;

        const { status, data } = await SystemNotificationService.Edit(id);
        if (!status()) return;

        const allowRedirect = handleClickNotification(notification);

        setSystemNotifications(data.list);
        signalR.SignalRConnection.send("HandleNotificationMobile", signalR.SignalRConnection.connectionId);

        if (!allowRedirect) return;
        if (redirectURL) nav(`${redirectURL}`);
    };

    const handleMarkAllSeen = async () => {
        const sr = await SystemNotificationService.MarkAllAsSeen();
        if (!sr.status()) return;

        getSystemNotifications();
        handleClearAllNotifications();
    };

    const handleClickNotification = (systemNotification: SystemNotificationModel) => {
        const { variant } = systemNotification;
        if (!variant) return true;

        const notificationVariant = notificationVariantRecord[variant] || "primary";

        switch (notificationVariant) {
            case "sporadic-task": {
                changeCompanyNotificationsTypeSporadic(systemNotification);
                return true;
            }
            case "issue-task": {
                redirectNotificationsTypeTask(systemNotification);
                return false;
            }
            case "issue-task-temp": {
                redirectNotificationsTypeTask(systemNotification);
                return false;
            }
            case "patch-notes": {
                openPatchNotesModal(systemNotification);
                return false;
            }

            default:
                return true;
        }
    };

    const changeCompanyNotificationsTypeSporadic = ({ redirectURL }: SystemNotificationModel) => {
        const redirectUrlParams = redirectURL.split("?")[1];
        if (!redirectUrlParams) return;

        const urlParams = new URLSearchParams(redirectUrlParams);
        const companyId = urlParams.get("CompanyId");
        if (!companyId) return;

        changeWorkingCompanyByCompanyId(Number(companyId));
    };

    const redirectNotificationsTypeTask = ({ redirectURL }: SystemNotificationModel) => {
        const [pathname, redirectUrlParams] = redirectURL.split("?");
        const issueId = pathname.match(/\d+$/);

        if (hasPermissionToView(session, SecScreen.ISSUE) && issueId != null)
            return nav(`/${PrivatePaths.ISSUE}/edit/${issueId[0]}`);

        const urlParams = new URLSearchParams(redirectUrlParams);
        const taskInstanceId = urlParams.get("taskInstanceId");
        const fK_TaskInstance = urlParams.get("FK_TaskInstance");

        // Handle Filter IssueGrid
        if (!!fK_TaskInstance) return nav(redirectURL);
        else if (!taskInstanceId) return nav(`/${PrivatePaths.DASHBOARD}`);

        if (hasPermissionToView(session, SecScreen.TASK_REVIEW))
            return nav(`/${PrivatePaths.TASK_PHOTO}/task/${taskInstanceId}`);

        nav(`/${PrivatePaths.DASHBOARD}/${taskInstanceId}`);
    };

    const openPatchNotesModal = (systemNotification: SystemNotificationModel) => {
        const redirectUrlParams = systemNotification.redirectURL.split("?")[1];
        if (!redirectUrlParams) return;

        const urlParams = new URLSearchParams(redirectUrlParams);
        const patchNoteVersionId = urlParams.get("PatchNoteVersionId");
        openModal(Number(patchNoteVersionId), { readonly: true });
    };

    useEffect(() => {
        getSystemNotifications();
    }, []);

    return (
        <SystemNotificationList
            list={systemNotifications}
            isLoading={isLoading}
            handleMarkAllSeen={handleMarkAllSeen}
            handleSeenNotification={handleSeenNotification}
        />
    );
};
