import { v4 } from "uuid";
import { BaseTaskFormModel } from "../EditBaseTask/models/EditBaseTaskFormModels";
import { BaseTaskCheckList } from "app/models/02-TAR/BaseTask/BaseTaskCheckListModel";
import { BaseTaskFieldOptionModel } from "app/models/02-TAR/BaseTask/BaseTaskFieldOptionModel";
import {
    CheckListModel,
    DynamicFieldsModel,
    DynamicFieldsType,
    DynamicTaskFieldOption,
} from "app/models/02-TAR/TaskWizard";
import { BaseTaskModel } from "app/models/02-TAR/BaseTask/BaseTaskModel";
import { BaseTaskFieldModel } from "app/models/02-TAR/BaseTask/BaseTaskFieldModel";
import { convertUTCtoLocaleDate, getHoursFromDateUTC } from "app/helpers";
import {
    selectRecurringTaskCustomValue,
    selectRecurringTaskValue,
    setDays,
} from "../../07-TAR-CRUD/EditTask/utilities";

export const mapBaseTaskToDataTaskModel = ({
    counter,
    name,
    description,
    fotoExample,
    imageBase64Content,
    isPhotoRequired,
    isCritical,
    baseTaskCheckList,
    baseTaskFields,
    allowAnyData,
    reportType,
    baseTaskRangeHours,
    baseTaskScheduler,
    baseTaskTemporalityDateRange,
    taskType,
    startDate,
    neverEnds,
    numRecurrencies,
    endDate,
    taskTemporalityType,
    fotoExampleId,
    minPhotosAllowed,
}: BaseTaskModel): BaseTaskFormModel => {
    const isRecurrent = baseTaskScheduler?.typeCode !== "ONE_TIME";

    return {
        counter,
        taskTitle: name || "",
        taskDesc: description || "",
        belongsToAPPCC: false,
        fK_PlanAPPCC: "",
        DepartmentForTask: { label: "", value: "" },
        SubDepartementForTask: { label: "", value: "" },
        userForTask: { label: "", value: "" },
        criticalTask: isCritical,
        somethingToReportImage: fotoExample || "",
        imageBase64: imageBase64Content,
        isPhotoRequired,
        minPhotosAllowed: minPhotosAllowed || (isPhotoRequired ? 1 : null),
        checkList: mapBaseTaskCheckListToCheckListModel(baseTaskCheckList),
        dynamicFields: mapBaseTaskFieldsToDynamicFields(baseTaskFields),
        reportData: [],
        reportType: reportType || "none",
        somethingToReportCheckBox: false,
        allowAnyData,

        // BaseTask Temporality
        temporalityType: taskTemporalityType,
        taskStart: !!startDate?.length ? startDate.toString() : new Date().toString(),
        taskHours: baseTaskRangeHours
            ? baseTaskRangeHours.map(({ hour, maxHour }) => {
                  const startHourFormated = getHoursFromDateUTC(convertUTCtoLocaleDate(new Date(hour)));
                  const endHourFormated = maxHour
                      ? getHoursFromDateUTC(convertUTCtoLocaleDate(new Date(maxHour)))
                      : undefined;
                  return {
                      id: v4(),
                      hour: startHourFormated,
                      maxHour: endHourFormated,
                      type: maxHour ? "range" : "hour",
                  };
              })
            : [],
        recurringTask: {
            isRecurrent,
            options: [],
            value:
                baseTaskScheduler?.calendarDayNumber !== 0
                    ? "custom"
                    : baseTaskScheduler?.repeatEvery !== 1
                    ? "custom"
                    : selectRecurringTaskValue(baseTaskScheduler?.typeCode || "ONE_TIME"),
            custom: {
                customValue:
                    baseTaskScheduler?.calendarDayNumber !== 0
                        ? "everyMonth"
                        : selectRecurringTaskCustomValue(baseTaskScheduler?.typeCode || "ONE_TIME"),
                days: setDays(baseTaskScheduler),
                repeatEvery: baseTaskScheduler?.repeatEvery || 1,
                selectedOptions: {
                    text: "",
                    value:
                        baseTaskScheduler?.calendarDayNumber !== 0
                            ? "everyMonthDayOfMonth"
                            : baseTaskScheduler?.calendarWeekNumber !== 0
                            ? "everyMonthFirstDayOfWeek"
                            : "",
                },
            },
            text: baseTaskScheduler?.name || "",
        },
        finish: {
            checked: neverEnds ? "never" : endDate ? "el" : numRecurrencies ? "after" : "never",
            value: neverEnds ? null : numRecurrencies ? numRecurrencies : endDate ? endDate : null,
        },
        taskType,
        fotoExampleId,
    };
};

const mapBaseTaskCheckListToCheckListModel = (checklist?: BaseTaskCheckList[]): CheckListModel[] =>
    checklist?.map(({ id, isDeleted, name }) => ({
        id: v4(),
        isDeleted,
        name,
        dbId: id,
        isEnabled: true,
        fK_BaseTaskCheckList: id,
    })) || [];

export const mapCheckListToBaseCheckList = (
    checkList: CheckListModel[],
    isDuplicate: boolean | undefined
): BaseTaskCheckList[] =>
    checkList.map<BaseTaskCheckList>(({ id, isDeleted, name, dbId }) => ({
        baseTaskId: 0,
        id: isDuplicate ? 0 : id ? dbId || 0 : 0,
        name: name,
        isDeleted,
    }));

export const mapBaseTaskFieldsToDynamicFields = (baseTaskFields?: BaseTaskFieldModel[]): DynamicFieldsModel[] =>
    baseTaskFields?.map<DynamicFieldsModel>(
        ({ id, label, dynamicFieldType, isDeleted, isRequired, baseTaskFieldOptions }, i) => ({
            id: v4(),
            dbId: id,
            label,
            dynamicFieldsType: dynamicFieldType as DynamicFieldsType,
            isDeleted,
            isRequired,
            taskFieldOptions:
                baseTaskFieldOptions?.map<DynamicTaskFieldOption>(({ label, id }) => ({
                    id: v4(),
                    dbId: id,
                    label,
                })) || [],
            order: i,
        })
    ) || [];

type MapDynamicFieldsBaseTask = {
    dynamicFields: DynamicFieldsModel[];
    baseTaskId?: string;
    isDuplicate?: boolean;
};

export const mapDynamicFieldsToBaseTaskFields = ({
    dynamicFields,
    baseTaskId,
    isDuplicate,
}: MapDynamicFieldsBaseTask): BaseTaskFieldModel[] =>
    dynamicFields.map<BaseTaskFieldModel>(
        ({ dynamicFieldsType, isRequired, label, taskFieldOptions, isDeleted, dbId }) => ({
            fK_BaseTask: baseTaskId ? Number(baseTaskId) : 0,
            id: !!isDuplicate ? 0 : dbId || 0,
            dynamicFieldType: dynamicFieldsType || "DATE",
            isDeleted,
            isRequired: !!isRequired,
            label: label || "",
            baseTaskFieldOptions:
                taskFieldOptions?.map<BaseTaskFieldOptionModel>(({ label }) => ({
                    label,
                    id: 0,
                    fK_BaseTaskField: 0,
                })) || [],
        })
    );
