import AddUsersModal from "app/components_v2/__modals/AddUsersModal/AddUsersModal";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { ColumnsType, SortedTypeModel } from "app/components_v2/Table/types";
import { faCirclePlus, faKeySkeleton, faMagnifyingGlass, faPen, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { FC, useEffect, useState } from "react";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { hasPermissionToAdd, hasPermissionToEdit, hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { IAppState } from "app/state/reducer";
import { IDownLoadCsvReturn } from "app/components_v2/__modals/ExportCsvModal/types";
import { Link, useNavigate } from "react-router-dom";
import { MobileRoleGridBody } from "./MobileRoleGridBody/MobileRoleGridBody";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { PaginationDefaults, PrivatePaths, SecScreen } from "app/shared/Constants";
import { RoleModel } from "app/models/01-SEG/Role/RoleModels";
import { RoleService } from "app/services";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableInputText } from "app/components_v2/Table/TableInputText/TableInputText";
import { useSession } from "app/hooks";
import { useTitle } from "app/hooks/CustomHooks";
import { useToast } from "app/hooks/Toast/useToast";
import {
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import RolesWizard from "app/components_v2/wizards/roles&permissions/rolesWizard";

type IAddRole = {
    usersId: number[];
    role: RoleModel;
};

const INITIAL_SORT_FIELD = "name";
const INITIAL_SORT_DIRECTION: SortedTypeModel = "asc";

const RoleGrid: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const instanceId = useSelector<IAppState, number | undefined>((state) => state.customerInstanceId);
    const { handleToast } = useToast();
    const session = useSession();
    const isOnBoarding = session?.isOnBoarding;

    const [data, setData] = useState<RoleModel[]>([]);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [query, setQuery] = useState<string>("");
    const [sortField, setSortField] = useState<string>(INITIAL_SORT_FIELD);
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>(INITIAL_SORT_DIRECTION);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedRole, setSelectedRole] = useState<RoleModel | null>(null);
    const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
    const [OpenRoles, setOpenRoles] = useState<boolean>(false);
    const [selectedUsersRole, setSelectedUsersRole] = useState<OptionModel[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    useTitle(t(TranslationTitles.ROLES_PAGE_TITLE));

    const columns: ColumnsType<RoleModel>[] = [
        {
            label: t(TranslationKeys.LABEL_ROLE),
            dataIndex: "name",
            render: (record) => <CellTitle title={record.name} />,
            sortedType: "default",
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "edit",
            render: (record) => (
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: () => getUsersRole(record),
                            hidden: !hasPermissionToEdit(SecScreen.ROLE_MANAGEMENT),
                            title: t(TranslationCommon.EDIT),
                        },
                        {
                            icon: faKeySkeleton,
                            onClick: () => navigate(`edit/${record.id}`),
                            title: t(TranslationCommon.EDIT),
                        },
                    ]}
                />
            ),
            className: "roleGrid__actions",
            alignCenter: true,
        },
    ];

    const getUsersRole = async (role: RoleModel) => {
        const { id: roleId } = role;
        const extraparams = `customerInstanceId=${instanceId || 0}&roleId=${roleId}`;

        const {
            data: roleData,
            error,
            status,
        } = await SegSelectorService.GetUserNamesWithIds({
            extraParams: extraparams,
        });

        if (!status()) {
            handleToast({ title: t(TranslationModals.FAILED_EDIT), variant: "danger" });
            console.error(error);
            setSelectedRole(null);
            return;
        }
        setSelectedUsersRole(roleData);
        setSelectedRole(role);
    };

    const getRoles = async (pi?: number, companyId?: string) => {
        setIsLoading(true);
        const pageIndexExtraparam = pi !== undefined ? pi : pageIndex - 1;

        const extraparams = `customerInstanceId=${instanceId || 0}&companyId=${
            companyId || session?.workingCompany?.companyId
        }`;

        const {
            data: roleData,
            error,
            status,
        } = await RoleService.GetRoles({
            pageSize: PaginationDefaults.PAGE_SIZE,
            pageIndex: pageIndexExtraparam,
            extraParams: extraparams,
            query: query,
            sortDirection: sortDirection === "default" ? INITIAL_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_SORT_FIELD : sortField,
        });

        if (!status()) {
            console.error(error);
            handleToast({ title: error, variant: "danger" });
            setIsLoading(false);
            return;
        }

        setData(roleData.list);
        setTotal(roleData.count);
        setIsLoading(false);
    };

    const onQuerySubmit = () => {
        if (!query.length) {
            setQuery("");
            setShowSearchInput(false);
        }
        getRoles(0);
        setPageIndex(1);
    };

    const handleExport = (params?: IDownLoadCsvReturn) => {
        const extraparams = `customerInstanceId=${instanceId || 0}`;

        return RoleService.GetRolesExport({
            extraParams: extraparams,
            query: query,
            sortDirection: sortDirection === "default" ? INITIAL_SORT_DIRECTION : sortDirection,
            sortField: sortDirection === "default" ? INITIAL_SORT_FIELD : sortField,
            ...params,
        });
    };

    const handleAddUsers = async ({ role, usersId }: IAddRole) => {
        setIsSaving(true);
        const newRole: RoleModel = { ...role, userIds: usersId };
        const { error, status } = await RoleService.Update(newRole);

        if (!status()) {
            handleToast({ title: error, variant: "danger" });
            setIsSaving(false);
            return;
        }

        handleToast({ title: t(TranslationKeys.ROLE_ADDED_SUCCESSFULLY), variant: "success" });
        getRoles();
        setSelectedRole(null);
        setIsSaving(false);
    };

    useEffect(() => {
        getRoles();
    }, [sortField, sortDirection]);

    return (
        <PageContainer paddingTop>
            {hasPermissionToView(session, SecScreen.TEMPORARY_ROLES) && !isOnBoarding && (
                <div className="roleGrid__temporary">
                    <Link to={`/${PrivatePaths.TEMPORARY_ROLE_PAGE}`} className="roleGrid__temporary__link">
                        {t(TranslationTitles.TEMPORARY_ROLES_TITLE)}
                    </Link>
                </div>
            )}
            <TableCollapsable
                cols={columns}
                data={data}
                placeholder={<TableError icon={faUsers} />}
                title={t(TranslationKeys.ROLES)}
                subTitle={`${total} ${total > 1 ? t(TranslationKeys.ROLES) : t(TranslationKeys.LABEL_ROLE)}`}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                onChangePageIndex={(pi) => {
                    setPageIndex(pi);
                    getRoles(pi - 1);
                }}
                sortField={sortField}
                sortDirection={sortDirection}
                onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                    setSortField(sortFieldParam);
                    if (sortedTypeParam) setSortDirection(sortedTypeParam);
                }}
                actionButtons={[
                    {
                        icon: faCirclePlus,
                        onClick: () => setOpenRoles(true),
                        hidden: !hasPermissionToAdd(SecScreen.ROLE_MANAGEMENT),
                        title: t(TranslationCommon.CREATE),
                    },
                    {
                        icon: faMagnifyingGlass,
                        onClick: () => {
                            setShowSearchInput(true);
                        },
                        inputComponent: {
                            component: (
                                <TableInputText
                                    onChange={setQuery}
                                    value={query}
                                    fetch={onQuerySubmit}
                                    cleanFunction={onQuerySubmit}
                                />
                            ),
                            show: showSearchInput,
                        },
                        title: t(TranslationCommon.SEARCH),
                    },
                ]}
                onClickExportCsv={handleExport}
                onDoubleClick={(record) => navigate(`edit/${record.id}`)}
                mobileBody={(row, _i, rowPosition) => (
                    <MobileRoleGridBody
                        key={row.id}
                        role={row}
                        onEditClick={() => getUsersRole(row)}
                        rowPosition={rowPosition}
                    />
                )}
            />
            {OpenRoles && (
                <GenericModal
                    header={{
                        title: t(TranslationTitles.ROLES_MAINTENANCE_TITLE),
                        onClose: () => setOpenRoles(false),
                    }}
                    size="lg"
                    hideChildrenPadding
                >
                    <RolesWizard
                        closeModal={() => {
                            setOpenRoles(false);
                            getRoles(0);
                            setPageIndex(1);
                        }}
                    />
                </GenericModal>
            )}
            {selectedRole && (
                <AddUsersModal
                    service={() =>
                        SegSelectorService.GetUserNamesWithIds({
                            extraParams: `customerInstanceId=${session?.user.customerInstanceId}`,
                        })
                    }
                    onClose={() => {
                        setSelectedRole(null);
                    }}
                    title={t(TranslationKeys.EDIT_ROLE)}
                    handleAddUsers={({ usersId, name }) => handleAddUsers({ role: { ...selectedRole, name }, usersId })}
                    users={selectedUsersRole}
                    name={selectedRole.name}
                    nameLabel={t(TranslationKeys.ROLE_NAME)}
                    isSaving={isSaving}
                />
            )}
        </PageContainer>
    );
};

export default RoleGrid;
