import { FC } from "react";
import { Tabs } from "../Tabs";
import { DashboardHeaderProps } from "./dashboardHeaderTypes";
import { TabHeader } from "../__containers/TabHeader/TabHeader";
import { RoundedFilterButtonGroup } from "../RoundedFilterButtonGroup/RoundedFilterButtonGroup";
import { AvatarStarRating } from "../AvatarStarRating/AvatarStarRating";
import { faArrowLeft, faSliders } from "@fortawesome/pro-regular-svg-icons";
import { useUserType } from "app/hooks/useUserType";
import { Icon } from "../Icon/Icon";

export const DashboardHeader: FC<DashboardHeaderProps> = ({
    tabs,
    currentTab,
    onSecundaryFilterClick,
    currentFilterButton,
    filterButtonItems,
    onChangeFilterButton,
    fullName,
    starRatingAVG,
    total,
    trend,
    userId,
    avatarImg,
    isLoading,
    text,
    isSupervisor,
    isCompany,
    goBack,
    isAnalist,
    showArroBack,
    areStarsClickable,
    isDashbordListLoading,
}) => {
    const { isWorker } = useUserType();

    return (
        <TabHeader
            tabs={
                <>
                    <Tabs tabs={tabs} currentTab={currentTab} hideChildren={true} isLoading={isDashbordListLoading} />
                    {!isWorker && <Icon icon={faSliders} onClick={onSecundaryFilterClick} />}
                </>
            }
            paddingSize="sm"
        >
            <div className="dashboardHeader__body__container">
                <div className="dashboardHeader__body__header">
                    <div className="dashboardHeader__body__header__container">
                        {showArroBack && <Icon onClick={goBack} icon={faArrowLeft} />}

                        <AvatarStarRating
                            fullName={fullName}
                            userId={userId}
                            starRatingAVG={starRatingAVG}
                            total={total}
                            trend={trend}
                            avatarImg={avatarImg}
                            isLoading={isLoading}
                            text={text}
                            isSupervisor={isSupervisor}
                            isAnalist={isAnalist}
                            showRating={true}
                            objectFit={isCompany ? "contain" : undefined}
                            background={isCompany ? "white" : undefined}
                            areStarsClickable={areStarsClickable}
                        />
                    </div>
                </div>
            </div>
            <div className="dashboardHeader__body__container">
                <div className="dashboardHeader__body__counters">
                    <RoundedFilterButtonGroup
                        items={filterButtonItems}
                        active={currentFilterButton}
                        onClick={onChangeFilterButton}
                        fontSize="xs"
                    />
                </div>
            </div>
        </TabHeader>
    );
};

export default DashboardHeader;
