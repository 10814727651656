import { ASSET_PAGES } from "app/shared/AssetConstants";

export type IuseGetAssets = {
    companyId?: number | null;
    fK_PlanAPPCC?: string;
};

export const fillExtraParams = ({ companyId, fK_PlanAPPCC }: IuseGetAssets) => {
    const extraParams = new URLSearchParams();
    extraParams.append("isDeleted", "false");

    if (companyId === null) extraParams.append("AssetCategory", ASSET_PAGES.ASSET_RANGE);
    else if (!!companyId) extraParams.append("companyId", String(companyId));
    if (!!fK_PlanAPPCC && fK_PlanAPPCC !== "-1") extraParams.append("fK_PlanAPPCC", String(fK_PlanAPPCC));

    return extraParams.toString();
};
