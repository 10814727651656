import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "app/translation/translationKeys";
import { Input } from "app/components_v2/__inputs";
import { CustomSelectOptions } from "./CustomSelectOptions";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { RecurrentTaskModel, WizardTaskStep4 } from "app/models/02-TAR/TaskWizard";
import { Label } from "app/components_v2/__inputs/Label";

type CustomRepeatEveryProps = {
    onChange: (fields: Partial<WizardTaskStep4>) => void;
    recurringTask: RecurrentTaskModel;
    customError?: string;
    disabled?: boolean;
    className?: string;
    errorClassName?: string;
    isWizardMode?: boolean;
    options: OptionsSearch[];
    inverted?: boolean;
};

export const CustomRepeatEvery: FC<CustomRepeatEveryProps> = ({
    recurringTask,
    onChange,
    customError,
    disabled,
    options,
    inverted,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Label label={t(TranslationKeys.REPEAT_EVERY)} inverted={inverted} />
            <div className="customRepeatEveryInputSelectContainer">
                <Input
                    onChange={(value) => {
                        onChange({
                            recurringTask: {
                                ...recurringTask,
                                custom: {
                                    ...recurringTask.custom,
                                    repeatEvery: Number(value),
                                },
                            },
                        });
                    }}
                    value={recurringTask.custom?.repeatEvery.toString()}
                    type="number"
                    errorMessage={customError}
                    disabled={disabled}
                    readOnlyOptions={{ type: "select" }}
                />
                <CustomSelectOptions
                    recurringTask={recurringTask}
                    onChange={onChange}
                    options={options}
                    disabled={disabled}
                />
            </div>
        </>
    );
};
