import { FC, useEffect, useState } from "react";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { useSession } from "app/hooks";
import { IssueTranslation, TranslationCommon, TranslationKeys } from "app/translation/translationKeys";
import { OptionsSearch } from "app/models/FormComponentsModel";
import { IssueGridSFModel, IssueStatusState } from "app/pages/05-QUA/Issue/state/context/issueGridContext";
import { IssueTypeCodeModel } from "app/shared/types/IssueTypes";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { SecondaryFilter } from "app/components_v2/SecondaryFilter/SecondaryFilter";
import { DateRangePicker, SelectOptions } from "app/components_v2/__inputs";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import { SelectCompanyModel } from "app/components_v2/Table/hooks/useSelectCompanyTable";

type IssueFilterProps = {
    filterData: IssueGridSFModel;
    initialFilterData: IssueGridSFModel;
    isSecondaryFilterOpen: boolean;
    onCancel?: () => void;
    onSave?: (values: IssueGridSFModel) => void;
    isOpen: boolean;
    currentTab?: IssueTypeCodeModel;
    selectCompany: SelectCompanyModel;
};

const ISSUE_STATUS: Record<IssueStatusState, IssueStatusState> = {
    BOTH: "BOTH",
    CLOSED: "CLOSED",
    OPEN: "OPEN",
};

export const IssueFilter: FC<IssueFilterProps> = ({
    filterData,
    initialFilterData,
    isSecondaryFilterOpen,
    onCancel,
    onSave,
    isOpen,
    currentTab,
    selectCompany,
}) => {
    const { t } = useTranslation();
    const session = useSession();

    const ISSUE_STATE_OPTIONS: OptionsSearch[] = [
        { label: t(TranslationKeys.ISSUE_STATUS_OPEN_AND_CLOSE), value: ISSUE_STATUS.BOTH },
        { label: t(TranslationKeys.ISSUE_STATUS_OPEN), value: ISSUE_STATUS.OPEN },
        { label: t(TranslationKeys.ISSUE_STATUS_CLOSE), value: ISSUE_STATUS.CLOSED },
    ];

    const [userOptions, setUserOptions] = useState<OptionModel[]>([]);
    const [assetOptions, setAssetOptions] = useState<OptionModel[]>([]);
    const [auditInstanceOptions, setAuditInstanceOptions] = useState<OptionModel[]>([]);
    const [issueClassificationOptions, setIssueClassificationOptions] = useState<OptionModel[]>([]);

    const [internalFilterData, setInternalFilterData] = useState<IssueGridSFModel>(filterData);

    const handleInputChange = (values: Partial<IssueGridSFModel>) =>
        setInternalFilterData((prev) => ({ ...prev, ...values }));

    const handleClickOutside = () => {
        if (!isOpen) return;
        handleInputChange(filterData);
        onCancel && onCancel();
    };

    const handleReset = () => handleInputChange(initialFilterData);

    const handleSave = () => onSave && onSave(internalFilterData);

    const getUsers = async () => {
        const { data, status } = await SegSelectorService.GetUserNamesWithIds({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}`,
        });

        if (!status()) {
            setUserOptions([]);
            return;
        }
        setUserOptions([{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...data]);
    };

    const getAssets = async () => {
        const { data, status } = await QuaSelectorService.GetAssets({
            extraParams: `customerInstanceId=${session?.user.customerInstanceId}&IsDeleted=false`,
        });

        if (!status()) {
            setAssetOptions([]);
            return;
        }
        setAssetOptions([{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...data]);
    };

    const getIssueAuditInstances = async () => {
        const { data, status } = await QuaSelectorService.GetIssueAuditInstances({
            extraParams: `companyId=${selectCompany.company.value}`,
        });

        if (!status()) {
            setAuditInstanceOptions([]);
            return;
        }

        setAuditInstanceOptions([{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...data]);
    };

    const getIssueClassification = async () => {
        const { data, status } = await QuaSelectorService.GetIssueClassification();

        if (!status()) {
            setIssueClassificationOptions([]);
            return;
        }

        setIssueClassificationOptions([{ label: t(TranslationCommon.SELECT_OPTION), value: "-1" }, ...data]);
    };

    useEffect(() => {
        if (!isSecondaryFilterOpen) return;
        handleInputChange(filterData);
    }, [isSecondaryFilterOpen, filterData]);

    useEffect(() => {
        if (!isSecondaryFilterOpen) return;
        getUsers();
        getIssueClassification();
    }, [isSecondaryFilterOpen]);

    useEffect(() => {
        if (isSecondaryFilterOpen && currentTab === "TASK_ASSET") getAssets();
        if (isSecondaryFilterOpen && currentTab === "AUDIT") getIssueAuditInstances();
    }, [isSecondaryFilterOpen, currentTab]);

    return (
        <SecondaryFilter
            isOpen={isSecondaryFilterOpen}
            onClickOutside={handleClickOutside}
            onCancel={handleClickOutside}
            onReset={handleReset}
            onSave={handleSave}
            showResetFilter={!isEqual(initialFilterData, internalFilterData)}
        >
            <DateRangePicker
                startDate={internalFilterData.startDate}
                endDate={internalFilterData.endDate}
                onChange={([startDate, endDate]) => handleInputChange({ startDate, endDate })}
                label={t(TranslationKeys.REQUEST_DATE)}
                placeholder={t(TranslationKeys.ALL_TIME_DATES)}
                showMoreOptions
            />
            <SelectOptions
                isMulti={false}
                onChange={({ value }) => handleInputChange({ senderUserId: value })}
                options={userOptions}
                label={t(TranslationKeys.RESPONSABLE)}
                selectedValue={internalFilterData.senderUserId !== "-1" ? internalFilterData.senderUserId : undefined}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
            />
            <SelectOptions
                isMulti={false}
                onChange={({ value }) =>
                    handleInputChange({ issueStatusState: ISSUE_STATUS[value as keyof typeof ISSUE_STATUS] })
                }
                options={ISSUE_STATE_OPTIONS}
                label={t(TranslationKeys.ISSUE_STATE)}
                selectedValue={internalFilterData.issueStatusState}
                placeholder={t(TranslationCommon.SELECT_OPTION)}
            />
            <SelectOptions
                isMulti={false}
                onChange={({ value }) => handleInputChange({ issueClassificationId: value })}
                options={issueClassificationOptions}
                label={t(IssueTranslation.ISSUE_CLASSIFICATION)}
                selectedValue={
                    internalFilterData.issueClassificationId !== "-1"
                        ? internalFilterData.issueClassificationId
                        : undefined
                }
                placeholder={t(TranslationCommon.SELECT_OPTION)}
            />
            {currentTab === "TASK_ASSET" && (
                <SelectOptions
                    isMulti={false}
                    onChange={({ value }) => handleInputChange({ assetId: value })}
                    options={assetOptions}
                    label={t(TranslationKeys.ASSET_PAGE)}
                    selectedValue={internalFilterData.assetId !== "-1" ? internalFilterData.assetId : undefined}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                />
            )}
            {currentTab === "AUDIT" && (
                <SelectOptions
                    isMulti={false}
                    onChange={({ value }) => handleInputChange({ auditInstanceId: value })}
                    options={auditInstanceOptions}
                    label={t(IssueTranslation.AUDIT_CODE)}
                    selectedValue={
                        internalFilterData.auditInstanceId !== "-1" ? internalFilterData.auditInstanceId : undefined
                    }
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                />
            )}
        </SecondaryFilter>
    );
};
