import { Input, Label, SelectOptions } from "app/components_v2/__inputs";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { TaskTemporalityDateRangeValue } from "app/models/02-TAR/TaskWizard";
import { TASK_TEMPORALITY_DATE_RANGE_VALUES } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/constants/editTaskConstants";
import {
    TaskTemporalityDateRangeTranslations,
    TranslationCommon,
    TranslationKeys,
} from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type TaskTemporalityDateRangeRepeatEveryProps = {
    repeatEvery: string;
    temporalityValue: TaskTemporalityDateRangeValue;
    isDisabled?: boolean;
    repeatEveryError: string;
    onChangeRepeatEvery: (value: string) => void;
    onChangeTemporality: (value: TaskTemporalityDateRangeValue) => void;
};

export const TaskTemporalityDateRangeRepeatEvery: FC<TaskTemporalityDateRangeRepeatEveryProps> = ({
    onChangeRepeatEvery,
    onChangeTemporality,
    repeatEvery,
    temporalityValue,
    isDisabled,
    repeatEveryError,
}) => {
    const { t } = useTranslation();
    const repeatEveryNumber = Number(repeatEvery);
    const options: OptionModel[] = [
        {
            label: repeatEveryNumber > 1 ? t(TranslationKeys.WEEKS) : t(TranslationKeys.WEEK),
            value: TASK_TEMPORALITY_DATE_RANGE_VALUES.WEEKLY,
        },
        {
            label: repeatEveryNumber > 1 ? t(TranslationKeys.MONTHS) : t(TranslationKeys.MONTH),
            value: TASK_TEMPORALITY_DATE_RANGE_VALUES.MONTHLY,
        },
        {
            label: t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_CUSTOM_LABEL),
            value: TASK_TEMPORALITY_DATE_RANGE_VALUES.CUSTOM,
        },
    ];

    return (
        <div className="taskTemporalityDateRangeRepeatEvery">
            <Label label={t(TranslationKeys.REPEAT_EVERY)} />
            <div className="taskTemporalityDateRangeRepeatEvery__container">
                {temporalityValue !== "CUSTOM" && (
                    <Input
                        type="number"
                        onChange={onChangeRepeatEvery}
                        value={repeatEvery}
                        errorMessage={repeatEveryError}
                        disabled={isDisabled}
                        placeholder={t(TranslationKeys.REPEAT_EVERY)}
                    />
                )}
                <SelectOptions
                    isMulti={false}
                    onChange={({ value }) => onChangeTemporality(value as TaskTemporalityDateRangeValue)}
                    options={options}
                    disabled={isDisabled}
                    isSearchable
                    selectedValue={temporalityValue}
                    placeholder={t(TranslationCommon.SELECT_OPTION)}
                />
            </div>
        </div>
    );
};
