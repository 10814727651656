import { FC } from "react";
import { DayBubble } from "app/components_v2/__inputs";
import { RecurrentTaskModel, WizardTaskStep4 } from "app/models/02-TAR/TaskWizard";

type CustomDaysBublesProps = {
    onChange: (fields: Partial<WizardTaskStep4>) => void;
    recurringTask: RecurrentTaskModel;
    value: string;
    isActive: boolean;
    disabled?: boolean;
    className?: string;
    bubbleType?: "primary" | "dark";
    id: number;
};

export const CustomDaysBubles: FC<CustomDaysBublesProps> = ({
    recurringTask,
    isActive,
    onChange,
    value,
    disabled,
    bubbleType = "primary",
    id,
}) => {
    return (
        <DayBubble
            active={isActive}
            letter={value}
            disabled={disabled}
            variant={bubbleType}
            onClick={() => {
                if (disabled) return;
                onChange({
                    recurringTask: {
                        ...recurringTask,
                        custom: {
                            ...recurringTask.custom,
                            days: recurringTask.custom.days.map((day) =>
                                day.id === id
                                    ? {
                                          ...day,
                                          isActive: !day.isActive,
                                      }
                                    : day
                            ),
                        },
                    },
                });
            }}
        />
    );
};
