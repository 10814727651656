import { FC } from "react";
import { GenericModal } from "../base/GenericModal/GenericModal";
import { GenericModalFooter } from "../base/GenericModal/GenericModalFooter/GenericModalFooter";
import { useTranslation } from "react-i18next";
import { TranslationCommon } from "app/translation/translationKeys";
import { TaskCheckList } from "app/pages/02-TAR/01-TAR-Dashboard/TaskDetail/TaskCheckList/TaskCheckList";
import { CheckListModel } from "app/models/02-TAR/TaskWizard";

type PreviewChecklistModalProps = {
    checkList: CheckListModel[];
    onClose: () => void;
};

export const PreviewChecklistModal: FC<PreviewChecklistModalProps> = ({ checkList, onClose }) => {
    const { t } = useTranslation();

    return (
        <GenericModal
            size="md-tall"
            footer={
                <GenericModalFooter
                    closeButton={{
                        text: t(TranslationCommon.CLOSE),
                        onClick: onClose,
                    }}
                />
            }
        >
            <TaskCheckList
                readOnly
                taskInstanceCheckList={checkList.map(({ name, isEnabled }, i) => ({
                    isActive: null,
                    taskCheckListId: i,
                    taskInstanceId: 0,
                    taskCheckListModel: { id: 0, isDeleted: false, name, isEnabled, fK_BaseTaskCheckList: null },
                }))}
            />
        </GenericModal>
    );
};
