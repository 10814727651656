import { Divider } from "app/components_v2/Divider/Divider";
import { FC } from "react";
import { TaskStart } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskTemporality/components";
import { TaskTemporalityDateRangeCustomDisabled } from "./TaskTemporalityDateRangeCustomDisabled/TaskTemporalityDateRangeCustomDisabled";
import { TaskTemporalityDateRangeFormModel } from "app/models/02-TAR/TaskWizard";
import { TaskTemporalityDateRangeMonthlyDisabled } from "./TaskTemporalityDateRangeMonthlyDisabled/TaskTemporalityDateRangeMonthlyDisabled";
import { TaskTemporalityDateRangeWeeklyDisabled } from "./TaskTemporalityDateRangeWeeklyDisabled/TaskTemporalityDateRangeWeeklyDisabled";
import { TaskTemporalityTitleDisabled } from "../TaskTemporalityTitleDisabled/TaskTemporalityTitleDisabled";
import { TaskTranslations } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";

type TaskTemporalityDateRangeDisabledProps = {
    taskStart: string;
    taskTemporalityDateRange: TaskTemporalityDateRangeFormModel;
};

export const TaskTemporalityDateRangeDisabled: FC<TaskTemporalityDateRangeDisabledProps> = ({
    taskTemporalityDateRange,
    taskStart,
}) => {
    const { t } = useTranslation();

    const { value, custom, weeklyDays, repeatEvery, monthly } = taskTemporalityDateRange;
    return (
        <div className="taskTemporalityDateRangeDisabled">
            <TaskTemporalityTitleDisabled title={t(TaskTranslations.TASK_TEMPORALITY_RANGE_DATES)} />
            <TaskStart onChange={() => {}} taskStart={taskStart} readonly />
            <Divider />
            {value === "CUSTOM" && <TaskTemporalityDateRangeCustomDisabled custom={custom} />}
            {value === "WEEKLY" && (
                <TaskTemporalityDateRangeWeeklyDisabled repeatEvery={repeatEvery} weeklyDays={weeklyDays} />
            )}
            {value === "MONTHLY" && (
                <TaskTemporalityDateRangeMonthlyDisabled repeatEvery={repeatEvery} monthly={monthly} />
            )}
        </div>
    );
};
