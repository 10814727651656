import { EditTaskDisabledFieldsModel, EditTaskValidation } from "app/models/02-TAR/Task/EditTask";
import {
    TaskTemporalityDateRangeFormModel,
    TaskTemporalityDateRangeMonthlyValue,
    TaskTemporalityDateRangeValue,
} from "app/models/02-TAR/TaskWizard";
import { days } from "../utilities/initialValues";

export const INITIAL_EDIT_TASK_DISABLEDS: EditTaskDisabledFieldsModel = {
    disabledDataToReport: false,
    disabledChecklist: false,
    disabledDynamicFields: false,
    disabledTaskTitle: false,
    disabledTaskDesc: false,
    disabledFotoExample: false,
    disabledIsPhotoRequired: false,
    disabledIsCritical: false,
    disabledBaseTaskType: true,
    disabledPlan: true,
    disabledType: "NONE",
};

export const INITIAL_EDIT_TASK_FORM_VALUE_ERRORS: EditTaskValidation = {
    taskTitle: "",
    planAPPCC: "",
    companyForTask: "",
    DepartmentForTask: "",
    userForTask: "",
    userOrQRForTask: "",
    reportData: "",
    taskStart: "",
    taskHours: {
        value: "",
        errors: [],
    },
    finishEl: "",
    finishResp: "",
    customReps: "",
    customDaysBubles: "",
    customMonth: "",
    selectOption: "",
    dynamicFields: [],
    checklist: [],
    dateRange: "",
    temporalityType: "",
    temporalityDateRangeRepeatEvery: "",
    temporalityDateRange: {
        weekly: "",
        monthlyDay: "",
        monthlyStartDay: "",
        monthlyEndDay: "",
    },
};

export const TASK_TEMPORALITY_DATE_RANGE_VALUES: Record<TaskTemporalityDateRangeValue, TaskTemporalityDateRangeValue> =
    {
        WEEKLY: "WEEKLY",
        MONTHLY: "MONTHLY",
        CUSTOM: "CUSTOM",
    };

export const TASK_TEMPORALITY_DATE_RANGE_MONTHLY_VALUES: Record<
    TaskTemporalityDateRangeMonthlyValue,
    TaskTemporalityDateRangeMonthlyValue
> = {
    FIRST_X_DAYS: "FIRST_X_DAYS",
    LAST_X_DAYS: "LAST_X_DAYS",
    CUSTOM: "CUSTOM",
};

export const INITIAL_TASK_TEMPORALITY_DATE_RANGE_FORM_MODEL: TaskTemporalityDateRangeFormModel = {
    dbId: 0,
    custom: { dateRange: [] },
    monthly: {
        day: "",
        customDays: { startDay: "", endDay: "" },
        value: "FIRST_X_DAYS",
        text: "",
    },
    repeatEvery: "1",
    text: "",
    value: "WEEKLY",
    weeklyDays: days,
};
