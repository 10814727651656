import {
    dateToDateStringWithHours,
    convertUTCtoLocaleDate,
    getFullDateWithTime,
    getWeekDay,
    transformMinutesHoursToDate,
    getFullDateWithTimeEndDate,
} from "app/helpers/Date.utilities";
import { selectTypeCode, selectTypeCodeCustom } from "../../WizardTasks/utilities/selectTypeCode";
import { TaskBodyToSendModel, TaskFieldModel2 } from "app/models/02-TAR/Task/TaskBody";
import {
    WizardTaskAllSteps,
    RecurrentTaskModel,
    DynamicFieldsModel,
    ReportDataList,
    CheckListModel,
    TaskTemporalityDateRangeFormModel,
} from "app/models/02-TAR/TaskWizard";
import { TaskCheckListInsertModel } from "app/models/02-TAR/Task/TaskCheckListModel";

export const mapTask = (
    data: WizardTaskAllSteps,
    instanceId: number | undefined,
    taskId?: string | number,
    isDuplicate?: boolean,
    offset?: number
): TaskBodyToSendModel => {
    const {
        companyForTask,
        userForTask,
        DepartmentForTask,
        SubDepartementForTask,
        workingPositionForTask,
        criticalTask,
        taskStart,
        taskDesc,
        fK_PlanAPPCC,
        finish,
        recurringTask,
        taskHours,
        taskTitle,
        imageBase64,
        fotoExampleId,
        isPhotoRequired,
        fK_BaseTask,
        taskType,
        isCreated,
        fK_Issue,
        temporalityType,
        taskTemporalityDateRange,
        minPhotosAllowed,
    } = data;

    let body: TaskBodyToSendModel = {
        fK_BaseTask: fK_BaseTask,
        taskType,
        customerInstanceId: instanceId ? instanceId : 0,
        name: taskTitle,
        description: taskDesc,
        fK_PlanAPPCC: fK_PlanAPPCC !== "-1" ? Number(fK_PlanAPPCC) : null,
        fK_Company: Number(companyForTask.value),
        fK_User: validateSelect(userForTask.value),
        fK_Department: validateSelect(DepartmentForTask.value),
        fK_Subdepartment: validateSelect(SubDepartementForTask.value),
        fK_WorkingPosition: validateSelect(workingPositionForTask.value),
        fotoExampleId: fotoExampleId ? fotoExampleId : null,
        fK_Foto: fotoExampleId ? fotoExampleId : null,
        isPhotoRequired: isPhotoRequired,
        imageBase64Content: imageBase64 ?? null,
        minPhotosAllowed: isPhotoRequired ? minPhotosAllowed : null,
        isCritical: criticalTask,
        endDate: finish.checked === "el" ? getFullDateWithTime(String(finish.value)) : null,
        startDate: getFullDateWithTime(taskStart),
        neverEnds:
            finish.checked === "never" && recurringTask && recurringTask.isRecurrent
                ? finish.checked === "never"
                : null,
        numRecurrencies: finish.checked === "after" ? Number(finish.value) : null,
        taskTemporalityType: temporalityType,
        taskRangeHours:
            temporalityType === "RANGE_DATES" || temporalityType === "SPORADIC"
                ? []
                : taskHours.map(({ hour, maxHour, type }) => {
                      const offsetInMinutes = offset ? offset * 60 : 0;

                      // Local to User offset
                      const startDateToUserOffset = convertUTCtoLocaleDate(
                          new Date(transformMinutesHoursToDate(hour)),
                          offsetInMinutes
                      );
                      const startDateConverted = dateToDateStringWithHours(startDateToUserOffset.toString());

                      let endDateConverted = null;
                      if (type === "range" && maxHour) {
                          const maxHourDateToUserOffset = convertUTCtoLocaleDate(
                              new Date(transformMinutesHoursToDate(maxHour)),
                              offsetInMinutes
                          );
                          if (startDateToUserOffset > maxHourDateToUserOffset) {
                              maxHourDateToUserOffset.setDate(maxHourDateToUserOffset.getDate() + 1);
                          }
                          endDateConverted = dateToDateStringWithHours(maxHourDateToUserOffset.toString());
                      }
                      return {
                          hour: startDateConverted,
                          maxHour: endDateConverted,
                      };
                  }),

        taskTemporalityDateRange: taskTemporalityDateRange.custom.dateRange.map(({ endDate, isDeleted, startDate }) => {
            const startDateFormatted = getFullDateWithTime(startDate);
            const endDateFormatted = getFullDateWithTimeEndDate(endDate, offset || 0);
            const isDateRangeCustom = taskTemporalityDateRange.value === "CUSTOM";
            return {
                id: 0,
                startDate: startDateFormatted,
                endDate: endDateFormatted,
                isDeleted: isDateRangeCustom ? isDeleted : true,
            };
        }),
        taskTemporalityDateRangeScheduler:
            temporalityType === "RANGE_DATES" && taskTemporalityDateRange.value !== "CUSTOM"
                ? {
                      fK_Task: taskId ? Number(taskId) : 0,
                      id: taskTemporalityDateRange.dbId || 0,
                      repeatEvery: Number(taskTemporalityDateRange.repeatEvery),
                      type: taskTemporalityDateRange.value,
                      monthlyType:
                          taskTemporalityDateRange.value === "MONTHLY" ? taskTemporalityDateRange.monthly.value : "",
                      monthlyDay:
                          taskTemporalityDateRange.value === "MONTHLY" &&
                          taskTemporalityDateRange.monthly.value !== "CUSTOM"
                              ? Number(taskTemporalityDateRange.monthly.day)
                              : null,
                      monthlyStartDay:
                          taskTemporalityDateRange.value === "MONTHLY" &&
                          taskTemporalityDateRange.monthly.value === "CUSTOM"
                              ? Number(taskTemporalityDateRange.monthly.customDays.startDay)
                              : null,
                      monthlyEndDay:
                          taskTemporalityDateRange.value === "MONTHLY" &&
                          taskTemporalityDateRange.monthly.value === "CUSTOM"
                              ? Number(taskTemporalityDateRange.monthly.customDays.endDay)
                              : null,
                      monday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 0),
                      tuesday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 1),
                      wednesday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 2),
                      thursday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 3),
                      friday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 4),
                      saturday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 5),
                      sunday: checkIfDateRangeWeeklyIsSelected(taskTemporalityDateRange, 6),
                  }
                : null,
        taskScheduler:
            temporalityType !== "RANGE_DATES"
                ? {
                      name:
                          recurringTask && recurringTask.isRecurrent
                              ? recurringTask.value !== "custom"
                                  ? recurringTask.text
                                  : `custom-every ${recurringTask.custom.repeatEvery} ${recurringTask.custom.customValue}`
                              : "Task is not recurring",

                      calendarDayNumber:
                          recurringTask &&
                          recurringTask.isRecurrent &&
                          recurringTask.value === "custom" &&
                          recurringTask.custom.customValue === "everyMonth"
                              ? recurringTask.custom.selectedOptions.value === "everyMonthDayOfMonth"
                                  ? new Date(taskStart).getDate()
                                  : 0
                              : 0,
                      calendarWeekNumber:
                          recurringTask && recurringTask.isRecurrent
                              ? recurringTask.custom.customValue === "everyMonth"
                                  ? recurringTask.custom.selectedOptions.value === "everyMonthFirstDayOfWeek"
                                      ? getWeekDay(taskStart)
                                      : 0
                                  : recurringTask.value === "everyMonth"
                                  ? getWeekDay(taskStart)
                                  : 0
                              : 0,
                      repeatEvery:
                          recurringTask && recurringTask.value === "custom" ? recurringTask.custom.repeatEvery : 1,
                      monday: checkIfIsWeekDayIsSelected(recurringTask, 0, new Date(taskStart).getDay() - 1),
                      tuesday: checkIfIsWeekDayIsSelected(recurringTask, 1, new Date(taskStart).getDay() - 1),
                      wednesday: checkIfIsWeekDayIsSelected(recurringTask, 2, new Date(taskStart).getDay() - 1),
                      thursday: checkIfIsWeekDayIsSelected(recurringTask, 3, new Date(taskStart).getDay() - 1),
                      friday: checkIfIsWeekDayIsSelected(recurringTask, 4, new Date(taskStart).getDay() - 1),
                      saturday: checkIfIsWeekDayIsSelected(recurringTask, 5, new Date(taskStart).getDay() - 1),
                      sunday: checkIfIsWeekDayIsSelected(recurringTask, 6, new Date(taskStart).getDay() - 1),
                      typeCode:
                          recurringTask && recurringTask.isRecurrent && recurringTask.value === "custom"
                              ? selectTypeCodeCustom(recurringTask.custom.customValue)
                              : recurringTask && recurringTask.isRecurrent
                              ? selectTypeCode(recurringTask.value)
                              : "ONE_TIME",
                  }
                : null,
        isCreated: isCreated || true,
        fK_Issue: fK_Issue || null,
    };

    body = taskId ? { ...body, id: Number(taskId) } : body;

    if (!isDuplicate) {
        body = getBodyIsEditing({ body, data, isDuplicate: false, taskId });
        return body;
    }
    body = getBodyIsNewOrDuplicating({ body, data, isDuplicate: true, taskId });
    return body;
};

export const checkIfIsWeekDayIsSelected = (
    recurringTask: RecurrentTaskModel | null,
    index: number,
    dayOnWeek: number
): boolean => {
    if (!recurringTask) return false;

    const recurringTaskPermitedValues = ["everyWeek", "everyMonth", "annually"];

    if (recurringTask.isRecurrent) {
        if (recurringTask.value === "custom") {
            if (recurringTask.custom.customValue === "week") return recurringTask.custom.days[index].isActive;

            if (
                recurringTask.custom.customValue === "everyMonth" &&
                recurringTask.custom.selectedOptions.value === "everyMonthFirstDayOfWeek"
            )
                return index === dayOnWeek;
        }
    }

    if (recurringTaskPermitedValues.includes(recurringTask.value)) return index === dayOnWeek;

    if (recurringTask.value === "mondayToFriday") return index <= 4;

    return false;
};

export const checkIfDateRangeWeeklyIsSelected = (
    { value, weeklyDays }: TaskTemporalityDateRangeFormModel,
    index: number
): boolean => {
    if (value !== "WEEKLY") return false;

    return weeklyDays[index].isActive || false;
};

const validateSelect = (value: string) => {
    if (value === "" || value === "-1") return null;
    return Number(value);
};

const getDynamicFields = (
    dynamicFields: DynamicFieldsModel[],
    isDuplicate: boolean | undefined,
    taskId: number
): TaskFieldModel2[] =>
    dynamicFields.map(({ dynamicFieldsType, isDeleted, isRequired, label, taskFieldOptions, dbId }, i) => ({
        id: isDuplicate ? undefined : dbId,
        label,
        isRequired,
        isDeleted,
        dynamicFieldType: dynamicFieldsType,
        taskFieldOptions: taskFieldOptions.map(({ label }) => ({ label })),
        fK_Task: taskId || undefined,
        isHidden: false,
        order: i,
    }));

const getAssets = (
    reportData: ReportDataList[],
    isDuplicate: boolean | undefined,
    taskId: number
): TaskFieldModel2[] => {
    let order = 0;
    return reportData.flatMap(({ assetId, assetsFields, dbId }) =>
        !!assetsFields?.length
            ? assetsFields.map<TaskFieldModel2>(
                  ({
                      name,
                      assetDynamicField,
                      assetFieldRangeId,
                      id,
                      isDeleted,
                      isHidden,
                      assetFieldOption,
                      isRequired,
                  }) => {
                      const taskField = {
                          isRequired,
                          dynamicFieldType: assetDynamicField,
                          id: isDuplicate ? undefined : id && dbId ? id || undefined : undefined,
                          isDeleted: !!isDeleted,
                          label: name,
                          fK_Asset2: assetId,
                          fK_AssetFieldRange: assetDynamicField === "NUMBER" ? assetFieldRangeId : undefined,
                          fK_Task: taskId ? Number(taskId) : undefined,
                          isHidden,
                          taskFieldOptions: assetFieldOption.map(({ name }) => ({ label: name })),
                          order,
                      };
                      order++;
                      return taskField;
                  }
              )
            : []
    );
};

const getChecklist = (checkList: CheckListModel[], isDuplicate: boolean | undefined): TaskCheckListInsertModel[] => {
    return checkList.map(({ id, isDeleted, name, dbId, isEnabled, fK_BaseTaskCheckList }) => ({
        id: isDuplicate ? undefined : id ? dbId || undefined : undefined,
        name,
        isDeleted,
        isEnabled,
        fK_BaseTaskCheckList,
    }));
};

const deleteDynamicFields = (
    dynamicFields: DynamicFieldsModel[],
    isDuplicate: boolean | undefined,
    taskId: number
): TaskFieldModel2[] => {
    return dynamicFields.map(({ dynamicFieldsType, isRequired, label, dbId, order }) => ({
        id: isDuplicate ? undefined : taskId ? dbId || undefined : undefined,
        label,
        isRequired,
        isDeleted: true,
        dynamicFieldType: dynamicFieldsType,
        fK_Task: Number(taskId),
        isHidden: false,
        order,
    }));
};

const deleteAssets = (
    reportData: ReportDataList[],
    isDuplicate: boolean | undefined,
    taskId: number
): TaskFieldModel2[] =>
    reportData.flatMap(({ dbId, assetId, assetsFields }) =>
        assetsFields
            ? assetsFields?.map(({ name, assetDynamicField, assetFieldRangeId, id, isHidden }) => ({
                  isRequired: true,
                  dynamicFieldType: assetDynamicField,
                  id: isDuplicate ? undefined : id && dbId ? id || undefined : undefined,
                  isDeleted: true,
                  label: name,
                  fK_Asset2: assetId,
                  fK_AssetFieldRange: assetDynamicField === "NUMBER" ? assetFieldRangeId : undefined,
                  fK_Task: taskId ? Number(taskId) : undefined,
                  isHidden,
                  order: 0,
              }))
            : []
    );

const deleteChecklist = (checkList: CheckListModel[]): TaskCheckListInsertModel[] => {
    return checkList.map(({ name, dbId, isEnabled, fK_BaseTaskCheckList }) => ({
        id: dbId,
        isDeleted: true,
        name,
        isEnabled,
        fK_BaseTaskCheckList,
    }));
};

type IGetBody = {
    data: WizardTaskAllSteps;
    body: TaskBodyToSendModel;
    isDuplicate?: boolean;
    taskId?: string | number;
};

const getBodyIsEditing = ({ body, data, isDuplicate, taskId }: IGetBody): TaskBodyToSendModel => {
    const { reportData, reportType, dynamicFields, checkList } = data;
    const resp: TaskBodyToSendModel =
        reportType === "DYNAMIC_FIELD"
            ? {
                  ...body,
                  taskFields2: [
                      ...deleteAssets(reportData, isDuplicate, Number(taskId)),
                      ...getDynamicFields(dynamicFields, isDuplicate, Number(taskId)),
                  ],
                  taskCheckList: deleteChecklist(checkList),
              }
            : reportType === "ASSET" || reportType === "RANGE_REGISTER"
            ? {
                  ...body,
                  taskFields2: [
                      ...getAssets(reportData, isDuplicate, Number(taskId)),
                      ...deleteDynamicFields(dynamicFields, isDuplicate, Number(taskId)),
                  ],
                  taskCheckList: deleteChecklist(checkList),
              }
            : reportType === "CHECKLIST"
            ? {
                  ...body,
                  taskCheckList: getChecklist(checkList, isDuplicate),
                  taskFields2: [
                      ...deleteAssets(reportData, isDuplicate, Number(taskId)),
                      ...deleteDynamicFields(dynamicFields, isDuplicate, Number(taskId)),
                  ],
              }
            : reportType === "NONE"
            ? {
                  ...body,
                  taskFields2: [
                      ...deleteAssets(reportData, isDuplicate, Number(taskId)),
                      ...deleteDynamicFields(dynamicFields, isDuplicate, Number(taskId)),
                  ],
                  taskCheckList: deleteChecklist(checkList),
              }
            : body;

    return resp;
};

const getBodyIsNewOrDuplicating = ({ body, data, isDuplicate, taskId }: IGetBody) => {
    const { reportData, reportType, dynamicFields, checkList } = data;
    const dynamicFieldsFiltered = dynamicFields.filter((x) => !x.isDeleted);
    const reportDataFiltered = reportData.filter((x) => !x.isDeleted);
    const checkListFiltered = checkList.filter((x) => !x.isDeleted);
    const resp: TaskBodyToSendModel =
        reportType === "DYNAMIC_FIELD"
            ? {
                  ...body,
                  taskFields2: [...getDynamicFields(dynamicFieldsFiltered, isDuplicate, Number(taskId))],
                  taskCheckList: [],
              }
            : reportType === "ASSET" || reportType === "RANGE_REGISTER"
            ? {
                  ...body,
                  taskFields2: [...getAssets(reportDataFiltered, isDuplicate, Number(taskId))],
                  taskCheckList: [],
              }
            : reportType === "CHECKLIST"
            ? {
                  ...body,
                  taskCheckList: getChecklist(checkListFiltered, isDuplicate),
                  taskFields2: [],
              }
            : reportType === "NONE"
            ? {
                  ...body,
                  taskFields2: [],
                  taskCheckList: [],
              }
            : body;

    return resp;
};
