import { DayBubble, Label } from "app/components_v2/__inputs";
import { ReadOnlyInput } from "app/components_v2/__inputs/ReadOnlyInput/ReadOnlyInput";
import { Divider } from "app/components_v2/Divider/Divider";
import { useTaskTemporality } from "app/components_v2/WizardTask/TaskTemporalityPeriodical/useTaskTemporality";
import { TaskTemporalityPeriodicalEnds } from "app/components_v2/WizardTask/TaskTemporalityPeriodicalEnds/TaskTemporalityPeriodicalEnds";
import { CustomErrorTaskTemporalityModel, TaskTemporalityModel } from "app/models/02-TAR/Task/EditTask";
import { RecurentTaskCustomValues, RecurrentTaskModel, TaskEnds, TaskHoursModel } from "app/models/02-TAR/TaskWizard";
import { TaskStart } from "app/pages/02-TAR/07-TAR-CRUD/EditTask/Components/TaskTemporality/components";
import {
    TaskTemporalityReadOnlyTranslations,
    TaskTranslations,
    TranslationKeys,
} from "app/translation/translationKeys";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { TaskTemporalityTitleDisabled } from "../TaskTemporalityTitleDisabled/TaskTemporalityTitleDisabled";

type TaskTemporalityPeriodicalDisabledProps = {
    taskStart: string;
    customError: CustomErrorTaskTemporalityModel;
    isDisabled?: boolean;
    min?: Date;
    taskstartLabel?: string;
    hint?: ReactNode;
    taskHours: TaskHoursModel[];
    recurringTask: RecurrentTaskModel;
    finish: TaskEnds;
    onChange: (fields: Partial<TaskTemporalityModel>) => void;
};

export const TaskTemporalityPeriodicalDisabled: FC<TaskTemporalityPeriodicalDisabledProps> = ({
    customError,
    onChange,
    taskStart,
    hint,
    isDisabled,
    min,
    taskstartLabel,
    taskHours,
    recurringTask,
    finish,
}) => {
    const { t } = useTranslation();

    const { weekOptions } = useTaskTemporality(taskStart, recurringTask);

    const recurentTaskValue = recurringTask.custom.customValue;
    const repeatEvery = recurringTask.custom.repeatEvery;
    const selectedOption = recurringTask.custom.selectedOptions.value;
    const weekDays = recurringTask.custom.days;

    const repeatEveryTitleSingular: Record<RecurentTaskCustomValues, string> = {
        day: TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_DAY_SINGULAR,
        week: TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_WEEK_SINGULAR,
        everyMonth: TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_MONTH_SINGULAR,
        everyYear: TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_YEAR_SINGULAR,
    };

    const repeatEveryTitlePlural: Record<RecurentTaskCustomValues, string> = {
        day: TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_DAY_PLURAL,
        week: TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_WEEK_PLURAL,
        everyMonth: TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_MONTH_PLURAL,
        everyYear: TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_PERIODICAL_YEAR_PLURAL,
    };

    const repeatEveryTitle =
        repeatEvery > 1
            ? t(repeatEveryTitlePlural[recurentTaskValue]).replace("{0}", `${repeatEvery}`)
            : t(repeatEveryTitleSingular[recurentTaskValue]).replace("{0}", `${repeatEvery}`);

    const getHours = (hour: string, maxHour?: string) => {
        if (!maxHour?.length) return hour;

        return `${hour} - ${maxHour}`;
    };

    return (
        <div className="taskTemporalityPeriodicalDisabled">
            <TaskTemporalityTitleDisabled title={t(TaskTranslations.TASK_TEMPORALITY_PERIODICAL)} />

            <TaskStart
                onChange={onChange}
                taskStart={taskStart}
                customError={customError.taskStart}
                disabled={isDisabled}
                min={min}
                label={taskstartLabel}
                hint={hint}
                readonly
            />
            <Divider />

            <Label label={t(TaskTemporalityReadOnlyTranslations.TASK_TEMPORALITY_HOURS_TITLE)} />
            <div className="taskTemporalityPeriodicalDisabled__hours">
                {taskHours.map(({ id, hour, maxHour }) => (
                    <ReadOnlyInput key={id} options={{ width: "auto" }} value={getHours(hour, maxHour)} type="date" />
                ))}
            </div>

            <Divider />

            {(recurentTaskValue === "everyMonth" || recurentTaskValue === "week") && (
                <Label label={t(TranslationKeys.REPEAT_EVERY)} />
            )}

            <div className="taskTemporalityPeriodicalDisabled__month">
                <ReadOnlyInput value={repeatEveryTitle} options={{ width: "auto" }} />
                {recurentTaskValue === "everyMonth" && (
                    <ReadOnlyInput
                        value={weekOptions.find(({ value }) => value === selectedOption)?.label || ""}
                        options={{ width: "auto" }}
                    />
                )}
            </div>

            {recurentTaskValue === "week" && (
                <div className="taskTemporalityPeriodicalDisabled__weekDays">
                    {weekDays.map(({ value, id, isActive }) => (
                        <DayBubble active={isActive} letter={value} onClick={() => {}} key={id} readonly />
                    ))}
                </div>
            )}

            <Divider />

            {!!taskStart.length && (
                <TaskTemporalityPeriodicalEnds
                    customError={customError}
                    finish={finish}
                    isDisabled={isDisabled}
                    min={taskStart}
                    onChange={onChange}
                />
            )}
        </div>
    );
};
