import { SortedTypeModel } from "app/components_v2/Table/types";
import { IssueGridSFModel } from "../state/context/issueGridContext";

export const INITIAL_ISSUE_SORT_FIELD = "id";
export const INITIAL_ISSUE_SORT_DIRECTION: SortedTypeModel = "default";
export const INITIAL_ISSUE_SF_VALUES: IssueGridSFModel = {
    issueStatusState: "BOTH",
    query: "",
    senderUserId: "-1",
    startDate: undefined,
    assetId: "-1",
    endDate: undefined,
    auditInstanceId: "-1",
    issueClassificationId: "-1",
    taskInstanceId: undefined,
};
