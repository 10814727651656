import DashboardHeader from "app/components_v2/DashboardHeader/DashboardHeader";
import { TabPropsReduced } from "app/components_v2/Tabs";
import { DashboardFilter } from "app/components_v2/__filters/DashboardFilter/DashboardFilter";
import { useSession, useTitle } from "app/hooks";
import { DashboardFilterDataMapper } from "app/mappers/DashboardFilterDataMapper";
import { TaskTranslations, TranslationKeys, TranslationTitles } from "app/translation/translationKeys";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TaskDetailModal from "../TaskDetail/TaskDetailModal";
import { DashboardTaskInstanceList } from "./DashboardTaskInstanceList";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { SporadicTaskDetailModal } from "app/components_v2/__modals/SporadicTaskDetailModal/SporadicTaskDetailModal";
import { SecScreen } from "app/shared/Constants";
import { hasPermission } from "app/routes/HelperRoleBasedAccess";
import { useDashbaord } from "../hooks/useDashbaord";
import { DashboardType } from "app/components_v2/__filters/DashboardFilter/types";

export type DashboardTotalCounters = {
    myTasks: number;
    sharedTasks: number;
    sporadicTasks: number;
};

export const NewDashboard = () => {
    const { taskInstanceId, taskId } = useParams();
    const { t } = useTranslation();
    const session = useSession();
    const {
        dashboardListProps,
        currentTab,
        isSecondaryFilterOpen,
        userInfo,
        counters,
        currentFilterButton,
        onChangeFilterButton,
        isUserInfoLoading,
        setIsSecondaryFilterOpen,
        onGoBack,
        onFilterSave,
        onComplete,
        items,
        filterData,
        isDashboardFocus,
        initFilterData,
        onCloseTaskDetail,
        onCompleteSporadicTask,
        onChangeTab,
    } = useDashbaord();

    useTitle(
        t(TranslationTitles.DASHBOARD_PAGE_TITLE).replace(
            "{0}",
            session?.workingCompany?.companyName || t(TranslationTitles.FOR_LINK)
        )
    );

    const tabs: TabPropsReduced[] = [
        {
            text: `${t(TranslationKeys.MY_TASKS)}`,
            onClick: onChangeTab,
            rightCount: counters.myTasks,
            children: (
                <DashboardTaskInstanceList
                    {...dashboardListProps}
                    filterData={DashboardFilterDataMapper(filterData, currentFilterButton)}
                    onChangePageIndex={dashboardListProps.onChangePageIndex}
                    currentTab={currentTab}
                />
            ),
            type: "dark",
            hidden: isDashboardFocus,
        },
        {
            text: `${t(TranslationKeys.SHARED_TASKS)}`,
            onClick: onChangeTab,
            children: (
                <DashboardTaskInstanceList
                    {...dashboardListProps}
                    filterData={DashboardFilterDataMapper(filterData, currentFilterButton)}
                    onChangePageIndex={dashboardListProps.onChangePageIndex}
                    currentTab={currentTab}
                />
            ),
            rightCount: counters.sharedTasks,
            type: "dark",
            hidden: isDashboardFocus,
        },
        {
            text: t(TaskTranslations.TASK_TEMPORALITY_SPORADIC),
            onClick: onChangeTab,
            children: (
                <DashboardTaskInstanceList
                    {...dashboardListProps}
                    filterData={DashboardFilterDataMapper(filterData, currentFilterButton)}
                    onChangePageIndex={dashboardListProps.onChangePageIndex}
                    currentTab={currentTab}
                />
            ),
            rightCount: counters.sporadicTasks,
            type: "dark",
            hidden: isDashboardFocus || currentFilterButton === 2 || !hasPermission(SecScreen.SPORADIC_TASK),
        },
    ];

    const currentTabToType: Record<number, DashboardType> = {
        0: "MYTASKS",
        1: "SHARED",
        2: "SPORADIC",
    };

    return (
        <>
            <PageContainer
                header={
                    <DashboardHeader
                        currentTab={currentTab}
                        tabs={tabs}
                        onSecundaryFilterClick={() => setIsSecondaryFilterOpen(true)}
                        currentFilterButton={currentFilterButton}
                        filterButtonItems={items}
                        onChangeFilterButton={onChangeFilterButton}
                        {...userInfo}
                        isLoading={isUserInfoLoading}
                        showArroBack={isDashboardFocus}
                        isDashbordListLoading={dashboardListProps.isLoading}
                        goBack={onGoBack}
                    />
                }
                fullHeight
            >
                {tabs[currentTab].children}
            </PageContainer>
            <DashboardFilter
                filterData={filterData}
                initialFilterData={initFilterData}
                isOpen={isSecondaryFilterOpen}
                onSave={onFilterSave}
                onCancel={() => {
                    setIsSecondaryFilterOpen(false);
                }}
                onClickOutside={() => {
                    setIsSecondaryFilterOpen(false);
                }}
                type={currentTabToType[currentTab] || "SPORADIC"}
                isAllChecked={filterData.isAllChecked}
            />
            {taskInstanceId && (
                <TaskDetailModal
                    onComplete={onComplete}
                    taskInstanceId={taskInstanceId}
                    onClose={onCloseTaskDetail}
                    editTaskDetail
                    hideFeedBack={true}
                />
            )}
            {taskId && (
                <SporadicTaskDetailModal
                    id={Number(taskId)}
                    onClose={onCompleteSporadicTask}
                    onComplete={onCompleteSporadicTask}
                    options={{ isEditable: true }}
                />
            )}
        </>
    );
};
