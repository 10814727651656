import { ActionButtonsModel } from "app/components_v2/Table/TableTabHeader/TableTabHeader";
import { Badge } from "app/components_v2/Badge/Badge";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { ColumnsType } from "app/components_v2/Table/types";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { convertUTCtoLocaleDate, getHourSecWithString } from "app/helpers";
import { GenericModal } from "app/components_v2/__modals/base/GenericModal/GenericModal";
import { isEqual } from "lodash";
import { MobileTaskRow } from "./components/MobileTaskRow/MobileTaskRow";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { PaginationDefaults, SecScreen } from "app/shared/Constants";
import { PlanAPPCCGridHeader } from "app/components_v2/PlanAPPCCGridHeader/PlanAPPCCGridHeader";
import { ReasignModal } from "app/components_v2/__modals/ReasignModal/ReasignModal";
import { ResponsibleAvatar } from "./components/ResponsibleAvatar/ResponsibleAvatar";
import { SelectAssignedModel } from "app/models/02-TAR/TaskPage";
import { selectBadgeTitle } from "app/helpers/task/selectBadgeTitle";
import { SelectWeekDayBubbles } from "app/components_v2/__inputs";
import { TabHeaderVariants } from "app/components_v2/__containers/TabHeader/types";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import { Tag } from "app/components_v2/Tag/Tag";
import { TaskFilter } from "app/components_v2/__filters/TaskFilter/TaskFilter";
import { TaskListDto } from "app/dtos/02-TAR/Task/TaskListDto";
import { useSession, useTitle } from "app/hooks";
import { useTaskGrid } from "./hooks/useTaskGrid";
import { useTranslation } from "react-i18next";
import { WizardTask } from "app/components_v2/wizards/WizardTask/WizardTask";
import {
    faCamera,
    faCameraSlash,
    faClipboardList,
    faClipboardListCheck,
    faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import {
    faCirclePlus,
    faClone,
    faMagnifyingGlass,
    faPen,
    faPeopleArrows,
    faSliders,
    faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import {
    hasPermissionToAdd,
    hasPermissionToDelete,
    hasPermissionToEdit,
    hasPermissionToView,
} from "app/routes/HelperRoleBasedAccess";
import {
    TaskTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
    TranslationTitles,
} from "app/translation/translationKeys";
import { TableInputText } from "app/components_v2/Table/TableInputText/TableInputText";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { CellPhoto } from "app/components_v2/Table/CellPhoto/CellPhoto";

export type CurrentPage = "task" | "plan";

export const TaskGrid = () => {
    const { t } = useTranslation();
    const { translateCustomerTypeKeys, translateCompany } = useCustomerTypeTranslation();
    const {
        checkedRows,
        clearAll,
        closeWizardTask,
        currentPage,
        currentTab,
        data,
        dayList,
        fetch,
        filterIcon,
        handleDeleteModalOpen,
        handleDeleteTasks,
        handleExport,
        handleModalClose,
        handleModalOpen,
        handleNavigate,
        handlePlanChange,
        handleReasign,
        handleSearch,
        initialFilterData,
        isDeleteModalOpen,
        isDeleting,
        isLoading,
        isReasignLoading,
        isReasignModalOpen,
        isWizardTaskOpen,
        onChangePageIndex,
        onCompanyChange,
        onFinishWizard,
        onQueryChange,
        onSave,
        pageIndex,
        planOptions,
        query,
        ref,
        secondaryFilterValues,
        selectCompany,
        selectedPlan,
        setCheckedRows,
        setIsDeleteModalOpen,
        setShowSearchInput,
        setShowSecondaryFilter,
        showSearchInput,
        showSecondaryFilter,
        sortDirection,
        sortField,
        tableTitle,
        tabs,
        taskGridSecScreen,
        title,
        total,
        handleChangeCompany,
        handleSortChange,
    } = useTaskGrid();

    const session = useSession();
    const offset = session?.user.timeZoneOffset;
    const variant: TabHeaderVariants = currentPage === "plan" ? "appcc" : "primary";

    const responsibleTitle: Record<SelectAssignedModel, string> = {
        all: t(TaskTranslations.TASK_ALL_COLUMN),
        user: t(TranslationKeys.WIZARD_HEADER_TASK_RESPONSIBLE),
        department: t(TranslationKeys.DEPARTMENT),
        workingPosition: t(TranslationKeys.WORKING_POSITION),
        subDepartment: t(TranslationKeys.SUBDEPARTMENT),
    };

    const dataIndexResponsible: Record<SelectAssignedModel, string> = {
        user: "User.FirstName",
        department: "Department.Name",
        workingPosition: "WorkingPosition.Name",
        all: "Subdepartment.Name",
        subDepartment: "Subdepartment.Name",
    };

    useTitle(t(currentPage === "plan" ? TranslationTitles.TASK_APPCC_PAGE_TITLE : TranslationTitles.TASK_PAGE_TITLE));

    if (!isLoading) ref.current = secondaryFilterValues.responsable.assignedTo;

    const columns: ColumnsType<TaskListDto>[] = [
        {
            label: t(TranslationKeys.DETAIL),
            sortedType: "default",
            dataIndex: "name",
            className: "taskGrid__detailCell",
            render: (record) => <CellTitle title={record.name} />,
        },
        {
            label: responsibleTitle[ref.current],
            className: "taskGrid__avatar",
            dataIndex: dataIndexResponsible[ref.current],
            sortedType: ref.current === "all" ? undefined : "default",
            render: (record) => <ResponsibleAvatar record={record} tab={ref.current || "all"} />,
        },
        {
            label: translateCompany("SINGULAR"),
            className: "taskGrid__avatar",
            dataIndex: "Company.Name",
            sortedType: "default",
            render: ({ company }) => {
                const { imageURL, name } = company;
                return <CellPhoto name={name} img={imageURL} size="xs" />;
            },
        },
        {
            label: t(TranslationKeys.HOURS),
            dataIndex: "RangeHours",
            sortedType: "default",
            className: "taskGrid__hours",
            alignCenter: true,
            render: (record) => {
                let title = "";
                if (!record.taskRangeHours?.length) return <span className="taskGrid__hoursRow">-</span>;
                record.taskRangeHours.forEach(({ hour, maxHour }) => {
                    title += `${getHourSecWithString(
                        convertUTCtoLocaleDate(new Date(hour), offset ? offset * -60 : undefined),
                        "h"
                    )}${
                        maxHour
                            ? ` - ${getHourSecWithString(
                                  convertUTCtoLocaleDate(new Date(maxHour), offset ? offset * -60 : undefined),
                                  "h"
                              )}`
                            : ""
                    } / `;
                });
                title = title.slice(0, -2);
                return <CellTitle title={title} alignCenter />;
            },
        },
        {
            label: t(TranslationKeys.FREQUENCY),
            dataIndex: "TaskScheduler.TypeCode",
            sortedType: "default",
            className: "taskGrid__typeCode",
            render: ({ taskType, taskScheduler, isDateRange }) => (
                <div className="taskGrid__typeCode__container">
                    {isDateRange || !taskScheduler ? (
                        <Badge variant="blue" title={t(TaskTranslations.TASK_TEMPORALITY_RANGE_DATES)} />
                    ) : taskType === "SPORADIC" ? (
                        <Badge variant="blue" title={t(TaskTranslations.TASK_TEMPORALITY_SPORADIC)} />
                    ) : taskScheduler?.typeCode === "ONE_TIME" ? (
                        <Badge variant="blue" title={selectBadgeTitle(taskScheduler?.typeCode, t)} />
                    ) : taskScheduler?.typeCode === "WEEKLY" ? (
                        <SelectWeekDayBubbles list={dayList(taskScheduler)} readonly size="xs" />
                    ) : (
                        <Badge variant="grey" title={selectBadgeTitle(taskScheduler?.typeCode, t)} />
                    )}
                </div>
            ),
        },
        {
            label: "",
            dataIndex: "icons",
            className: "taskGrid__icons",
            render: (record) => {
                return (
                    <CellIcons
                        variant="ligthGray"
                        icons={[
                            {
                                icon: faTriangleExclamation,
                                hidden: !record.isCritical,
                            },
                            {
                                icon: faClipboardListCheck,
                                hidden: !record.hasCheckList,
                            },
                            {
                                icon: record.hasPhotoExample ? faCamera : faCameraSlash,
                                hidden: !record.isPhotoRequired,
                            },
                            {
                                icon: faClipboardList,
                                hidden: !record.hasDataToReport,
                            },
                        ]}
                    />
                );
            },
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "acciones",
            className: "taskGrid__actions",
            alignCenter: true,
            render: (record) => (
                <CellIcons
                    icons={[
                        {
                            icon: faClone,
                            onClick: () => handleNavigate(`duplicate/${record.id}`),
                            hidden: !hasPermissionToAdd(taskGridSecScreen),
                            title: t(TranslationKeys.DUPLICATE),
                        },
                        {
                            icon: faPen,
                            onClick: () => handleNavigate(`edit/${record.id}`),
                            title: t(TranslationCommon.EDIT),
                        },
                    ]}
                />
            ),
        },
    ];

    const actionButtons: ActionButtonsModel[] = [
        {
            icon: faCirclePlus,
            onClick: () => handleNavigate(`fastNew?companyId=${selectCompany.company.value}`),
            hidden: !hasPermissionToAdd(taskGridSecScreen) || showSearchInput,
            title: t(TranslationCommon.CREATE),
        },
        {
            icon: faPeopleArrows,
            onClick: handleModalOpen,
            disabled: !checkedRows.length,
            hidden: !hasPermissionToEdit(SecScreen.REASIGN_TASK) || showSearchInput,
            title: t(TranslationKeys.REASIGN_TASK),
        },
        {
            icon: faTrash,
            onClick: handleDeleteModalOpen,
            disabled: !checkedRows.length,
            hidden: !hasPermissionToDelete(taskGridSecScreen) || showSearchInput,
            title: t(TranslationCommon.DELETE),
        },
        {
            icon: faMagnifyingGlass,
            onClick: () => setShowSearchInput(true),
            inputComponent: {
                component: (
                    <TableInputText
                        onChange={onQueryChange}
                        value={query}
                        fetch={handleSearch}
                        cleanFunction={handleSearch}
                        variant={variant}
                    />
                ),
                show: showSearchInput,
            },
            title: t(TranslationCommon.SEARCH),
        },
        {
            icon: filterIcon,
            onClick: () => {
                onCompanyChange({ isOpen: true });
                setShowSearchInput(false);
            },
            inputComponent: {
                component: (
                    <TableSelectCompany
                        onChange={handleChangeCompany}
                        value={selectCompany.company}
                        onClickOutside={() => onCompanyChange({ isOpen: false })}
                        variant={variant}
                    />
                ),
                show: selectCompany.isOpen,
            },
            title: translateCustomerTypeKeys(TranslationCommon.CHANGE_COMPANY, { firstReplace: "SINGULAR" }),
            hidden: showSearchInput,
        },
        {
            icon: faSliders,
            onClick: () => setShowSecondaryFilter(true),
            showMarkableIcon: !isEqual(
                {
                    ...initialFilterData,
                    responsable: {
                        ...initialFilterData.responsable,
                        assignedTo: secondaryFilterValues.responsable.assignedTo,
                    },
                },
                secondaryFilterValues
            ),
            title: t(TranslationCommon.FILTER),
            hidden: showSearchInput,
        },
    ];

    return (
        <>
            <PageContainer
                paddingTop={currentPage !== "plan"}
                header={
                    currentPage === "plan" && (
                        <PlanAPPCCGridHeader
                            title={`${title["plan"]} ${
                                selectCompany.company.value === "-1" ? `(${selectCompany.company.label})` : ""
                            }`}
                            onChange={handlePlanChange}
                            planOptions={planOptions}
                            selectedValue={selectedPlan}
                        />
                    )
                }
            >
                <TaskFilter
                    filterData={secondaryFilterValues}
                    initialFilterData={{
                        ...initialFilterData,
                        responsable: {
                            ...initialFilterData.responsable,
                            assignedTo: secondaryFilterValues.responsable.assignedTo,
                        },
                    }}
                    showSecondaryFilter={showSecondaryFilter}
                    companyId={selectCompany.company.value}
                    onCancel={() => setShowSecondaryFilter(false)}
                    onSave={onSave}
                    variant={variant}
                />
                <TableCollapsable
                    cols={columns.filter((col) => !col.hidden)}
                    data={data}
                    variant={variant}
                    placeholder={
                        <TableError description={t(TranslationKeys.TABLE_EMPTY_STATE_TASK)} icon={faClipboardList} />
                    }
                    title={tableTitle}
                    isLoading={isLoading}
                    pageIndex={pageIndex}
                    pageSize={PaginationDefaults.PAGE_SIZE}
                    total={total}
                    onChangePageIndex={(pi) => {
                        onChangePageIndex(pi);
                        fetch({ pi: pi - 1 });
                    }}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    onChangeSortDirectionField={handleSortChange}
                    tabs={tabs}
                    currentTab={currentTab}
                    onDoubleClick={(row) => handleNavigate(`edit/${row.id}`)}
                    actionButtons={actionButtons}
                    clearAll={clearAll}
                    allowCheckbox={
                        hasPermissionToView(session, SecScreen.REASIGN_TASK) || hasPermissionToDelete(taskGridSecScreen)
                    }
                    onClickExportCsv={handleExport}
                    onCheckboxChange={setCheckedRows}
                    mobileBody={(row, _i, rowPosition, isChecked, onChangeCheckbox) => (
                        <MobileTaskRow
                            data={row}
                            key={row.id}
                            isChecked={isChecked}
                            onChangeCheckbox={onChangeCheckbox}
                            selectedTab={ref.current || ""}
                            rowPosition={rowPosition}
                        />
                    )}
                />
                {isReasignModalOpen && (
                    <ReasignModal
                        onClose={handleModalClose}
                        onReasign={handleReasign}
                        isLoading={isReasignLoading}
                        variant={variant}
                    />
                )}
                {isDeleteModalOpen && (
                    <ConfirmModal
                        onConfirmText={t(TranslationCommon.DELETE)}
                        onCloseText={t(TranslationCommon.CANCEL)}
                        onClose={() => setIsDeleteModalOpen(false)}
                        onConfirm={handleDeleteTasks}
                        description={
                            currentPage === "plan"
                                ? t(TranslationModals.DELETE_REGISTRY_DESCRIPTION)
                                : t(TranslationModals.DELETE_TASK_DESCRIPTION)
                        }
                        title={
                            currentPage === "plan"
                                ? t(TranslationKeys.DELETE_REGISTER)
                                : t(TranslationModals.DELETE_TASK_TITLE)
                        }
                        isLoading={isDeleting}
                        type="delete"
                        variant={variant}
                    >
                        {!!checkedRows.length && (
                            <div className="taskGrid__deleteModal">
                                {checkedRows.map((task) => (
                                    <Tag
                                        key={task.id}
                                        message={task.name || ""}
                                        width="auto"
                                        pointer={false}
                                        variant="red"
                                    />
                                ))}
                            </div>
                        )}
                    </ConfirmModal>
                )}
                {isWizardTaskOpen && (
                    <GenericModal
                        header={{
                            title: t(TranslationKeys.NEW_TASK),
                            onClose: closeWizardTask,
                            variant,
                        }}
                        size="lg"
                        hideChildrenPadding
                    >
                        <WizardTask
                            onFinish={onFinishWizard}
                            onClose={closeWizardTask}
                            hasPlan={currentPage === "plan"}
                        />
                    </GenericModal>
                )}
            </PageContainer>
        </>
    );
};
