import { CustomDays, WizardTaskAllSteps } from "app/models/02-TAR/TaskWizard";
import i18n from "app/translation/i18n";
import { TranslationKeys } from "app/translation/translationKeys";

export const days: CustomDays[] = [
    {
        isActive: false,
        value: i18n.t(TranslationKeys.DAY_WEEK_MONDAY_FIRST_LETTER),
        id: 0,
    },
    {
        isActive: false,
        value: i18n.t(TranslationKeys.DAY_WEEK_TUESDAY_FIRST_LETTER),
        id: 1,
    },
    {
        isActive: false,
        value: i18n.t(TranslationKeys.DAY_WEEK_WEDNESDAY_FIRST_LETTER),
        id: 2,
    },
    {
        isActive: false,
        value: i18n.t(TranslationKeys.DAY_WEEK_THURSDAY_FIRST_LETTER),
        id: 3,
    },
    {
        isActive: false,
        value: i18n.t(TranslationKeys.DAY_WEEK_FRIDAY_FIRST_LETTER),
        id: 4,
    },
    {
        isActive: false,
        value: i18n.t(TranslationKeys.DAY_WEEK_SATURDAY_FIRST_LETTER),
        id: 5,
    },
    {
        isActive: false,
        value: i18n.t(TranslationKeys.DAY_WEEK_SUNDAY_FIRST_LETTER),
        id: 6,
    },
];

export const initialStepsValues: WizardTaskAllSteps = {
    // Step 1
    fK_BaseTask: null,
    taskTitle: "",
    taskDesc: "",
    belongsToAPPCC: false,
    fK_PlanAPPCC: "",

    // Step 2
    companyForTask: { label: "", value: "", worksWithQr: false },
    DepartmentForTask: { label: "", value: "" },
    SubDepartementForTask: { label: "", value: "" },
    userForTask: { label: "", value: "" },
    workingPositionForTask: { label: "", value: "" },
    // Step 3
    somethingToReportCheckBox: false,
    reportType: "NONE",
    somethingToReportDesc: "",
    somethingToReportCheckBoxImage: false,
    somethingToReportImage: "",
    imageBase64: "",
    isPhotoRequired: false,
    minPhotosAllowed: null,
    criticalTask: false,
    reportData: [],
    activeReassignedTo: null,
    checkList: [],
    dynamicFields: [],
    // Step 4
    temporalityType: "NONE",
    taskStart: "",
    taskHours: [],
    taskTemporalityDateRange: {
        dbId: null,
        custom: {
            dateRange: [],
        },
        monthly: {
            day: "",
            customDays: { startDay: "", endDay: "" },
            value: "FIRST_X_DAYS",
            text: "",
        },
        repeatEvery: "1",
        text: "Semanal",
        value: "WEEKLY",
        weeklyDays: days,
    },
    recurringTask: {
        isRecurrent: true,
        options: [],
        value: "everyDay",
        custom: {
            customValue: "day",
            days: days,
            repeatEvery: 1,
            selectedOptions: { text: "", value: "" },
        },
        text: "everyDay",
    },
    finish: {
        checked: "never",
        value: null,
    },
    taskType: "NORMAL",
};
