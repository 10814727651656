import { SelectOptions } from "app/components_v2/__inputs";
import { TaskInstanceFieldModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldModel2";
import { TaskInstanceFieldValueModel } from "app/models/02-TAR/TaskInstance/TaskInstanceFieldValueModel";
import { TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type DropdownTaskFieldProps = {
    field: TaskInstanceFieldModel;
    readonly?: boolean;
    errorMessage?: string;
    onChange: (tif: TaskInstanceFieldModel) => void;
};

export const DropdownTaskField: FC<DropdownTaskFieldProps> = ({ field, onChange, readonly, errorMessage }) => {
    const { t } = useTranslation();

    const { label, taskInstanceFieldValues, id } = field;

    return (
        <SelectOptions
            id={String(id)}
            isMulti={false}
            onChange={({ value }) => {
                const newTIFieldValues: TaskInstanceFieldValueModel[] = taskInstanceFieldValues.map((tifv) => ({
                    ...tifv,
                    value: tifv.id === Number(value) ? "true" : "false",
                }));
                onChange({
                    ...field,
                    taskInstanceFieldValues: newTIFieldValues,
                });
            }}
            label={label}
            errorMessage={errorMessage}
            readonly={readonly}
            selectedValue={
                taskInstanceFieldValues.find(({ value }) => value)?.id
                    ? String(taskInstanceFieldValues.find(({ value }) => value === "true")?.id)
                    : ""
            }
            options={taskInstanceFieldValues.map(({ id, label }) => ({
                label: t(label),
                value: String(id),
            }))}
            placeholder={t(TranslationKeys.DYNAMIC_FIELD_PLACEHOLDER_SELECT)}
        />
    );
};
