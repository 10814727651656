import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { PatchNotesTranslations } from "app/translation/translationKeys";
import PatchNoteVersionService from "app/services/01-SEG/PatchNoteVersionService";
import { INITIAL_PATCH_NOTE } from "../constants/PatchNotesConstants";
import { PatchNoteVersionModel } from "app/models/01-SEG/PatchNoteVersion/PatchNoteVersionModels";
import { SortedTypeModel } from "app/components_v2/Table/types";
import { PatchNoteVersionGridModel } from "../models/PatchNoteVersionGridModel";
import { ActionType } from "app/models/FormComponentsModel";
import { PatchNotesModalContext } from "app/state/context/PatchNotesModalContext/PatchNotesModalContext";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";

export const usePatchNotes = () => {
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();
    const { openModal } = useContext(PatchNotesModalContext);

    const { t } = useTranslation();

    const [patchNoteVersionList, setPatchNoteVersionList] = useState<PatchNoteVersionModel[]>([]);
    const [patchNote, setPatchNote] = useState<PatchNoteVersionModel>(INITIAL_PATCH_NOTE);
    const [total, setTotal] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [sortField, setSortField] = useState<string>();
    const [sortDirection, setSortDirection] = useState<SortedTypeModel>("default");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSavingLoading, setIsSavingLoading] = useState<boolean>(false);
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [isLoadingConfirmModal, setIsLoadingConfirmModal] = useState<boolean>(false);

    const getPatchNoteVersions = async ({ pi, sortF, sortD }: PatchNoteVersionGridModel = {}) => {
        setIsLoading(true);

        const { data, status, getParsedError } = await PatchNoteVersionService.GetData({
            pageIndex: pi !== undefined ? pi - 1 : 0,
            sortField: sortF || sortField,
            sortDirection: sortD || sortDirection,
        });

        if (handleErrorManager(status(), getErrorMessage(getParsedError()))) {
            setPatchNoteVersionList([]);
            setTotal(0);
            setIsLoading(false);
        }
        setPatchNoteVersionList(data.list);
        setTotal(data.count);
        setIsLoading(false);
    };

    const handleSubmit = async (type: ActionType, patchNote: PatchNoteVersionModel) => {
        setIsSavingLoading(true);
        const service =
            type === "create"
                ? PatchNoteVersionService.Save(patchNote)
                : PatchNoteVersionService.Edit(patchNote.id, patchNote);
        const { status, getParsedError } = await service;
        if (handleErrorManager(status(), getErrorMessage(getParsedError()))) {
            setIsSavingLoading(false);
            return;
        }
        handleSuccessManager(
            t(
                type === "create"
                    ? PatchNotesTranslations.PATCH_NOTE_CREATED_SUCCESSFULLY
                    : PatchNotesTranslations.PATCH_NOTE_EDITED_SUCCESSFULLY
            )
        );
        setIsSavingLoading(false);
        getPatchNoteVersions({ pi: pageIndex, sortF: sortField, sortD: sortDirection });
        const closeModal = type === "create" ? setShowCreateModal : setShowEditModal;
        closeModal(false);
    };

    const onShowCreateModal = (value: boolean) => {
        setPatchNote(INITIAL_PATCH_NOTE);
        setShowCreateModal(value);
    };

    const onShowEditModal = async (id: number) => {
        const { data, status, getParsedError } = await PatchNoteVersionService.GetOne(id);
        if (handleErrorManager(status(), getErrorMessage(getParsedError()))) return;
        setPatchNote(data);
        setShowEditModal(true);
    };

    const onShowDeleteModal = (patchNote: PatchNoteVersionModel) => {
        setPatchNote(patchNote);
        setShowDeleteModal(true);
    };

    const onShowSendModal = (id: number) => {
        setPatchNote({ ...patchNote, id });
        openModal(id);
    };

    const onPageIndexChange = (value: number) => {
        setPageIndex(value);
        getPatchNoteVersions({ pi: value });
    };

    const onSortDirectionFieldChange = (sortF: string, sortD?: SortedTypeModel) => {
        setSortField(sortF);
        if (sortD) setSortDirection(sortD);
        getPatchNoteVersions({ sortF, sortD: sortD || sortDirection });
    };

    const onDelete = async (id: number) => {
        setIsSavingLoading(true);
        setIsLoadingConfirmModal(true);

        const { status, getParsedError } = await PatchNoteVersionService.Delete(id);

        setIsSavingLoading(false);
        setIsLoadingConfirmModal(false);
        setShowDeleteModal(false);

        if (handleErrorManager(status(), getErrorMessage(getParsedError()))) return;

        getPatchNoteVersions();
    };

    const onCloseCreateModal = () => {
        getPatchNoteVersions({ pi: pageIndex, sortF: sortField, sortD: sortDirection });
        setShowCreateModal(false);
    };

    const onCloseEditModal = () => {
        getPatchNoteVersions({ pi: pageIndex, sortF: sortField, sortD: sortDirection });
        setShowEditModal(false);
    };

    const onCloseDeleteModal = () => setShowDeleteModal(false);

    return {
        getPatchNoteVersions,
        handleSubmit,
        isLoading,
        isSavingLoading,
        onCloseCreateModal,
        onCloseDeleteModal,
        onCloseEditModal,
        onDelete,
        onPageIndexChange,
        onShowCreateModal,
        onShowDeleteModal,
        onShowEditModal,
        onSortDirectionFieldChange,
        pageIndex,
        patchNote,
        patchNoteVersionList,
        showCreateModal,
        showDeleteModal,
        showEditModal,
        sortDirection,
        sortField,
        total,
        onShowSendModal,
        isLoadingConfirmModal,
    };
};
