import { getMonthName } from "app/helpers";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { AssetModelFormValues } from "app/models/05-QUA/AssetModels";
import AssetService from "app/services/05-QUA/AssetService";
import { PrivatePaths, SecScreen } from "app/shared/Constants";
import {
    AssetTranslations,
    TranslationCommon,
    TranslationKeys,
    TranslationModals,
} from "app/translation/translationKeys";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { v4 } from "uuid";
import { AssetFormValidationsModel } from "../models/AssetFormModel";
import { fillAsset } from "../utilities/fillAsset";
import { mapAsset } from "../utilities/mapAsset";
import { useAssetValidate } from "./useAssetValidate";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import SensorEntriesService from "app/services/04-SEN/SensorEntriesService";
import { useErrorManager } from "app/hooks/ErrorHandler/useErrorManager";
import { useSuccessManager } from "app/hooks/SuccessHandler/useSuccessManager";

import { hasPermissionToView } from "app/routes/HelperRoleBasedAccess";
import { AssetCategory } from "../../AssetGrid/models/AssetGridModel";
import AssetRangeService from "app/services/05-QUA/AssetRangeService";
const INITIAL_FORM_VALUES: AssetModelFormValues = {
    assetFields: [],
    assetId: "",
    description: "",
    fK_AssetType: 0,
    fK_Company: 0,
    fK_PlanAPPCC: 0,
    name: "",
    isRelatedWithTasks: false,
    notWorking: false,
    allowSensorSerialNumber: false,
    sensorSerialNumber: "",
};

const INITIAL_ERROR_MESSAGES: AssetFormValidationsModel = {
    errorCompany: "",
    errorDynamicAsset: {
        dynamicAssets: [],
        errorMessage: "",
    },
    errorName: "",
    errorType: "",
    errorPlanAPPCC: "",
    errorAssetId: "",
    errorSerialNumber: "",
};

export const useAssetForm = () => {
    const { assetId } = useParams();
    const { pathname } = useLocation();
    const nav = useNavigate();
    const session = useSession();
    const { t } = useTranslation();
    const { handleToast, removeToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { handleErrorManager } = useErrorManager();
    const { handleSuccessManager } = useSuccessManager();
    const { validate } = useAssetValidate();
    const [urlParams] = useSearchParams();

    const assetCategory: AssetCategory = pathname.includes(PrivatePaths.ASSETS_RANGE) ? "ASSET_RANGE" : "ASSET";
    const assetPrivatePath = assetCategory === "ASSET" ? PrivatePaths.ASSETS : PrivatePaths.ASSETS_RANGE;
    const fkPlan = urlParams.get("planId") && urlParams.get("planId") != "-1" ? Number(urlParams.get("planId")) : 0;
    const serialNumber = urlParams.get("serialNumber");
    const hasPermissionsToViewSensors = hasPermissionToView(session, SecScreen.AUTOMATIC_RECORD);
    const assetService = assetCategory === "ASSET" ? AssetService : AssetRangeService;

    const [data, setData] = useState<AssetModelFormValues>({
        ...INITIAL_FORM_VALUES,
        fK_PlanAPPCC: fkPlan,
        assetId: serialNumber || "",
    });
    const [dataCopy, setDataCopy] = useState<AssetModelFormValues>({
        ...INITIAL_FORM_VALUES,
        fK_PlanAPPCC: fkPlan,
        assetId: serialNumber || "",
    });
    const [validations, setValidations] = useState<AssetFormValidationsModel>(INITIAL_ERROR_MESSAGES);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isAssetDeleted, setIsAssetDeleted] = useState<boolean>(false);
    const [isAssetDeleting, setIsAssetDeleting] = useState<boolean>(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
    const [isAssetDeleteModalOpen, setisAssetDeleteModalOpen] = useState<boolean>(false);
    const [isReplaceSensorEntriesModalOpen, setIsReplaceSensorEntriesModalOpen] = useState<boolean>(false);
    const [sensorEntriesCount, setSensorEntriesCount] = useState<number>(0);
    const [isSensorLoading, setIsSensorLoading] = useState<boolean>(false);

    const handleCloseDeleteModal = () => setisAssetDeleteModalOpen(false);
    const handleOpenDeleteModal = () => setisAssetDeleteModalOpen(true);

    const isEditPathName = useCallback(() => {
        return pathname.split("/").includes("edit");
    }, [pathname]);

    const isEditPage = !!(isEditPathName() && assetId);
    const [isAssetLoading, setIsAssetLoading] = useState<boolean>(isEditPage);

    const handleInputChange = (value: Partial<AssetModelFormValues>) => setData((prev) => ({ ...prev, ...value }));

    const getAsset = async () => {
        setIsAssetLoading(true);
        const { data: dataSr, status } = await assetService.GetOne(Number(assetId));
        const doNotHavePermissionsAndHaveSensor = !hasPermissionsToViewSensors && !!dataSr?.sensorSerialNumber.length;

        if (!status() || doNotHavePermissionsAndHaveSensor) {
            handleToast({
                title: doNotHavePermissionsAndHaveSensor
                    ? t(AssetTranslations.ASSET_NOT_FOUND)
                    : t(TranslationCommon.FAILED_DATA_LOADED),
                type: "alert",
                variant: "danger",
            });
            handleNavigate(`/${assetPrivatePath}`);
            setIsAssetLoading(false);
            return;
        }

        setIsAssetDeleted(!!dataSr.isDeleted);
        const dataMapped = fillAsset(dataSr);
        setData(dataMapped);
        setDataCopy(dataMapped);
        setIsAssetLoading(false);
    };

    const validation = () => {
        const { isValid, validations: newValidations } = validate({ data, validations, assetCategory });
        setValidations(newValidations);

        if (
            handleErrorManager(
                isValid,
                t(TranslationModals.ASSET_FAILED_TITLE),
                t(TranslationModals.ASSET_FAILED_DESCRIPTION)
            )
        )
            return false;

        return true;
    };

    const handleNavigate = (path: string) => {
        const planId = urlParams.get("planId");
        if (planId) {
            nav(`${path}?planId=${planId}`);
            return;
        }
        nav(`${path}`);
    };

    const handleSubmit = async () => {
        if (!validation()) return;

        if (!!data.sensorSerialNumber.length) {
            await handleCheckExistingSensorSerialNumber(data.sensorSerialNumber);
            return;
        }

        await handleSave();
    };

    const handleSave = async () => {
        if (isEditPage) {
            await handleUpdate();
            return;
        }

        await handleCreate();
    };

    const handleCreate = async () => {
        setIsSaving(true);
        const assetToBack = mapAsset({
            data,
            customerInstanceId: session?.user.customerInstanceId || 0,
            fkAsset: null,
        });
        const { data: dataSr, status } = await assetService.Save(assetToBack);
        if (handleErrorManager(status(), t(TranslationModals.FAILED_SAVE))) {
            setIsSaving(false);
            return;
        }
        handleSuccessManager(t(TranslationModals.SUCCESS_SAVE));

        const dataMapped = fillAsset(dataSr);
        setData(dataMapped);
        setDataCopy(dataMapped);
        setIsSaving(false);
        handleNavigate(`/${assetPrivatePath}/edit/${dataSr.id}`);
    };

    const handleUpdate = async () => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        const toastId = v4();
        const day = tomorrow.getDate();
        const month = getMonthName(tomorrow.toString());
        handleToast({
            title: t(TranslationModals.REGENERATING_TASKS_TITLE),
            subtitle: t(TranslationModals.GENERATING_TASKS_DESCRIPTION)
                .replace("{0}", String(day))
                .replace("{1}", month),
            variant: "primary",
            type: "alert",
            isLoading: true,
            id: toastId,
        });

        setIsSaving(true);

        const assetToBack = mapAsset({
            data,
            customerInstanceId: session?.user.customerInstanceId || 0,
            fkAsset: Number(assetId),
        });
        const { status, getParsedError } = await assetService.Update(assetToBack, Number(assetId));
        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            removeToast(toastId);
            setIsSaving(false);
            return;
        }
        removeToast(toastId);
        handleSuccessManager(t(TranslationModals.SUCCESS_SAVE));

        setIsSaving(false);
        handleNavigate(`/${assetPrivatePath}`);
    };

    const handleCheckExistingSensorSerialNumber = async (sensorSerialNumber: string) => {
        setIsSensorLoading(true);
        const { data, status, getParsedError } = await SensorEntriesService.GetSensorEntriesCount(sensorSerialNumber);

        const errorMessage = getErrorMessage(getParsedError());
        if (handleErrorManager(status(), errorMessage)) {
            setIsSensorLoading(false);
            return;
        }

        if (data == null) {
            await handleSave();
            setIsSensorLoading(false);
            return;
        }
        setSensorEntriesCount(data);
        setIsSensorLoading(false);
        setIsReplaceSensorEntriesModalOpen(true);
    };

    const handleCancelModal = () => {
        setData(dataCopy);
        setIsCancelModalOpen(false);
    };

    const handleDelete = async () => {
        if (!assetId) return;
        if (isAssetDeleted) {
            await activateAsset(assetId);
            return;
        }

        await deactivateAsset(assetId);
    };

    const activateAsset = async (id: string) => {
        setIsAssetDeleting(true);
        const { status } = await assetService.Activate(id);
        if (handleErrorManager(status(), t(TranslationKeys.FAILED_ACTIVE_ASSET))) {
            setIsAssetDeleting(false);
            return;
        }

        handleSuccessManager(t(TranslationKeys.SUCCESS_ACTIVE_ASSET));
        setIsAssetDeleting(false);
        setIsAssetDeleted(false);
    };

    const deactivateAsset = async (id: string) => {
        setIsAssetDeleting(true);
        const { status } = await assetService.Delete(id);
        if (handleErrorManager(status(), t(TranslationKeys.FAILED_DEACTIVE_ASSET))) {
            setIsAssetDeleting(false);
            return;
        }

        handleSuccessManager(t(TranslationKeys.SUCCESS_DEACTIVE_ASSET));
        handleInputChange({ isRelatedWithTasks: false });
        setIsAssetDeleting(false);
        setIsAssetDeleted(true);
    };

    useEffect(() => {
        if (isEditPage) getAsset();
    }, []);

    useEffect(() => {
        if (serialNumber == null) return;
        setData({ ...INITIAL_FORM_VALUES, sensorSerialNumber: serialNumber, allowSensorSerialNumber: true });
    }, [serialNumber]);

    return {
        assetId: assetId != null ? Number(assetId) : undefined,
        data,
        dataCopy,
        handleCancelModal,
        handleCloseDeleteModal,
        handleDelete,
        handleOpenDeleteModal,
        handleSave,
        handleSubmit,
        isAssetDeleted,
        isAssetDeleteModalOpen,
        isAssetDeleting,
        isAssetLoading,
        isCancelModalOpen,
        isEditPage,
        isReplaceSensorEntriesModalOpen,
        isSaving,
        onInputChange: handleInputChange,
        sensorEntriesCount,
        setIsCancelModalOpen,
        setIsReplaceSensorEntriesModalOpen,
        validations,
        isSensorLoading,
        assetCategory,
    };
};
