import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useIssueGrid } from "../hooks/useIssueGrid";
import { useState } from "react";
import {
    faClose,
    faFilter,
    faMagnifyingGlass,
    faPen,
    faSearch,
    faSliders,
    faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";
import {
    IssueTranslation,
    TranslationCommon,
    TranslationKeys,
    TranslationTitles,
} from "app/translation/translationKeys";
import { PaginationDefaults, SecScreen } from "app/shared/Constants";
import { INITIAL_ISSUE_SF_VALUES, INITIAL_ISSUE_SORT_DIRECTION } from "../constants/issueConstants";
import { hasPermission } from "app/routes/HelperRoleBasedAccess";
import { convertUTCtoLocaleDate, getFullDateWithTimeReadable } from "app/helpers";
import { getIssueTableErrorCode } from "../helpers/getIssueTableError";
import { ColumnsType } from "app/components_v2/Table/types";
import { currentTabToIssueType } from "../helpers/currentTabToIssueType";
import { IssueGridSFModel } from "../state/context/issueGridContext";
import { PageContainer } from "app/components_v2/__containers/PageContainer/PageContainer";
import { TableCollapsable } from "app/components_v2/Table/TableCollapsable/TableCollapsable";
import { TableError } from "app/components_v2/Table/TableError/TableError";
import { Badge } from "app/components_v2/Badge/Badge";
import { BadgeVariant } from "app/components_v2/Badge/badgeTypes";
import { IssueFilter } from "app/components_v2/__filters/IssueFilter/IssueFilter";
import { Input } from "app/components_v2/__inputs";
import { TableSelectCompany } from "app/components_v2/Table/TableSelectCompany/TableSelectCompany";
import { MobileIssueRow } from "./components/MobileIssueRow/MobileIssueRow";
import { CellTitle } from "app/components_v2/Table/CellTitle/CellTitle";
import { CellIcons } from "app/components_v2/Table/CellIcons/CellIcons";
import { useTitle } from "app/hooks";
import { OpenCloseStatusCode } from "app/shared/types/OpenCloseStatusCode";
import { isEqual } from "lodash";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { CellWithAvatar } from "app/components_v2/Table/CellWithAvatar/CellWithAvatar";
import { IssueListDto } from "app/dtos/05-QUA/Issue/IssueListDto";

export const IssueGrid = () => {
    const { t } = useTranslation();
    const nav = useNavigate();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    useTitle(t(TranslationTitles.ISSUE_PAGE_TITLE));

    const {
        onChangePageIndex,
        pageIndex,
        currentTab,
        data,
        getData,
        isLoading,
        total,
        tabs,
        sortDirection,
        sortField,
        handleSortChange,
        secondaryFilterValues,
        onSFChange,
        onQuerySearch,
        showSearchInput,
        setShowSearchInput,
        handleCleanFetch,
        onCompanyChange,
        selectCompany,
        handleExport,
        handleCompanyChange,
    } = useIssueGrid();

    const { query, issueStatusState } = secondaryFilterValues;

    const [isSecondaryFilterOpen, setIsSecondaryFilterOpen] = useState<boolean>(false);

    const columns: ColumnsType<IssueListDto>[] = [
        {
            label: t(TranslationKeys.ISSUE_ID),
            sortedType: "default",
            dataIndex: "id",
            className: "issueGrid__id",
            render: ({ id }) => <CellTitle title={id} />,
        },
        {
            label: t(IssueTranslation.AUDIT_CODE),
            sortedType: "default",
            dataIndex: "auditId",
            className: "issueGrid__auditCode",
            hidden: currentTab !== 3,
            render: ({ auditCode }) => <CellTitle title={auditCode} />,
        },
        {
            label: t(TranslationKeys.TITLE_ACTIVE),
            dataIndex: "asset.name",
            className: "issueGrid__asset",
            render: ({ assetName, fK_Asset }) => {
                const name = !!fK_Asset ? `${fK_Asset} - ${assetName}` : assetName;
                return <CellTitle title={name} />;
            },
            hidden: currentTab !== 1,
        },
        {
            label: t(TranslationKeys.ISSUE_DESCRIPTION),
            dataIndex: "description",
            className: `issueGrid__description`,
            render: ({ description }) => <CellTitle title={description} />,
        },
        {
            label: t(TranslationKeys.ISSUE_TYPE_TASK),
            dataIndex: "taskInstance.taskName",
            className: "issueGrid__asset",
            render: ({ taskInstanceName }) => <CellTitle title={taskInstanceName} />,
            hidden: currentTab !== 0,
        },
        {
            label: t(TranslationKeys.ISSUE_OPEN_DATE),
            dataIndex: "openedByUserId",
            sortedType: "default",
            className: "issueGrid__openDate",
            render: ({ openDate, openedByUserId, openedByUserName, openedByProfilePictureUrl }) => {
                const openDateFormated = getFullDateWithTimeReadable(convertUTCtoLocaleDate(openDate).toString());
                return (
                    <CellWithAvatar
                        badgeTitle={openDateFormated}
                        badgeVariant="grey"
                        avatarName={openedByUserName}
                        colorId={openedByUserId}
                        img={openedByProfilePictureUrl || ""}
                    />
                );
            },
        },
        {
            label: t(TranslationKeys.ASSET_TYPE_COLUMN),
            dataIndex: "fK_TaskInstance",
            sortedType: "default",
            className: "issueGrid__actualStatus",
            alignCenter: true,
            hidden: currentTab !== 1,
            render: ({ assetOrigin }) => {
                const variant: BadgeVariant = assetOrigin === "GENERIC" ? "green" : "blue";
                const title =
                    assetOrigin === "GENERIC"
                        ? t(TranslationKeys.ISSUE_TYPE_GENERIC)
                        : t(TranslationKeys.ISSUE_TYPE_TASK);

                return (
                    <div className="issueGrid__actualStatus__row">
                        <Badge variant={variant} title={title} />
                    </div>
                );
            },
        },
        {
            label: t(TranslationKeys.ISSUE_ACTUAL_STATUS),
            dataIndex: "actualStatus",
            sortedType: "default",
            className: "issueGrid__actualStatus",
            alignCenter: true,
            render: (record) => {
                const { actualStatus } = record;
                const variant: Record<OpenCloseStatusCode, BadgeVariant> = {
                    OPEN: "red",
                    CLOSED: "green",
                };
                const title: Record<OpenCloseStatusCode, string> = {
                    OPEN: t(TranslationKeys.ISSUE_STATUS_OPEN),
                    CLOSED: t(TranslationKeys.ISSUE_STATUS_CLOSE),
                };
                return (
                    <div className="issueGrid__actualStatus__row">
                        <Badge variant={variant[actualStatus]} title={title[actualStatus]} />
                    </div>
                );
            },
        },
        {
            label: t(TranslationKeys.ACTIONS),
            dataIndex: "edit",
            className: "issueGrid__edit",
            render: (record) => (
                <CellIcons
                    icons={[
                        {
                            icon: faPen,
                            onClick: () => nav(`edit/${record.id}`),
                            title: t(TranslationCommon.EDIT),
                        },
                    ]}
                />
            ),
            hidden: !hasPermission(SecScreen.ISSUE),
        },
    ];

    const handleSaveSecondaryFilter = (values: IssueGridSFModel) => {
        onSFChange(values);
        setIsSecondaryFilterOpen(false);
        getData({ pi: 0, issueSf: values });
        onChangePageIndex(1);
    };

    return (
        <PageContainer paddingTop>
            <IssueFilter
                filterData={secondaryFilterValues}
                initialFilterData={INITIAL_ISSUE_SF_VALUES}
                isOpen={isSecondaryFilterOpen}
                isSecondaryFilterOpen={isSecondaryFilterOpen}
                onCancel={() => setIsSecondaryFilterOpen(false)}
                onSave={handleSaveSecondaryFilter}
                currentTab={currentTabToIssueType(currentTab)}
                selectCompany={selectCompany}
            />
            <TableCollapsable
                cols={columns.filter((col) => !col.hidden)}
                data={data}
                placeholder={
                    <TableError
                        title={t(getIssueTableErrorCode(currentTabToIssueType(currentTab), issueStatusState))}
                        icon={faTriangleExclamation}
                    />
                }
                title={`${t(TranslationTitles.ISSUE_PAGE_TITLE)} (${selectCompany.company.label})`}
                subTitle={`${total} ${t(TranslationKeys.ISSUES)}`}
                isLoading={isLoading}
                pageIndex={pageIndex}
                pageSize={PaginationDefaults.PAGE_SIZE}
                total={total}
                onChangePageIndex={(pi) => {
                    onChangePageIndex(pi);
                    getData({ pi: pi - 1 });
                }}
                sortField={sortField}
                sortDirection={sortDirection}
                onChangeSortDirectionField={(sortFieldParam, sortedTypeParam) => {
                    handleSortChange(sortFieldParam, sortedTypeParam || INITIAL_ISSUE_SORT_DIRECTION);
                }}
                tabs={tabs}
                currentTab={currentTab}
                onDoubleClick={({ id }) => nav(`edit/${id}`)}
                actionButtons={[
                    {
                        icon: faMagnifyingGlass,
                        onClick: () => {
                            setShowSearchInput(true);
                        },
                        inputComponent: {
                            component: (
                                <div>
                                    <Input
                                        focus
                                        onChange={(value) => onSFChange({ query: value })}
                                        iconRight={!!query?.length ? faSearch : faClose}
                                        value={query}
                                        onClickIcon={handleCleanFetch}
                                        onPressEnter={onQuerySearch}
                                        whiteInput
                                        transparent
                                    />
                                </div>
                            ),
                            show: showSearchInput,
                        },
                        title: t(TranslationCommon.SEARCH),
                    },
                    {
                        icon: faFilter,
                        onClick: () => {
                            onCompanyChange({ isOpen: true });
                            setShowSearchInput(false);
                        },
                        inputComponent: {
                            component: (
                                <TableSelectCompany
                                    onChange={handleCompanyChange}
                                    value={selectCompany.company}
                                    onClickOutside={() => onCompanyChange({ isOpen: false })}
                                />
                            ),
                            show: selectCompany.isOpen,
                        },
                        title: translateCustomerTypeKeys(TranslationCommon.CHANGE_COMPANY, {
                            firstReplace: "SINGULAR",
                        }),
                    },
                    {
                        icon: faSliders,
                        onClick: () => setIsSecondaryFilterOpen(true),
                        showMarkableIcon: !isEqual(secondaryFilterValues, INITIAL_ISSUE_SF_VALUES),
                        title: t(TranslationCommon.FILTER),
                    },
                ]}
                mobileBody={(row, _i, rowPosition) => (
                    <MobileIssueRow
                        data={row}
                        key={row.id}
                        rowPosition={rowPosition}
                        onClick={() => nav(`edit/${row.id}`)}
                    />
                )}
                onClickExportCsv={handleExport}
            />
        </PageContainer>
    );
};
