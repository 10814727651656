import { DynamicFieldItem } from "../DynamicFieldItem/DynamicFieldItem";
import { DynamicTaskFieldOption } from "app/models/02-TAR/TaskWizard";
import { FC } from "react";
import { Taskfield2Error } from "app/models/02-TAR/Task/EditTask";
import { DynamicFieldDropdownItems } from "../DynamicFieldDropdownItems/DynamicFieldDropdownItems";

type DynamicFieldItemDropdownProps = {
    value: string;
    items: DynamicTaskFieldOption[];
    isRequired: boolean;
    id: string;
    hideAddNew: boolean;
    error?: Taskfield2Error;
    focus?: boolean;
    disabled?: boolean;
    onChange: (value: string) => void;
    onCheckboxChange: (value: boolean) => void;
    onDelete: () => void;
    onDeleteItem: (id: string) => void;
    onAddNewItem: () => void;
    onChangeItem: (id: string, value: string) => void;
};

export const DynamicFieldItemDropdown: FC<DynamicFieldItemDropdownProps> = ({
    id,
    isRequired,
    items,
    onAddNewItem,
    onChange,
    onChangeItem,
    onCheckboxChange,
    onDelete,
    onDeleteItem,
    value,
    hideAddNew,
    error,
    focus,
    disabled,
}) => {
    return (
        <div className="dynamicFieldItemDropdown">
            <DynamicFieldItem
                checked={isRequired}
                dynamicFieldType="DROPDOWN"
                id={id}
                onChange={onChange}
                onChangeCheckbox={onCheckboxChange}
                onDeleteItem={onDelete}
                value={value}
                errorMessage={error?.errorMessage}
                focus={focus}
                disabled={disabled}
            />
            <DynamicFieldDropdownItems
                hideAddNew={hideAddNew}
                items={items}
                onAddNewItem={onAddNewItem}
                onChangeItem={onChangeItem}
                onDeleteItem={onDeleteItem}
                disabled={disabled}
                error={error?.taskfieldOptions}
            />
        </div>
    );
};
