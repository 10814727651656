import { Label } from "app/components_v2/__inputs";
import { ReadOnlyInput } from "app/components_v2/__inputs/ReadOnlyInput/ReadOnlyInput";
import { Divider } from "app/components_v2/Divider/Divider";
import {
    TaskTemporalityDateRangeMonthlyFormModel,
    TaskTemporalityDateRangeMonthlyValue,
} from "app/models/02-TAR/TaskWizard";
import { TaskTemporalityDateRangeTranslations, TranslationKeys } from "app/translation/translationKeys";
import { FC } from "react";
import { useTranslation } from "react-i18next";

type TaskTemporalityDateRangeMonthlyDisabledProps = {
    monthly: TaskTemporalityDateRangeMonthlyFormModel;
    repeatEvery: string;
};

export const TaskTemporalityDateRangeMonthlyDisabled: FC<TaskTemporalityDateRangeMonthlyDisabledProps> = ({
    monthly,
    repeatEvery,
}) => {
    const { t } = useTranslation();
    const { value, day, customDays } = monthly;

    const repeatEveryNumber = Number(repeatEvery);
    const repeatEveryTitle =
        repeatEveryNumber === 1
            ? t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTHLY_REPEAT_EVERY_LABEL_SINGULAR)
            : t(
                  TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTHLY_REPEAT_EVERY_LABEL_PLURAL
              ).replace("{0}", repeatEvery);

    const dayNumber = Number(day);
    const frequencyFirstXDaysTitle =
        dayNumber === 1
            ? t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTHLY_FIRST_X_DAYS_LABEL_SINGULAR)
            : t(
                  TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTHLY_FIRST_X_DAYS_LABEL_PLURAL
              ).replace("{0}", day);
    const frequencyLastXDaysTitle =
        dayNumber === 1
            ? t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTHLY_LAST_X_DAYS_LABEL_SINGULAR)
            : t(
                  TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTHLY_LAST_X_DAYS_LABEL_PLURAL
              ).replace("{0}", day);
    const frequencyCustomDay = t(TaskTemporalityDateRangeTranslations.TASK_TEMPORALITY_DATE_RANGE_MONTHLY_CUSTOM_LABEL)
        .replace("{0}", customDays.startDay)
        .replace("{1}", customDays.endDay);
    const frequencyTitle: Record<TaskTemporalityDateRangeMonthlyValue, string> = {
        CUSTOM: frequencyCustomDay,
        FIRST_X_DAYS: frequencyFirstXDaysTitle,
        LAST_X_DAYS: frequencyLastXDaysTitle,
    };

    return (
        <div className="taskTemporalityDateRangeMonthlyDisabled">
            <Label label={t(TranslationKeys.REPEAT)} />
            <div className="taskTemporalityDateRangeMonthlyDisabled__label">
                <ReadOnlyInput value={repeatEveryTitle} options={{ width: "auto" }} />
            </div>
            <div className="taskTemporalityDateRangeMonthlyDisabled__label">
                <ReadOnlyInput value={frequencyTitle[value]} options={{ width: "auto" }} />
            </div>
        </div>
    );
};
