import { Switch } from "app/components_v2/__inputs";
import { ConfirmModal } from "app/components_v2/__modals/ConfirmModal/ConfirmModal";
import { IssueModal } from "app/components_v2/__modals/IssueModal/IssueModal";
import { useToast } from "app/hooks/Toast/useToast";
import AssetService from "app/services/05-QUA/AssetService";
import { IssueTypeCode } from "app/shared/Constants";
import { IssueModalContext } from "app/state/context/issueModalContext/issueModalContext";
import { AssetTranslations, TranslationCommon } from "app/translation/translationKeys";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

type AssetNotWorkingProps = {
    onChange: (isNotWorking: boolean) => void;
    isWorking: boolean;
    assetId: number;
    companyId: number;
    isDisabled?: boolean;
};

export const AssetNotWorking: FC<AssetNotWorkingProps> = ({ isWorking, onChange, assetId, companyId, isDisabled }) => {
    const { t } = useTranslation();
    const { fillValues } = useContext(IssueModalContext);
    const { handleToast } = useToast();

    const [isIssueModalAssetNotWorkingOpen, setIsIssueModalAssetNotWorkingOpen] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const openIssueModal = () => {
        if (!isWorking) {
            setShowConfirmModal(true);
            return;
        }

        fillValues({
            issueType: IssueTypeCode.TASK_ASSET,
            assetId,
            isAssetWorking: false,
            companyId,
        });
        setIsIssueModalAssetNotWorkingOpen(true);
    };

    const handleSubmitIssue = (isSubmitted: boolean) => {
        setIsIssueModalAssetNotWorkingOpen(false);
        if (!isSubmitted) return;

        onChange(true);
    };

    const handleIssueIsWorking = async () => {
        setIsSubmitting(true);
        const { status } = await AssetService.NotWorking(assetId, { value: false });

        if (!status()) {
            handleToast({ title: t(AssetTranslations.CANNOT_BE_REPAIRED), type: "alert", variant: "danger" });
            setIsSubmitting(false);
            return;
        }
        setIsSubmitting(false);
        setShowConfirmModal(false);
        onChange(false);
    };

    return (
        <>
            <div className="assetForm__container__notWorking">
                <Switch
                    checked={!isWorking}
                    onChange={openIssueModal}
                    label={t(AssetTranslations.IS_ASSET_NOT_WORKING)}
                    disabled={isDisabled}
                />
            </div>
            {isIssueModalAssetNotWorkingOpen && (
                <IssueModal onCloseModal={handleSubmitIssue} disableAsset variant="appcc" type="ASSET" />
            )}
            {showConfirmModal && (
                <ConfirmModal
                    title={t(AssetTranslations.ASSET_REPARATION_TITLE)}
                    description={t(AssetTranslations.ASSET_REPARATION_DESCRIPTION)}
                    onConfirm={handleIssueIsWorking}
                    onConfirmText={t(TranslationCommon.CONFIRM)}
                    onClose={() => setShowConfirmModal(false)}
                    onCloseText={t(TranslationCommon.CANCEL)}
                    isLoading={isSubmitting}
                    variant="appcc"
                />
            )}
        </>
    );
};
