import { AlertPillSection } from "./AlertPillSection/AlertPillSection";
import { AvatarSection } from "./AvatarSection/AvatarSection";
import { BadgeSection } from "./BadgeSection/BadgeSection";
import { ButtonsSection } from "./ButtonsSection/ButtonsSection";
import { CheckboxSection } from "./CheckboxSection/CheckboxSection";
import { ExportCSVModalSection } from "./ExportCSVModalSection/ExportCSVModalSection";
import { FilterButtonSection } from "./FilterButtonSection/FilterButtonSection";
import { InputsSection } from "./InputsSection/InputsSection";
import { LoaderSection } from "./LoaderSection/LoaderSection";
import { MobileFooterSection } from "./MobileFooterSection/MobileFooterSection";
import { ModalSection } from "./ModalSection/ModalSection";
import { NotificationSection } from "./NotificationSection/NotificationSection";
import { RadioSection } from "./RadioSection/RadioSection";
import { RoundedFilterButtonGroupSection } from "./RoundedFilterButtonGroupSection/RoundedFilterButtonGroupSection";
import { SecondaryFilterSection } from "./SecondaryFilterSection/SecondaryFilterSection";
import { SwitchSection } from "./SwitchSection/SwitchSection";
import { TasbSection } from "./TabSection/TasbSection";
import TableSection from "./TableSection/TableSection";
import { TableTaskPillSection } from "./TableTaskPillSection/TableTaskPillSection";
import { TagSection } from "./TagSection/TagSection";
import { TaskpillSection } from "./TaskpillSection/TaskpillSection";
import { ToastsSection } from "./ToastsSection/ToastsSection";
import { UploadPhotoSection } from "./UploadPhotoSection/UploadPhotoSection";
import { UserValorationSection } from "./UserValorationSection/UserValorationSection";
import WizardsSection from "./WizardsSection/WizardsSection";
import { WizardHeaderSection } from "./WizardHeaderSection/WizardHeaderSection";
import { WorkerPillSection } from "./WorkerPillSection/WorkerPillSection";
import { RankingPillSection } from "./RankingPillSection/RankingPillSection";
import { RankingPillCounterSection } from "./CompanyPillCounterSection/CompanyPillCounterSection";
import { HeaderAnalystSection } from "./HeaderAnalystSection/HeaderAnalystSection";
import { CriticalTaskSection } from "./CriticalTaskSection/CriticalTaskSection";
import { BreadCrumbsSection } from "./BreadCrumbsSection/BreadCrumbsSection";
import { SelectOutSideSection } from "./SelectOutSideSection/SelectOutSideSection";
import { LegendItemSection } from "./LegendItemSection/LegendItemSection";
import { ProgressBarSection } from "./ProgressBarSection/ProgressBarSection";
import { Section } from "./Section/Section";
import { WebcamSection } from "./WebcamSection";
import { PopoverSection } from "./PopoverSection/PopoverSection";
import { SelectWithActionsSection } from "./SelectWithActionsSection/SelectWithActionsSection";
import { TranscriptSection } from "./TranscriptSection/TranscriptSection";
import { CreateQrSection } from "./CreateQrSection/CreateQrSection";
import { BarChartSection } from "./BarChartSection/BarChartSection";
import { LineChartSection } from "./LineChartSection/LineChartSection";
import { OtpInputSection } from "./OtpInputSection/OtpInputSection";
import { SquareButtonSection } from "./SquareButtonSection/SquareButtonSection";
import { FullScreenModalSection } from "./FullScreenModalSection/FullScreenModalSection";
import { ChatBotGPTSection } from "./ChatBotGPTSection/ChatBotGPTSection";
import { AuditoRecorderSection } from "./AudioRecorderSection/AuditoRecorderSection";
import { EditImageSection } from "./EditImageSection/EditImageSection";
import { InputWithPopoverSection } from "./InputWithPopoverSection/InputWithPopoverSection";
import { DragFileSection } from "./DragFileSection/DragFileSection";
import { TextAreaSection } from "./TextAreaSection/TextAreaSection";

export const DesignSysComponent = () => {
    return (
        <div className="designSys">
            <AvatarSection show />
            <ButtonsSection />
            <TextAreaSection />
            <DragFileSection />
            <InputWithPopoverSection />
            <AuditoRecorderSection />
            <EditImageSection />
            <WebcamSection />
            <ChatBotGPTSection />
            <FullScreenModalSection />
            <SquareButtonSection />
            <OtpInputSection />
            <CreateQrSection />
            <TranscriptSection />
            <PopoverSection />
            <Section title="FORM COMPONENTS">
                <InputsSection />
                <SelectOutSideSection />
                <SelectWithActionsSection />
                <SwitchSection />
                <RadioSection />
                <CheckboxSection />
            </Section>
            <ToastsSection />
            <Section title="MODALS">
                <ModalSection />
                <ExportCSVModalSection />
                <WizardsSection />
                <WizardHeaderSection />
                <SecondaryFilterSection />
            </Section>
            <BadgeSection />
            <Section title="TABLES">
                <TableSection />
                <TableTaskPillSection />
            </Section>
            <Section title="PILLS">
                <RankingPillCounterSection />
                <RankingPillSection />
                <TaskpillSection />
                <WorkerPillSection />
                <AlertPillSection />
                <NotificationSection />
            </Section>
            <Section title="ESTADISTICS">
                <BarChartSection />
                <LineChartSection />
                <LegendItemSection />
                <ProgressBarSection />
                <UserValorationSection />
                <HeaderAnalystSection />
            </Section>
            <Section title="OTROS">
                <TasbSection />
                <BreadCrumbsSection />
                <TagSection />
                <RoundedFilterButtonGroupSection />
                <LoaderSection />
                <CriticalTaskSection />
                <MobileFooterSection />
                <UploadPhotoSection />
                <FilterButtonSection />
            </Section>
        </div>
    );
};
