import { AssetDynamicFieldModel, AssetModelFormValues } from "app/models/05-QUA/AssetModels";
import { AssetFieldInsertModel } from "app/models/05-QUA/AssetModels/AssetFieldModel";
import { AssetTypeModel } from "app/models/05-QUA/AssetModels/AssetTypeModel";
import { AssetTypeOption } from "app/shared/Constants";
import { AssetTypeOptionModel } from "app/models/05-QUA/AssetModels/AssetTypeOptionModel";
import { AssetTypeTranslations, TranslationErrors } from "app/translation/translationKeys";
import { getInitials } from "app/helpers/getInitials";
import { OptionModel } from "app/models/02-TAR/OptionModel";
import { useCustomerTypeTranslation } from "app/hooks/CustomerType/useCustomerTypeTranslation";
import { useEffect, useState } from "react";
import { useFetchErrors } from "app/hooks/useFetchErrors";
import { useSession } from "app/hooks";
import { useToast } from "app/hooks/Toast/useToast";
import { v4 } from "uuid";
import AssetTypeService from "app/services/05-QUA/AssetTypeService";
import QuaSelectorService from "app/services/05-QUA/QuaSelectorService";
import SegSelectorService from "app/services/01-SEG/SegSelectorService";
import { useTranslation } from "react-i18next";

type IUseAssetFormInfo = {
    isEditPage: boolean;
    onInputChange: (fields: Partial<AssetModelFormValues>) => void;
    assetFields: AssetFieldInsertModel[];
};

type GroupedAssetTypeRange = {
    description: string;
    min: number;
    max: number;
    type: string;
    assetTypeOptions: AssetTypeOptionModel[];
};

type GroupedAssetType = {
    id: number;
    name: string;
    defaultRanges: GroupedAssetTypeRange[];
};

export const useAssetFormInfo = ({ assetFields, isEditPage, onInputChange }: IUseAssetFormInfo) => {
    const { t } = useTranslation();

    const session = useSession();
    const { handleToast } = useToast();
    const { getErrorMessage } = useFetchErrors();
    const { translateCustomerTypeKeys } = useCustomerTypeTranslation();

    const [companyOptions, setCompanyOptions] = useState<OptionModel[]>([]);
    const [iscompanyLoading, setIscompanyLoading] = useState<boolean>(true);

    const [assetTypeOptions, setAssetTypeOptions] = useState<AssetTypeModel[]>([]);
    const [isAssetTypeLoading, setIsAssetTypeLoading] = useState<boolean>(true);

    const [planAPPCCOptions, setPlanAPPCCOptions] = useState<OptionModel[]>([]);
    const [isPlanLoading, setIsPlanLoading] = useState<boolean>(true);

    const getCompany = async () => {
        setIscompanyLoading(true);
        const { status, data } = await SegSelectorService.GetCompanyNamesWithIds({
            extraParams: `SupervisorId=${session?.user.id}`,
        });

        if (!status()) {
            setCompanyOptions([]);
            handleToast({
                title: getErrorMessage(
                    translateCustomerTypeKeys(TranslationErrors.COMPANIES_FAILED_LOAD_DATA, {
                        firstReplace: "PLURAL",
                        isGenderSensitive: true,
                    })
                ),
                type: "alert",
                variant: "danger",
            });
            setIscompanyLoading(false);
            return;
        }

        setCompanyOptions(
            data.map((data) => ({
                ...data,
                initials: getInitials(data.label),
            }))
        );
        setIscompanyLoading(false);
    };

    const getAssetType = async () => {
        setIsAssetTypeLoading(true);
        const { data, status, getParsedError } = await AssetTypeService.GetList();
        if (!status()) {
            setAssetTypeOptions([]);
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsAssetTypeLoading(false);
            return;
        }
        setAssetTypeOptions(data);
        setIsAssetTypeLoading(false);
    };

    const getPlans = async () => {
        setIsPlanLoading(true);
        const { data, status, getParsedError } = await QuaSelectorService.GetPlansAPPCC();
        if (!status()) {
            setAssetTypeOptions([]);
            handleToast({
                title: getErrorMessage(getParsedError()),
                type: "alert",
                variant: "danger",
            });
            setIsPlanLoading(false);
            return;
        }
        setPlanAPPCCOptions(data);
        setIsPlanLoading(false);
    };

    const transformAndGroupByName = (assetTypeOptions: AssetTypeModel[]): GroupedAssetType[] => {
        const grouped: Record<string, GroupedAssetType> = {};

        assetTypeOptions.forEach(({ description, id, max, min, name, type, assetTypeOptions }) => {
            const range: GroupedAssetTypeRange = {
                description: description,
                min: min ?? 0,
                max: max ?? 0,
                type: type,
                assetTypeOptions,
            };
            if (!grouped[name])
                grouped[name] = {
                    id: id,
                    name: name,
                    defaultRanges: [range],
                };
            else grouped[name].defaultRanges.push(range);
        });

        return Object.values(grouped);
    };

    const onChangeAssetType = ({ value }: OptionModel) => {
        const newAssetTypeId = Number(value);

        const selectedAssetType = assetTypeOptions.find(({ id }) => id === newAssetTypeId);
        if (!selectedAssetType) return;

        const { name } = selectedAssetType;

        if (name === AssetTypeTranslations.OTHER_OPTION) {
            onInputChange({ fK_AssetType: newAssetTypeId });
            return;
        }

        const groupedAssetTypeOptions = transformAndGroupByName(assetTypeOptions);
        const selectedGroupedAssetType = groupedAssetTypeOptions.find((item) => item.id === selectedAssetType.id);

        const defaultAssetFields: AssetFieldInsertModel[] =
            selectedGroupedAssetType?.defaultRanges.map(({ description, max, min, type, assetTypeOptions }) => {
                return {
                    id: v4(),
                    isDeleted: false,
                    assetDynamicField: type === "STRING" ? "TEXT" : (type as AssetDynamicFieldModel),
                    assetFieldRange:
                        type === "NUMBER"
                            ? {
                                  fK_MeasurementUnit: 0,
                                  id: 0,
                                  max: max || 0,
                                  min: min || 0,
                                  unit: "",
                              }
                            : null,
                    label: t(description),
                    isHidden: false,
                    isRequired: true,
                    assetFieldOption:
                        type === AssetTypeOption.DROPDOWN
                            ? assetTypeOptions.map(({ isDeleted, name }) => ({
                                  dbId: null,
                                  fK_AssetField: 0,
                                  id: v4(),
                                  isDeleted,
                                  name,
                              }))
                            : [],
                };
            }) ?? [];

        onInputChange({
            fK_AssetType: newAssetTypeId,
            assetFields: !!assetFields.length ? (isEditPage ? assetFields : defaultAssetFields) : defaultAssetFields,
        });
    };

    useEffect(() => {
        getCompany();
        getAssetType();
        getPlans();
    }, []);

    return {
        companyOptions,
        iscompanyLoading,
        groupedAssetTypeOptions: transformAndGroupByName(assetTypeOptions),
        isAssetTypeLoading,
        planAPPCCOptions,
        isPlanLoading,
        onChangeAssetType,
    };
};
